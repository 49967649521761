import { createSelector } from 'reselect'

const getSettingsSlice = (state) => state.settings;
const getUserSlice = (state) => state.users;

export const getUserLoading = createSelector(
  [getUserSlice],
  (users) => {
    return users.loadingProfile
  }
)

export const getUserInfo = createSelector(
  [getUserSlice],
  (users) => {
    return users.userInfo || {}
  }
)
export const getIsStaff = createSelector(
  [getUserInfo],
  (userInfo) => {
    return userInfo ? userInfo.is_staff : false;
  }
)
export const getAdminDentalPractice = createSelector(
  [getSettingsSlice],
  (settings) => {
    return settings.adminCurrentDentalPractice 
      ? settings.adminCurrentDentalPractice || settings.adminCurrentDentalPractice.value
      : null;
  }
)
