import React, { useEffect } from 'react';
import { Button, Image, Text } from '@chakra-ui/react';
import { customAlphabet } from 'nanoid';
import storage from 'js/_helpers/storage';
import lineIcon from '../../../images/line.png';
import { useQuerySearchParams } from 'js/_hooks/useSearchParams';
import axios from 'axios';
import qs from 'qs';
import jwt from 'jsonwebtoken';
const maxAge = 120;

// documentation: https://developers.line.biz/en/docs/line-login/integrate-line-login/
// button guidelines: https://developers.line.biz/en/docs/line-login/login-button/#design-guidelines

export const LineLogin = ({
  clientID,
  clientSecret,
  state,
  scope,
  locale,
  setPayload = null,
  setIdToken,
  redirectURI,
  isLoading,
  text,
}) => {
  const queryParams = useQuerySearchParams();
  const lineLogin = () => {
    // random generator
    const nanoid = customAlphabet(
      'useandom26T198340PX75pxJACKVERYMINDBUSHWOLFGQZbfghjklqvwyzrict',
      15
    );
    const nonce = nanoid();
    storage.setLineNonce(nonce);
    // Build query string.
    const query = new URLSearchParams();
    query.set('response_type', 'code');
    query.set('client_id', clientID);
    query.set('state', state);
    query.set('scope', scope);
    query.set('nonce', nonce);
    query.set('ui_locales', locale);
    query.set('prompt', 'consent');
    query.set('max_age', maxAge);
    query.set('bot_prompt', 'normal');
    //query.set('initial_amr_display', 'lineqr');
    query.set('bot_prompt', 'aggressive');

    // Build the Line authorise URL.
    const lineAuthoriseURL =
      'https://access.line.me/oauth2/v2.1/authorize?' +
      query.toString() +
      '&redirect_uri=' +
      redirectURI;
    // Redirect to external URL.
    window.location.href = lineAuthoriseURL;
  };

  const getAccessToken = () => {
    var hasCodeProperty = queryParams.has('code');
    if (hasCodeProperty) {
      const reqBody = {
        grant_type: 'authorization_code',
        code: queryParams.get('code'),
        redirect_uri: redirectURI,
        client_id: clientID,
        client_secret: clientSecret,
      };
      const reqConfig = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };

      axios
        .post('https://api.line.me/oauth2/v2.1/token', qs.stringify(reqBody), reqConfig)
        .then((res) => {
          if (setPayload)
            // set payload will give you access_token, but nothing else, setIdToken will give profile info, email, etc.
            setPayload({
              ...res.data,
              friendship_status_changed: queryParams.get('friendship_status_changed'),
            });

          try {
            const decodedIdToken = jwt.verify(res.data.id_token, clientSecret, {
              algorithms: ['HS256'],
              audience: clientID.toString(),
              issuer: 'https://access.line.me',
              nonce: storage.getLineNonce(),
            });

            if (setIdToken)
              setIdToken({
                ...res.data,
                ...decodedIdToken,
                friendship_status_changed: queryParams.get('friendship_status_changed'),
                access_token: res.data.access_token,
              });
          } catch (err) {
            // If token is invalid.
            console.log(err);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    // check if page is from redirected line login
    if (queryParams.get('code')) {
      getAccessToken(window.location.href);
    }
  }, [clientID]);
  // press #06C7554D
  return (
    <Button
      backgroundColor={'#06C755'}
      leftIcon={<Image height="40px" src={lineIcon} />}
      _hover={{
        backgroundImage: 'linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.1))',
        backgroundColor: '#06C755',
      }}
      _active={{
        backgroundImage: 'linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3))',
        backgroundColor: '#06C755',
      }}
      _disabled={{
        bg: '#FFFFFF',
        color: '#1E1E1E33',
        border: '1px solid #E5E5E599',
      }}
      color="white"
      isLoading={isLoading}
      width="100%"
      borderRadius="10px"
      height="50px"
      onClick={() => lineLogin()}
    >
      <Text ml={2}>{text}</Text>
    </Button>
  );
};
