import { activityLogConstants } from '../_constants';

const initState = {
  activityLog: [],
  isLoading: false,
}

export function activityLog(state = initState, action) {
  switch (action.type) {
    case activityLogConstants.GET_ACTIVITYLOG_REQUEST:
      return {
        isLoading: true
      }
    case activityLogConstants.GET_ACTIVITYLOG_SUCCESS:
      // dependent on after or before

      return {
        isLoading: false,
        activityLog: action.data.activity_log
      }
    case activityLogConstants.GET_ACTIVITYLOG_FAILURE:
      return {
        isLoading: false
      }

    default:
      return state
  }
}
