import create from 'zustand';
import produce from 'immer';

// Log every time state is changed
const log = config => (set, get, api) => config(args => {
  console.log("  applying", args)
  set(args)
  console.log("  new state", get())
}, get, api)

// Turn the set method into an immer proxy
const immer = config => (set, get, api) => config((partial, replace) => {
  const nextState = typeof partial === 'function'
      ? produce(partial)
      : partial
  return set(nextState, replace)
}, get, api)

export const useAdminClinicStore = create(
  
  log(
    immer((set) => ({
      dpid: null,
      adminClinic: null,
      setAdminClinic: (item) =>
        set((state) => ({
            ...state,
            dpid: item?.data?.id,
            adminClinic: item
        })),
      clearAdminClinic: () =>
        set((state) => ({
            ...state,
            dpid: null,
            adminClinic: null
        })),
      setAdminClinicWithPractice: (item) =>
        set((state) => ({
            ...state,
            dpid: item?.id,
            adminClinic: {
              value: item?.id,
              label: item?.practice_name,
              data: item
            }
        }))
    }))
  )
);