import { BASE_URL } from 'js/_config';
import { useState } from 'react';
import { useHistory, useLocation, useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useNavigatorLanguage } from 'js/_hooks/useNavigatorLanguage';
import { Flex, Box, Stack, Image, Text, FormErrorMessage, Link as ChakraLink } from '@chakra-ui/react';
import { ButtonFilled } from 'js/_components/Button/ButtonFilled';


import { GOOGLE_CLIENT_ID } from 'js/_config';
import { useAuth } from '../_libs/auth';
import { useNotificationStore } from 'js/Notifications/store';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { LineLogin } from 'js/_components/LineLogin'
import { FormattedMessage, useIntl } from 'react-intl';
import { getIntlLocale } from '../Intl';
import { isBlank } from 'js/_helpers/utils';
import { nanoid } from 'nanoid';
import logo from '../../images/logo.svg';
import { LoginEmailFormContainer } from './LoginEmailFormContainer';

import { FORM_ERROR } from "final-form";
const FORM_WIDTH = 350;

export const LoginAdminForm = () => {
  const [ page, setPage ] = useState(0);
  const { language, setNavigatorLanguage } = useNavigatorLanguage();
  const intlLocale = useSelector(getIntlLocale);

  const location = useLocation();
  const history = useHistory();
  const urlParams = useParams();
  const { formatMessage } = useIntl();
  const { login, isLoggingIn } = useAuth();

  const [ isGoogleLoading, setGoogleLoading ] = useState(false);
  const [ googleError, setGoogleError ] = useState(false);
  const [ generalFormError, setGeneralFormError ] = useState(false);

  const state = nanoid();
  console.log("urlParams ", urlParams.login_type)

  const onSuccessfulLogin = (from) => {
    if (from && from.includes('/login')){
      history.push(location.state.from.pathname)
    } else {
      history.push('/');
    }
  }

  const setIdToken = async (data) => {
    console.log('setIdToken ', data);
    let from = location?.state?.from?.pathname
    /*if (isBlank(data.email)) {
      useNotificationStore.getState().addNotification({
        type: 'error',
        title: formatMessage({
          id: 'user.linelogin.failed.title',
          description: 'user line login failed title',
          defaultMessage: 'LINE เข้าสู่ระบบ',
        }),
        message: formatMessage({
          id: 'user.linelogin.failed.email.message',
          description: 'user line login email field not given',
          defaultMessage: 'ไม่ได้ระบุช่องอีเมล',
        }),
      });
      return;
    }*/
    const authCredentials = {
      code: data.access_token,
      user_id: data.sub,
      nonce: data.nonce,
      id_token: data.id_token,
      provider: 'line',
      friendship_status_changed: data.friendship_status_changed,
    };
    try{
      await login(authCredentials);
      onSuccessfulLogin(from);
    } catch(err){
      return {
        ...err,
        [FORM_ERROR] : 'general error'
      }
    }
  };

  
  const handleLoginGoogle = async (response) => {
    let from = location?.state?.from?.pathname
    setGoogleLoading(true);
    setGoogleError(false);
    // NOT THIS ONE const access_tok = response.accessToken;
    const tok_id = response.credential //THIS ONE
    if (!tok_id){
      setGoogleError('google error')
      setGoogleLoading(false);
      return
    }
    try{
      const authCredentials = { code: tok_id, provider: 'google'};
      await login(authCredentials);
      onSuccessfulLogin(from);
    } catch (err){
      setGeneralFormError('general error')
    } finally {
      setGoogleLoading(false);
    }
  }

  if (page === 1){
    return(
      <>
        <Flex justify={'center'} grow={1} w={FORM_WIDTH}>
          <LoginEmailFormContainer onBack={() => setPage(0)} />
        </Flex>
      </>
    )
  } 

  console.log("urlParams.login_type ", urlParams.login_type)
  
  if (urlParams.login_type === 'admin'){
    console.log("admin type page")
    return(
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <Flex align={'center'} direction={'column'} justify={'center'} w={FORM_WIDTH} grow={1}>
          <Box>
            <Flex justify={'center'}>
              <Text color={'#7B8887'} fontWeight={'600'} fontSize={'20px'}>
                {'WELCOME TO'}
              </Text>
            </Flex>
            <Image
              mt={8}
              w="380px"
              src={logo}
            />
          </Box>
          <Flex direction={'column'} mt={16} w={FORM_WIDTH}>
            <Stack spacing={6}>
              <ButtonFilled
                bg={`brandLight`}
                minW={'100%'}
                py={10}
                onClick={() => setPage(1  )}
              >
              <FormattedMessage 
                  id={"loginForm.loginWithEmailAddress"}
                  defaultMessage={"Login with Email Address"}
              />
              </ButtonFilled>
              <Flex 
                  w={'100%'} 
                  mt={[6, 4]} mx={'auto'} 
                  align="center" direction="column"
                  pointerEvents={'all'}
                  >
                  <GoogleLogin
                    shape={'pill'}
                    size={'large'}
                    logo_alignment={'left'}
                    width={FORM_WIDTH}
                    height={'50px'}
                    theme={'filled_blue'}
                    locale={intlLocale}
                    onSuccess={handleLoginGoogle}
                    onError={(err) => {
                      setGoogleError('error')
                    }}
                  />
                  <div>
                    <FormErrorMessage>
                      { googleError ? googleError : ''
                      }
                    </FormErrorMessage>
                  </div>
                </Flex>

              <ChakraLink textAlign={'center'} as={Link} to={ '/admin/login'} mt={['10px']}>
                <Text>
                  <FormattedMessage 
                    id={"loginForm.otherLoginOptions"}
                    defaultMessage={"Other Login Options"}
                  />
                </Text>
              </ChakraLink>
              
            </Stack>
          </Flex>
        </Flex>
      </GoogleOAuthProvider>
    )
  } else {
    return(
        <Flex align={'center'} direction={'column'} justify={'center'} w={FORM_WIDTH} grow={1}>
          <Box>
            <Flex justify={'center'}>
              <Text color={'#7B8887'} fontWeight={'600'} fontSize={'20px'}>
                {'WELCOME TO'}
              </Text>
            </Flex>
            <Image
              mt={8}
              w="380px"
              src={logo}
            />
          </Box>
          <Flex direction={'column'} mt={16} w={FORM_WIDTH}>
            <Stack spacing={6}>
              <ButtonFilled
                bg={`brandLight`}
                minW={'100%'}
                py={10}
                onClick={() => setPage(1  )}
              >
                <FormattedMessage 
                    id={"loginForm.loginWithEmailAddress"}
                    defaultMessage={"Login with Email Address"}
                />
              </ButtonFilled>
               {/* <LineLogin
                  clientID={`${process.env.REACT_APP_LINE_ID}`}
                  clientSecret={`${process.env.REACT_APP_LINE_KEY}`}
                  state={state}
                  scope={'profile openid email'}
                  setIdToken={setIdToken}
                  locale={language}
                  redirectURI={`${BASE_URL}/auth/login/${language}`}
                  isLoading={isLoggingIn}
                  text={formatMessage({'id': 'line login label', 'description': 'line login label', 'defaultMessage':'LINE Login' })}
                /> */}
              <ChakraLink textAlign={'center'} as={Link} to={ '/admin/login/admin' } mt={['10px']}>
                <Text>
                  <FormattedMessage 
                    id={"loginForm.otherLoginOptions"}
                    defaultMessage={"Other Login Options"}
                  />
                </Text>
              </ChakraLink>
              
            </Stack>
          </Flex>
        </Flex>
    )
  }
}