import { activityNotificationsActions } from './actions';
import {
  start,
  stop,
  reset,
  request,
  markRead,
  addEntries,
  addPreviousEntries
} from './logic';

/**
 * middleware ( ActivityNotifications )
 * sets up actions for reducer to follow up on
 *
 */

export default store => next => (action) => {
    const { actionTypes, isPollingAction } = activityNotificationsActions;
    if (!isPollingAction(action)) {
        return next(action);
    }

    switch (action.type) {
        // TODO: add additional actions... such as mark read.
        case actionTypes.start: return start(store, action, next);
        case actionTypes.stop: return stop(store, action, next);
        case actionTypes.reset: return reset(store, action, next);
        case actionTypes.request: return request(store, action, next);
        case actionTypes.addEntries: return addEntries(store, action, next);
        case actionTypes.markRead: return markRead(store, action, next);
        default: return false;
    }
};
