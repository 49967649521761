import { MOMENT_DATE_FORMAT, API_DATE_FORMAT } from '../_config';
import { mapValues } from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import {localeFormatDate} from './date';

var stringToColor = function(str) {
  if (!str){
    return 'gray'
  }
  var ind = 'abcdefghijklmnopqrstuvwxyz'.indexOf(str[0].toLowerCase())
  var colors = ['gray', 'red', 'orange', 'yellow', 'green', 'teal', 'blue', 'cyan', 'purple', 'pink']
  return colors[ind % colors.length]
}

var stringToRandomColor = function(str){
  var hash = 0;

  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = '#';
  for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}

function isBlank(str) {
  return (!str || /^\s*$/.test(str));
}

function isNew(data, user){
  if (user.is_staff === true){
    // 3 or 0
    if ((data.status === 3) || (data.status === 0)) {
      return true;
    } else {
      return false;
    }
  } else {
    // 2
    if ( (data.status === 0) || (data.status === 2)){
      return true;
    } else {
      return false;
    }
  }
}

function showLongText(text){
  if (typeof text === "string"){
    return (text.length > 30) ? text.substring(0,30) + "..." : text ;
  }
}

function getFullNameString(nameFormatString, firstName, lastName){
  return nameFormatString.replace('{givenName}', firstName).replace('{familyName}', lastName)
}

function formatPatientSearchString(nameFormatString, firstName, lastName, birthdate, locale){
  return nameFormatString.replace('{givenName}', firstName)
                          .replace('{familyName}', lastName)
                          .replace('{birthdate}',localeFormatDate(birthdate, locale))
}

function extractSelectValues(values){
  return mapValues(values, (obj)=>{
    if(obj.value === undefined){
      return obj
    }  else {
      return obj.value
    }
  })
}

function convertToAPIDate(values) {
  return mapValues(values, (obj)=>{
    if(typeof obj.value === undefined){
      return obj
    }  else {
      return obj.value
    }
  })
}

function convertToAPIValues(values, locale){
  return mapValues(values, (obj)=>{
    if (moment.isMoment(obj)){
      return obj.format(API_DATE_FORMAT)
    }
    if (moment.isDate(obj)){
      return moment(obj).format(API_DATE_FORMAT)
    }
    if (obj == null){
      return obj
    }
    console.log("obj", obj)
    if (obj.hasOwnProperty("value")){
      return obj.value;
    } 
    if (obj.hasOwnProperty("id")){
      return obj.id
    }
    return obj
  })
}

export {isNew, isBlank, stringToColor, getFullNameString, extractSelectValues, convertToAPIDate, formatPatientSearchString, showLongText, convertToAPIValues};
