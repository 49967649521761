import { settingsConstants } from '../_constants';
import { settingsService } from '../_services';
import localeData from '../../locales/data.json';
import { updateIntl } from 'react-intl-redux';

export const settingsActions = {
    preloadFormData,
    getProviders,
    getPatients,
    getOperatories,
    doSearch,
    getDentalAccounts,
    setSettingValue,

    getFeeSchedules,
    getCoverageTables,
    getInsuranceClaim,
    getProcedureCodes,
    getLedgerOptions,
    getPatientCommunications,
    getPrintSettings,
    getRecare,
    loadConfig,
    setLanguage,
    setVersioning
};

function loadConfig() {
	return dispatch => {
		dispatch(request());
		settingsService.loadConfig()
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};
    function request() { return { type: settingsConstants.GET_CONFIGDATA_REQUEST } }
    function success(data) { return { type: settingsConstants.GET_CONFIGDATA_SUCCESS, data } }
    function failure(error) { return { type: settingsConstants.GET_CONFIGDATA_FAILURE, error } }
}

function setVersioning(ver, lang){
  return dispatch => {
    // filter lang
    let splitLang = lang.split(',');
    localStorage.lang = splitLang[0].toLowerCase();
    localStorage.version = ver;
    dispatch(updateIntl({locale: lang, messages: localeData[lang]}));
  }
}

function setLanguage(language){
  return dispatch => {
    localStorage.lang = language;
    dispatch(updateIntl({locale: language, messages: localeData[language]}));
  }
}

function setSettingValue(data) {
  return dispatch => {
    dispatch(set(data));
  }
  function set(data) {  return { type: settingsConstants.SET_SETTINGVALUE, data}}
}

function preloadFormData() {
	return dispatch => {
		dispatch(request());
		settingsService.preloadFormData()
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: settingsConstants.GET_FORMDATA_REQUEST } }
    function success(data) { return { type: settingsConstants.GET_FORMDATA_SUCCESS, data }   }
    function failure(error) { return { type: settingsConstants.GET_FORMDATA_FAILURE, error } }
};

function getDentalAccounts() {
	return dispatch => {
		dispatch(request());
		settingsService.getDentalAccounts()
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: settingsConstants.GET_DENTALACCOUNTS_REQUEST } }
    function success(data) { return { type: settingsConstants.GET_DENTALACCOUNTS_SUCCESS, data }   }
    function failure(error) { return { type: settingsConstants.GET_DENTALACCOUNTS_FAILURE, error } }
}

function getProviders() {
	return dispatch => {
		dispatch(request());
		settingsService.getProviders()
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: settingsConstants.GET_PROVIDERS_REQUEST } }
    function success(data) { return { type: settingsConstants.GET_PROVIDERS_SUCCESS, data }   }
    function failure(error) { return { type: settingsConstants.GET_PROVIDERS_FAILURE, error } }
};

function getOperatories() {
	return dispatch => {
		dispatch(request());
		settingsService.getOperatories()
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: settingsConstants.GET_OPERATORIES_REQUEST } }
    function success(data) { return { type: settingsConstants.GET_OPERATORIES_SUCCESS, data }   }
    function failure(error) { return { type: settingsConstants.GET_OPERATORIES_FAILURE, error } }
};

function getPatients() {
	return dispatch => {
		dispatch(request());
		settingsService.getPatients()
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: settingsConstants.GET_PATIENTS_REQUEST } }
    function success(data) { return { type: settingsConstants.GET_PATIENTS_SUCCESS, data }   }
    function failure(error) { return { type: settingsConstants.GET_PATIENTS_FAILURE, error } }
};

function getFeeSchedules() {
	return dispatch => {
		dispatch(request());
		settingsService.getFeeSchedules()
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: settingsConstants.GET_PATIENTS_REQUEST } }
    function success(data) { return { type: settingsConstants.GET_PATIENTS_SUCCESS, data }   }
    function failure(error) { return { type: settingsConstants.GET_PATIENTS_FAILURE, error } }
};

function getCoverageTables() {
	return dispatch => {
		dispatch(request());
		settingsService.getCoverageTables()
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: settingsConstants.GET_PATIENTS_REQUEST } }
    function success(data) { return { type: settingsConstants.GET_PATIENTS_SUCCESS, data }   }
    function failure(error) { return { type: settingsConstants.GET_PATIENTS_FAILURE, error } }
};

function getInsuranceClaim() {
	return dispatch => {
		dispatch(request());
		settingsService.getInsuranceClaim()
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: settingsConstants.GET_INSURANCECLAIM_REQUEST } }
    function success(data) { return { type: settingsConstants.GET_INSURANCECLAIM_SUCCESS, data }   }
    function failure(error) { return { type: settingsConstants.GET_INSURANCECLAIM_FAILURE, error } }
};

function getProcedureCodes() {
	return dispatch => {
		dispatch(request());
		settingsService.getProcedureCodes()
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: settingsConstants.GET_PROCEDURES_REQUEST } }
    function success(data) { return { type: settingsConstants.GET_PROCEDURES_SUCCESS, data }   }
    function failure(error) { return { type: settingsConstants.GET_PROCEDURES_FAILURE, error } }
};

function getLedgerOptions(){
	return dispatch => {
		dispatch(request());
		settingsService.getLedgerOptions()
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: settingsConstants.GET_LEDGEROPTIONS_REQUEST } }
    function success(data) { return { type: settingsConstants.GET_LEDGEROPTIONS_SUCCESS, data }   }
    function failure(error) { return { type: settingsConstants.GET_LEDGEROPTIONS_FAILURE, error } }
};

function getPatientCommunications(){
	return dispatch => {
		dispatch(request());
		settingsService.getLedgerOptions()
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: settingsConstants.GET_PATIENTCOMMUNICATIONS_REQUEST } }
    function success(data) { return { type: settingsConstants.GET_PATIENTCOMMUNICATIONS_SUCCESS, data }   }
    function failure(error) { return { type: settingsConstants.GET_PATIENTCOMMUNICATIONS_FAILURE, error } }
};

function getPrintSettings(){
	return dispatch => {
		dispatch(request());
		settingsService.getPrintSettings()
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: settingsConstants.GET_PRINTSETTINGS_REQUEST } }
    function success(data) { return { type: settingsConstants.GET_PRINTSETTINGS_SUCCESS, data }   }
    function failure(error) { return { type: settingsConstants.GET_PRINTSETTINGS_FAILURE, error } }
};

function getRecare(){
	return dispatch => {
		dispatch(request());
		settingsService.getRecare()
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: settingsConstants.GET_RECARE_REQUEST } }
    function success(data) { return { type: settingsConstants.GET_RECARE_SUCCESS, data }   }
    function failure(error) { return { type: settingsConstants.GET_RECARE_FAILURE, error } }
};

function doSearch(query) {
	//dispatch(request());
	settingsService.doSearch(query)
			.then(
				data => success(data),
				error => failure(error)
			);

    //function request() { return { type: settingsConstants.GET_SEARCH_REQUEST } }
    function success(data) {
    	console.log(data, "success");
    	return { options: data.search_list }
    }
    function failure(error) { return { options: []} }
};
