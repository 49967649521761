import { patientConstants } from '../_constants';
import moment from 'moment';

/*
 * Update state for state.scheduling here...
 * have to copy state then return it
 * sets entire state, doesn't update just one field
 * shortcuts: https://daveceddia.com/immutable-updates-react-redux/
 *
 * init {appointments: [], dentistList: [], }
 *
 */
const initChart = {
	1: [],
	2: [],
	3: [],
	4: [],
	5: [],
	6: [],
	7: [],
	8: [],
	9: [],
	10: [],
	11: [],
	12: [],
	13: [],
	14: [],
	15: [],
	16: [],
	17: [],
	18: [],
	19: [],
	20: [],
	21: [],
	22: [],
	23: [],
	24: [],
	25: [],
	26: [],
	27: [],
	28: [],
	29: [],
	30: [],
	31: [],
	32: []
}

/*

	1-

*/

const initChatBox = {
	activeChatBoxMessages: [],
	activeChatBoxDetails: {},
	activeChatBoxTitle: "",
	activeChatBox: null,
	activeChatLastRetrieved: null

}

const initInvoiceForm = []

const initProcForm = {
    patient: "",
    tooth: "",
    surface: "",
    procedure: "",
    condition: "",
    dentist: "",
    notes: "",
    status: "",
    cost: 0,
    insurance_coverage: 0
}

const initInsuranceForm = {
	id: null,
	type: "DENTAL",
	status: "ACTIVE",
	carrier_name:"",
	carrier_id:"",
	group_number:"",
	address:"",
	subscriber_id:"",
	employer_name:"",
	employer_id:"",
	plan_number:"",
	policyholder:"",
	patient: "",
	policyholder_relationship: "SELF"
}

const initPatientForm = {
    id: null,
    first_name: "",
    middle_name: "",
    last_name: "",
    gender: "",
    date_of_birth: "",
    phone_number: "",
    email_address: "",
    social_no: "",
    address: "",
    license_number: "",
    marital_status: "",
    medical_notes: "",
    econtact_first_name: "",
    econtact_last_name: "",
    econtact_name: "",
    econtact_relation: "",
    econtact_address: "",
    econtact_phone_number: "",
    main_provider: null
}

const initPatientLedgerForm = {
	ledger_type: "",
	payment_from: "",
	payment_type: "",
	amount: 0,
	invoice: null
}

const initNoteForm = {
	patient: "",
	tooth: [],
	appointment: "",
	provider: "",
	additional_provider: "",
	description: "",
	signed_by: "",
	date: moment(new Date())

}

const initState = {
	currentPatientID: null,
	currentContentView: "CHARTING",
	isVideoOpen: false,
	videoChatApptId: "",
	loading: false,
	loadingSendMessage: false,
	loadingVideoChatToken: false,

	procedureCodeList: [],
	procedureStatusList: [],
	conditionList: [],
	toothNumberList: [],

	patientForm: {...initPatientForm},
	patientFormLoading: false,
	patientFormError: "",

	insuranceForm: {...initInsuranceForm},
	insuranceFormLoading: false,
	insuranceFormError: "",

	procLoading: false,
	procFormClear: false,
	procForm: {...initProcForm},

	invoiceForm: [],
	invoiceFormOpen: false,
	invoiceFormLoading: false,
	invoiceFormError: "",

	noteForm: {...initNoteForm},
	noteFormLoading: false,
	noteFormError: "",
	noteFormOpen: false,

	procFormCurrentTooth: null,
	procFormCurrentSurfaces: [],

	patientBillingProceduresLoading: false,
	patientBillingProceduresError: false,
	patientUnbilledProcedures: [],
	patientTreatmentPlanProcedures: [],


	patientLedgerFormOpen: false,
	patientLedgerFormLoading: false,
	patientLedgerForm: {...initPatientLedgerForm},
	patientLedgerLoading: false,
	patientLedgerError: false,
	patientLedger: [],

	patientInsurancesLoading: false,
	patientInsurancesError: false,
	patientInsurances: [],

	patientTreatmentPlanProcedureIDs: [],

	procedures: [],
	appointments: [],
	patient: {},
	patientNotes: [],
	upcomingAppointments: [],
	nextPatientAppointment: null,
	uploads: [],
	error: "",
	chatList: [],
	chatObjects: {},
	chatLastRetrieved: null,
	activeChatBoxMessages: [],
	activeChatBoxDetails: {},
	activeChatBoxTitle: "",
	activeChatBoxAvatar: "",
	activeChatBox: null,
	activeChatLastRetrieved: null,
	toothObj: {},
	toothList: [],
	toothNotesObj: {},
	notesObj: {},
	notes: [],
	charting: {...initChart},
	//add active procedure for viewing
	chartingCurrentTooth: null,
	chartingCurrentRegions: [],
	chartingCurrentToothProcedures: [],
	chartingCurrentProcedure: null,

	uploadsIndex: 0,
	uploadsNumberOf: 5,

};

export function patient(state = initState, action){
	switch (action.type){
		case patientConstants.INITIALIZE:
			return initState

		case patientConstants.GETPATIENT_PAGEDETAILS_REQUEST:
			return {
				...initState,
				error: "",
				loading: true
			};
		case patientConstants.GETPATIENT_PAGEDETAILS_SUCCESS:
			const tempToothObj = action.data.toothObj;
			const toothChart = {...initChart};
			for (var i=0; i < action.data.toothList.length; i++){
				// loop through procedures. add filled surfaces to chart.
				let currentTooth = action.data.toothList[i];
				let tempCurrentSurfaceList = tempToothObj[currentTooth].map((proc, index) => {
					return proc.surface
				});
				// addds a comma to tooth wtih multiple proceuddres... fix it later
				let currentSurfaceList = tempCurrentSurfaceList.reduce(function(prev, curr) {
					if ((curr != null ) && (curr.length > 0)){
						return prev.concat(curr.split(""))
					} else {
						return prev
					}
				}, []);
				let tempToothSurfaceList = (currentSurfaceList || []).concat(toothChart[ currentTooth ]);
				toothChart[currentTooth] = [...new Set(tempToothSurfaceList)];
			}
			return {
				...state,
				error: "",
				loading: false,
				currentPatientID: action.data.patient.id,
				patient: action.data.patient,
				appointments: action.data.appointments,
				procedures: action.data.procedures,
				uploads: action.data.uploads,
				nextPatientAppointment: action.data.patient_appointment,
				upcomingAppointments: action.data.upcoming_appointments,
				charting: toothChart,
				notes: action.data.notes,
				toothObj: action.data.toothObj,
				toothList: action.data.toothList,
				proceduresObj: action.data.proceduresObj,
				notesObj: action.data.notesObj,
				toothNotesObj: action.data.toothNotesObj
			};
		case patientConstants.SUBMIT_PATIENT_IMAGEUPLOAD_SUCCESS:
			return {
				...state,
				uploads: [action.data.new_patient_upload , ...state.uploads ]
			}

		case patientConstants.GETPATIENT_PAGEDETAILS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};

		case patientConstants.GETPATIENT_DETAILS_REQUEST:
			return {
				...state,
				patientFormLoading: true,
				patientFormError: ""
			}
		case patientConstants.GETPATIENT_DETAILS_SUCCESS:
			return {
				...state,
				patientFormLoading: false,
				patientFormError: "",
				patientForm: action.data.patient
			}
		case patientConstants.GETPATIENT_DETAILS_ERROR:
			return {
				...state,
				patientFormLoading: false,
				patientFormError: "Error loading patient data",
			}

		case patientConstants.GETPATIENT_BILLINGPROCEDURES_REQUEST:
			return {
				...state,
				patientBillingProceduresLoading: true,
				patientBillingProceduresError: ""
			}
		case patientConstants.GETPATIENT_BILLINGPROCEDURES_SUCCESS:
			return {
				...state,
				patientBillingProceduresLoading: false,
				patientBillingProceduresError: "",
				patientUnbilledProcedures: action.data.unbilled_procedures,
				patientTreatmentPlanProcedures: action.data.treatmentplan_procedures,
				patientTreatmentPlanProcedureIDs: action.data.treatmentplan_procedures.map(proc => proc.id),
				patientLedger: action.data.ledger_entries
			}
		case patientConstants.GETPATIENT_BILLINGPROCEDURES_FAILURE:
			return {
				...state,
				patientBillingProceduresLoading: false,
				patientBillingProceduresError: "Error loading patient procedures",
			}

		case patientConstants.GETPATIENT_INSURANCE_REQUEST:
			return {
				...state,
				patientInsurancesLoading: true,
				patientInsurancesError: ""
			}
		case patientConstants.GETPATIENT_INSURANCE_SUCCESS:
			return {
				...state,
				patientInsurancesLoading: false,
				patientInsurancesError: "",
				patientInsurances: action.data.insurances
			}
		case patientConstants.GETPATIENT_INSURANCE_FAILURE:
			return {
				...state,
				patientInsurancesLoading: false,
				patientInsurancesError: "Error loading patient insurance"
			}


		case patientConstants.CHANGEPATIENT_TREATMENTPLAN_LIST:
			return {
				...state,
				patientTreatmentPlanProcedureIDs: action.data
			}

		case patientConstants.COMPLETEPATIENT_TREATMENTPLAN_REQUEST:
			// LOAD THE ENTIRE BILLING PAGE, COULD BE COMPLETE TP OR COMPLETE AND BILL TP
			return {
				...state,
				patientBillingProceduresLoading: true,
				patientBillingProceduresError: ""
			}
		case patientConstants.COMPLETEPATIENT_TREATMENTPLAN_SUCCESS:
			return {
				...state,
				patientBillingProceduresLoading: false,
				patientBillingProceduresError: "",
				patientUnbilledProcedures: action.data.unbilled_procedures,
				patientTreatmentPlanProcedures: action.data.treatmentplan_procedures,
				patientTreatmentPlanProcedureIDs: action.data.treatmentplan_procedures.map(proc => proc.id),

				patientLedger: action.data.ledger_entries
			}
		case patientConstants.COMPLETEPATIENT_TREATMENTPLAN_FAILURE:
			return {
				...state,
				patientBillingProceduresLoading: false,
				patientBillingProceduresError: "Error loading patient procedures",
			}


		case patientConstants.EDITPATIENT_DETAILS_REQUEST:
			return {
				...state,
				patientFormLoading: true,
				patientFormError: ""
			}
		case patientConstants.EDITPATIENT_DETAILS_SUCCESS:
			return {
				...state,
				patientFormLoading: false,
				patientFormError: "",
				patient: action.data.patient,
				patientForm: {...initPatientForm},
				patientFormOpen: false
			}
		case patientConstants.EDITPATIENT_DETAILS_FAILURE:
			return {
				...state,
				patientFormLoading: false,
				patientFormError: "Error saving patient data",
			}

		/*
			Invoice Form
		*/
		case patientConstants.OPENINVOICEFORM:
			return {
				...state,
				invoiceFormOpen: true,
				invoiceForm: state.patientUnbilledProcedures.map((proc, index) => {
					return {
						...proc,
						procedure: proc.procedure ? proc.procedure.id: "",

					}
				})
			}
		case patientConstants.CLOSEINVOICEFORM:
			return {
				...state,
				invoiceFormOpen: false,
				invoiceForm: []
			}
		case patientConstants.CHANGEINVOICEFORM:
			let newInvoiceForm = [];
			if (action.data.index == 0){
				newInvoiceForm = [
					{...state.invoiceForm[action.data.index], ...action.data.form},
					...state.invoiceForm.slice(1)
				]
			} else if (action.data.index == (state.invoiceForm.length-1)){
				newInvoiceForm = [
					...state.invoiceForm.slice(0,action.data.index),
					{...state.invoiceForm[action.data.index], ...action.data.form},

				]
			} else {
				newInvoiceForm = [
					...state.invoiceForm.slice(0,action.data.index),
					{...state.invoiceForm[action.data.index], ...action.data.form},
					...state.invoiceForm.slice(action.data.index+1)
				]
			}
			console.log(action.data.index)
			console.log(state.invoiceForm[action.data.index])
			console.log(action.data.form)
			return {
				...state,
				invoiceForm: newInvoiceForm
			}

		case patientConstants.CREATEPATIENT_INVOICE_REQUEST:
			return {
				...state,
				invoiceFormLoading: true,
				invoiceFormError: ""
			}
		case patientConstants.CREATEPATIENT_INVOICE_SUCCESS:
			// need to get received invoice and add to list of invoices? under billing?
			return {
				...state,
				invoiceFormLoading: false,
				invoiceFormError: "",
				invoiceForm: [],
				invoiceFormOpen: false,
				patientUnbilledProcedures: action.data.unbilled_procedures,
				patientLedger: [...state.patientLedger, action.data.new_ledger_entry]

			}
		case patientConstants.CREATEPATIENT_INVOICE_FAILURE:
			return {
				...state,
				invoiceFormLoading: false,
				invoiceFormError: ""
			}

		case patientConstants.CREATEPATIENT_INSURANCE_REQUEST:
			return {
				...state,
				insuranceFormLoading: true,
				insuranceFormError: ""
			}
		case patientConstants.CREATEPATIENT_INSURANCE_SUCCESS:
			// need to get received invoice and add to list of invoices? under billing?
			return {
				...state,
				insuranceFormLoading: false,
				insuranceFormError: "",
				insuranceForm: {...initInsuranceForm},
				insuranceFormOpen: false,
				patientInsurances: [...state.patientInsurances, action.data.new_insurance]

			}
		case patientConstants.CREATEPATIENT_INSURANCE_FAILURE:
			return {
				...state,
				insuranceFormLoading: false,
				insuranceFormError: ""
			}


		case patientConstants.OPENLEDGERENTRYFORM:
			return {
				...state,
				patientLedgerFormOpen: true
			}

		case patientConstants.CLOSELEDGERENTRYFORM:
			return {
				...state,
				patientLedgerFormOpen: false,
				patientLedgerForm: {...initPatientLedgerForm}
			}

		case patientConstants.CHANGELEDGERENTRYFORM:
			return {
				...state,
				patientLedgerForm: {...state.patientLedgerForm ,...action.form}
			}


		case patientConstants.CREATEPATIENT_LEDGERENTRY_REQUEST:
			return {
				...state,
				patientLedgerFormLoading: true,
				patientLedgerFormError: ""
			}
		case patientConstants.CREATEPATIENT_LEDGERENTRY_SUCCESS:
			// need to get received invoice and add to list of invoices? under billing?
			return {
				...state,
				patientLedgerFormLoading: false,
				patientLedgerFormError: "",
				patientLedgerForm: {...initPatientLedgerForm},
				patientLedgerFormOpen: false,
				patientLedger: [...state.patientLedger, action.data.new_ledger_entry]

			}
		case patientConstants.CREATEPATIENT_LEDGERENTRY_FAILURE:
			return {
				...state,
				patientLedgerFormLoading: false,
				patientLedgerFormError: ""
			}


		/*
		 * Patient form
		 */
		case patientConstants.OPENPATIENTFORM:
			return {
				...state,
				patientFormOpen: true
			}

		case patientConstants.CLOSEPATIENTFORM:
			return {
				...state,
				patientFormOpen: false,
				patientForm: {...initPatientForm}
			}

		case patientConstants.CHANGEPATIENTFORM:
			console.log(state.procForm)
			return {
				...state,
				patientForm: {...state.patientForm ,...action.form}
			}

		case patientConstants.OPENINSURANCEFORM:
			return {
				...state,
				insuranceFormOpen: true,
				insuranceForm: {...state.insuranceForm,
								policyholder: state.patient.id,
								patient: state.patient.id
							}
			}

		case patientConstants.CLOSEINSURANCEFORM:
			return {
				...state,
				insuranceFormOpen: false,
				insuranceForm: {...initInsuranceForm}
			}

		case patientConstants.CHANGEINSURANCEFORM:
			return {
				...state,
				insuranceForm: {...state.insuranceForm ,...action.data}
			}



		case patientConstants.CHANGESURFACEFORM:
			return {
				...state,
				procFormCurrentSurfaces: action.data
			}

		case patientConstants.ADDPROCEDURE_REQUEST:
			return {
				...state,
				procLoading:  true
			};

		case patientConstants.ADDPROCEDURE_SUCCESS:
			let newChartObj = {};
			const newProc = action.data.procedure;
			const newToothChart = state.charting;
			const newProceduresObj = {...state.proceduresObj}
			const newToothList = [... new Set(state.toothList.concat([newProc.tooth]))]

			const newToothObj = {...state.toothObj}

			const surfaceList = newProc.surface.split("");
			if (newProc.tooth ){
				// add new proceudre to tooth chart
				newToothChart[newProc.tooth] = [...new Set(surfaceList.concat(newToothChart[newProc.tooth]))]
				newToothObj[newProc.tooth] = (newProc.tooth in newToothObj) ? [...newToothObj[newProc.tooth], newProc] : [newProc];
				newProceduresObj[newProc.id] = newProc;
				if (newProc.tooth == state.chartingCurrentTooth){
					newChartObj = {	charting: newToothChart,
						chartingCurrentRegions: newToothChart[newProc.tooth],
						chartingCurrentToothProcedures: newToothObj[newProc.tooth],
						chartingCurrentProcedure: null
					}
				}
			}
			return {
				...state,

				procLoading: false,
				procFormOpen: false,
				procForm: {...initProcForm},
				procedures : [
					...state["procedures"],
					action.data.procedure
				],
				toothObj: newToothObj ,
				proceduresObj: newProceduresObj,
				toothList: newToothList,
				procError: "",
				procFormClear: true,
				...newChartObj
				// add something to clear the form?
			};
		case patientConstants.ADDPROCEDURE_FAILURE:
			return {
				...state,
				procLoading: false,
			};

		case patientConstants.ADDNOTE_REQUEST:
			return {
				...state,
				noteFormLoading: true,
				noteFormError: "",
			}

		case patientConstants.ADDNOTE_SUCCESS:
			const newNote = action.data.new_clinical_note;
			const tempToothNotesObj = {...state.toothNotesObj}
			for (i=0;i<newNote.tooth.length;i++){
				tempToothNotesObj[newNote.tooth[i]] = (newNote.tooth[i] in tempToothNotesObj) ? [...tempToothNotesObj[newNote.tooth[i]], newNote.id] : newNote.id ;
			}
			return {
				...state,
				noteFormLoading: false,
				noteFormError: "",
				noteFormOpen: false,
				noteForm: {...initNoteForm},
				notes: [action.data.new_clinical_note, ...state.notes],
				//notes: [action.data.new_clinical_note, ...state.patientNotes],
				notesObj: {...state.notesObj, [newNote.id]: newNote},
				toothNotesObj: tempToothNotesObj

			}

		case patientConstants.ADDNOTE_FAILURE:
			return {
				...state,
				noteFormLoading: false,
				noteFormError: "Failed to create note"
			}

		case patientConstants.DELETEPROCEDURE_REQUEST:
			return {
				...state,
				appointments: [...state.appointments, action.data.appointment]
			};
		case patientConstants.DELETEPROCEDURE_SUCCESS:
			return {
				...state,
			}
		case patientConstants.DELETEPROCEDURE_FAILURE:
			return {
				...state,
			};
		case patientConstants.UNMOUNT_PATIENT:
			return {
				...initState,
				["charting"]: {...initChart}
			}

		// uploads navigation
		case patientConstants.PREV_UPLOADS:
			return {
				...state,
				uploadsIndex: Math.max(state.uploadsIndex - state.uploadsNumberOf, 0)
			}

		case patientConstants.NEXT_UPLOADS:
			return {
				...state,
				uploadsIndex: state.uploadsIndex + state.uploadsNumberOf
			}


		case patientConstants.CHANGE_PAGECONTENT:
			return {
				...state,
				currentContentView: action.contentView
			}


		case patientConstants.OPEN_VIDEO_CHAT:
			return {
				...state,
				videoChatApptId: action.apptID,
				isVideoOpen: true

			}
		case patientConstants.END_VIDEO_SESSION:
			return {
				...state,
				videoChatApptId: "",
				isVideoOpen: false
			}

		case patientConstants.OPENPROCEDUREPOPUP:
			return {
				...state,
				procedurePopupOpen: true
			}

		case patientConstants.CLOSEPROCEDUREPOPUP:
			return {
				...state,
				procedurePopupOpen: false
			}

		/*
		 * Procedure form
		 */
		case patientConstants.OPENPROCFORM:
			const test =  {...state.procForm, ...action.data}
			return {
				...state,
				procFormCurrentTooth: action.data.tooth,
				procFormCurrentSurfaces: [action.data.surface],
				procFormOpen: true,
				procForm: {...state.procForm, ...action.data}
			}

		case patientConstants.CLOSEPROCFORM:
			return {
				...state,
				procFormOpen: false,
				procForm: {...initProcForm}
			}

		case patientConstants.CHANGEPROCFORM:
			console.log(state.procForm)
			return {
				...state,
				procForm: {...state.procForm ,...action.form}
			}

		/*
		 * Clinical note form: open, change close
		 */
		case patientConstants.OPENNOTEFORM:
			return {
				...state,
				noteFormOpen: true,
				noteForm: {...state.noteForm, ...action.data}
			}

		case patientConstants.CLOSENOTEFORM:
			return {
				...state,
				noteFormOpen: false,
				noteForm: {...initNoteForm}
			}

		case patientConstants.CHANGENOTEFORM:

			return {
				...state,
				noteForm: {...state.noteForm ,...action.form}
			}

		/*
		 * LOAD PROCEUDRE CODES, TOOTH REGIONS, ETC
		 *
		 */
		case patientConstants.GETPROCEDURECODES_REQUEST:
			return {
				...state,

				errorProcedureCodes: "",
				loadingProcedureCodes: true
			};
		case patientConstants.GETPROCEDURECODES_SUCCESS:
			return {
				...state,

				errorProcedureCodes: "",
				procedureCodeList: action.data.code_list,
				procedureStatusList: action.data.procedurestatus_list,
				conditionList: action.data.condition_list,
				loadingProcedureCodes: false
			};
		case patientConstants.GETPROCEDURECODES_FAILURE:
			return {
				...state,

				errorProcedureCodes: action.error,
				loadingProcedureCodes: false
			};

		case patientConstants.GET_TOOTHREGIONS_REQUEST:
			return {
				...state,

				errorToothRegion: "",
				loadingToothRegions: true
			};
		case patientConstants.GET_TOOTHREGIONS_SUCCESS:
			return {
				...state,

				errorToothRegion: "",
				toothRegionList: action.data.toothregion_list,
				loadingToothRegions: false
			};
		case patientConstants.GET_TOOTHREGIONS_FAILURE:
			return {
				...state,

				errorToothRegion: action.error,
				loadingToothRegions: false
			};

		case patientConstants.GET_TOOTHNUMBERS_REQUEST:
			return {
				...state,

				errorToothNumbers: "",
				loadingToothNumbers: true
			};
		case patientConstants.GET_TOOTHNUMBERS_SUCCESS:
			return {
				...state,

				errorToothNumber: "",
				toothNumberList: action.data.toothnumber_list,
				loadingToothNumbers: false
			};
		case patientConstants.GET_TOOTHNUMBERS_FAILURE:
			return {
				...state,

				errorToothNumber: action.error,
				loadingToothNumbers: false
			};

		case patientConstants.GETVIDEOCHAT_TOKEN_SUCCESS:
			return {
				...state,

				errorVideoChatToken: "",
				loadingVideoChatToken: false,
				video_chat_token: action.data.token,
				isVideoOpen: true
			};

		case patientConstants.GETVIDEOCHAT_TOKEN_SUCCESS:
			return {
				...state,

				errorVideoChatToken: "",
				loadingVideoChatToken: false,
				video_chat_token: action.data.token,
				isVideoOpen: true
			};

		case patientConstants.GETVIDEOCHAT_TOKEN_FAILURE:
			return {
				...state,
				loadingVideoChatToken: false,
				errorVideoChatToken: action.error
			};


		// Select Tooth
		case patientConstants.CHART_SELECT_TOOTH:
			var actionCurrentTooth;
			if (state.chartingCurrentTooth == action.toothNum){
				actionCurrentTooth = null;
			} else {
				actionCurrentTooth = action.toothNum;
			}
			const tempToothProcedures = state.toothObj[actionCurrentTooth] || [];

			return {
				...state,

				chartingCurrentTooth: actionCurrentTooth,
				chartingCurrentRegions: state.charting[actionCurrentTooth] || [],
				chartingCurrentToothProcedures: tempToothProcedures,
				//chartingCurrentProcedure: ( tempToothProcedures.length > 0 ) ? tempToothProcedures[0] : null
				chartingCurrentProcedure: null

			}
		// select procedure
		case patientConstants.CHART_SELECT_TOOTH_PROCEDURE:
			const tooth = action.data.toothNum;
			const procID = action.data.procID;
			return {
				...state,
				chartingCurrentTooth: tooth || null,
				chartingCurrentRegions: state.charting[tooth] || [],
				chartingCurrentToothProcedures: state.toothObj[tooth] || [],
				chartingCurrentProcedure: state.proceduresObj[procID],
				procedurePopupOpen: true
			}

		// Get Inbox
		case patientConstants.GET_INBOX_REQUEST:
			return {
				...state,

				errorInbox: "",
				loadingInbox: true
			};
		case patientConstants.GET_INBOX_SUCCESS:
			// chatList is list of ids [1,2,3]
			// chatObject is dictionary of objects by key(id)  {1: {..}, 2: {..}}
			return {
				...state,

				errorInbox: "",
				chatList: action.data.chat_list,
				chatObjects: action.data.chat_objects,
				loadingInbox: false ,
				chatLastRetrieved: new Date()
			};
		case patientConstants.GET_INBOX_FAILURE:
			return {
				...state,

				errorInbox: action.error,
				loadingInbox: false
			};

		// Get Unread Inbox
		case patientConstants.GET_NEWINBOX_REQUEST:
			return {
				...state,

				errorInbox: "",
			};

		case patientConstants.GET_NEWINBOX_SUCCESS:
			// update the chat list and objects with only new items
			const tempChatObjects = {...state.chatObjects};
			const tempChatList = action.data.chat_list.concat(state.chatList);
			let finalChatList = [...new Set(tempChatList)];
			// replace the chat object with newlyd received objects
			for (var i=0; i < action.data.chat_list.length; i++){
				tempChatObjects[action.data.chat_list[i]] = action.data.chat_objects[action.data.chat_list[i]];
			};
			return {
				...state,

				errorInbox: "",
				chatObjects: tempChatObjects,
				chatList: finalChatList,
				chatLastRetrieved: new Date()
			};
		case patientConstants.GET_NEWINBOX_FAILURE:
			return {
				...state,

				errorInbox: action.error,
			};

		// open chat box
		case patientConstants.OPENCHATBOX:
			return {
				...state,
				activeChatBox: action.data.chatID,
				activeChatBoxTitle: action.data.name,
				activeChatBoxAvatar: action.data.avatar
			}
		// close chat box
		case patientConstants.CLOSECHATBOX:
			return {
				...state,
				activeChatBoxMessages: [],
				activeChatBoxDetails: {},
				activeChatBoxTitle: "",
				activeChatBoxAvatar: "",
				activeChatBox: null,
				activeChatLastRetrieved: null
			}

		// Get Messages for Active Chat Box
		case patientConstants.GET_MESSAGES_REQUEST:
			return {
				...state,

				errorChatBox: "",
				loadingChatBox: true
			};
		case patientConstants.GET_MESSAGES_SUCCESS:
			return {
				...state,

				errorChatBox: "",
				activeChatBoxDetails: action.data.thread,
				activeChatBoxMessages: action.data.message_list,
				//activeChatBoxAvatar: "",
				activeChatLastRetrieved: new Date(),
				loadingChatBox: false
			};
		case patientConstants.GET_MESSAGES_FAILURE:
			return {
				...state,

				errorChatBox: action.error,
				loadingChatBox: false
			};

		// Get Unread Inbox
		case patientConstants.GET_NEWMESSAGES_REQUEST:
			return {
				...state,
			};

		case patientConstants.GET_NEWMESSAGES_SUCCESS:
			return {
				...state,
				activeChatBoxMessages: state.activeChatBoxMessages.concat(action.data.message_list)
			};
		case patientConstants.GET_NEWMESSAGES_FAILURE:
			return {
				...state,
			};


		// Send Message
		case patientConstants.SEND_MESSAGE_REQUEST:
			return {
				...state,

				errorSendMessage: "",
				loadingSendMessage: true,
				activeChatLastRetrieved: new Date(),
			};

		case patientConstants.SEND_MESSAGE_SUCCESS:
			return {
				...state,

				errorSendMessage: "",
				loadingSendMessage: false,
				activeChatBoxMessages: [...state.activeChatBoxMessages, {message:action.data.new_message}],
				activeChatLastRetrieved: new Date(action.data.new_message.sent_at)


			};
		case patientConstants.SEND_MESSAGE_FAILURE:
			return {
				...state,
				errorSendMessage: action.error,
				loadingSendMessage: false
			};





    default:
      return state
	}
}
