import { videochatConstants } from '../_constants';
import { videochatService } from '../_services';
import { history } from '../_helpers';
import moment from 'moment';

export const videochatActions = {
    getVideoChatDetails
};


function getVideoChatDetails(apptID){
    return dispatch => {
        dispatch(request());
        videochatService.getVideoChatDetails(apptID)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: videochatConstants.GETVIDEOCHAT_DETAILS_REQUEST } }
    function success(data) { 
        return { type: videochatConstants.GETVIDEOCHAT_DETAILS_SUCCESS, data } 
    }
    function failure(error) { return { type: videochatConstants.GETVIDEOCHAT_DETAILS_FAILURE, error } }
}
