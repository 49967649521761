import { Box, Text } from "@chakra-ui/react";

export default function Logo(props) {
  return (
    <Box {...props}>
      <Text 
        fontSize="18px" 
        color={'#fff'}
        letterSpacing={'2.4px'}
        textDecoration={'none'}
      >
        BEFOREDENT
      </Text>
    </Box>
  );
}