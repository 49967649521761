import { imageSetConstants } from '../_constants';
import { initState_formRequiredImages } from '../_config';

const initState = {
  requiredImages: initState_formRequiredImages
}

export function imageSet(state = initState, action) {
  switch (action.type) {
    case imageSetConstants.UPDATE_REQUIREDIMAGES:
      return {
        ...state,
        requiredImages: action.data
      };
    default:
      return state
  }
}
