import { alertConstants } from '../_constants';
import { history } from '../_helpers';
import localeData from '../../locales/data.json';
import { updateIntl } from 'react-intl-redux'

export const alertActions = {
    show,
    success,
    error,
    clear
};

function show(data){
    return { type: alertConstants.SHOW_GENERAL, data}
}

function success(message, type) {
    return { type: alertConstants.SUCCESS, message: message, title_type: type };
}

function error(err) {
    return { type: alertConstants.ERROR, message: err.error  };
}

function clear() {
    return { type: alertConstants.CLEAR };
}
