import {
  Modal,
  ModalOverlay,
  ModalContent,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import { initReactQueryAuth } from "react-query-auth";

import { userService } from "../_services";
import storage from "../_helpers/storage";

async function handleTokenResponse(data) {
  const { key, active } = data;
  if (active) {
    storage.setToken(key);
    return key;
  }
}
// load data from user
async function loadUser() {
  if (storage.getToken()) {
    try {
      const data = await userService.getInfo();
      if (data?.user.country) {
        storage.setClinicCountry(
          data.user.dental_practice.country.toLowerCase()
        );
      }
      if (data?.country) {
        storage.setCountry(data.country.toLowerCase());
      }
      if (data?.locale) {
        storage.setLocale(data.locale.toLowerCase());
      }
      return data;
    } catch (error) {
      console.log("auth.loadUser error");
    }
  }
  return null;
}

async function loginFn(data) {
  console.log("requestUrl loginFn ", data);
  if (data?.provider.toLowerCase() === "kakao") {
    const response = await userService.loginSocial(data);
    await handleTokenResponse(response);
  } else if (data?.provider.toLowerCase() === "line") {
    const response = await userService.loginSocial(data);
    await handleTokenResponse(response);
  } else if (data?.provider.toLowerCase() === "google") {
    const response = await userService.loginSocial(data);
    await handleTokenResponse(response);
  } else if (data?.provider.toLowerCase() === "code") {
    const response = await userService.loginCode(data);
    await handleTokenResponse(response);
  } else {
    const response = await userService.login(data);
    await handleTokenResponse(response);
  }
  try {
    const user = await loadUser();
    return user;
  } catch (error) {
    return null;
  }
}

async function registerFn(data) {
  if (data?.provider.toLowerCase() === "kakao") {
    const response = await userService.register(data);
    await handleTokenResponse(response);
  } else if (data?.provider.toLowerCase() === "line") {
    const response = await userService.register(data);
    await handleTokenResponse(response);
  } else if (data?.provider.toLowerCase() === "google") {
    const response = await userService.register(data);
    await handleTokenResponse(response);
  } else {
    const response = await userService.register(data);
    await handleTokenResponse(response);
  }

  try {
    const user = await loadUser();
    return user;
  } catch (error) {
    return null;
  }
}

async function logoutFn() {
  userService.logout();
  storage.clearToken();
  window.location.assign(window.location.origin);
}

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  logoutFn,
  LoaderComponent() {
    return (
      <>
        <Modal isOpen={true} onClose={() => null} isCentered>
          <ModalOverlay bg="blackAlpha.300" />
          <ModalContent bg="none" boxShadow="none">
            <Flex align="center" justify="Center">
              <Spinner thickness="4px" speed="0.65s" size="xl" />
            </Flex>
          </ModalContent>
        </Modal>
      </>
    );
  },
};

export const { AuthProvider, useAuth } = initReactQueryAuth(authConfig);
