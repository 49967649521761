import React from "react";
import { lazyImport } from "../../_helpers/lazyImport";

import { Switch, Redirect } from "react-router-dom";

import { PrivateRouteAlt, PrivateRoute } from "../../_components";

const { AdminAppointmentRequests } = lazyImport(
  () => import("./AdminAppointmentRequests"),
  "AdminAppointmentRequests"
);
const { AppointmentRequests } = lazyImport(
  () => import("./AppointmentRequests"),
  "AppointmentRequests"
);
const { ClinicDashboardPage } = lazyImport(
  () => import("./ClinicDashboardPage"),
  "ClinicDashboardPage"
);
const { Appointments } = lazyImport(
  () => import("./Appointments"),
  "Appointments"
);
const { AdminAppointments } = lazyImport(
  () => import("./AdminAppointments"),
  "AdminAppointments"
);
const { PatientsPage } = lazyImport(
  () => import("./PatientsPage"),
  "PatientsPage"
);
const { PatientsDetailPage } = lazyImport(
  () => import("./PatientsDetailPage"),
  "PatientsDetailPage"
);
const { DentistDetailPage } = lazyImport(
  () => import("./DentistDetailPage"),
  "DentistDetailPage"
);
const { DentistsPage } = lazyImport(
  () => import("./DentistsPage"),
  "DentistsPage"
);
const { SettingPage } = lazyImport(
  () => import("./SettingPage"),
  "SettingPage"
);
const { AnalyticsDashboardPage } = lazyImport(
  () => import("./AnalyticsDashboardPage"),
  "AnalyticsDashboardPage"
);
const { BroadcastPage } = lazyImport(
  () => import("./BroadcastPage"),
  "BroadcastPage"
);
const { BroadcastMessageDetailPage } = lazyImport(
  () => import("./BroadcastMessageNewPage"),
  "BroadcastMessageDetailPage"
);
const { AppointmentRequestsViewPage } = lazyImport(
  () => import("./AppointmentRequestsViewPage"),
  "AppointmentRequestsViewPage"
);
const { QRCode } = lazyImport(() => import("./QRCode"), "QRCode");

//const { RecipientsList } = lazyImport(()=> import("./Recipients/RecipientsList"), "RecipientsList")
//const { ScheduledMessagesList } =lazyImport(()=> import("./Messages/MessagesList"), "ScheduledMessagesList")

const AppointmentsRoutes = () => {
  return (
    <Switch>
      <PrivateRouteAlt exact path="/appointments/scanqr">
        <QRCode />
      </PrivateRouteAlt>
      <PrivateRouteAlt exact path="/appointments/requests">
        <AppointmentRequests />
      </PrivateRouteAlt>
      <PrivateRouteAlt exact path="/appointments/admin/requests">
        <AdminAppointmentRequests />
      </PrivateRouteAlt>
      <PrivateRouteAlt exact path="/appointments/admin/appointments">
        <AdminAppointments />
      </PrivateRouteAlt>
      {/* <PrivateRouteAlt exact path="/appointments/calendar">
        <Appointments />
      </PrivateRouteAlt> */}
      <PrivateRouteAlt exact path="/appointments/patients">
        <PatientsPage />
      </PrivateRouteAlt>
      <PrivateRouteAlt exact path="/appointments/patients/detail/:patientId">
        <PatientsDetailPage />
      </PrivateRouteAlt>
      <PrivateRouteAlt exact path="/appointments/dentists">
        <DentistsPage />
      </PrivateRouteAlt>
      <PrivateRouteAlt exact path="/appointments/dentists/detail/:dentistId">
        <DentistDetailPage />
      </PrivateRouteAlt>
      <PrivateRouteAlt exact path="/appointments/broadcast">
        <BroadcastPage />
      </PrivateRouteAlt>
      <PrivateRouteAlt exact path="/appointments/broadcast/create">
        <BroadcastMessageDetailPage />
      </PrivateRouteAlt>
      <PrivateRouteAlt exact path="/appointments/settings">
        <SettingPage />
      </PrivateRouteAlt>
      <PrivateRouteAlt exact path="/appointments">
        {/* <ClinicDashboardPage /> */}
        <Appointments />
      </PrivateRouteAlt>
      <PrivateRouteAlt exact path="/appointments/dashboard">
        <AnalyticsDashboardPage />
      </PrivateRouteAlt>
      <PrivateRouteAlt exact path="/appointments/view-request">
        <AppointmentRequestsViewPage />
      </PrivateRouteAlt>
    </Switch>
  );
};

export default AppointmentsRoutes;
