import { treatmentplanConstants } from './treatmentplan.constants';

const initState = {
  id: undefined,
  modelsUrl: undefined,
  modelsLoadError: false,
  detailsLoadError: false
}

export function treatmentplan(state = initState, action={}) {
  switch (action.type) {
    case treatmentplanConstants.GET_TREATMENTPLANDETAILS_REQUEST:
      return {
        ...state,
        detailsLoadError: false
      }
    case treatmentplanConstants.GET_TREATMENTPLANDETAILS_SUCCESS:
      return {
        ...state,
        detailsLoadError: false,
        ...action.data.treatment_plan
      }
    case treatmentplanConstants.GET_TREATMENTPLANDETAILS_FAILURE:
      return {
        ...state,
        detailsLoadError: true
      }
    default:
      return state
  }
};
