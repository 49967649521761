import create from 'zustand';
import produce from 'immer';

// Log every time state is changed
const log = config => (set, get, api) => config(args => {
  console.log("  applying", args)
  set(args)
  console.log("  new state", get())
}, get, api)

// Turn the set method into an immer proxy
const immer = config => (set, get, api) => config((partial, replace) => {
  const nextState = typeof partial === 'function'
      ? produce(partial)
      : partial
  return set(nextState, replace)
}, get, api)

export const useSelectOptionsStore = create(
  
  log(
    immer((set) => ({
      clinicRoles: null,
      accountRoles: null,
      dentistRoles: null,
      countries: null,
      reportAttachmentTypes: null,
      addOptions: (field, options) =>
        set((state) => ({
          ...state,
          [field] : options
        }))
    }))
  )
);