import { useState, useEffect } from "react";
import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import {
  VStack,
  Flex,
  Box,
  FormControl,
  FormLabel,
  Text,
  InputGroup,
  Input,
  InputRightElement,
  FormHelperText,
  FormErrorMessage,
  Button,
} from "@chakra-ui/react";
import { useAuth } from "../_libs/auth";

import { InputField } from "js/_components/Forms/InputField";
import {
  required,
  isFieldErrorfromMeta,
  getErrorMessage,
} from "js/_libs/forms";

import { userService } from "js/_services";

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";

import { FormattedMessage, useIntl } from "react-intl";
import { ButtonFilled } from "js/_components/Button/ButtonFilled";
import PrevArrowIcon from "images/icons/PrevArrowIcon";

export const ForgotForm = () => {
  const [ isSent, setIsSent ] = useState(false);  
  const history = useHistory();
  const resetPasswordMatch = useRouteMatch('/reset/:uid/:token')
  const { formatMessage } = useIntl();

  const onBack = () => {
    history.push('/login')
};

  const handleReset = async (values) => {
    try{
      console.log("sending from page")
      await userService.forgotPassword(values);
      // change to message screen
      setIsSent(true);
    } catch (err){
      var errType = 'generalError';
      return {
        ...err,
        [FORM_ERROR] : errType
      }
    }
  }

  return (
    <Form
      onSubmit={handleReset}
      initialValues={{uid: resetPasswordMatch?.params?.uid, token: resetPasswordMatch?.params?.token}}
      render={({
        handleSubmit,
        reset,
        submitting,
        submitError,
        pristine,
        validating,
        values,
      }) => (
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Flex
            h={["100%", "fit-content"]}
            direction={"column"}
            justify={["center", "space-between"]}
          >
            <Box>
              <Flex justify={"flex-start"} align={"center"}>
                <PrevArrowIcon
                  onClick={onBack}
                  _hover={{
                    cursor: "pointer",
                  }}
                  height={"15px"}
                  width={"15px"}
                  color={"black"}
                />
                <Text
                  ml={10}
                  color={"#1B2B29"}
                  fontWeight={"600"}
                  fontSize={"30px"}
                  textTransform={"capitalize"}
                >
                  <FormattedMessage 
                    id="loginForm.forgot"
                    defaultMessage="Forgot Password"
                  />
                </Text>
              </Flex>
              <Flex mt={['20px']}>

                <Text
                  color={"#1B2B29"}
                  fontSize={"14px"}
                  textTransform={""}
                  maxW={"420px"}
                  lineHeight={"24px"}
                >
                  <FormattedMessage 
                    id="forgotForm.subHeader"
                    defaultMessage="Please provide the email address for your account. We will send you an email that will allow you to reset your password"
                  />
                </Text>
              </Flex>
              <VStack spacing={10} mt={10}>
                <Field name="email" validate={required}>
                  {({ input, meta }) => (
                    <InputField
                      borderColor={"rgba(123, 136, 135, 0.5)"}
                      borderRadius={"15px"}
                      inputProps={{
                        borderRadius: "15px",
                        minH: "50px",
                      }}
                      input={input}
                      meta={meta}
                      type="email"
                      label={formatMessage({"id": "forgotForm.email", "defaultMessage": "Email Address"})}
                    />
                  )}
                </Field>
              </VStack>
            </Box>
            <Flex w={'full'} align={'center'} direction={'column'} mt={'15px'}>
                <FormErrorMessage>
                    {submitError && (
                        <FormattedMessage 
                            id="resetForm.generalError"
                            defaultMessage="error"
                        />
                    )}
                </FormErrorMessage>
            </Flex>
            <Flex mt={4}>
              <ButtonFilled
                bg={`brandLight`}
                w={"100%"}
                py={10}
                type={"submit"}
                isLoading={submitting}
                disabled={submitting || pristine || isSent}
                textTransform={"capitalize"}
              >
                <FormattedMessage
                    id={"forgotForm.reset"}
                    defaultMessage={"Reset Password"}
                />
              </ButtonFilled>
            </Flex>
            {
                isSent && (
                    <Flex mt={['20px']}>

                        <Text
                        color={"#1B2B29"}
                        fontSize={"14px"}
                        textTransform={""}
                        maxW={"420px"}
                        lineHeight={"24px"}
                        >
                        <FormattedMessage 
                            id="forgotForm.message.subHeader"
                            defaultMessage="Please check your email for instructions on how to reset your password"
                        />
                        </Text>
                    </Flex>
                )
            }
          </Flex>
        </form>
      )}
    />
  );
};
