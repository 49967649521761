import { API_URLS } from "../../_config";
import { authHeader, handleResponse } from "../../_helpers";

// clinic calls
export function updateClinicAppointment(appointmentId, data, dpid = null) {
  const requestUrl = API_URLS.updateClinicAppointmentDetails;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace("{appointmentId}", appointmentId);

  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}
export function deleteClinicsAppointment(appointmentId, data, dpid = null) {
  const requestUrl = API_URLS.deleteClinicAppointment;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
    // body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace("{appointmentId}", appointmentId);

  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function fetchClinicAppointmentDetails(appointmentId, dpid = null) {
  const requestUrl = API_URLS.getClinicAppointmentDetails;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let url = requestUrl.url.replace("{appointmentId}", appointmentId);

  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function fetchAdminAppointmentCollection(searchQuery) {
  // dpid already added in searchQuery
  const requestUrl = API_URLS.getAdminAppointmentCollection;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  let url = requestUrl.url;
  if (searchQuery) {
    url = url + "?" + searchQuery;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

export function fetchClinicAppointmentCollection(searchQuery, dpid = null) {
  // dpid already added in searchQuery
  const requestUrl = API_URLS.getClinicAppointmentCollection;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let url = requestUrl.url + `?${searchQuery}`;
  if (dpid) {
    url = url + "&dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function createClinicAppointment(appointmentId, data = {}, dpid = null) {
  const requestUrl = API_URLS.createClinicAppointment;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url;

  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function fetchClinicDentistCollection(searchQuery, dpid = null) {
  // dpid already added in searchQuery
  const requestUrl = API_URLS.getClinicDentistAccounts;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let url = requestUrl.url + `?${searchQuery}`;
  if (dpid) {
    url = url + "&dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function fetchClinicRoomCollection(searchQuery, dpid = null) {
  // dpid might or might not be in searchQuery
  const requestUrl = API_URLS.getClinicOperatories;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let url = requestUrl.url + `?${searchQuery}`;
  if (dpid) {
    url = url + "&dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

// createClinicOperatories updateClinicOperatories

export function addOpratories(appointmentId, data = {}, dpid = null) {
  const requestUrl = API_URLS.createClinicOperatories;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url;

  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function updateClinicOperatories(operatoryId, data = {}, dpid = null) {
  const requestUrl = API_URLS.updateClinicOperatories;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace("{operatoryId}", operatoryId);
  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function deleteClinicOperatories(operatoryId, dpid = null) {
  const requestUrl = API_URLS.deleteClinicOperatories;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let url = requestUrl.url.replace("{operatoryId}", operatoryId);

  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function addClinicPatient(appointmentId, data = {}, dpid = null) {
  const requestUrl = API_URLS.createClinicPatient;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url;

  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function updateClinicPatient(patientId, data = {}, dpid = null) {
  const requestUrl = API_URLS.updateClinicPatientDetails;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace("{patientId}", patientId);
  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

// admin calls
export function fetchAdminAppointmentRequestCollection(searchQuery) {
  // dpid already added in searchQuery
  const requestUrl = API_URLS.getAdminAppointmentRequestCollection;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let url = requestUrl.url;
  if (searchQuery) {
    url = url + "?" + searchQuery;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

export function fetchAdminAppointmentRequestCount(searchQuery="", dpid = null){
  const requestUrl = API_URLS.getAdminAppointmentRequestCount;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let url = requestUrl.url + `?${searchQuery}`;
  if (dpid) {
    url = url + "&dpid=" + dpid;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

export function fetchAppointmentDetails(appointmentId, dpid = null) {
  const requestUrl = API_URLS.getAppointmentDetails;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let url = requestUrl.url.replace("{appointmentId}", appointmentId);

  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}



export function fetchAppointmentRequestDetails(requestId, dpid = null) {
  const requestUrl = API_URLS.getAppointmentRequestDetails;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let url = requestUrl.url.replace("{requestId}", requestId);

  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function fetchAppointmentRequestCount( dpid = null) {
  const requestUrl = API_URLS.getAppointmentRequestCount;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let url = requestUrl.url;

  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}


export function fetchAppointmentCollection(searchQuery, dpid = null) {
  // dpid already added in searchQuery
  const requestUrl = API_URLS.getAppointmentCollection;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "same-origin",
  };
  let url = requestUrl.url + `?${searchQuery}`;
  if (dpid) {
    url = url + "&dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}



export function fetchAppoinmentRequestCollection(searchQuery="", dpid = null) {
  // dpid already added in searchQuery
  const requestUrl = API_URLS.getAppointmentRequestCollection;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let url = requestUrl.url + `?${searchQuery}`;

  if (dpid) {
    url = url + "&dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function createAppointment(data, dpid = null) {}

export function fetchAppoinmentRequestList(searchQuery="", dpid = null) {
  // dpid already added in searchQuery
  const requestUrl = API_URLS.getAppointmentRequestList;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let url = requestUrl.url + `?${searchQuery}`;

  if (dpid) {
    url = url + "&dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function createFromAppointmentRequest(
  requestId,
  data = {},
  dpid = null
) {
  const requestUrl = API_URLS.createFromAppointmentRequest;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace("{requestId}", requestId);

  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function rejectAppointmentRequest(requestId, data = {}, dpid = null) {
  const requestUrl = API_URLS.rejectAppointmentRequest;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace("{requestId}", requestId);

  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function cancelAppointmentRequest(requestId, data = {}, dpid = null) {
  const requestUrl = API_URLS.cancelAppointmentRequest;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace("{requestId}", requestId);

  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function forwardAppointmentRequest(requestId, data = {}, dpid = null) {
  const requestUrl = API_URLS.forwardAppointmentRequest;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace("{requestId}", requestId);

  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function fetchClinicAppoinmentRequestCollection(searchQuery="", dpid = null) {
  // dpid already added in searchQuery
  const requestUrl = API_URLS.getClinicAppointmentRequests;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let url = requestUrl.url + `?${searchQuery}`;

  if (dpid) {
    url = url + "&dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function acceptClinicAppoinmentRequest(requestId, dpid = null) {
  const requestUrl = API_URLS.acceptClinicAppointmentRequests;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let url = requestUrl.url.replace("{requestId}", requestId);

  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}
export function rejectClinicAppoinmentRequest(
  requestId,
  data = {},
  dpid = null
) {
  const requestUrl = API_URLS.rejectClinicAppointmentRequests;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace("{requestId}", requestId);

  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function fetchPatientCollection(offset = 0, pg_sz = 10, q = "", tag = "", dpid = null) {
  const requestUrl = API_URLS.getClinicPatientCollection;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let url = requestUrl.url;

  url = url + "?offset=" + offset + "&pg_sz=" + pg_sz + "&q=" + q;
  if (dpid) {
    url = url + "&dpid=" + dpid;
  }
  if (tag) {
    url = url + "&tag=" + tag;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

export function fetchClinicSinglePatientDetails(id, dpid = null) {
  const requestUrl = API_URLS.getClinicPatientDetails;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  let url = requestUrl.url.replace("{patientId}", id);
  if (dpid) {
    url = url + "?dpid=" + dpid;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

export function getClinicPatientUploadCollectionList(
  id,
  offset = 0,
  pg_sz = 10,
  dpid = null
) {
  const requestUrl = API_URLS.getClinicPatientUploadCollection;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  let url = requestUrl.url.replace("{patientId}", id);
  url = url + "?offset=" + offset + "&pg_sz=" + pg_sz;
  if (dpid) {
    url = url + "&dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function fetchPatientAppointmentCollection(
  id,
  viewKey,
  orderBy,
  offset = 0,
  pg_sz = 10,
  dpid = null
) {
  const requestUrl = API_URLS.getPatientAppointmentCollection;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let url = requestUrl.url
    .replace("{patientId}", id)
    .replace("{viewKey}", viewKey)
    .replace("{orderBy}", orderBy);

  url = url + "&offset=" + offset + "&pg_sz=" + pg_sz;
  if (dpid) {
    url = url + "&dpid=" + dpid;
  }

  return fetch(url, requestOptions).then(handleResponse);
}
export function fetchDentistAppointmentCollection(
  id,
  viewKey,
  orderBy,
  offsetPage,
  pageSize = 10,
  dpid = null
) {
  const requestUrl = API_URLS.getDentistAppointmentCollection;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let url = requestUrl.url
    .replace("{dentistId}", id)
    .replace("{viewKey}", viewKey)
    .replace("{orderBy}", orderBy);

  url = url + "&offset=" + offsetPage + "&pg_sz=" + pageSize;
  if (dpid) {
    url = url + "&dpid=" + dpid;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

export function fetchClinicDentistAccounts(dpid=null) {
  const requestUrl = API_URLS.getClinicDentistAccounts;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let url = requestUrl.url;
  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function fetchClinicDentistAccountsList(offset = 0, pg_sz = 10, dpid = null) {
  const requestUrl = API_URLS.getClinicDentistAccountsList;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let url = requestUrl.url;

  url = url + "?offset=" + offset + "&pg_sz=" + pg_sz;
  if (dpid) {
    url = url + "&dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}
// getClinicDentistAccountsList
export function fetchClinicDentistAccountsRoles(dpid = null) {
  const requestUrl = API_URLS.getDentalAccountsRole;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let url = requestUrl.url;
  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}
export function fetchMyAccounts(dpid = null) {
  const requestUrl = API_URLS.getMyAccounts;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let url = requestUrl.url;
  if (dpid) {
    url = url + "?dpid=" + dpid;
  }

  return fetch(url, requestOptions).then(handleResponse);
}
export function getClinicInfoSetting(dpid = null) {
  const requestUrl = API_URLS.getClinicInfo;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let url = requestUrl.url;
  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}
export function getTagsCollection() {
  const requestUrl = API_URLS.getTagsList;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(requestUrl.url, requestOptions).then(handleResponse);
}

export function addTagsCollection(data = {}) {
  const requestUrl = API_URLS.createTagsList;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url;

  return fetch(url, requestOptions).then(handleResponse);
}

export function deleteTagsCollection(data = {}) {
  const requestUrl = API_URLS.removeTagsList;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url;

  return fetch(url, requestOptions).then(handleResponse);
}

export function updateTagsCollection(id, data = {}) {
  const requestUrl = API_URLS.updateTagsList;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  let url = requestUrl.url.replace("{id}", id);

  return fetch(url, requestOptions).then(handleResponse);
}

export function getPatientTagsCollection(patientId) {
  const requestUrl = API_URLS.getPatientTagsList;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  let url = requestUrl.url.replace("{patientId}", patientId);

  return fetch(url, requestOptions).then(handleResponse);
}

export function addPatientTagsCollection(patientId, data = {}) {
  const requestUrl = API_URLS.addPatientTagsList;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace("{patientId}", patientId);

  return fetch(url, requestOptions).then(handleResponse);
}
export function removeatientTagsCollection(patientId, data = {}) {
  const requestUrl = API_URLS.removePatientTagsList;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace("{patientId}", patientId);

  return fetch(url, requestOptions).then(handleResponse);
}

export function deletePatientTagsCollection(patientId, data = {}) {
  const requestUrl = API_URLS.removePatientTagsList;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace("{patientId}", patientId);

  return fetch(url, requestOptions).then(handleResponse);
}

export function getLineSetting(dpid = null) {

  const requestUrl = API_URLS.getLineSetting;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  let url = requestUrl.url;
  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function updateLineSettingData(data = {}, dpid = null) {
  const requestUrl = API_URLS.updateLineSetting;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url;

  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}
export function fetchClinicSetting(dpid = null) {
  const requestUrl = API_URLS.getClinicSetting;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let url = requestUrl.url;
  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

// Dashboard summary data fetching
export function fetchAppointmentSummary(month, dpid) {
  const requestUrl = API_URLS.getAnalyticsSummary;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  let url = requestUrl.url.replace("{month}", month);
  if (dpid) {
    url = url + "&dpid=" + dpid;
  }

  return fetch(url, requestOptions).then(handleResponse);
}
export function fetchRetentionSummary(range, dpid) {
  const requestUrl = API_URLS.getRetentionSummary;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  let url = requestUrl.url.replace("{range}", range);
  if (dpid) {
    url = url + "&dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}
export function fetchChartData(range, year, dpid = null) {
  const requestUrl = API_URLS.getChartData;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  let url = requestUrl.url.replace("{range}", range).replace("{year}", year);
  if (dpid) {
    url = url + "&dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function fetchAppointmentTypes(dpid = null) {
  const requestUrl = API_URLS.getAppointmentTypes;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  let url = requestUrl.url;
  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function createAppointmentTypes(data = {}, dpid = null) {
  const requestUrl = API_URLS.createAppointmentType;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url;

  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function updateAppointmentTypes(typeId, data = {}, dpid = null) {
  const requestUrl = API_URLS.updateAppointmentType;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace("{typeId}", typeId);
  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function createClinicAvailableHoursSchedule(data = {}, dpid = null) {
  const requestUrl = API_URLS.createClinicAvailableHours;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url;

  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function updateClinicSchduleAvailableHours(
  availableTimeId,
  data = {},
  dpid = null
) {
  const requestUrl = API_URLS.updateClinicAvailableHours;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace("{availableTimeId}", availableTimeId);
  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function deleteAppointmentTypes(typeId, dpid = null) {
  const requestUrl = API_URLS.deleteAppointmentType;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let url = requestUrl.url.replace("{typeId}", typeId);
  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function updateClinicSettings(data = {}, dpid = null) {
  const requestUrl = API_URLS.updateClinicSetting;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url;

  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function fetchClinicAvailableHours(searchQuery, dpid = null) {
  const requestUrl = API_URLS.getClinicAvailableHours;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let url = requestUrl.url + `?${searchQuery}`;
  if (dpid) {
    url = url + "&dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function updateClinicMyInfo(data = {}, dpid = null) {
  const requestUrl = API_URLS.updateClinicInfo;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url;

  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}
export function updateClinicAccountAvatar(formData = {}, dpid = null) {
  const requestUrl = API_URLS.updateAccountAvatar;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader() },
    body: formData,
  };
  let url = requestUrl.url;

  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function updateClinicMyAccount(data = {}, dpid = null) {
  const requestUrl = API_URLS.updateCliMyAccount;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url;

  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}
export function addClinicDentist(data = {}, dpid = null) {
  const requestUrl = API_URLS.createClinicDentistAccounts;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url;

  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function getClinicDentistDetail(id, dpid) {
  const requestUrl = API_URLS.getClinicDentistAccountDetails;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  let url = requestUrl.url.replace("{dentistId}", id);
  if (dpid) {
    url = url + "?dpid=" + dpid;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

export function updateClinicDentistAccountDetails(
  dentistId,
  data = {},
  dpid = null
) {
  const requestUrl = API_URLS.updateClinicDentistAccountDetails;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace("{dentistId}", dentistId);
  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

//BroadCastMessage

export function fetchBroadcastMessageCollection(dpid = null) {
  const requestUrl = API_URLS.getBroadcastMessagesCollection;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  let url = requestUrl.url;
  if (dpid) {
    url = url + "?dpid=" + dpid;
  }

  return fetch(url, requestOptions).then(handleResponse);
}
export function fetchBroadcastFilterOption(dpid = null) {
  const requestUrl = API_URLS.getBroadcastFilterOption;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  let url = requestUrl.url;
  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}
// createBroadcastMessagesCollection

export function addBroadcastMessages(data = {}, dpid=null) {
  const requestUrl = API_URLS.createBroadcastMessages;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url;
  if (dpid) {
    url = url + "?dpid=" + dpid;
  }

  return fetch(url, requestOptions).then(handleResponse);
}
