import { schedulingConstants } from '../_constants'
import moment from 'moment';
/*
 * Update state for state.scheduling here...
 * have to copy state then return it
 * sets entire state, doesn't update just one field
 * shortcuts: https://daveceddia.com/immutable-updates-react-redux/
 *
 * init {appointments: [], dentistList: [], }
 *
 */

const initApptForm = {
    patient: "",
    start_time: "",
    end_time: "",
    dentist: "",
    notes: ""
}

const initPatientForm = {
    first_name: "",
    middle_name: "",
    last_name: "",
    email_address: "",
    phone_number: "",
    date_of_birth: ""
}


const initState = {
	appointments: [],
	activityLog: [],
	dentistList: [],
	dentalPracticePatients: [{label: "", id:0}],
	dentalPracticeProviders: [],
	dentalPracticeOperatories: [],
	loading: false,
	loadingApptForm: false,
	loadingPatientForm: false,
	apptFormOpen: false,
	errorPatientForm: "",
	errorApptForm: "",
	error: "",
	selDate: new Date(),
	selView: "work_week",
	patientForm: {...initPatientForm},
	patientFormOpen: false,
	apptForm: {...initApptForm},
	currentPatient: null,
	addDentistFormOpen: false,
	addDentistForm: {dentist: ""}
};

export function scheduling(state = initState, action){
	switch (action.type){
		// open Appt form
		case schedulingConstants.OPENAPPTFORM:
			return {
				...state,
				apptFormOpen: true,
				apptForm: {
					...action.apptForm					
				}
			}
		case schedulingConstants.OPENNEWAPPTFORM:
			return {
				...state,
				apptFormOpen: true,
				apptForm: {
					...action.form					
				}
			}
		// close form Appt form
		case schedulingConstants.CLOSEAPPTFORM:
			return {
				...state,
				apptFormOpen: false,
				receivedAppt: null,
				currentPatient: null,
				apptForm: {...initApptForm}
			}
		// clear form from received appt
		case schedulingConstants.CLEARAPPTFORM:
			return {
				...state,
				receivedAppt: null,
				currentPatient: null
			}
		case schedulingConstants.CHANGEAPPTFORM:
			return {
				...state,
				apptForm: {...state.apptForm ,...action.form}
			}

		case schedulingConstants.CHANGEAPPTPATIENT:
			return {
				...state,
				apptForm: {...state.apptForm, patient: action.patient.id},
				currentPatient: {...action.patient}
			}

		// open Patient form
		case schedulingConstants.OPENPATIENTFORM:
			return {
				...state,
				patientFormOpen: true
			}
		// close form Patient form
		case schedulingConstants.CLOSEPATIENTFORM:
			return {
				...state,
				patientFormOpen: false,
				patientForm: {...initPatientForm}
			}

		// open Add Dentist form
		case schedulingConstants.OPENADDDENTISTFORM:
			return {
				...state,
				addDentistFormOpen: true
			}
		// close Add Dentist form
		case schedulingConstants.CLOSEADDDENTISTFORM:
			return {
				...state,
				addDentistFormOpen: false,
				addDentistForm: {dentist: ""}
			}
		case schedulingConstants.CHANGEPATIENTFORM:
			return {
				...state,
				patientForm: {...state.patientForm, ...action.form}
			}
		// change calendar view
		case schedulingConstants.CHANGEVIEW:
			const currTime = new Date();
			const currDate = state.selDate
			currDate.setHours(currTime.getHours());
			currDate.setMinutes(currTime.getMinutes());
			return {
				...state,
				selView: action.view,
				selDate: currDate
			};

		// change calendar date from small month calendar
		case schedulingConstants.CHANGEDATE:
			return {
				...state,
				selDate: action.date
			};

		// get patient list
		case schedulingConstants.GETPRACTICEPATIENTS_REQUEST:
			return {
				...state,
				error: "",
				loading: true
			};
		case schedulingConstants.GETPRACTICEPATIENTS_SUCCESS:
			return {
				...state,
				error: "",
				loading: false,
				dentalPracticePatients: action.data.patient_list.map( (patient, index) => {
					  let patientName, birthDate;
					  if (patient.middle_name){
					    patientName = patient.first_name + " " + patient.middle_name+ " " + patient.last_name
					  } else {
					    patientName = patient.first_name + " "+ patient.last_name
					  }
					  if (patient.date_of_birth){
					    birthDate = moment(patient.date_of_birth).format('L') + " ";
					  } else {
					    birthDate = " ";
					  }

					  return{ ...patient, "label": patientName + " " + birthDate + (patient.phone_number && patient.phone_number) + " " + (patient.email_address && patient.email_address)}
				})
			};
		case schedulingConstants.GETPRACTICEPATIENTS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};


		// get operatory list
		case schedulingConstants.GETPRACTICEOPERATORIES_REQUEST:
			return {
				...state,
				error: "",
				loading: true
			};
		case schedulingConstants.GETPRACTICEOPERATORIES_SUCCESS:
			return {
				...state,
				error: "",
				loading: false,
				dentalPracticeOperatories: action.data.operatory_list
			};
		case schedulingConstants.GETPRACTICEOPERATORIES_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};


		// get dentist list
		case schedulingConstants.GETPRACTICEPROVIDERS_REQUEST:
			return {
				...state,
				error: "",
				loading: true
			};
		case schedulingConstants.GETPRACTICEPROVIDERS_SUCCESS:
			return {
				...state,
				error: "",
				loading: false,
				dentalPracticeProviders: action.data.dentist_list.map( (dentist, index) => {
					  let patientName;
					  if (dentist.middle_name){
					    patientName = dentist.first_name + " " + dentist.middle_name+ " " + dentist.last_name
					  } else {
					    patientName = dentist.first_name + " "+ dentist.last_name
					  }

					  return{ "id": dentist.id, "name": patientName }
				})
			};
		case schedulingConstants.GETPRACTICEPROVIDERS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};

		// get appointments for calendar req, succ, fail
		case schedulingConstants.GETAPPOINTMENTS_REQUEST:
			return {
				...state,
				error: "",
				loading: true
			};
		case schedulingConstants.GETAPPOINTMENTS_SUCCESS:
			return {
				...state,
				error: "",
				loading: false,
				appointments: action.data.appointments,
				dentistList: action.data.dentists,
				dentistListID: action.data.dentists.map( (k) =>  k.id)
			};
		case schedulingConstants.GETAPPOINTMENTS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};

		// get Activity Log  req, succ, fail
		case schedulingConstants.GETACTIVITYLOG_REQUEST:
			return {
				...state,
				error: "",
				loadingActivityLog: true
			};
		case schedulingConstants.GETACTIVITYLOG_SUCCESS:
			if (action.data.activity_log.length > 0){
				return {
					...state,
					error: "",
					loadingActivityLog: false,
					activityLog: action.data.activity_log.concat(state.activityLog).slice(0,9),
					activityLogLatest : action.data.activity_log[0].created_at
				};
			}else {
				return {
					...state,
					error: "",
					loadingActivityLog: false,
				};
			}
		case schedulingConstants.GETACTIVITYLOG_FAILURE:
			return {
				...state,
				loadingActivityLog: false,
				error: action.error
			};

		// get Appointments req, suc, fail
		case schedulingConstants.GETAPPOINTMENT_BYID_REQUEST:
			return {
				...state,
				error: "",
				loadingApptForm: true
			};
		case schedulingConstants.GETAPPOINTMENT_BYID_SUCCESS:
			return {
				...state,
				error: "",
				loadingApptForm: false,
				receivedAppt: action.data.appointment
			};
		case schedulingConstants.GETAPPOINTMENT_BYID_FAILURE:
			return {
				...state,
				loadingApptForm: false,
				error: action.error
			};

		// add dentist filter
		case schedulingConstants.ADDDENTIST:
			return {
				...state,
				dentistList: action.dentistList,
				dentistListID: action.dentistList.map( (k) =>  k.id)
			};
		// remove dentist filter
		case schedulingConstants.REMOVEDENTIST:
			return {
				...state,
				dentistList: action.dentistList,
				dentistListID: action.dentistList.map( (k) =>  k.id)
			};

		// create a new appointment req, succ, fail
		case schedulingConstants.CREATEAPPOINTMENT_REQUEST:
			return {
				...state,
				loadingApptForm: true,
				errorApptForm: ""
			};
		case schedulingConstants.CREATEAPPOINTMENT_SUCCESS:
			return {
				...state,
				appointments: [...state.appointments, action.data.appointment],
				loadingApptForm:false,
				errorApptForm: "",
				apptFormOpen: false,
				receivedAppt: null,
				apptForm: {...initApptForm},
				
			};
		case schedulingConstants.CREATEAPPOINTMENT_FAILURE:
			return {
				...state,
				loadingApptForm:false,
				errorApptForm: "Something went wrong"
			}

		// create a new patient in appointment form req, succ, fail
		case schedulingConstants.CREATEPATIENT_REQUEST:
			return {
				...state,
				loadingPatientForm: true,
				errorPatientForm: ""
			};
		case schedulingConstants.CREATEPATIENT_SUCCESS:
			let patientName, birthDate;
			if (action.data.patient.middle_name){
			    patientName = action.data.patient.first_name + " " + action.data.patient.middle_name+ " " + action.data.patient.last_name
			} else {
			    patientName = action.data.patient.first_name + " "+ action.data.patient.last_name
			}
			  if (action.data.patient.date_of_birth){
			    birthDate = moment(action.data.patient.date_of_birth).format('L') + " ";
			} else {
			    birthDate = " ";
			}
			return {
				...state,
				dentalPracticePatients: [...state.dentalPracticePatients, {...action.data.patient, "label": patientName + " " + birthDate + (action.data.patient.phone_number && action.data.patient.phone_number) + " " + (action.data.patient.email_address && action.data.patient.email_address) }],
				currentPatient : {...action.data.patient, "label": patientName + " " + birthDate + (action.data.patient.phone_number && action.data.patient.phone_number) + " " + (action.data.patient.email_address && action.data.patient.email_address) },
				apptForm: {
					...state.apptForm,
					patient: action.data.patient.id
				},
				patientFormOpen: false,
				loadingPatientForm: false,
				errorPatientForm: ""
			};
		case schedulingConstants.CREATEPATIENT_FAILURE:
			return {
				...state,
				loadingPatientForm:false,
				errorPatientForm: action.error
			}
    default:
      return state
	}
}