const storage = {
  getToken: () => {
    try {
      return JSON.parse(window.localStorage.getItem(`key`));
    } catch {
      return null;
    }
  },
  setToken: (token) => {
    window.localStorage.setItem(`key`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`key`);
  },
  getDefaultCamera: () => {
    try {
      return JSON.parse(window.localStorage.getItem(`camera`));
    } catch {
      return null;
    }
  },
  setDefaultCamera: (token) => {
    window.localStorage.setItem(`camera`, JSON.stringify(token));
  },
  clearDefaultCamera: () => {
    window.localStorage.removeItem(`camera`);
  },
  setCountry: (country) => {
    window.localStorage.setItem(`country`, JSON.stringify(country));
  },
  setClinicCountry: (country) => {
    window.localStorage.setItem(`clinic_country`, country);
  },
  getCountry: () => {
    try {
      return JSON.parse(window.localStorage.getItem(`country`));
    } catch {
      return null;
    }
  },
  setLocale: (locale) => {
    window.localStorage.setItem(`locale`, JSON.stringify(locale));
  },
  getLocale: () => {
    try {
      return JSON.parse(window.localStorage.getItem(`locale`));
    } catch {
      return null;
    }
  },
  setDashboardSetting: (setting, data) => {
    window.localStorage.setItem(`dashboard_${setting}`, JSON.stringify(data));
  },
  getDashboardSetting: (setting) => {
    try {
      return JSON.parse(window.localStorage.getItem(`dashboard_${setting}`));
    } catch {
      return null;
    }
  },
  clearDashboardSetting: (setting) => {
    window.localStorage.removeItem(`dashboard_${setting}`);
  },

  setCurrentDate: (currentDate) => {
    window.localStorage.setItem(`currentDate`, currentDate);
  },
};

export default storage;
