import { useQuery } from 'react-query';

import { queryConfig } from 'js/_libs/react-query';

import { fetchLineClinicDetails } from './api/getLinePractice';

export const QUERY_KEY_NAME = 'LINEClinicDetails';

export const getLineClinicDetails = ({ clinicId, dpid}) => {
  return fetchLineClinicDetails(clinicId, dpid)
};

const newQueryConfig = {
    ...queryConfig, 
    queries : {
      ...queryConfig.queries, 
      refetchOnMount: false
    },
};

export const LineClinicDetailsQuery = ({ clinicId, config}) => {
  return useQuery({
    ...newQueryConfig,
    ...config,
    queryKey: [QUERY_KEY_NAME, {clinicId}],
    queryFn: () => getLineClinicDetails({ clinicId}),
  });
}; 
