import { API_URLS } from 'js/_config';
import { authHeader, handleResponse } from 'js/_helpers';

export function fetchLineClinicDetails(clinicId, dpid=null){
  const requestUrl = API_URLS.lineCheck;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{clinicId}', clinicId);

  console.log("url ", url)

  if (dpid){
    url = url + '?dpid='+ dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}
