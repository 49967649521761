import { createSelector } from 'reselect'

const getIntlSlice = (state) => state.intl;
export const getIntl = createSelector(
  [getIntlSlice],
  (intl) => {
    return intl;
  }
)
export const getIntlMessages = createSelector(
  [getIntlSlice],
  (intl) => {
    return intl.messages;
  }
)
export const getIntlLocale = createSelector(
  [getIntlSlice],
  (intl) => {
    return intl.locale;
  }
)