import React from 'react';

import PopupDialogConfirm from './PopupDialogConfirm';

const tempi18n = {
	'dialog.confirm': "Confirm" ,
	'dialog.title': "Title" ,
	'buttons.continue': "Continue",
	'buttons.cancel': "Cancel"
}

function PopupDialogUnsaved (props) {
	return (
		<PopupDialogConfirm
			content={tempi18n['dialog.confirm']}
			title={tempi18n['dialog.title']}

			confirmButtonText={tempi18n['buttons.continue']}
			cancelButtonText={tempi18n['buttons.cancel']}

			onConfirm={props.onConfirm}
			onCancel={props.onCancel}
		/>
	);
}

export default PopupDialogUnsaved;
