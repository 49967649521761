import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
//import { reduxPollingMiddleware } from 'redux-polling';
import { default as reduxPollingMiddleware} from '../ActivityNotifications/middleware';
import { createLogger } from 'redux-logger';
import rootReducer from '../_reducers';
import createSagaMiddleware from 'redux-saga';

import storage from '../_helpers/storage';

import rootSaga from './saga';

// mount it on the Store

const loggerMiddleware = createLogger();
// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

export const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware,
        sagaMiddleware,
        reduxPollingMiddleware
    )
);

//run the saga
sagaMiddleware.run(rootSaga)
