import React from 'react';
import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import { v4 as uuidv4 } from 'uuid';

const PubnubProvider = ({ children, ...props }) => {
    const pubnub = new PubNub({
        subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBEKEY,
        uuid: uuidv4()
        
    });

    return (
        <PubNubProvider client={pubnub}>
            {children}
        </PubNubProvider>
    )
}

export default PubnubProvider;