import { Icon } from '@chakra-ui/react';

const PrevArrowIcon = (props) => {
  return (
    <Icon width="6px" height="10px" viewBox="0 0 6 10" color={'white'} {...props}>
      <path 
        d="M6 0.335417L6 9.66458C6 9.9444 5.70564 10.1006 5.50469 9.92736L0.11569 5.26277C-0.038564 5.12926 -0.038564 4.87216 0.11569 4.73723L5.50469 0.0726433C5.70564 -0.100645 6 0.0555992 6 0.335417Z" 
        fill="currentColor"
      />
    </Icon>
  );
};

export default PrevArrowIcon;
