import { useState } from "react";
import { LoginEmailForm } from "./LoginEmailForm";
import { LoginEmailFormCode } from "./LoginEmailFormCode";


export const LoginEmailFormContainer = ({onBack}) => {
    const [ isReset, setIsReset ] = useState(false);
  
    const handleResetChange = () => {
      setIsReset(!isReset);
    }
  
    if (isReset === true){
      return (
        <LoginEmailFormCode onBack={onBack} handlePageChange={handleResetChange} />
      ) 
    } else {
        return (
          <LoginEmailForm onBack={onBack} handlePageChange={handleResetChange} />
        )
    }
  }