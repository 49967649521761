
import React, { useState } from 'react';

import { authHeader } from 'js/_helpers/auth-header';
import { useSelector, useDispatch } from 'react-redux';
import { toUpper } from 'lodash';
import { 
  FormControl, 
  FormLabel, 
  FormHelperText,
  Input, 
  FormErrorMessage ,
  Flex,
} from '@chakra-ui/react';
import { AdminHelpPopover } from 'js/_components/Forms/AdminHelpPopover';
import { SearchFieldComponent } from 'js/_components/Forms/SearchFieldComponent';
import { API_URLS } from 'js/_config';

import { isFieldErrorfromMeta, getErrorMessage } from 'js/_libs/forms';
import { getIntlMessages, getIntlLocale } from 'js/Intl';
import { getAdminCurrentDentalPractice } from 'js/Settings';

const getDpidValue = (dpid) => {
  return dpid && dpid.value || dpid;
}


const formatOptionLabel = ({value, label, data, ...props}) => {
  if (value?.length === 0 || value === '0' || value === 0){
    return(
      <Flex p={2} align="center">
        <div fontSize="18px" fontFamily="Proxima Nova Semibold" mr={5}>{label}</div>
      </Flex>
    )
  } else {
    return(
      <Flex p={2} align="center">
        <Flex direction="column">
          <div fontSize="18px" fontFamily="Proxima Nova Semibold" mr={5}>{data?.practice_name} {data?.internal_practice_name ? `( ${data?.internal_practice_name} )` : ''} - {data?.address}</div>
        </Flex>
      </Flex>
    )
  }
}

export const ClinicSearchNoInput = ({label, name, value, meta, onSelect, isInAdminMode=false, isAdminPage=false, helpheader=null, helpbody= null, disabled=false, clinicType="scanning", placeholder=null, dpid=null}) => {
  const intlMessages = useSelector(getIntlMessages);
  const intlLocale = useSelector(getIntlLocale);
  const currentAdminDentalPractice = useSelector(getAdminCurrentDentalPractice);


  const onChange = (val) => {
    onSelect(val);
  }

  const onInputChange = (val) => {
  }

  const handleClinicsLoading = (input) => {
    
    if (!input){
      return Promise.resolve({ options: []})
    }

    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    };

    let url = API_URLS.dentalPracticeSearch.url+`?q=${input}`;
    if (dpid || currentAdminDentalPractice) {
      url = url + `&dpid=${getDpidValue(dpid) || currentAdminDentalPractice}`;
    }

    return fetch(url, requestOptions)
      .then((response) => response.json())
      .then((json) => {
        const options = json.search_list.map((obj, ind) => {
          return {value: obj.value,
                  label: obj.label,
                  data: obj,
          }
        })
        return [ ...options ];
      });
  }

  console.log("clinicsearhcnoinput ", isAdminPage, value)
  return (
    <>

        <SearchFieldComponent
          name={name}
          intlMessages={intlMessages}
          intlLocale={intlLocale}
          value = {value}
          label={label}
          disabled={disabled}
          intl={intlMessages['patientForm.patient.search']}
          placeholder={placeholder}
          formatOptionLabel={formatOptionLabel}
          createNewValue={null}
          onInputChange={onInputChange}
          loadOptions={handleClinicsLoading}
          onChange={onChange}
          selectStyles={{
            control: (provided, state) => {
              let backgroundColor = '#FFFFFF';
              let borderColor = state.isFocused ? '#3182ce' : '#E2E8F0'
              if (!isAdminPage){
                if (state.hasValue){
                  backgroundColor = '#7B8887';
                } else {
                  borderColor = '#ff0000'; // show light red to indicate that this is required
                }
              }
              return {
                ...provided,
                boxShadow: state.isFocused ? '0 0 0 1px #3182ce' : 'none' , 
                borderColor, 
                height: 40,
                "&:hover": {
                  borderColor: '#CBD5E0'
                },
                backgroundColor,
              }
            },
            singleValue: (provided, state) => {
              let color = '#7B8887';
              if (!isAdminPage && state.hasValue){
                color = '#FFFFFF';
              }
              return { 
                ...provided, 
                fontFamily: 'Poppins',
                color
              }
            }
          }}
        />
    </>
  )

}