import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';
import localeData from '../../locales/data.json';
import { updateIntl } from 'react-intl-redux'

export const userActions = {
    login,
    logout,
    resetPassword,
    updateActivityLog,
    register,
    getInfo,
    getAll,
    delete: _delete
};

function resetPassword(password, password2, uid, token) {
    return dispatch => {
        dispatch(request());

        userService.resetPassword(password, password2, uid, token)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(data) { return { type: userConstants.ENDUSER_RESETPASSWORD_REQUEST } }
    function success(data) { return { type: userConstants.ENDUSER_RESETPASSWORD_SUCCESS } }
    function failure(error) { return { type: userConstants.ENDUSER_RESETPASSWORD_FAILURE, error } }
};

function login(email, password, redirectURL) {
    return dispatch => {
        dispatch(request({ email }));

        userService.login(email, password)
            .then(
                user => {
                    dispatch(success(user));
                    history.push(redirectURL);
                },
                error => {
                    dispatch(failure(error));
                    console.log(error)
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
};

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
};

function register(user) {
    return dispatch => {
        dispatch(request(user));

        userService.register(user)
            .then(
                user => {
                    dispatch(success());
                    history.push('/login');
                    dispatch(alertActions.success('Registration successful'));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
};

function getInfo() {
    return dispatch => {
        dispatch(request());

        userService.getInfo()
            .then(
                user => {
                  // gotta update locale
                  dispatch(updateIntl({locale: user?.user?.language || localStorage.lang || 'en-us', messages: localeData[user?.user?.language || localStorage.lang  || 'en-us']}));
                  dispatch(success(user));
                },
                error => {
                  dispatch(failure(error))
                }
            );
    };

    function request() { return { type: userConstants.GETINFO_REQUEST } }
    function success(user) { return { type: userConstants.GETINFO_SUCCESS, user } }
    function failure(error) { return { type: userConstants.GETINFO_FAILURE, error } }
};

function updateActivityLog(ts){
  const data = {'read_at': ts}
  return dispatch => {
    userService.updateActivityLog(data)
      .then(

      )
  }
}

function getAll() {
    return dispatch => {
        dispatch(request());

        userService.getAll()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
};

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        userService.delete(id)
            .then(
                user => {
                    dispatch(success(id));
                },
                error => {
                    dispatch(failure(id, error));
                }
            );
    };

    function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: userConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error } }
};
