
import React, { useEffect } from 'react';
import { Redirect, useLocation, useHistory } from 'react-router-dom';

import { useAuth } from '../_libs/auth';

export function RequireAuth({ children }) {
  const auth = useAuth();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if ((auth?.user?.user?.roles || []).includes('DENTAL')){
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)){
        // If it's a mobile device, redirect to a different route
        history.push('/appointments/scanqr');
      }
    }
  }, [history, auth?.user?.user?.roles]);  // Depend on 'history' to run only once at component mount

  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
  }

  if (auth.user && (auth.user.user.roles || []).includes('DENTAL')) {
    if (!location.pathname.startsWith('/appointments')) {
      return <Redirect to={{ pathname: '/appointments', state: { from: location } }} />;
    }
  }

  return children;
}
