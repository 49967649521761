export const patientRequestsConstants = {

  GET_PATIENTDETAILS_REQUEST: 'PATIENTREQUEST_GET_PATIENTDETAILS_REQUEST',
  GET_PATIENTDETAILS_SUCCESS: 'PATIENTREQUEST_GET_PATIENTDETAILS_SUCCESS',
  GET_PATIENTDETAILS_FAILURE: 'PATIENTREQUEST_GET_PATIENTDETAILS_FAILURE',

  GET_PATIENTREQUESTS_REQUEST: 'PATIENTREQUEST_GET_PATIENTREQUESTS_REQUEST',
  GET_PATIENTREQUESTS_SUCCESS: 'PATIENTREQUEST_GET_PATIENTREQUESTS_SUCCESS',
  GET_PATIENTREQUESTS_FAILURE:  'PATIENTREQUEST_GET_PATIENTREQUESTS_FAILURE',

  GET_PATIENTREQUESTIMAGES_REQUEST: 'PATIENTREQUEST_GET_PATIENTREQUESTIMAGES_REQUEST',
  GET_PATIENTREQUESTIMAGES_SUCCESS: 'PATIENTREQUEST_GET_PATIENTREQUESTIMAGES_SUCCESS',
  GET_PATIENTREQUESTIMAGES_FAILURE:  'PATIENTREQUEST_GET_PATIENTREQUESTIMAGES_FAILURE',

  GET_PATIENTIMAGESET_REQUEST: 'PATIENTREQUEST_GET_PATIENTIMAGESET_REQUEST',
  GET_PATIENTIMAGESET_SUCCESS: 'PATIENTREQUEST_GET_PATIENTIMAGESET_SUCCESS',
  GET_PATIENTIMAGESET_FAILURE: 'PATIENTREQUEST_GET_PATIENTIMAGESET_FAILURE',

  GET_PATIENTNOTES_REQUEST: 'PATIENTREQUEST_GET_PATIENTNOTES_REQUEST',
  GET_PATIENTNOTES_SUCCESS: 'PATIENTREQUEST_GET_PATIENTNOTES_SUCCESS',
  GET_PATIENTNOTES_FAILURE: 'PATIENTREQUEST_GET_PATIENTNOTES_FAILURE',

  EDIT_REQUESTMESSAGE_REQUEST: 'PATIENTREQUEST_EDIT_REQUESTMESSAGE_REQUEST',
  EDIT_REQUESTMESSAGE_SUCCESS: 'PATIENTREQUEST_EDIT_REQUESTMESSAGE_SUCCESS',
  EDIT_REQUESTMESSAGE_FAILURE: 'PATIENTREQUEST_EDIT_REQUESTMESSAGE_FAILURE',

  GET_PATIENTREQUESTDETAILS_REQUEST: 'PATIENTREQUEST_GET_PATIENTREQUESTDETAILS_REQUEST',
  GET_PATIENTREQUESTDETAILS_SUCCESS: 'PATIENTREQUEST_GET_PATIENTREQUESTDETAILS_SUCCESS',
  GET_PATIENTREQUESTDETAILS_FAILURE: 'PATIENTREQUEST_GET_PATIENTREQUESTDETAILS_FAILURE',

  UPDATE_IMAGESET: 'PATIENTREQUEST_UPDATE_IMAGESET',
  UPDATE_PATIENTNOTES: 'PATIENTREQUEST_UPDATE_PATIENTNOTES',
  UPDATE_CONSULTREQUESTMESSAGES: 'PATIENTREQUEST_UPDATE_CONSULTREQUESTMESSAGES',
  UPDATE_CONSULTREQUESTDETAILS: 'PATIENTREQUEST_UPDATE_CONSULTREQUESTDETAILS',
  UPDATE_CONSULTREQUESTMESSAGES_TREATMENTPLAN: 'UPDATE_CONSULTREQUESTMESSAGES_TREATMENTPLAN',
  UPDATE_CONSULTREQUESTMESSAGES_TREATMENTPLAN_CONFIRMED: 'UPDATE_CONSULTREQUESTMESSAGES_TREATMENTPLAN_CONFIRMED',
  UPDATE_CONSULTREQUESTMESSAGES_TREATMENTPLAN_REJECTED: 'UPDATE_CONSULTREQUESTMESSAGES_TREATMENTPLAN_REJECTED',

  UPDATE_MESSAGEATTACHMENT: 'PATIENTREQUEST_UPDATE_MESSAGEATTACHMENT',
  ADD_MESSAGEATTACHMENT: 'PATIENTREQUEST_ADD_MESSAGEATTACHMENT',

  CHANGE_PATIENTIMAGESETSTATUS_REQUEST: 'PATIENTREQUEST_CHANGESTATUS_PATIENTIMAGESETSTATUS_REQUEST',
  CHANGE_PATIENTIMAGESETSTATUS_SUCCESS: 'PATIENTREQUEST_CHANGESTATUS_PATIENTIMAGESETSTATUS_SUCCESS',
  CHANGE_PATIENTIMAGESETSTATUS_FAILURE: 'PATIENTREQUEST_CHANGESTATUS_PATIENTIMAGESETSTATUS_FAILURE',

  UPDATE_PATIENT_DETAILS: 'PATIENTREQUEST_UPDATE_PATIENT_DETAILS',
}
