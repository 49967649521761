import {forEach} from 'lodash';
import JSZip from 'jszip';
import moment from 'moment';
import { saveAs } from 'file-saver';

function zipMultiple(imageList, fileName, onError=null){
  var zip = new JSZip();
  var count = 0;
  forEach(imageList, async function(value){
    let singleFileName = value.image_group+"."+value.file_name[0].split(".").slice(-1)[0];
    let resp = null;
    try{
      resp = await fetch(value.url, {
        method: 'GET'
      });
    } catch (err) {
      console.log("zipmmultiple error ", err);
      onError && onError(err);
      return;
    }
    if (!resp){
      console.log("empty download response ")
      return;
    }
    const downloadedFile = await resp.blob();
    console.log("response",downloadedFile)
    console.log("response",resp)
    zip.file(singleFileName, downloadedFile, {binary:true} )
    count++;
    if (count === imageList.length) {
      zip.generateAsync({type:'blob'}).then(function(content) {
        saveAs(content, fileName);
      });
    }
  })

/*
  [].forEach(function(file){
    var filename = file.name;
    // loading a file and add it in a zip file
    JSZipUtils.getBinaryContent(file.url, function (err, data) {
      if(err) {
        throw err; // or handle the error
      }
      zip.file(filename, data, {binary:true});
      count++;
      if (count == urls.length) {
        zip.generateAsync({type:'blob'}).then(function(content) {
          saveAs(content, fileName);
        });
      }
    });
  });*/
}
export { zipMultiple };
