import { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  VStack,
  Flex,
  Box,
  FormControl,
  FormLabel,
  Text,
  InputGroup,
  Input,
  InputRightElement,
  FormHelperText,
  FormErrorMessage,
  Button,
} from "@chakra-ui/react";
import { useAuth } from "../_libs/auth";

import { InputField } from "js/_components/Forms/InputField";
import {
  required,
  isFieldErrorfromMeta,
  getErrorMessage,
} from "js/_libs/forms";

import { upperFirst } from "lodash";
import { isBlank } from "js/_helpers";
import { useCountdown } from "js/_hooks/useCountdown";

import { userService } from "js/_services";

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";

import { FormattedMessage } from "react-intl";
import { ButtonFilled } from "js/_components/Button/ButtonFilled";
import PrevArrowIcon from "images/icons/PrevArrowIcon";
import { useIntl } from "react-intl";

export const LoginEmailFormCode = ({ onBack }) => {
  const urlParams = useParams();
  const location = useLocation();
  const history = useHistory();
  const { formatMessage } = useIntl();

  const { login, isLoggingIn } = useAuth();

  const [countdown, setCountdown] = useState(60);
  const [isCounting, setIsCounting] = useState(false);

  useEffect(() => {
    let interval;

    if (isCounting && countdown > 0) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsCounting(false);
    }

    return () => clearInterval(interval);
  }, [isCounting, countdown]);

  const onSuccessfulLogin = (from) => {
    if (from && !from.includes("/login")) {
      console.log("from ", from);
      console.log("so go to... ", location.state.from.pathname);
      history.push(location.state.from.pathname);
    } else {
      console.log("from ", from);
      console.log("so go to... ", "appointments");
      history.push("/appointments");
    }
  };

  const handleSendCode = async (email = null) => {
    // send code
    console.log("send code please");
    if (!isCounting || isLoggingIn || isBlank(email)) {
    }
    if (isCounting || isLoggingIn || isBlank(email)) {
      return;
    }
    await userService.requestLoginCode({ email });
    setCountdown(60);
    setIsCounting(true);
  };

  const handleLogin = async (values) => {
    let from = location?.state?.from?.pathname;
    let loginCreds = {
      ...values,
    };
    if (values.hasOwnProperty("code")) {
      loginCreds["provider"] = "code";
    } else {
      loginCreds["provider"] = "";
    }

    try {
      const resp = await login(loginCreds);
      if (resp?.role === "DENT") {
        history.push("/appointments");
      } else if (from && from !== "/login") {
        history.push(location.state.from.pathname);
        return null;
      } else {
        history.push("/");
        return null;
      }
    } catch (err) {
      return {
        ...err,
        [FORM_ERROR]: "general error",
      };
    }
  };

  return (
    <Form
      onSubmit={handleLogin}
      //mutators={{ setFieldData }}
      render={({
        handleSubmit,
        reset,
        submitting,
        pristine,
        validating,
        values,
      }) => (
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Flex
            h={["100%", "fit-content"]}
            direction={"column"}
            justify={["center", "space-between"]}
          >
            <Box>
              <Flex justify={"flex-start"} align={"center"}>
                <PrevArrowIcon
                  onClick={onBack}
                  _hover={{
                    cursor: "pointer",
                  }}
                  height={"15px"}
                  width={"15px"}
                  color={"black"}
                />
                <Text
                  ml={10}
                  color={"#1B2B29"}
                  fontWeight={"600"}
                  fontSize={"30px"}
                >
                  <FormattedMessage
                    id="loginForm.loginButton.label"
                    defaultMessage={"Log In"}
                  />
                </Text>
              </Flex>
              <VStack spacing={10} mt={10}>
                <Field name="email" validate={required}>
                  {({ input, meta }) => (
                    <InputField
                      borderColor={"rgba(123, 136, 135, 0.5)"}
                      borderRadius={"15px"}
                      inputProps={{
                        borderRadius: "15px",
                        minH: "50px",
                      }}
                      input={input}
                      meta={meta}
                      type="email"
                      label={formatMessage({
                        id: "appointments.setting.tabs.email.label",
                        defaultMessage: "Email Address",
                      })}
                      placeholder={formatMessage({
                        id: "appointments.setting.tabs.email.label",
                        defaultMessage: "Email Address",
                      })}
                    />
                  )}
                </Field>

                <Field
                  name={urlParams.login_type === "admin" ? "password" : "code"}
                  validate={required}
                >
                  {({ input, meta }) => (
                    <FormControl
                      id={input.name}
                      isInvalid={isFieldErrorfromMeta(meta)}
                    >
                      <FormLabel align="center" fontSize={"14px"} pb={1}>
                        {urlParams.login_type === "admin" ? (
                          <FormattedMessage
                            id={"loginpage.password.label"}
                            description={"loginpage password label"}
                            defaultMessage={upperFirst("password")}
                          />
                        ) : (
                          <FormattedMessage
                            id={"loginpage.authorizationCode.label"}
                            description={"loginpage authorization code label"}
                            defaultMessage={"Verification Code"}
                          />
                        )}
                      </FormLabel>
                      <InputGroup size="md">
                        <Input
                          {...input}
                          borderColor={"rgba(123, 136, 135, 0.5)"}
                          borderRadius={"15px"}
                          pr="80px"
                          type={
                            urlParams.login_type === "admin"
                              ? "password"
                              : "text"
                          }
                          minH="50px"
                          fontSize={"14px"}
                          background="white"
                          placeholder={
                            urlParams.login_type === "admin"
                              ? upperFirst("enter password")
                              : formatMessage({
                                  id: "appointments.setting.tabs.verificationCode.label",
                                  defaultMessage: "Enter verification code",
                                })
                          }
                        />
                        <InputRightElement width="80px" h={"50px"}>
                          <Flex h="50px" align={"center"}>
                            <Button
                              isDisabled={
                                isBlank(values.email) ||
                                isCounting ||
                                isLoggingIn
                              }
                              letterSpacing={"1px"}
                              size="sm"
                              variant={"link"}
                              color={"#179B8B"}
                              onClick={() => handleSendCode(values.email)}
                            >
                              {!isCounting ? (
                                <FormattedMessage
                                  id={"loginpage.authorizationCode.sendCode"}
                                  description={
                                    "loginpage authorization code send code button"
                                  }
                                  defaultMessage={"send code"}
                                />
                              ) : (
                                `${countdown} s`
                              )}
                            </Button>
                          </Flex>
                        </InputRightElement>
                      </InputGroup>
                      {getErrorMessage(meta) ? (
                        <FormErrorMessage>
                          {getErrorMessage(meta)}
                        </FormErrorMessage>
                      ) : (
                        <FormHelperText></FormHelperText>
                      )}
                    </FormControl>
                  )}
                </Field>
              </VStack>
            </Box>
            <Flex mt={10}>
              <ButtonFilled
                bg={`brandLight`}
                w={"100%"}
                py={10}
                type={"submit"}
                isLoading={submitting}
              >
                <FormattedMessage
                  id="loginForm.loginButton.label"
                  defaultMessage={"Log In"}
                />
              </ButtonFilled>
            </Flex>
          </Flex>
        </form>
      )}
    />
  );
};
