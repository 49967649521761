import {
    Modal,
    ModalContent,
    ModalOverlay,
    ModalHeader,
    Box,
    ModalBody,
    ModalFooter,
    Button,
    Text,
    Flex,
    Link
} from '@chakra-ui/react';
import { useHistory} from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { AiOutlineClose } from "react-icons/ai";
import { useNotificationStore } from 'js/Notifications/store';
import { useEffect } from 'react';

import { GetBillingPortal } from './api/useBillingQuery';

/**
 *  Billing Modal Permission Popup. S
 *  Opens when api returns 499 status code.
 * @param {*} param0 
 * @returns 
 */

const BillingButton = ({onClick, isEnabled=false}) => {
    const { data, isLoading, isError } = GetBillingPortal({referrerUrl: window.location.href, enabled: isEnabled});

    const handleClick = () => { 
        onClick(data?.url);
    }

    return (
        <Link href={data?.url} isExternal>
            <Button 
                variant='solid'
                colorScheme='brandDark'
                onClick={handleClick}
                textAlign={'center'}
                isLoading={isLoading}
                isDisabled={isError}
            >
                <FormattedMessage 
                    id="billingModal.permissions.button.label"
                    defaultMessage="Open Payment Settings"
                />
            </Button>
        </Link>
    )
}

export const BillingModal = ({isOpen=false, onClose}) => {
    const history = useHistory();
    const error = useNotificationStore((state)=> state.error)
    const clearError = useNotificationStore((state)=> state.clearError)

    const handleClick = () => {
        clearError();
        //history.push("/appointments/settings");
    }

    return (
        <Modal
            size={"xl"}
            isCentered
            isOpen={isOpen || (error !== null)}
            onClose={onClose}
        >
        <ModalOverlay bg="blackAlpha.300" />
        <ModalContent p={"10px 30px 10px 30px"}>
            <ModalHeader display={"flex"} alignItems={"center"}>
            <Text width={"100%"}>
                <FormattedMessage 
                    id={"billingModal.title"}
                    description={"billingModal.title"}
                    defaultMessage={"Billing"}
                />
            </Text>
            <Box
                style={{ cursor: "pointer" }}
                position={"absolute"}
                right={"3rem"}
                onClick={handleClick}
            >
                <AiOutlineClose fontSize={"20px"} color={"#179B8B"} />
            </Box>
            </ModalHeader>
            <ModalBody>
            <Box w="full">
                <Text
                color={"#7B8887"}
                fontSize={"14px"}
                fontWeight={"400"}
                textAlign={"center"}
                p={"0"}
                >
                <FormattedMessage
                    id={"billingModal.text.permissions"}
                    description={"billingModal.text.permissions"}
                    defaultMessage={
                    "Your account is currently not active. Please check your billing settings."
                    }
                />
                </Text>
            </Box>
            </ModalBody>
            <ModalFooter>
                <Flex justify={'center'} w={'full'}>
                    <BillingButton 
                        isEnabled={error !== null}
                    />
                </Flex>
            </ModalFooter>
        </ModalContent>
        </Modal>
    )
}