import { activityNotificationsActions } from './actions';

const { actionTypes, isPollingAction } = activityNotificationsActions;

const initialPollingState = {
    isActive: false,
    requestPayload: undefined,
    history: [],
    lastEntry: undefined,
    firstEntry: undefined
};

export const reduxPollingNamespace = 'activityNotificationsPolling';

export function getPollingState(state, pollingName) {
    return (state[reduxPollingNamespace] || {})[pollingName] || initialPollingState;
}

function createPollingReducer() {
    const initialState = {};

    return function (state = initialState, action) {
        if (!isPollingAction(action)) {
            return state;
        }
        const { meta: { pollingName, historyLimit } } = action;
        const prevPollingState = state[pollingName];
        let nextPollingState;
        let nextHistory;
        let lastEntry;
        let firstEntry;

        switch (action.type) {
            case actionTypes.start:
                nextPollingState = {
                    ...initialPollingState,
                    ...prevPollingState,
                    isActive: true,
                    requestPayload: action.payload,
                };
                break;

            case actionTypes.stop:
                nextPollingState = {
                    ...prevPollingState,
                    isActive: false,
                };
                break;

            case actionTypes.reset:
                nextPollingState = initialPollingState;
                break;

            case actionTypes.markRead:
              if (action.payload.length > 0){
                nextHistory = prevPollingState.history.map( (obj, ind) => {
                  if (obj.data.message.id === action.payload[0]){
                    obj.data.message.unread = false;
                  }
                  return obj;
                })

                nextPollingState = {
                  ...prevPollingState,
                  history: nextHistory
                };
              } else {
                nextPollingState = prevPollingState;
              }
              break;

            case actionTypes.addEntries:
                //TODO: reverse order here
                if (historyLimit === 0) {
                    nextHistory = prevPollingState.history;
                } else {
                    nextHistory = [ ...prevPollingState.history, ...action.payload ];

                    if (historyLimit > 0) {
                        nextHistory = nextHistory.slice(-historyLimit);
                    }
                }
                if (prevPollingState.history.length === 0 && action.payload.length > 0) {
                  [ firstEntry ] = action.payload.slice(0,1);
                }
                [ lastEntry ] = action.payload.slice(-1);

                nextPollingState = {
                    ...prevPollingState,
                    history: nextHistory,
                    lastEntry,
                    firstEntry
                };
                break;

            case actionTypes.addPreviousEntries:
                //TODO: reverse order here
                if (historyLimit === 0) {
                    nextHistory = prevPollingState.history;
                } else {
                    nextHistory = [...action.payload,  ...prevPollingState.history ];

                    if (historyLimit > 0) {
                        nextHistory = nextHistory.slice(-historyLimit);
                    }
                }

                if (action.payload.length > 0){
                  [ firstEntry ] = action.payload.slice(0,1);
                }

                nextPollingState = {
                    ...prevPollingState,
                    history: nextHistory,
                    firstEntry
                };
                break;

            default: nextPollingState = undefined;
                break;
        }

        const nextState = !nextPollingState ? state : {
            ...state,
            [pollingName]: nextPollingState,
        };

        return nextState;
    };
}

export default createPollingReducer();
