import React from 'react';
import { lazyImport } from '../../_helpers/lazyImport';

import { Switch, Redirect } from 'react-router-dom';

import { PrivateRouteAlt } from '../../_components';
const { TreatmentPlans } = lazyImport(()=> import("./TreatmentPlans"), "TreatmentPlans")
const { TreatmentPlanDetails } = lazyImport(() => import("./TreatmentPlanDetails"), "TreatmentPlanDetails")

const TreatmentPlanRoutes = () => {
  return (
    <Switch>
      <PrivateRouteAlt  path="/treatmentplans/treatment/:treatmentId"><TreatmentPlanDetails /></PrivateRouteAlt>
      <PrivateRouteAlt  path="/treatmentplans/:pageType"><TreatmentPlans /></PrivateRouteAlt>
      <PrivateRouteAlt  path="/treatmentplans"><Redirect to={'/treatmentplans/treatments'} /></PrivateRouteAlt>
    </Switch>
  )
}

export default TreatmentPlanRoutes;