import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Flex, Box
} from '@chakra-ui/react';
import cn from 'classnames';
import { AVAILABLE_LANGUAGES } from '../_config';
import { settingsActions } from '../_actions';

import { updateIntl } from 'react-intl-redux';
import { useLocale } from '../_hooks/useLocale'

const baseCssClassName = 'LanguageBar';
const zhCssClassName = `${baseCssClassName}__zh`;
const dividerCssClassName = `${baseCssClassName}__divider`;
const enCssClassName = `${baseCssClassName}__en`;

export const LanguageBar = () => {
  const { setLocale } = useLocale();

  const onClickTH = () => {
    setLocale('th')
    //this.props.dispatch(updateIntl({locale: 'th', messages: localeData.th}))
  }

  const onClickEN = () => {
    setLocale('en')
    //this.props.dispatch(updateIntl({locale: 'en', messages: localeData.en}))
  }

  const onClickLanguage = (lang) => {
    setLocale(lang)
    //this.props.dispatch(settingsActions.setLanguage(lang));
  }

  return (
    <Flex 
      position={'absolute'}
      right={0}
      w={['full', 'fit-content']}
      px={[4, '100px']}
      py={8}
      justify={['center', 'flex-end']}
      fontSize={[16, 20]}
      color={'#405159'}
      fontFamily={'Noto Sans SC, Proxima Nova, sans-serif'}
    >
      { AVAILABLE_LANGUAGES.map( (lang, indx) => {
        return (
          <>
            <Box 
              fontFamily={(lang==='zh') ? 'Noto Sans SC, sans-serif' : 'Noto Sans SC, Proxima Nova, sans-serif'}
              onClick={() => onClickLanguage(lang.value)}
              _hover={{cursor:'pointer'}}
            >
              {lang.label}
            </Box>
            { (indx !== AVAILABLE_LANGUAGES.length-1) &&
              <Box 
                key={`${lang.value}_div`}  
                mx={[4]}
              >
                  |
              </Box>
            }
          </>
        )
      }) }
    </Flex>
  )
}