import React, { Component, Fragment } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';

import cn from 'classnames';

import {
  Button as ButtonChakra, Flex, Box, Center
} from '@chakra-ui/react';

import googlesvg from '../../images/GoogleIcon.svg';
import { GoogleIcon } from '../../images/googleIcon';
import { GoogleLogin, GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';

import { useAuth } from '../_libs/auth';

import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from "final-form";

import { LanguageBar } from '../HomePage';

import { toUpper, upperFirst } from 'lodash';

import { URLS, GOOGLE_CLIENT_ID } from '../_config';

import { getIntlMessages, getIntlLocale } from '../Intl';

import { userConstants } from '../_constants';
import { userActions } from '../_actions';
import { userService } from '../_services';

import '../../css/login.style.css';

// components are from ai project
import Spinner from '../_components/Spinner';
import Alignment from "../_components/Alignment/Alignment";

import Button from '../_components/Button/Button';

const baseCssClassName = 'Login';
const formCssClassName = `${baseCssClassName}__Form`;
const formHeaderCssClassName = `${formCssClassName}__Header`;
const formErrorCssClassName = `${formCssClassName}__FormError`;
const formLogoCssClassName = `${formCssClassName}__Logo`;
const formMainCssClassName = `${formCssClassName}__Form-main`;
const formSecondaryCssClassName = `${formCssClassName}__Form-secondary`;
const formButtonCssClassName = `${formCssClassName}__FormButton`;
const messageCssClassName = `${formCssClassName}__Message`;
const messageTextCssClassName = `${formCssClassName}__MessageText`;
const formHelpCssClassName = `${formCssClassName}__Help`;
const formForgotTextCssClassName = `${formCssClassName}__forgotText`;
const formRegisterTextCssClassName = `${formCssClassName}__registerText`;
const formRegisterTextOneCssClassName = `${formRegisterTextCssClassName}__LineOne`;
const formRegisterTextTwoCssClassName = `${formRegisterTextCssClassName}__LineTwo`;


/* form helpers */
const REGEX_CHINESE = /[\u4e00-\u9fff]|[\u3400-\u4dbf]|[\u{20000}-\u{2a6df}]|[\u{2a700}-\u{2b73f}]|[\u{2b740}-\u{2b81f}]|[\u{2b820}-\u{2ceaf}]|[\uf900-\ufaff]|[\u3300-\u33ff]|[\ufe30-\ufe4f]|[\uf900-\ufaff]|[\u{2f800}-\u{2fa1f}]/u;

const required = value => (value ? undefined : 'required')
const mustBeNumber = value => (isNaN(value) ? 'must_be_number' : undefined)
const minValue = min => value =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`
const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)
const noChineseCharacters = str => (REGEX_CHINESE.test(str) ? 'no_chinese_characters' : undefined)

export const LoginPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { login, isLoggingIn } = useAuth();
  const [ isGoogleLoading, setGoogleLoading ] = React.useState(false);
  const [ googleError, setGoogleError ] = React.useState(false);
  const [ generalFormError, setGeneralFormError ] = React.useState(false);
  const intlMessages = useSelector(getIntlMessages);
  const intlLocale = useSelector(getIntlLocale);


  // handles form submission
  const handleLogin = async (values) => {
    if (isGoogleLoading) return;
    let from = location?.state?.from?.pathname
    try{
      await login({...values, provider: ""});
      if (from && from.startsWith('/login')){
        history.push(location.state.from.pathname)
      } else {
        history.push('/');
      }
    } catch (err){
      return {
        ...err,
        [FORM_ERROR] : upperFirst(intlMessages['loginForm.generalError'])
      }
    }
  }

  const handleLoginGoogle = async (response) => {
    let from = location?.state?.from?.pathname
    setGoogleLoading(true);
    setGoogleError(false);
    // NOT THIS ONE const access_tok = response.accessToken;
    const tok_id = response.credential //THIS ONE
    if (!tok_id){
      setGoogleError(upperFirst(intlMessages['form.error.general.google']) )
      setGoogleLoading(false);
      return
    }
    try{
      const authCredentials = { code: tok_id, provider: 'google'};
      await login(authCredentials);
      if (from && from !== '/login'){
        history.push(location.state.from.pathname)
      } else {
        history.push('/');
      }
    } catch (err){
      setGeneralFormError(intlMessages['form.error.general.error'])
    } finally {
      setGoogleLoading(false);
    }
  }
  
  return (
    <Box
      height={'100vh'}
      background={['#fff', '#f7f9fa']}
      fontFamily={'Proxima Nova'}
    >
      <LanguageBar />
      <Center h="100%">
        <Flex className={formCssClassName}
          background={'#FFF'}
          px={[8, 20]}
          pb={50}
          pt={[110, 50]}
          minH={['full', 500]}
          minW={['full', 350]}
          direction={'column'}
        >
        <Form
          onSubmit={handleLogin}
          //mutators={{ setFieldData }}
          render = {({
            submitError,
            formError,
            handleSubmit,
            mutators,
            form,
            reset,
            submitting,
            pristine,
            validating,
            values
          }) => (
            <form
              onSubmit={handleSubmit}
              >
              <Box className={formLogoCssClassName} 
                fontSize={20}
                color={'gray'}
                textAlign={'center'}
                letterSpacing={'6px'}
              >
                <Box>
        					BEFOREDENT<span></span>
        				</Box>
              </Box>
              <div className={formHeaderCssClassName}>
                <span>{toUpper(intlMessages['loginForm.login'])}</span>
                <div className={cn(formErrorCssClassName)}>
                  {(submitError || generalFormError) && <div className="Error">{generalFormError || formError || submitError}</div>}
                </div>
              </div>
              <div className={formMainCssClassName}>
                {validating && <Spinner />}
                <Field name="email" validate={composeValidators(required)}>
                  {({ input, meta}) => (
                    <div className="">
                      <input {...input} className="form-control Input_Text" type="email" placeholder={upperFirst(intlMessages['loginForm.email'])} />
                      <div className={cn("Error__Label", {"Error": (meta.error || meta.submitError) && meta.touched} )} >
                        {(meta.error || meta.submitError) && meta.touched && intlMessages[`form.error.${(meta.error || meta.submitError)}`]}
                      </div>
                    </div>
                  )}
                </Field>
                <Field name="password" validate={composeValidators(required)}>
                  {({ input, meta}) => (
                    <div className="">
                      <input {...input} className="form-control Input_Text" type="password" placeholder={upperFirst(intlMessages['loginForm.password'])} />
                      <div className={cn("Error__Label", {"Error": (meta.error || meta.submitError) && meta.touched} )} >
                        {(meta.error || meta.submitError) && meta.touched && intlMessages[`form.error.${(meta.error || meta.submitError)}`]}
                      </div>
                    </div>
                  )}
                </Field>
              </div>
              <div className={formButtonCssClassName}>
                <ButtonChakra
                    w={'250px'}
                    py={'25px'}
                    px={'35px'}
                    letterSpacing={'1.4px'}
                    fontWeight={'normal'}
                    margin={0}
                    fontSize={16}
                    colorScheme={'brand'}
                    variant={'primary'}
                    isLoading={submitting}
                    isDisabled={validating}
                    type={"submit"}
                  >
                  {toUpper(intlMessages['loginForm.login'])}
                </ButtonChakra>
              </div>
              <Flex 
                w={'250px'} 
                mt={[6, 4]} mx={'auto'} 
                align="center" direction="column"
                pointerEvents={submitting ? 'none' : 'all'}
                >
                <GoogleLogin
                  shape={'pill'}
                  logo_alignment={'left'}
                  width={'250'}
                  theme={'filled_blue'}
                  locale={intlLocale}
                  onSuccess={handleLoginGoogle}
                  onError={(err) => {
                    setGoogleError(intlMessages['form.error.general.google'])
                  }}
                />
                <div className={cn(formErrorCssClassName)}>
                  {googleError && <div className="Error">{googleError}</div>}
                </div>
              </Flex>
              {/*<Flex justify={'center'} mt={4}>
                  <ButtonChakra
                    onClick={() => googleLogin()}
                    minW={'200px'}
                    minH={'48px'}
                    isLoading={isGoogleLoading} 
                    background={'#FFF'}
                    fontSize={14}
                    padding={"15px 20px"}
                    color={"#273238"}
                    border={"1px solid #efefef"}
                    boxShadow={'md'}
                  >
                    {intlMessages['login.form.google.button.text'] || 'Sign in with Google'}
                  </ButtonChakra>
                  {googleError && <Text color="red" fontSize="10px" fontWeight="bold">{googleError}</Text>}
                  </Flex>*/}
            </form>
          )}
        />
          <div className={formHelpCssClassName}>
            <div className={formForgotTextCssClassName}>
              <Link
                to={URLS.forgotPassword}
              >
                <span>{intlMessages['loginForm.forgot']}</span>
              </Link>
            </div>
          </div>
          <Flex
            textAlign={'center'}
            justify={'center'}
            flexGrow={[1, 0]}
            align={'flex-end'}
          >
            <Flex direction={'column'}
              mt={[6,115]}
            >
                <span className={formRegisterTextOneCssClassName}>{intlMessages['loginForm.registerText']}</span>
              <Link
                to={'/'}
                className={formRegisterTextTwoCssClassName}
              >
                <span>{toUpper(intlMessages['loginForm.register'])}</span>
              </Link>
            </Flex>
          </Flex>
        </Flex>
      </Center>
    </Box>
  )
}

