import { authHeader, handleResponse } from '../_helpers';
import { BASE_URL, API_URLS } from '../_config';
import storage from '../_helpers/storage';

export const userService = {
    login,
    loginSocial,
    requestLoginCode,
    loginCode,
    logout,
    connectLine,
    register,
    forgotPassword,
    resetPassword,
    changePassword,
    updateSettings,
    getInfo,
    getAll,
    getById,
    update,
    getActivityLog,
    updateActivityLog,
    delete: _delete
};

function connectLine(data, country, clinicId, token){
  const requestUrl = API_URLS.lineLink;
  const requestOptions = {
      method: requestUrl.method,
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(data)
  };

  let url = requestUrl.replace("{clinicId}", clinicId).replace("{token}", token)

  return fetch(url, requestOptions)
      .then(response => {
          if (!response.ok) {
              return response.json().then(Promise.reject.bind(Promise));
          }
          return response.json();
      })
      .then(data => {
          return data;
      });
}

function requestLoginCode(data){
  const requestUrl = API_URLS.requestLoginCode;
  const requestOptions = {
      method: requestUrl.method,
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(data)
  };

  return fetch(requestUrl.url, requestOptions)
      .then(response => {
          if (!response.ok) {
              return response.json().then(Promise.reject.bind(Promise));
          }
          return response.json();
      })
      .then(data => {
          return data;
      });
}

function loginCode(data) {
    const requestUrl = API_URLS.loginCode;
    const requestOptions = {
        method: requestUrl.method,
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    };

    console.log("requestUrl ", requestUrl)
    return fetch(requestUrl.url, requestOptions)
        .then(response => {
            if (!response.ok) {
                return response.json().then(Promise.reject.bind(Promise));
            }
            return response.json();
        })
        .then(data => {
            return data;
        });
}

function login(data) {
    const requestUrl = API_URLS.login;
    const requestOptions = {
        method: requestUrl.method,
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    };

    console.log("requestUrl ", requestUrl)
    return fetch(requestUrl.url, requestOptions)
        .then(response => {
            if (!response.ok) {
                return response.json().then(Promise.reject.bind(Promise));
            }
            return response.json();
        })
        .then(data => {
            return data;
        });
}

function loginSocial(data) {
  const requestUrl = API_URLS.loginSocial;
  const requestOptions = {
      method: requestUrl.method,
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(data)
  };

  return fetch(requestUrl.url, requestOptions)
      .then(response => {
          if (!response.ok) {
              return response.json().then(Promise.reject.bind(Promise));
          }
          return response.json();
      })
      .then(data => {
        return data;
      });
}

function logout() {
    const requestUrl = API_URLS.logout;
    const requestOptions = {
        method: requestUrl.method,
        headers: { 'Content-Type': 'application/json', ...authHeader()},
        body: JSON.stringify({})
    };

    return fetch(requestUrl.url, requestOptions).then(
        response => {
          return
        }
    )
}

function forgotPassword(data){
  const requestUrl = API_URLS.forgotPassword;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url;
  console.log("fetching now...")

  return fetch(url, requestOptions).then(handleResponse);
}

function resetPassword(data){
  const requestUrl = API_URLS.resetPassword;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url;

  return fetch(url, requestOptions).then(handleResponse);
}

function changePassword(data){
  const requestUrl = API_URLS.changePassword;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url;

  return fetch(url, requestOptions).then(handleResponse);
}

function getInfo() {
    const requestUrl = API_URLS.getUserinfo;
    const requestOptions = {
      method: requestUrl.method,
      headers: { ...authHeader()}
    };
    let url = requestUrl.url;

    return fetch(url, requestOptions).then(handleResponse);
}

function updateSettings(data){
  const requestUrl = API_URLS.updateSettings;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url;

  return fetch(url, requestOptions).then(handleResponse);
}

function updateActivityLog(data) {
  const requestUrl = API_URLS.updateActivityLog;
    const requestOptions = {
      method: requestUrl.method,
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(BASE_URL+'/activity/update', requestOptions).then(handleResponse);;
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(BASE_URL+'/api/d/dental_account/accounts', requestOptions).then(handleResponse);
}

function getById(_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(BASE_URL+'/users/' + _id, requestOptions).then(handleResponse);
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' , 'Referrer-Policy': 'no-referrer-when-downgrade'},
        body: JSON.stringify(user)
    };

    return fetch(BASE_URL+'/users/register', requestOptions).then(handleResponse);
}

function getActivityLog(){
  const requestUrl = API_URLS.getActivityLog;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader()}
  };
  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(BASE_URL+'/users/' + user.id, requestOptions).then(handleResponse);;
}



// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(BASE_URL+'/users/' + id, requestOptions).then(handleResponse);;
}
