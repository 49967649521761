export const schedulingConstants = {
	GETAPPOINTMENTS_REQUEST : 'SCHEDULING_GETAPPOINTMENTS_REQUEST',
	GETAPPOINTMENTS_SUCCESS : 'SCHEDULING_GETAPPOINTMENTS_SUCCESS',
	GETAPPOINTMENTS_FAILURE : 'SCHEDULING_GETAPPOINTMENTS_FAILURE',

	GETAPPOINTMENT_BYID_REQUEST : 'SCHEDULING_GETAPPOINTMENT_BYID_REQUEST',
	GETAPPOINTMENT_BYID_SUCCESS : 'SCHEDULING_GETAPPOINTMENT_BYID_SUCCESS',
	GETAPPOINTMENT_BYID_FAILURE : 'SCHEDULING_GETAPPOINTMENT_BYID_FAILURE',

	ADDDENTIST: 'SCHEDULING_ADDDENTIST',
	REMOVEDENTIST: 'SCHEDULING_REMOVEDENTIST',

	GETACTIVITYLOG_REQUEST: 'SCHEDULING_GETACTIVITYLOG_REQUEST',
	GETACTIVITYLOG_SUCCESS: 'SCHEDULING_GETACTIVITYLOG_SUCCESS',
	GETACTIVITYLOG_FAILURE: 'SCHEDULING_GETACTIVITYLOG_FAILURE',

	GETPRACTICEOPERATORIES_REQUEST: 'SCHEDULING_GETPRACTICEOPERATORIES_REQUEST',
	GETPRACTICEOPERATORIES_SUCCESS: 'SCHEDULING_GETPRACTICEOPERATORIES_SUCCESS',
	GETPRACTICEOPERATORIES_FAILURE: 'SCHEDULING_GETPRACTICEOPERATORIES_FAILURE',

	GETPRACTICEPROVIDERS_REQUEST: 'SCHEDULING_GETPRACTICEPROVIDERS_REQUEST',
	GETPRACTICEPROVIDERS_SUCCESS: 'SCHEDULING_GETPRACTICEPROVIDERS_SUCCESS',
	GETPRACTICEPROVIDERS_FAILURE: 'SCHEDULING_GETPRACTICEPROVIDERS_FAILURE',

	GETPRACTICEPATIENTS_REQUEST: 'SCHEDULING_GETPRACTICEPATIENTS_REQUEST',
	GETPRACTICEPATIENTS_SUCCESS: 'SCHEDULING_GETPRACTICEPATIENTS_SUCCESS',
	GETPRACTICEPATIENTS_FAILURE: 'SCHEDULING_GETPRACTICEPATIENTS_FAILURE',


	CREATEPATIENT_REQUEST : 'SCHEDULING_CREATEPATIENT_REQUEST',
	CREATEPATIENT_SUCCESS : 'SCHEDULING_CREATEPATIENT_SUCCESS',
	CREATEPATIENT_FAILURE : 'SCHEDULING_CREATEPATIENT_FAILURE',

	CREATEAPPOINTMENT_REQUEST : 'SCHEDULING_CREATEAPPOINTMENT_REQUEST',
	CREATEAPPOINTMENT_SUCCESS : 'SCHEDULING_CREATEAPPOINTMENT_SUCCESS',
	CREATEAPPOINTMENT_FAILURE : 'SCHEDULING_CREATEAPPOINTMENT_FAILURE',


	OPENADDDENTISTFORM: 'SCHEDULING_OPENADDDENTISTFORM',
	CLOSEADDDENTISTFORM: 'SCHEDULING_CLOSEADDDENTISTFORM',
	OPENAPPTFORM: 'SCHEDULING_OPENAPPTFORM',
	OPENNEWAPPTFORM: 'SCHEDULING_OPENNEWAPPTFORM',
	CLOSEAPPTFORM: 'SCHEDULING_CLOSEAPPTFORM',
	CLEARAPPTFORM: 'SCHEDULING_CLEARAPPTFORM',
	OPENPATIENTFORM: 'SCHEDULING_OPENPATIENTFORM',
	CLOSEPATIENTFORM: 'SCHEDULING_CLOSEPATIENTFORM',
	CHANGEDATE: 'SCHEDULING_CHANGE_DATE',
	CHANGEVIEW: 'SCHEDULING_CHANGE_VIEW',

	CHANGEAPPTPATIENT: 'SCHEDULING_CHANGEAPPTPATIENT',
	CHANGEAPPTFORM: 'SCHEDULING_CHANGEAPPTFORM',
	CHANGEPATIENTFORM: 'SCHEDULING_CHANGEPATIENTFORM',
}