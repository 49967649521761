import { API_URLS } from '../../_config';
import { authHeader, handleResponse } from '../../_helpers';

// clinic calls
export function fetchChannels(){
  const requestUrl = API_URLS.getChannels;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url
  return fetch(url, requestOptions).then(handleResponse);
}
