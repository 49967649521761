import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { RequireAuth } from '../_routes';
import { MainLayout } from './Layout/MainLayout';
export const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={props => (
        <RequireAuth>
          <Component {...props} />
        </RequireAuth>
    )} />
  )
}
export const PrivateRouteAlt = ({children, ...rest}) => {
  return (
    <Route {...rest}>
        <RequireAuth>
          <MainLayout>
            {children}
          </MainLayout>
        </RequireAuth>
     </Route>
  )
}