export const adminConstants = {

  SEARCH_MODELS_REQUEST: 'ADMIN_SEARCH_MODELS_REQUEST' ,
  SEARCH_MODELS_SUCCESS: 'ADMIN_SEARCH_MODELS_SUCCESS' ,
  SEARCH_MODELS_FAILURE: 'ADMIN_SEARCH_MODELS_FAILURE' ,
  SEARCH_MODELS_CLEAR: 'ADMIN_SEARCH_MODELS_CLEAR' ,

  CREATE_NEWACCOUNT_REQUEST: 'ADMIN_CREATE_NEWACCOUNT_REQUEST',
  CREATE_NEWACCOUNT_SUCCESS: 'ADMIN_CREATE_NEWACCOUNT_SUCCESS',
  CREATE_NEWACCOUNT_FAILURE: 'ADMIN_CREATE_NEWACCOUNT_FAILURE',

  GET_PRACTICEDETAILS_REQUEST: 'ADMIN_GET_PRACTICEDETAILS_REQUEST' ,
  GET_PRACTICEDETAILS_SUCCESS: 'ADMIN_GET_PRACTICEDETAILS_SUCCESS' ,
  GET_PRACTICEDETAILS_FAILURE: 'ADMIN_GET_PRACTICEDETAILS_FAILURE' ,

  UPDATE_PRACTICEDETAILS: 'ADMIN_UPDATE_PRACTICEDETAILS',

  GET_ACCOUNTDETAILS_REQUEST: 'ADMIN_GET_ACCOUNTDETAILS_REQUEST' ,
  GET_ACCOUNTDETAILS_SUCCESS: 'ADMIN_GET_ACCOUNTDETAILS_SUCCESS' ,
  GET_ACCOUNTDETAILS_FAILURE: 'ADMIN_GET_ACCOUNTDETAILS_FAILURE' ,

  UPDATE_ACCOUNTDETAILS: 'ADMIN_UPDATE_ACCOUNTDETAILS',

	GET_USERACCOUNTS_REQUEST: 'ADMIN_GET_USERACCOUNTS_REQUEST',
	GET_USERACCOUNTS_SUCCESS: 'ADMIN_GET_USERACCOUNTS_SUCCESS',
	GET_USERACCOUNTS_FAILURE: 'ADMIN_GET_USERACCOUNTS_FAILURE',


	GET_PRACTICELOCATIONS_REQUEST: 'ADMIN_GET_PRACTICELOCATIONS_REQUEST',
	GET_PRACTICELOCATIONS_SUCCESS: 'ADMIN_GET_PRACTICELOCATIONS_SUCCESS',
	GET_PRACTICELOCATIONS_FAILURE: 'ADMIN_GET_PRACTICELOCATIONS_FAILURE',

	OPEN_USERACCOUNTFORM : 'ADMIN_OPEN_USERACCOUNTFORM',
	CLOSE_USERACCOUNTFORM : 'ADMIN_CLOSE_USERACCOUNTFORM',
	CHANGE_USERACCOUNTFORM : 'ADMIN_CHANGE_USERACCOUNTFORM',
	CHANGE_DENTALACCOUNTFORM : 'ADMIN_CHANGE_DENTALACCOUNTFORM',
	CANCEL_DENTALACCOUNTFORM : 'ADMIN_CANCEL_DENTALACCOUNTFORM',

	CHANGE_DENTALACCOUNTAVAILABILITY: 'ADMIN_CHANGE_DENTALACCOUNTAVAILABILITY',
	EDIT_DENTALACCOUNT_AVAILABILITY_REQUEST: 'ADMIN_EDIT_DENTALACCOUNTAVAILABILITY_REQUEST',
	EDIT_DENTALACCOUNT_AVAILABILITY_SUCCESS: 'ADMIN_EDIT_DENTALACCOUNTAVAILABILITY_SUCCESS',
	EDIT_DENTALACCOUNT_AVAILABILITY_FAILURE: 'ADMIN_EDIT_DENTALACCOUNTAVAILABILITY_FAILURE',


	EDIT_DENTALACCOUNT_INFO_REQUEST: 'ADMIN_EDIT_DETALACCOUNTFORM_REQUEST',
	EDIT_DENTALACCOUNT_INFO_SUCCESS: 'ADMIN_EDIT_DETALACCOUNTFORM_SUCCESS',
	EDIT_DENTALACCOUNT_INFO_FAILURE: 'ADMIN_EDIT_DETALACCOUNTFORM_FAILURE',

	SUBMIT_USERACCOUNTFORM_REQUEST: 'ADMIN_SUBMIT_USERACCOUNTFORM_REQUEST',
	SUBMIT_USERACCOUNTFORM_SUCCESS: 'ADMIN_SUBMIT_USERACCOUNTFORM_SUCCESS',
	SUBMIT_USERACCOUNTFORM_FAILURE: 'ADMIN_SUBMIT_USERACCOUNTFORM_FAILURE',



	GET_USERACCOUNTINFO_REQUEST: 'ADMIN_GET_USERACCOUNTINFO_REQUEST',
	GET_USERACCOUNTINFO_SUCCESS: 'ADMIN_GET_USERACCOUNTINFO_SUCCESS',
	GET_USERACCOUNTINFO_FAILURE: 'ADMIN_GET_USERACCOUNTINFO_FAILURE',

	CANCEL_PRACTICELOCATIONFORM: 'ADMIN_CANCEL_PRACTICELOCATIONFORM',
	CHANGE_PRACTICELOCATIONFORM: 'ADMIN_CHANGE_PRACTICELOCATIONFORM',
	GET_PRACTICELOCATIONINFO_REQUEST: 'ADMIN_GET_PRACTICELOCATIONINFO_REQUEST',
	GET_PRACTICELOCATIONINFO_SUCCESS: 'ADMIN_GET_PRACTICELOCATIONINFO_SUCCESS',
	GET_PRACTICELOCATIONINFO_FAILURE: 'ADMIN_GET_PRACTICELOCATIONINFO_FAILURE',

	EDIT_PRACTICELOCATION_INFO_REQUEST: 'ADMIN_EDIT_PRACTICELOCATIONFORM_REQUEST',
	EDIT_PRACTICELOCATION_INFO_SUCCESS: 'ADMIN_EDIT_PRACTICELOCATIONFORM_SUCCESS',
	EDIT_PRACTICELOCATION_INFO_FAILURE: 'ADMIN_EDIT_PRACTICELOCATIONFORM_FAILURE',

	CHANGE_PAGECONTENT : 'ADMIN_CHANGE_PAGECONTENT',
}
