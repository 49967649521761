import { useEffect } from 'react';
import { VStack, Flex } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';

import { useNotificationStore } from './store';

import { Notification } from './Notification';

export const Notifications = () => {
  const notifications = useNotificationStore((state)=> state.notifications)
  const dismissNotification = useNotificationStore((state) => state.dismissNotification)


  return (
      <Flex justify={["center", "flex-end"]} w={"full"} bottom="26px" right="10px" position={'absolute'} zIndex={9999}>
        <VStack width={['100%', "max-content"]} align={'flex-end'} spacing={3} zIndex="10" marginTop={5} mx={3}>
          {(notifications || []).map((notification) => (
            <AnimatePresence key={notification.id}>
              <Notification
                key={notification.id}
                notification={notification}
                onDismiss={dismissNotification}
              />
            </AnimatePresence>
          ))}
        </VStack>
      </Flex>
  );
};
