import { Icon } from '@chakra-ui/react';

const LogoutIcon = (props) => {
  const iconWidth = props?.width || 18;
  const iconHeight = props?.height || 15;
  return (
    <Icon 
      viewBox="0 0 18 15" 
      color={'white'} 
      {...props}
      width={`${iconWidth}px`}
      height={`${iconHeight}px`}
    >        
        <path d="M7.34694 1.125C7.34694 1.09375 7.35077 1.01562 7.35842 0.890625C7.36607 0.765625 7.36798 0.662109 7.36416 0.580078C7.36033 0.498047 7.34885 0.40625 7.32972 0.304688C7.31059 0.203125 7.27232 0.126953 7.21492 0.0761719C7.15753 0.0253906 7.07908 0 6.97959 0H3.30612C2.39541 0 1.61671 0.330078 0.970026 0.990234C0.323342 1.65039 0 2.44531 0 3.375V11.625C0 12.5547 0.323342 13.3496 0.970026 14.0098C1.61671 14.6699 2.39541 15 3.30612 15H6.97959C7.07908 15 7.16518 14.9629 7.23788 14.8887C7.31059 14.8145 7.34694 14.7266 7.34694 14.625C7.34694 14.5938 7.35077 14.5156 7.35842 14.3906C7.36607 14.2656 7.36798 14.1621 7.36416 14.0801C7.36033 13.998 7.34885 13.9062 7.32972 13.8047C7.31059 13.7031 7.27232 13.627 7.21492 13.5762C7.15753 13.5254 7.07908 13.5 6.97959 13.5H3.30612C2.80102 13.5 2.36862 13.3164 2.00893 12.9492C1.64923 12.582 1.46939 12.1406 1.46939 11.625V3.375C1.46939 2.85938 1.64923 2.41797 2.00893 2.05078C2.36862 1.68359 2.80102 1.5 3.30612 1.5H6.88776L7.01977 1.48828L7.15179 1.45312L7.24362 1.38867L7.32398 1.2832L7.34694 1.125ZM18 7.5C18 7.29688 17.9273 7.12109 17.7819 6.97266L11.537 0.597656C11.3916 0.449219 11.2194 0.375 11.0204 0.375C10.8214 0.375 10.6492 0.449219 10.5038 0.597656C10.3584 0.746094 10.2857 0.921875 10.2857 1.125V4.5H5.14286C4.94388 4.5 4.77168 4.57422 4.62628 4.72266C4.48087 4.87109 4.40816 5.04688 4.40816 5.25V9.75C4.40816 9.95312 4.48087 10.1289 4.62628 10.2773C4.77168 10.4258 4.94388 10.5 5.14286 10.5H10.2857V13.875C10.2857 14.0781 10.3584 14.2539 10.5038 14.4023C10.6492 14.5508 10.8214 14.625 11.0204 14.625C11.2194 14.625 11.3916 14.5508 11.537 14.4023L17.7819 8.02734C17.9273 7.87891 18 7.70312 18 7.5Z" fill="currentColor"/>
    </Icon>
  );
};

export default LogoutIcon;