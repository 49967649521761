import { useQuery } from 'react-query';

import { queryConfig } from '../../_libs/react-query';

import { fetchClinicBillingPortal } from './_services';

export const QUERY_KEY_NAME = 'ClinicBillingPortal';

export const getBillingPortal = ({ referrerUrl}) => {
  return fetchClinicBillingPortal(referrerUrl)
};

const newQueryConfig = {
    ...queryConfig, 
    queries : {
      ...queryConfig.queries, 
      refetchOnMount: false
    },
};

export const GetBillingPortal = ({ referrerUrl, config, enabled=false}) => {
  return useQuery({
    ...newQueryConfig,
    config: {
        ...config,
      enabled: enabled,
    },
    queryKey: [QUERY_KEY_NAME, {referrerUrl}],
    queryFn: () => getBillingPortal({ referrerUrl}),
  });
}; 
