import {
  IconButton, 
  Popover, 
  PopoverTrigger, 
  PopoverContent, 
  PopoverArrow, 
  PopoverCloseButton, 
  PopoverHeader, 
  PopoverBody
} from '@chakra-ui/react';

import { FaInfo} from 'react-icons/fa';

export const AdminHelpPopover = ({header, body=""}) => ( 
  <Popover size={"lg"} fontSize="11px" placement="right-start">
  <PopoverTrigger>
    <IconButton 
      ml={3}
      size="xs"
      height="16px"
      width="16px"
      borderRadius="1000px"
      icon={
        <FaInfo fontSize="8px" />
      }
    />
  </PopoverTrigger>
  <PopoverContent p={3} minW="300px" whiteSpace={'pre-line'}>
    <PopoverArrow />
    <PopoverCloseButton />
    {header && <PopoverHeader>{header}</PopoverHeader>}
    <PopoverBody>{body}</PopoverBody>
  </PopoverContent>
  </Popover>)