export const patientSearchConstants = {


	OPEN_MESSAGEFORM : 'PATIENTSEARCH_OPEN_MESSAGEFORM',
	CLOSE_MESSAGEFORM: 'PATIENTSEARCH_CLOSE_MESSAGEFORM',
	CHANGE_MESSAGEFORM : 'PATIENTSEARCH_CHANGE_MESSAGEFORM',


	SEND_MESSAGE_REQUEST: 'PATIENTSEARCH_SEND_MESSAGE__REQUEST',
	SEND_MESSAGE_SUCCESS: 'PATIENTSEARCH_SEND_MESSAGE__SUCCESS',
	SEND_MESSAGE_FAILURE: 'PATIENTSEARCH_SEND_MESSAGE__FAILURE',

	SEARCH_PATIENT_REQUEST: 'PATIENTSEARCH_SUBMIT_PATIENTFORM_REQUEST',
	SEARCH_PATIENT_SUCCESS: 'PATIENTSEARCH_SUBMIT_PATIENTFORM_SUCCESS',
	SEARCH_PATIENT_FAILURE: 'PATIENTSEARCH_SUBMIT_PATIENTFORM_FAILURE',

	CHANGE_PATIENTINPUT: 'PATIENTSEARCH_CHANGE_PATIENTINPUT',

}