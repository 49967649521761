import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'

class ReinitializeForm extends React.Component {
  static propTypes = {
    load: PropTypes.func.isRequired,
  }

  state = {
    originalValues: undefined,
    initialValues: undefined
  }

  load = () => {
    const { initValues } = this.props;
    this.setState({
      initialValues : initValues
    })
  }

  componentDidMount() {
    this.load()
  }

  render() {
    const {
      ...rest
    } = this.props
    const { initialValues } = this.state
    return (
      <Form {...rest} initialValues={initialValues} />
    )
  }
}

export { ReinitializeForm };
