import { authHeader, handleResponse } from '../_helpers';
import { BASE_URL, API_URLS } from '../_config';

export const activityLogService = {
    getActivityLog,
    getNewActivityLog
};

function getActivityLog(){
  const requestUrl = API_URLS.getActivityLog;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader()}
  };
  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function getNewActivityLog(ts){
  const requestUrl = API_URLS.getActivityLog;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader()}
  };
  let url = requestUrl.url.replace('{op}', 'new').replace('{ts}', ts);
  return fetch(url, requestOptions).then(handleResponse);
}
