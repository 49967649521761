import { useQuery } from 'react-query';

import { queryConfig, queryClient } from 'js/_libs/react-query';

import { fetchAppointmentRequestCount } from './_services';

export const QUERY_KEY_NAME = 'AppointmentRequestCount';

export const getAppointmentRequestCount = (dpid) => {
  return fetchAppointmentRequestCount(dpid)
};

const newQueryConfig = {
    ...queryConfig, 
    queries : {
      ...queryConfig.queries, 
      refetchOnMount: false
    },
};

export const refetchAppointmentRequestCount = (dpid) => {
  queryClient.refetchQueries([QUERY_KEY_NAME, {dpid}]);
};

export const GetAppointmentRequestCount = ({dpid=null}) => {
  return useQuery({
    ...newQueryConfig,
    queryKey: [QUERY_KEY_NAME, {dpid}],
    queryFn: () => getAppointmentRequestCount(dpid),
  });
}; 
