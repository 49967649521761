import React, {Fragment} from 'react';
import ReactConfirmAlert, { confirmAlert } from 'react-confirm-alert';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import cn from 'classnames';
import { upperFirst, toUpper, startCase, camelCase, find } from 'lodash';
import { required, composeValidators, STATUS_CHOICES } from '../_config'
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from "final-form";
import { localeFormatDate, convertToAPIValues } from '../_helpers';

import { ReportAttachmentsSelectField } from '../AdminPage/SelectFields';
import { SelectAdapterNonClearable, SelectAdapterNonClearableCreatable } from '../_components';

import '../../css/react-custom-confirm-alert.css';
import '../../css/confirmPopUp.style.css';

const requestPopUpCssClassName = 'ConfirmPopUp';
const requestPopUpTitleCssClassName = `${requestPopUpCssClassName}__title`;
const requestPopUpBodyCssClassName = `${requestPopUpCssClassName}__body`;
const requestPopUpFooterCssClassName = `${requestPopUpCssClassName}__footer`;
const requestPopUpFooterButtonCssClassName = `${requestPopUpFooterCssClassName}__button`;

const inputCssClassName = `${requestPopUpCssClassName}__inputs`;
const selectRowCssClassName = `${requestPopUpCssClassName}__SelectRow`;
const selectFieldCssClassName = `${requestPopUpCssClassName}__SelectField`;

const requestPopUpTextAreaBodyCssClassName = `${requestPopUpBodyCssClassName}-textInput`;
const requestPopUpSelectBodyCssClassName = `${requestPopUpBodyCssClassName}-select`;

const textareaRows = 5;

/*
  PopUpCustomUIText has an API to send
  @checkAnd
 */
class PopUpCustomUIText extends React.Component {
  constructor(props){
    super(props);
    this.state = {text: "", isLoading:false}
  }

  handleChange = (event) => {
    this.setState({text: event.target.value})
  }

  submitForm = (values) => {
    //this.props.handleSubmit();
    // enter API connection here
    const { handleSubmit, onSuccess, onClose, intl } = this.props;
    this.setState({isLoading: true})
    handleSubmit(values)
      .then(
        data => {
          console.log("popupuitext handleSubmit thgen")
          console.log(data)
          onSuccess(data);
          this.setState({isLoading: false})
          onClose();
          return undefined;
        },
        error => {
          this.setState({isLoading: false})
          return { [FORM_ERROR]: intl.messages['form.error.general_error'] };
        }
      )
  }

  render() {
      const { title, message, className, intl, onClose,
              cancelText, confirmText } = this.props;
      return (
        <div className={requestPopUpCssClassName}>
          <div className={requestPopUpTitleCssClassName}>{upperFirst(title)}</div>
          <div className={requestPopUpBodyCssClassName}>
            <div>{upperFirst(message)}</div>
            <div className={requestPopUpTextAreaBodyCssClassName}>
              <Form
                onSubmit={this.submitForm}
                //mutators={{ setFieldData }}
                render = {({
                  handleSubmit,
                  mutators,
                  form,
                  reset,
                  submitting,
                  pristine,
                  validating,
                  values,
                }) => (
                  <form id="PopUpCustomUIText" onSubmit={handleSubmit}>
                    <Field name="body" validate={composeValidators(required)}>
                      {({ input, meta}) => (
                        <div className={inputCssClassName}>
                          <textarea
                            {...input}
                            className="form-control Input_Text"
                            rows={textareaRows}
                            type="text"
                            placeholder={upperFirst(intl.messages['patient.reportReply.treatmentplan.reject.popUp.placeholder'])}
                          />
                          <div className={cn("Error__Label",{"Error": meta.error && meta.touched} )} >
                              {(meta.error && meta.touched) ? intl.messages[`form.error.${meta.error}`] : undefined}
                          </div>
                        </div>
                      )}
                    </Field>
                  </form>
                )}
              />
            </div>
          </div>
          <div className={requestPopUpFooterCssClassName}>
            <button className={requestPopUpFooterButtonCssClassName}
              disabled={this.state.isLoading}
              onClick={() => {
                onClose();
              }}
            >
              {toUpper(cancelText)}
            </button>
            <button className={requestPopUpFooterButtonCssClassName}
              disabled={this.state.isLoading}
              onClick={() => {
                document.getElementById('PopUpCustomUIText').dispatchEvent(new Event('submit', { cancelable: true }))
              }}
            >
              {toUpper(confirmText)}
            </button>
          </div>
        </div>
      )
  }
}

class PopUpCustomUIChangeStatus extends React.Component {
  constructor(props){
    super(props);
    this.state = {text: "", isLoading:false}
  }

  handleChange = (event) => {
    this.setState({text: event.target.value})
  }

  submitForm = (values) => {
    //this.props.handleSubmit();
    // enter API connection here
    const { handleSubmit, onSuccess, onClose, intl } = this.props;
    this.setState({isLoading:true})
    handleSubmit(values)
      .then(
        data => {
          onSuccess(data);
          onClose();
          this.setState({isLoading:false})
          return undefined;
        },
        error => {
          this.setState({isLoading:false})
          return { [FORM_ERROR]: intl.messages['form.error.general_error'] };
        }
      )
  }

  render() {
      const { title, message, className, intl, onClose, items, defaultValue,
              cancelText, confirmText, currentStatus } = this.props;
      return (
        <div className={requestPopUpCssClassName}>
          <div className={requestPopUpTitleCssClassName}>{upperFirst(title)}</div>
          <div className={requestPopUpBodyCssClassName}>
            <div>{upperFirst(message)}</div>
            <div className={requestPopUpSelectBodyCssClassName}>
              <Form
              onSubmit={this.submitForm}
              initialValues = {{status: currentStatus}}
              //mutators={{ setFieldData }}
              render = {({
                handleSubmit,
                mutators,
                form,
                reset,
                submitting,
                pristine,
                validating,
                values,
              }) => (
                <form id="PopUpCustomUIUpdateStatus" onSubmit={handleSubmit}>
                  <div className={selectRowCssClassName}>
                    <Field
                      className={selectFieldCssClassName}
                      name="status"
                      intl={intl}
                      label={toUpper(intl.messages['string.requestStatus'])}
                      component={SelectAdapterNonClearable}
                      items={items}
                      defaultValue={defaultValue}
                      placeholder={upperFirst(intl.messages['string.requestStatus'])}
                      validate={required}
                      intl={intl}
                    />
                  </div>
                  <Field name="body" >
                    {({ input, meta}) => (
                      <div className={inputCssClassName}>
                        <textarea
                          {...input}
                          className={cn("form-control Input_Text", {"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)})}
                          rows={textareaRows}
                          type="text"
                          placeholder={upperFirst(intl.messages['popUp.updateStatus.placeholder'])}
                        />
                        <div className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
                            {(meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit) ? intl.messages[`form.error.${meta.error || meta.submitError}`] : undefined}
                        </div>
                      </div>
                    )}
                  </Field>
                </form>
              )}
            />
            </div>
          </div>
          <div className={requestPopUpFooterCssClassName}>
            <button className={requestPopUpFooterButtonCssClassName}
              onClick={() => {
                onClose();
              }}
            >
              {toUpper(cancelText)}
            </button>
            <button className={requestPopUpFooterButtonCssClassName}
              onClick={() => {
                document.getElementById('PopUpCustomUIUpdateStatus').dispatchEvent(new Event('submit', { cancelable: true }))
              }}
              disabled={this.state.isLoading}
            >
              {toUpper(confirmText)}
            </button>
          </div>
        </div>
      )
  }
}

const errorAlert = (intl, title, message, buttonText, onClick, onClose) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <ConfirmAlertUrlUI
          title={title || (intl && intl.messages['popUp.error.title'])}
          message={message || (intl && intl.messages['popUp.error.message'])}
          confirmText={buttonText || (intl && intl.messages['popUp.error.button'])}
          onClick = {undefined || onClick}
          onClose = {onClose}
        />
      )
    }
  })
}

const errorAlertOnlyMessages = (intlMessages, title, message, buttonText, onClick, onClose) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <ConfirmAlertUrlUI
          title={title || (intlMessages['popUp.error.title'])}
          message={message || (intlMessages['popUp.error.message'])}
          confirmText={buttonText || (intlMessages['popUp.error.button'])}
          onClick = {undefined || onClick}
          onClose = {onClose}
        />
      )
    }
  })
}

const PopUpCustomUI = ({title, message , onClose, handleSubmit, cancelText, confirmText}) =>(
    <div className={requestPopUpCssClassName}>
      <div className={requestPopUpTitleCssClassName}>{upperFirst(title)}</div>
      <div className={requestPopUpBodyCssClassName}>{upperFirst(message)}</div>
      <div className={requestPopUpFooterCssClassName}>
        <button className={requestPopUpFooterButtonCssClassName}
          onClick={() => {
            onClose();
          }}
        >
          {toUpper(cancelText)}
        </button>
        <button className={requestPopUpFooterButtonCssClassName}
          onClick={() => {
            handleSubmit();
            onClose();
          }}
        >
          {toUpper(confirmText)}
        </button>
      </div>
    </div>
)

const PopupAttachmentLabelUI = ({title, message , items, onClose, onCancel, handleSubmit, cancelText, confirmText}) => {

  const submitForm = (vals) => {
    console.log(vals.label)
    handleSubmit(vals.label.value)
    onClose();
  }

  return (
    <div className={requestPopUpCssClassName}>
    <div className={requestPopUpTitleCssClassName}>{upperFirst(title)}</div>
    <div className={requestPopUpBodyCssClassName}>
      <div>{upperFirst(message)}</div>
      <div className={requestPopUpSelectBodyCssClassName}>
        <Form
        onSubmit={submitForm}
        //mutators={{ setFieldData }}
        render = {({
          handleSubmit,
          mutators,
          form,
          reset,
          submitting,
          pristine,
          validating,
          values,
        }) => (
          <form id="PopUpCustomUIUpdateStatus" onSubmit={handleSubmit}>
            <div className={selectRowCssClassName}>
              <Field
                  className={selectFieldCssClassName}
                  name="label"
                  component={SelectAdapterNonClearableCreatable}
                  items={items}
                  validate={required}
                />
            </div>
          </form>
        )}
      />
      </div>
    </div>
    <div className={requestPopUpFooterCssClassName}>
      <button className={requestPopUpFooterButtonCssClassName}
        onClick={() => {
          onCancel()
          onClose();
        }}
      >
        {toUpper(cancelText)}
      </button>
      <button className={requestPopUpFooterButtonCssClassName}
        onClick={() => {
          document.getElementById('PopUpCustomUIUpdateStatus').dispatchEvent(new Event('submit', { cancelable: true }))
        }}
      >
        {toUpper(confirmText)}
      </button>
    </div>
  </div>
  )
}

const ConfirmAlertUrlUI = ({title, message, confirmText, onClick, onClose}) =>(
    <div className={requestPopUpCssClassName}>
      <div className={requestPopUpTitleCssClassName}>{upperFirst(title)}</div>
      <div className={requestPopUpBodyCssClassName}>{upperFirst(message)}</div>
      <div className={requestPopUpFooterCssClassName}>
      <button
        className={requestPopUpFooterButtonCssClassName}
        onClick={() => {
            (onClick && onClick());
            onClose()
        }}
      >
        {toUpper(confirmText)}
      </button>
      </div>
    </div>
)

class ConfirmAlertUrl extends React.Component {
  constructor(props){
    super(props);
  }

  render(){
    const { title, message, confirmText, url } = this.props;

    return(
      <ReactConfirmAlert
        customUI= {({ onClose }) => {
          return (
            <div className={requestPopUpCssClassName}>
              <div className={requestPopUpTitleCssClassName}>{upperFirst(title)}</div>
              <div className={requestPopUpBodyCssClassName}>{upperFirst(message)}</div>
              <div className={requestPopUpFooterCssClassName}>
                <button
                  className={requestPopUpFooterButtonCssClassName}
                >
                  {toUpper(confirmText)}
                </button>
              </div>
            </div>
          )}
        }
      />
    )
  }
}

class ConfirmPopUp extends React.Component {
  constructor(props){
    super(props);
  }

  render(){
    const { title, message, className, intl } = this.props;

    return(
      <ReactConfirmAlert
        customUI= {({ onClose }) => {
          return (
            <div className={requestPopUpCssClassName}>
              <div className={requestPopUpTitleCssClassName}>{upperFirst(intl.messages['popUp.cancel.title'])}</div>
              <div className={requestPopUpBodyCssClassName}>{upperFirst(intl.messages['popUp.cancel.message'])}</div>
              <div className={requestPopUpFooterCssClassName}>
                <button className={requestPopUpFooterButtonCssClassName}
                  onClick={() => {
                    onClose();
                  }}
                >
                  {toUpper(intl.messages['popUp.cancel.close'])}
                </button>
                <button className={requestPopUpFooterButtonCssClassName}
                  onClick={() => {
                    this.handleSubmit();
                    onClose();
                  }}
                >
                  {toUpper(intl.messages['popUp.cancel.confirm'])}
                </button>
              </div>
            </div>
          )}
        }
      />
    )
  }
}

function mapStateToProps(state){
  const { intl } = state;
  return { intl };
}

const connectedConfirmPopUp = connect(mapStateToProps)(ConfirmPopUp);
export {PopUpCustomUI, PopUpCustomUIText, PopUpCustomUIChangeStatus, PopupAttachmentLabelUI, ConfirmAlertUrlUI, errorAlert, errorAlertOnlyMessages, connectedConfirmPopUp as ConfirmPopUp};
