import React, { Component } from 'react';
import cn from 'classnames';
import { render } from 'react-dom'
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import Spinner from '../_components/Spinner';
import Select from 'react-select';

import {
  CustomButton,
  CustomDialog,
  CustomDialogContent,
  CustomDialogActions,
} from '../_components/Parts';


const required = value => (value ? undefined : 'Required')
const mustBeNumber = value => (isNaN(value) ? 'Must be a number' : undefined)
const minValue = min => value =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`
const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)

// another way to validate.
// field by field or put all in one monolith check
const validate = values => {
  const errors = {}
  if (!values.firstName) {
    errors.firstName = 'Required'
  }
  if (!values.fruit) {
    errors.fruit = 'Required'
  }
  return errors
}

const simpleMemoize = fn => {
  let lastArg
  let lastResult
  return arg => {
    if (arg !== lastArg) {
      lastArg = arg
      lastResult = fn(arg)
    }
    return lastResult
  }
}

// NOTE: move this to a library or something
const SelectAdapter = ({input, meta, items, label, ...rest }) => (
  <div>
    <label>{label}</label>
    <Select
      {...input}
      className={ cn({"Input_Error": meta.error && meta.touched})}
      onChange={inputValue => input.onChange(inputValue)}
      options={items}
    />
    <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >{meta.error && meta.touched && meta.error}</div>
  </div>

)

// for testing purposes
const patients = [
  {value: '3', label: 'Jason Chung'},
  {value: '4', label: 'Amy Chong'},
  {value: '5', label: 'Shin Um'},
  {value: '6', label: 'Seung Lee'},
  {value: '7', label: 'Ji Hwan Park'}
]

const dentists = [
  {value: '1', label: 'Jacob Chu'},
  {value: '2', label: 'Wang Xiu'},
  {value: '3', label: 'Aaron Masterman'}
]
class AppointmentForm extends Component {
  constructor(props) {
    super(props);
  }

  onSubmit = async values => {
    console.log("submitting to forms service", values)
    // call api to submit this...
    //formsService.submitPatientForm(values).then(
    //  data => success(data),
    //  error => failure(error)
    //)
    console.log("done");
  }

  submitFormSuccess = async form => {
    // send notification to react, and  have sage listening for it
    //this.props.dispatch()
    // probably close from also this.setState(isOpen: false)
    this.props.closeModal()
    alert("submitted!")
  }

  render() {
    const { clickCancel, clickQuickPatient } = this.props;

    return (
      <Form
        onSubmit={this.onSubmit}
        render = {({
          handleSubmit,
          reset,
          submitting,
          pristine,
          validating,
          values
        }) => (
            <form onSubmit={handleSubmit}>
              {validating && <Spinner />}
              <CustomDialogContent className="DialogFormContent">
                <div className="">
                  {/*enter title and stuff here*/}
                  <div className={'form-group form-row col-sm-12'}>
                    <div className="col-sm-12">
                      <h3>Appointment</h3>
                      <p>Quickly add a new appointment</p>
                    </div>
                  </div>
                  <div className={'form-group form-row col-sm-12'}>
                    <div className="col-sm-5">
                      <Field
                        name="patient"
                        items={patients}
                        label="Add a Patient"
                        component={SelectAdapter}
                        placeholder="Patient"
                        validate={required}
                      />
                    </div>
                    <div className="col-sm-4">
                      <button
                        type="button"
                        className="Button Button__Stop"
                        onClick={clickQuickPatient}
                        >
                        New Patient
                      </button>
                    </div>
                  </div>
                  <div className={'form-group form-row col-sm-12'}>
                      <div className="col-sm-12">
                        <Field
                          name="dentist"
                          items={dentists}
                          label="Dentist"
                          component={SelectAdapter}
                          placeholder="Select a provider"
                          validate={required}
                        />
                        {/* this.props.dentalPracticeProviders, de
                        <Field
                          name="operatory"
                          items={operatories}
                          label="Operatory"
                          component={SelectAdapter}
                          placeholder="Select an operatory"
                        />*/}
                      </div>
                  </div>
                  <div className={'form-group form-row col-sm-12'}>
                    <div className="col-sm-12">
                      <Field name="notes">
                        {({ input, meta}) => (
                          <div>
                            <label className="Input__Label">Appointment Notes</label>
                            <textarea {...input} className="form-control Input_Text" type="text" placeholder="Appointment Notes" />
                            <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >{meta.error && meta.touched && meta.error}</div>
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className={'form-group form-row col-sm-12'}>
                    <div className="col-sm-6">
                      <Field name="date">
                          {({ input, meta}) => (
                            <div>
                              <label className="Input__Label">Date</label>
                              <input {...input} className="form-control Input_Text" type="date" placeholder="" />
                              <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >{meta.error && meta.touched && meta.error}</div>
                            </div>
                          )}
                      </Field>
                    </div>
                    <div className="col-sm-3">
                      <Field name="start_time">
                        {({ input, meta}) => (
                          <div>
                            <label className="Input__Label">Start Time</label>
                            <input {...input} className="form-control Input_Text" type="time" placeholder="" />
                            <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >{meta.error && meta.touched && meta.error}</div>
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col-sm-3">
                       <Field name="end_time">
                          {({ input, meta}) => (
                            <div>
                              <label className="Input__Label">End Time</label>
                              <input {...input} className="form-control Input_Text" type="time" placeholder="" />
                              <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >{meta.error && meta.touched && meta.error}</div>
                            </div>
                          )}
                      </Field>
                    </div>
                  </div>
                </div>
              </CustomDialogContent>
              <CustomDialogActions>
                <div className="Buttons">
                <button
                  type="button"
                  className="Button Button__Cancel"
                  onClick={clickCancel}
                  disabled={submitting}
                  >
                  CANCEL
                </button>
                  <button type="submit" className="Button Button__Save" disabled={submitting}>
                    Submit
                  </button>
                </div>
              </CustomDialogActions>


            <pre>{JSON.stringify(values, 0, 2)}</pre>
            </form>
        )}
      />
    )
  }
}

class AppointmentFormModal extends Component {
    constructor(props){
      super(props);
    }

    render(){
      const { isOpen, closeModal} = this.props;
      console.log("isOpneed?", isOpen)
      return (
         <CustomDialog className="popUpForm"
            open={isOpen}
            onClose={null}
            aria-labelledby="form-dialog-title"
            maxWidth='sm'
          >
            <AppointmentForm
              clickCancel={closeModal}
            />
          </CustomDialog>
      )
    }
}

export {AppointmentFormModal, SelectAdapter};
