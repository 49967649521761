import React, {PureComponent, Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { UNIT_PIXEL, parseSize} from '../_helpers/styles';

export { Grid };

/*
 * Table's Header Cell
 */
const headerCellPropTypes = {
	cellKey: PropTypes.string.isRequired,
	width: PropTypes.number.isRequired,
	contextTitle: PropTypes.string,
	children: PropTypes.node.isRequired,
};

const headerCellContextTypes = {
	gridCssClassName: PropTypes.string.isRequired,
};

function HeaderCell (props, context) {
	return (
		<div
			className={classnames([
				`${context.gridCssClassName}__header-cell`,
				`${context.gridCssClassName}__header-cell__m-${props.cellKey}`,
			])}
			style={{ width: props.width }}
		>
			<div className={`${context.gridCssClassName}__header-cell-content`} title={props.contextTitle}>
				{props.children}
			</div>
		</div>
	);
}

/*
 * Table's Header Row
 */
const headerRowPropTypes = {
	children: PropTypes.node.isRequired,
};

const headerRowContextTypes = {
	gridCssClassName: PropTypes.string.isRequired,
};

function HeaderRow (props, context) {
	return (
		<div className={`${context.gridCssClassName}__header-row`} style={{ height: props.height }}>
			{props.children}
		</div>
	);
}

/*
 * Table's Content Cell
 */
const contentCellPropTypes = {
	cellKey: PropTypes.string.isRequired,
	width: PropTypes.number.isRequired,
	contextTitle: PropTypes.string,
	children: PropTypes.node.isRequired,
	onClick: PropTypes.func,
};

const contentCellContextTypes = {
	gridCssClassName: PropTypes.string.isRequired,
};

const ContentCell = (props, context) => {
	return (
		<div
			className={classnames([
				`${context.gridCssClassName}__content-cell`,
				`${context.gridCssClassName}__content-cell__m-${props.cellKey}`,
			])}
			style={{ width: props.width }}
			onClick={props.onClick}
			onDoubleClick={props.onDoubleClick}
		>
			<div className={`${context.gridCssClassName}__content-cell-content`} title={props.contextTitle}>
				{props.children}
			</div>
		</div>
	);
}

/*
 * Table's Content Row
 */
const contentRowPropTypes = {
	modifiers: PropTypes.arrayOf(PropTypes.string.isRequired),
	children: PropTypes.node.isRequired,
	onClick: PropTypes.func,
	onDoubleClick: PropTypes.func,
};

const contentRowContextTypes = {
	gridCssClassName: PropTypes.string.isRequired,
};

function ContentRow (props, context) {
	const {
		modifiers,
		children,
		onClick,
		onDoubleClick,
		onMouseOver,
		onMouseOut,
		height,
	} = props;

	const style = {
		cursor: (onClick ? 'pointer' : 'default'),
		height,
	};

	const baseCssClassName = `${context.gridCssClassName}__content-row`;

	const className = classnames([ baseCssClassName ]
		.concat((modifiers || []).map((modifier) => `${baseCssClassName}__m-${modifier}`)));

	return (
		<div
			className={className}
			onClick={onClick}
			onDoubleClick={onDoubleClick}
			onMouseEnter={onMouseOver}
			onMouseLeave={onMouseOut}
			style={style}
		>
			{children}
		</div>
	);
}

/*
 * Table CSS container
 */
class GridProvider extends PureComponent {
	static propTypes = {
		cssClassName: PropTypes.string.isRequired,
		children: PropTypes.node.isRequired,
	}

	static childContextTypes = {
		gridCssClassName: PropTypes.string.isRequired,
	}

	getChildContext () {
		return {
			gridCssClassName: this.props.cssClassName,
		};
	}

	render () {
		return this.props.children;
	}
}

/*
 * Tables Container
 */
class GridContainer extends Component {
 	static propTypes = {
 		minWidth: PropTypes.number.isRequired,
 		columns: PropTypes.arrayOf(PropTypes.shape({
 			key: PropTypes.string.isRequired,
 			width: PropTypes.oneOfType([
 				PropTypes.number.isRequired,
 				PropTypes.string.isRequired,
 			]).isRequired,
 		})).isRequired,
 		data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
 		getHeaderRowRenderOptions: PropTypes.func.isRequired,
 		getContentRowRenderOptions: PropTypes.func.isRequired,
 		onScrolledToEnd: PropTypes.func,
 	}

 	constructor (props) {
 		super(props);

 		this._wrapperEl = null;

 		this.state = this._getState(props);
 	}

 	UNSAFE_componentWillReceiveProps (propsNext) {
 		this.setState(this._getState(propsNext));
 	}

 	_handleRef = (el) => {
 		if ( !el ) {
 			return;
 		}

 		this._wrapperEl = el;

 		this.setState(this._getState(this.props));
 	}

 	_parseColumnWidth = ({ width }) => {
 		return parseSize(width);
 	}

 	_getRowWidth (minWidth) {
 		return Math.max(minWidth, this._wrapperEl.clientWidth);
 	}

 	_measureColumns (columns, rowWidth) {
 		const columnsWidthInfo = columns.map(this._parseColumnWidth);

 		const fixedColumnsWidth = columnsWidthInfo
 			.filter(({ unit }) => (unit === UNIT_PIXEL))
 			.reduce((result, { value }) => (result + value), 0);

 		const remainingWidth = Math.max(0, rowWidth - fixedColumnsWidth);

 		return columns.map((column, index) => {
 			const { value, unit } = columnsWidthInfo[index];

 			if ( unit === UNIT_PIXEL ) {
 				return column;
 			}

 			return {
 				...column,
 				width: Math.floor(remainingWidth * value / 100),
 			};
 		});
 	}

 	_getState ({
 		minWidth,
 		columns,
 		getHeaderRowRenderOptions,
 		getContentRowRenderOptions,
 		data,
 	}) {
 		if ( !this._wrapperEl ) {
 			return {
 				headerRows: [],
 				rows: [],
 			};
 		}

 		const rowWidth = this._getRowWidth(minWidth);
 		const measuredColumns = this._measureColumns(columns, rowWidth);

 		return {
 			headerRows: getHeaderRowRenderOptions().render(measuredColumns),
 			rows: data.map((rowData, index) => {
 				return getContentRowRenderOptions(rowData, index).render(measuredColumns);
 			}),
 		};
 	}

 	render () {
 		const {
 			headerRows,
 			rows,
 		} = this.state;

 		return (
 			<div
 				ref={this._handleRef}
 			>
 				{headerRows}
 				{rows}
 			</div>
 		);
 	}
 }

/*
 * Table
 */
const gridPropTypes = {
	cssClassName: PropTypes.string.isRequired,
	cssClassModifier: PropTypes.string,
	minWidth: PropTypes.number.isRequired,
	columns: PropTypes.arrayOf(PropTypes.shape({
		key: PropTypes.string.isRequired,
		width: PropTypes.oneOfType([
			PropTypes.number.isRequired,
			PropTypes.string.isRequired,
		]).isRequired,
		headerVal: PropTypes.node,
		headerContextTitle: PropTypes.string,
		getVal: PropTypes.func.isRequired,
		getContextTitle: PropTypes.func.isRequired,
	})).isRequired,
	headerRowHeight: PropTypes.number.isRequired,
	contentRowHeight: PropTypes.number.isRequired,
	getContentRowKey: PropTypes.func.isRequired,
	getContentRowModifiers: PropTypes.func,
	data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
	onContentRowClick: PropTypes.func,
	onContentRowDoubleClick: PropTypes.func,
	onScrolledToEnd: PropTypes.func,
};

const Grid = (props) => {
	const {
		cssClassName,
		cssClassModifier,
		minWidth,
		columns,
		headerRowHeight,
		contentRowHeight,
		getContentRowKey,
		getContentRowModifiers,
		data,
		onContentRowClick,
		onContentRowDoubleClick,
		onContentRowMouseOver,
		onContentRowMouseOut,
		onContentCellClick,
		onContentCellDoubleClick,
		onScrolledToEnd,
	} = props;

	return (
		<GridProvider cssClassName={cssClassName}>
			<div
				className={classnames([
					cssClassName,
					cssClassModifier && `${cssClassName}__m-${cssClassModifier}`,
				])}
			>
				<GridContainer
					columns={columns}
					minWidth={minWidth}
					data={data}
					getHeaderRowRenderOptions={() => {
						return {
							render: (columns) => {
								return (
									<HeaderRow>
										{columns.map((column) => {
											return (
												<HeaderCell
													key={column.key}
													cellKey={column.key}
													width={column.width}
													contextTitle={column.headerContextTitle}
												>
													{column.headerVal}
												</HeaderCell>
											);
										})}
									</HeaderRow>
								);
							},
							height: headerRowHeight,
						};
					}}
					getContentRowRenderOptions={(rowData, index) => {
						const rowClickHandler = () => {
							if ( onContentRowClick ) {
								onContentRowClick(rowData);
							}
						};

						const rowDoubleClickHandler = () => {
							if ( onContentRowDoubleClick ) {
								onContentRowDoubleClick(rowData);
							}
						};

						const rowMouseOverHandler = () => {
							if ( onContentRowMouseOver ) {
								onContentRowMouseOver(rowData);
							}
						};

						const rowMouseOutHandler = () => {
							if ( onContentRowMouseOut ) {
								onContentRowMouseOut(rowData);
							}
						};

						return {
							render: (columns) => {
								const cellClickHandler = (column) => {
									if ( onContentCellClick ) {
										onContentCellClick(column);
									}
								};
								const cellDoubleClickHandler = (column, rowData) => {
									if ( onContentCellDoubleClick ) {
										onContentCellDoubleClick(column, rowData)
									}
								};
								return (
									<ContentRow
										key={getContentRowKey(rowData, index)}
										modifiers={getContentRowModifiers(rowData)}
										onClick={rowClickHandler}
										//onDoubleClick={rowDoubleClickHandler}
										onMouseOver={rowMouseOverHandler}
										onMouseOut={rowMouseOutHandler}
										height={contentRowHeight}
									>
										{columns.map((column) => {
											return (
												<ContentCell
													key={column.key}
													cellKey={column.key}
													width={column.width}
													contextTitle={column.getContextTitle(rowData, index)}
													onClick={() => cellClickHandler(column)}
													onDoubleClick={() => cellDoubleClickHandler(column, rowData)}
												>
													{column.getVal(rowData, index)}
												</ContentCell>
											);
										})}
									</ContentRow>
								);
							},
						};
					}}
					onScrolledToEnd={onScrolledToEnd}
				/>
			</div>
		</GridProvider>
	);
}

HeaderCell.propTypes = headerCellPropTypes;
HeaderCell.contextTypes = headerCellContextTypes;
HeaderRow.propTypes = headerRowPropTypes;
HeaderRow.contextTypes = headerRowContextTypes;
ContentCell.propTypes = contentCellPropTypes;
ContentCell.contextTypes = contentCellContextTypes;
ContentRow.propTypes = contentRowPropTypes;
ContentRow.contextTypes = contentRowContextTypes;
Grid.propTypes = gridPropTypes;

export default Grid;
