export const patientConstants = {
	INITIALIZE: 'PATIENT_INITIALIZE',

	GETPATIENT_PAGEDETAILS_REQUEST : 'PATIENT_GETPATIENT_PAGEDETAILS_REQUEST',
	GETPATIENT_PAGEDETAILS_SUCCESS : 'PATIENT_GETPATIENT_PAGEDETAILS_SUCCESS',
	GETPATIENT_PAGEDETAILS_FAILURE : 'PATIENT_GETPATIENT_PAGEDETAILS_FAILURE',

	GETPATIENT_DETAILS_REQUEST : 'PATIENT_GETPATIENT_DETAILS_REQUEST',
	GETPATIENT_DETAILS_SUCCESS : 'PATIENT_GETPATIENT_DETAILS_SUCCESS',
	GETPATIENT_DETAILS_FAILURE : 'PATIENT_GETPATIENT_DETAILS_FAILURE',

	EDITPATIENT_DETAILS_REQUEST: 'PATIENT_EDITPATIENT_DETAILS_REQUEST',
	EDITPATIENT_DETAILS_SUCCESS: 'PATIENT_EDITPATIENT_DETAILS_SUCCESS',
	EDITPATIENT_DETAILS_FAILURE: 'PATIENT_EDITPATIENT_DETAILS_FAILURE',

	GETPATIENT_BILLINGPROCEDURES_REQUEST : 'PATIENT_GETPATIENT_BILLINGPROCEDURES_REQUEST',
	GETPATIENT_BILLINGPROCEDURES_SUCCESS : 'PATIENT_GETPATIENT_BILLINGPROCEDURES_SUCCESS',
	GETPATIENT_BILLINGPROCEDURES_FAILURE : 'PATIENT_GETPATIENT_BILLINGPROCEDURES_FAILURE',

	GETPATIENT_INSURANCE_REQUEST : 'PATIENT_GETPATIENT_INSURANCE_REQUEST',
	GETPATIENT_INSURANCE_SUCCESS : 'PATIENT_GETPATIENT_INSURANCE_SUCCESSS',
	GETPATIENT_INSURANCE_FAILURE : 'PATIENT_GETPATIENT_INSURANCE_FAILURE',

	CHANGEPATIENT_TREATMENTPLAN_LIST: 'PATIENT_CHANGEPATIENT_TREATMENTPLAN_LIST',

	OPENPROCEDUREPOPUP: 'PATIENT_OPENPROCEDUREPOPUP',
	CLOSEPROCEDUREPOPUP: 'PATIENT_CLOSEPROCEDUREPOPUP',

	COMPLETEPATIENT_TREATMENTPLAN_REQUEST: 'PATIENT_COMPLETEPATIENT_TREATMENTPLAN_REQUEST',
	COMPLETEPATIENT_TREATMENTPLAN_SUCCESS: 'PATIENT_COMPLETEPATIENT_TREATMENTPLAN_SUCCESS',
	COMPLETEPATIENT_TREATMENTPLAN_FAILURE: 'PATIENT_COMPLETEPATIENT_TREATMENTPLAN_FAILURE',

	CREATEPATIENT_INVOICE_REQUEST: 'PATIENT_CREATEPATIENT_INVOICE_REQUEST',
	CREATEPATIENT_INVOICE_SUCCESS: 'PATIENT_CREATEPATIENT_INVOICE_SUCCESS',
	CREATEPATIENT_INVOICE_FAILURE: 'PATIENT_CREATEPATIENT_INVOICE_FAILURE',

	CREATEPATIENT_INSURANCE_REQUEST: 'PATIENT_CREATEPATIENT_INSURANCE_REQUEST',
	CREATEPATIENT_INSURANCE_SUCCESS: 'PATIENT_CREATEPATIENT_INSURANCE_SUCCESS',
	CREATEPATIENT_INSURANCE_FAILURE: 'PATIENT_CREATEPATIENT_INSURANCE_FAILURE',

	CREATEPATIENT_LEDGERENTRY_REQUEST: 'PATIENT_CREATEPATIENT_LEDGERENTRY_REQUEST',
	CREATEPATIENT_LEDGERENTRY_SUCCESS: 'PATIENT_CREATEPATIENT_LEDGERENTRY_SUCCESS',
	CREATEPATIENT_LEDGERENTRY_FAILURE: 'PATIENT_CREATEPATIENT_LEDGERENTRY_FAILURE',

	ADDPROCEDURE_REQUEST : 'PATIENT_ADDPROCEDURE_REQUEST',
	ADDPROCEDURE_SUCCESS : 'PATIENT_ADDPROCEDURE_SUCCESS',
	ADDPROCEDURE_FAILURE : 'PATIENT_ADDPROCEDURE_FAILURE',

	ADDNOTE_REQUEST : 'PATIENT_ADDNOTE_REQUEST',
	ADDNOTE_SUCCESS : 'PATIENT_ADDNOTE_SUCCESS',
	ADDNOTE_FAILURE : 'PATIENT_ADDNOTE_FAILURE',

	EDITPROCEDURE_REQUEST : 'PATIENT_EDITPROCEDURE_REQUEST',
	EDITPROCEDURE_SUCCESS : 'PATIENT_EDITPROCEDURE_SUCCESS',
	EDITPROCEDURE_FAILURE : 'PATIENT_EDITPROCEDURE_FAILURE',

	DELETEPROCEDURE_REQUEST : 'PATIENT_DELETEPROCEDURE_REQUEST',
	DELETEPROCEDURE_SUCCESS : 'PATIENT_DELETEPROCEDURE_SUCCESS',
	DELETEPROCEDURE_FAILURE : 'PATIENT_DELETEPROCEDURE_FAILURE',

	UNMOUNT_PATIENT :'PATIENT_UNMOUNT_PATIENT',
	PREV_UPLOADS :'PATIENT_PREV_UPLOADS',
	NEXT_UPLOADS :'PATIENT_NEXT_UPLOADS',

	CHANGE_PAGECONTENT : 'PATIENT_CHANGE_PAGECONTENT',
	OPEN_VIDEO_CHAT : 'PATIENT_OPEN_VIDEO_CHAT',
	END_VIDEO_SESSION : 'PATIENT_END_VIDEO_SESSION',

	OPENINSURANCEFORM : 'PATIENT_OPENINSURANCEFORM',
	CLOSEINSURANCEFORM : 'PATIENT_CLOSEINSURANCEFORM',
	CHANGEINSURANCEFORM : 'PATIENT_CHANGEINSURANCEFORM',

	OPENINVOICEFORM : 'PATIENT_OPENINVOICEFORM',
	CLOSEINVOICEFORM : 'PATIENT_CLOSEINVOICEFORM',
	CHANGEINVOICEFORM : 'PATIENT_CHANGEINVOICEFORM',

	OPENPROCFORM : 'PATIENT_OPENPROCFORM',
	CLOSEPROCFORM : 'PATIENT_CLOSEPROCFORM',
	CHANGEPROCFORM : 'PATIENT_CHANGEPROCFORM',

	OPENNOTEFORM : 'PATIENT_OPENNOTEFORM',
	CLOSENOTEFORM : 'PATIENT_CLOSENOTEFORM',
	CHANGENOTEFORM : 'PATIENT_CHANGENOTEFORM',

	OPENPATIENTFORM : 'PATIENT_OPENPATIENTFORM',
	CLOSEPATIENTFORM : 'PATIENT_CLOSEPATIENTFORM',
	CHANGEPATIENTFORM : 'PATIENT_CHANGEPATIENTFORM',

	OPENLEDGERENTRYFORM : 'PATIENT_OPENLEDGERENTRYFORM',
	CLOSELEDGERENTRYFORM : 'PATIENT_CLOSELEDGERENTRYFORM',
	CHANGELEDGERENTRYFORM : 'PATIENT_CHANGELEDGERENTRYFORM',

	CHANGESURFACEFORM: 'PATIENT_CHANGESURFACEFORM',
	CHANGETOOTHFORM: 'PATIENT_CHANGETOOTHFORM',

	GETPROCEDURECODES_REQUEST: 'PATIENT_GETPROCEDURECODES_REQUEST',
	GETPROCEDURECODES_SUCCESS: 'PATIENT_GETPROCEDURECODES_SUCCESS',
	GETPROCEDURECODES_FAILURE: 'PATIENT_GETPROCEDURECODES_FAILURE',

	GET_TOOTHREGIONS_REQUEST: 'PATIENT_GET_TOOTHREGIONS_REQUEST',
	GET_TOOTHREGIONS_SUCCESS: 'PATIENT_GET_TOOTHREGIONS_SUCCESS',
	GET_TOOTHREGIONS_FAILURE: 'PATIENT_GET_TOOTHREGIONS_FAILURE',

	GET_TOOTHNUMBERS_REQUEST: 'PATIENT_GET_TOOTHNUMBERS_REQUEST',
	GET_TOOTHNUMBERS_SUCCESS: 'PATIENT_GET_TOOTHNUMBERS_SUCCESS',
	GET_TOOTHNUMBERS_FAILURE: 'PATIENT_GET_TOOTHNUMBERS_FAILURE',

	GETVIDEOCHAT_TOKEN_SUCCESS: 'PATIENT_GETVIDEOCHAT_TOKEN_SUCCESS',
	GETVIDEOCHAT_TOKEN_FAILURE: 'PATIENT_GETVIDEOCHAT_TOKEN_FAILURE',

	CHART_SELECT_TOOTH: 'PATIENT_CHART_SELECT_TOOTH',
	CHART_SELECT_TOOTH_PROCEDURE: 'PATIENT_CHART_SELECT_TOOTH_PROCEDURE',

	GET_INBOX_REQUEST: 'PATIENT_GET_INBOX_REQUEST',
	GET_INBOX_SUCCESS: 'PATIENT_GET_INBOX_SUCCESS',
	GET_INBOX_FAILURE: 'PATIENT_GET_INBOX_FAILURE',
	GET_NEWINBOX_REQUEST: 'PATIENT_GET_NEWINBOX_REQUEST',
	GET_NEWINBOX_SUCCESS: 'PATIENT_GET_NEWINBOX_SUCCESS',
	GET_NEWINBOX_FAILURE: 'PATIENT_GET_NEWINBOX_FAILURE',
	OPENCHATBOX: 'PATIENT_OPENCHATBOX',
	CLOSECHATBOX: 'PATIENT_CLOSECHATBOX',
	GET_MESSAGES_REQUEST:'PATIENT_GET_MESSAGES_REQUEST',
	GET_MESSAGES_SUCCESS:'PATIENT_GET_MESSAGES_SUCCESS',
	GET_MESSAGES_FAILURE:'PATIENT_GET_MESSAGES_FAILURE',
	GET_NEWMESSAGES_REQUEST:'PATIENT_GET_NEWMESSAGES_REQUEST',
	GET_NEWMESSAGES_SUCCESS:'PATIENT_GET_NEWMESSAGES_SUCCESS',
	GET_NEWMESSAGES_FAILURE:'PATIENT_GET_NEWMESSAGES_FAILURE',
	SEND_MESSAGE_REQUEST:'PATIENT_SEND_MESSAGE_REQUEST',
	SEND_MESSAGE_SUCCESS:'PATIENT_SEND_MESSAGE_SUCCESS',
	SEND_MESSAGE_FAILURE:'PATIENT_SEND_MESSAGE_FAILURE',

	SUBMIT_PATIENT_IMAGEUPLOAD_SUCCESS: 'PATIENT_SUBMIT_PATIENT_IMAGEUPLOAD_SUCCESS'
}