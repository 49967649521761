import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';

import { FaSearch } from 'react-icons/fa'
class NotificationsIcon extends React.Component{
  render(){

    return (
    <svg width="21px" height="30px" viewBox="0 0 21 28" version="1.1">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" className={this.props.className}>
            <g id="notification" transform="translate(-1146, -24)">
                <g id="Group-32" transform="translate(1140, 21)">
                    <rect id="Rectangle-3" x="0" y="0" width="32" height="32"></rect>
                    <g id="Group-31" transform="translate(7, 3)">
                        <g id="web_alarm" stroke="#FFFFFF" strokeWidth="1.4">
                            <path d="M17.68,15.33 L15.68,13.11 L15.68,10 C15.6737499,7.35596632 14.1039993,4.96602103 11.68,3.91 L11.68,3.34 C11.68,1.87644541 10.4935546,0.69 9.03,0.69 C7.56644541,0.69 6.38,1.87644541 6.38,3.34 L6.38,3.91 C3.95600067,4.96602103 2.38625007,7.35596632 2.38,10 L2.38,13.1 L0.38,15.32 L0.38,18 L5.66,18 C5.88544107,19.6832933 7.32167728,20.94 9.02,20.94 C10.7183227,20.94 12.1545589,19.6832933 12.38,18 L17.66,18 L17.68,15.33 Z" id="Page-1-3"></path>
                            <path d="M2.48,10 L10.68,10" id="Stroke-4"></path>
                        </g>
                        <circle id="notifDot" className="notificationDot" fill="#253238" fillRule="nonzero" cx="17" cy="25" r="3"></circle>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    )
  }
}

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaSearch />
    </components.DropdownIndicator>
  )
}

class NavBarSearchInput extends React.Component{

	render () {
		return (
				<AsyncSelect
					{...this.props}
          styles={
            {
              indicatorSeparator:  (provided, state) => {
                const display = 'none';
                return { ...provided, display };
              }
            }
          }
					multi={false}
					backspaceRemoves={true}
          components={{DropdownIndicator}}
				/>
		);
	}
};


export { NavBarSearchInput, NotificationsIcon } ;
