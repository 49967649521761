import React from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { FormControl } from '@chakra-ui/react';
import { toUpper, capitalize } from 'lodash';


import { getIntlMessages } from '../../Intl';

class SelectInput extends React.Component{
	render () {
		return (
				<Select
					{...this.props}
          ref={this.props.inputRef }
          autoload={true}
					backspaceRemovesValue={true}
				/>
		);
	}
};

const defaultStyles = {
  indicatorSeparator:  (provided, state) => {
    const display = 'none';
    return { ...provided, display };
  },
  placeholder: (provided, state) => {
    return { ...provided, color: '#A0AEC0' }
  },
  control: (provided, state) => {
    return { ...provided, 
      boxShadow: state.isFocused ? '0 0 0 1px #3182ce' : 'none' , 
      borderColor: state.isFocused? '#3182ce' :'#E2E8F0', 
      height: 40,
      "&:hover": {
        borderColor: '#CBD5E0'
      },
    }
  },
  menuPortal: (provided, state) => {
    return { ...provided,
      zIndex: 9999
    }
  },
}

const errorStyles = {
  indicatorSeparator:  (provided, state) => {
    const display = 'none';
    return { ...provided, display };
  },
  placeholder: (provided, state) => {
    return { ...provided, color: '#A0AEC0' }
  },
  control: (provided, state) => {
    return { ...provided, 
      boxShadow: state.isFocused ? '0 0 0 1px #3182ce' : 'none' , 
      borderColor: '#d0021b', 
      height: 40,
      "&:hover": {
        borderColor: '#d0021b'
      },
    }
  },
  menuPortal: (provided, state) => {
    return { ...provided,
      zIndex: 9999
    }
  },
}

export const SelectFieldFromDefault = ({
  name,
  meta, 
  input,
  options,
  selectName, 
  isError,
  menuPortalTarget=document.body,
  multi=true,
  onChange=null,
  disabled=false,
  clearable=true,
  formatOption=null,
  ...rest }) => {

    return (
      <FormControl id={name} key={`SelectField_${name}`}>
        <SelectInput
          {...rest}
          onChange={(v) => {onChange && onChange(v);input.onChange(v)}}
          value={input.value}
          cache={false}
          isLoading={!options}
          options={options}
          isDisabled={disabled}
          isClearable={clearable}
          isMulti={multi}
          menuPortalTarget={menuPortalTarget}
          styles={ isError ? {...defaultStyles, ...errorStyles} : defaultStyles }
          onBlur={input.onBlur}
          onBlurResetsInput={false}
          onSelectResetsInput={false}
        />
      </FormControl>
    )
  }

/**
 * Select from a list of options.
 * Automitaclly creates {value: val, label: label} from given array of string options
 */
export const SelectFieldFromList = ({
  name,
  meta, 
  input,
  options,
  selectName, 
  isError,
  menuPortalTarget=document.body,
  multi=true,
  onChange=null,
  disabled=false,
  clearable=true,
  formatOption=null,
  selectStyles={},
  ...rest }) => {

    const intlMessages = useSelector(getIntlMessages);

    // format option with translation in data.json
    const _formatOption = (val) => {
      return { 
        value: val, 
        label: capitalize(intlMessages[`select.${selectName}.option.${val}`] || val)
      }
    }

    // used to select value for onChange
    const retrieveValues = (vals) => {
      
      if (!multi) {
        onChange && onChange(vals && vals.value)
        return vals && vals.value;
      }
      const returnVals = (vals || []).map( selectVal => selectVal.value)
      onChange && onChange(returnVals)
      return returnVals
    }

    // return options
    const _options = () => {
      return (options || []).length > 0
        ? options.map(elem => _formatOption(elem))
        : [];
    }

    // get value(s) in {value, label} format
    const _value = () => {
      if (!multi) return input.value && _formatOption(input.value);
      if ((input.value || []).length > 0){
        return input.value.map( item => _formatOption(item))
      } else {
        return []
      }
    }

    return (
      <FormControl id={name} key={`MultipleSelectAsyncField_${name}`}>
        <SelectInput
          {...rest}
          onChange={inputVal => {onChange && onChange();input.onChange(retrieveValues(inputVal))} }
          value={_value()}
          cache={false}
          isLoading={!options}
          options={_options()}
          isDisabled={disabled}
          isClearable={clearable}
          menuPortalTarget={menuPortalTarget}
          isMulti={multi}
          styles={ isError ? errorStyles : {...defaultStyles, ...selectStyles} }
          onBlur={input.onBlur}
          onBlurResetsInput={false}
          onSelectResetsInput={false}
        />
      </FormControl>
    )
  }

export const SelectFieldFromListRaw = ({
    name, 
    value,
    options,
    selectName, 
    isError,
    inputRef=null,
    multi=true,
    onChange=null,
    menuPortalTarget=document.body,
    initTranslations=null,
    disabled=false,
    clearable=true,
    formatOption=null,
    selectStyles={},
    ...rest }) => {

      const translations = initTranslations ?  initTranslations : {
        'appt_reminder':'Appt Reminder: Send x days before appointment.',
        'appt_scheduled': 'Appt Confirmation: Send when appointment is created.',
        'pay_reminder': 'Payment Reminder: Send when payment is required.',
        'pay_confirmed': 'Payment Confirmation: Send when payment has been processed.',
        'ship_confirmed': 'Shipped: Send when product has been shipped.',
        'other': 'Other'
      }

      // format option with translation in data.json
      const _formatOption = (val) => {
        return { 
          value: val, 
          label: capitalize(translations[val] || val)
        }
      }
  
      // used to select value for onChange
      const retrieveValues = (vals) => {
        
        if (!multi) {
          onChange && onChange(vals && vals.value)
          return vals && vals.value;
        }
        const returnVals = (vals || []).map( selectVal => selectVal.value)
        onChange && onChange(returnVals)
        return returnVals
      }
  
      // return options
      const _options = () => {
        return (options || []).length > 0
          ? options.map(elem => _formatOption(elem))
          : [];
      }
  
      // get value(s) in {value, label} format
      const _value = () => {
        if (!multi) return value && _formatOption(value);
        if ((value || []).length > 0){
          return value.map( item => _formatOption(item))
        } else {
          return []
        }
      }
  
      return (
        <FormControl id={name} key={`MultipleSelectAsyncFieldRaw_${name}`}>
          <SelectInput
            {...rest}
            onChange={inputVal => {onChange(retrieveValues(inputVal))} }
            value={_value()}
            inputRef={inputRef}
            cache={false}
            isLoading={!options}
            options={_options()}
            isDisabled={disabled}
            isClearable={clearable}
            menuPortalTarget={menuPortalTarget}
            isMulti={multi}
            styles={ isError 
              ? {...defaultStyles, ...selectStyles, ...errorStyles} 
              : {...defaultStyles, ...selectStyles} 
            }
            onBlurResetsInput={false}
            onSelectResetsInput={false}
          />
        </FormControl>
      )
    }