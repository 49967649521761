import React , { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SelectFieldFromList } from '../_components/Forms/MultipleSelectAsyncField';

import { snakeCase, toUpper } from 'lodash';

import { ADMIN_API_URLS, API_URLS } from '../_config';
import { authHeader } from '../_helpers/auth-header';

import { useSelectOptionsStore } from './stores/select';

import { isFieldErrorfromMeta, getErrorMessage } from '../_libs/forms';

import { getIntlMessages } from '../Intl';

export const ReportAttachmentsSelectField = ({input, meta, disabled=false}) => {
  const { reportAttachmentTypes : selectOptions, addOptions } = useSelectOptionsStore();
  const selectName = 'reportAttachmentTypes';

  const intlMessages = useSelector(getIntlMessages);

  useEffect(() => {
    if (!selectOptions){
      // clinicRoles has not been loaded
      //fetch options from 
      let url = API_URLS.getSelectOptions.url + snakeCase(selectName);
      const requestOptions = {
        method: 'GET',
        headers: authHeader()
      };
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then(
          (json) => {
            addOptions(selectName, json.sort());
          },
          (err) => {
            return []
          }
        )
    }
    return () => {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptions])

  return (
    <SelectFieldFromList 
      input={input}
      name={input.name}
      disabled={disabled}
      selectName={selectName}
      multi={false}
      isError={isFieldErrorfromMeta(meta)}
      options={selectOptions}
    />
  )
}

export const CountriesSelectField = ({input, meta, disabled=false, onChange=null}) => {
  const { countries : selectOptions, addOptions } = useSelectOptionsStore();
  const selectName = 'countries';

  const intlMessages = useSelector(getIntlMessages);

  useEffect(() => {
    if (!selectOptions){
      // clinicRoles has not been loaded
      //fetch options from 
      let url = ADMIN_API_URLS.getSelectOptions.url + snakeCase(selectName);
      const requestOptions = {
        method: 'GET',
        headers: authHeader()
      };
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then(
          (json) => {
            addOptions(selectName, json.sort());
          },
          (err) => {
            return []
          }
        )
    }
    return () => {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptions])

  return (
    <SelectFieldFromList 
      input={input}
      name={input.name}
      onChange={onChange}
      disabled={disabled}
      selectName={selectName}
      multi={false}
      isError={isFieldErrorfromMeta(meta)}
      options={selectOptions}
    />
  )
}

export const ClinicRolesSelectField = ({input, meta, disabled=false}) => {
  const { clinicRoles: selectOptions, addOptions } = useSelectOptionsStore();
  const selectName = 'clinicRoles';

  useEffect(() => {
    if (!selectOptions){
      // clinicRoles has not been loaded
      //fetch options from 
      let url = ADMIN_API_URLS.getSelectOptions.url + snakeCase(selectName);
      const requestOptions = {
        method: 'GET',
        headers: authHeader()
      };
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then(
          (json) => {
            addOptions(selectName, json) ;
          },
          (err) => {
            return []
          }
        )
    }
    return () => {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptions])

  return (
    <SelectFieldFromList 
      input={input}
      name={input.name}
      disabled={disabled}
      selectName={selectName}
      isError={isFieldErrorfromMeta(meta)}
      options={selectOptions}
    />
  )
}

export const AccountRolesSelectField = ({input, meta, disabled=false}) => {
  const { accountRoles: selectOptions, addOptions } = useSelectOptionsStore();
  const selectName = 'accountRoles';

  useEffect(() => {
    if (!selectOptions){
      // clinicRoles has not been loaded
      //fetch options from 
      let url = ADMIN_API_URLS.getSelectOptions.url + snakeCase(selectName);
      const requestOptions = {
        method: 'GET',
        headers: authHeader()
      };
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then(
          (json) => {
            addOptions(selectName, json) ;
          },
          (err) => {
            return []
          }
        )
    }
    return () => {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptions])

  return (
    <SelectFieldFromList 
      input={input}
      name={input.name}
      disabled={disabled}
      selectName={selectName}
      isError={isFieldErrorfromMeta(meta)}
      options={selectOptions}
    />
  )
}

export const DentistRolesSelectField = ({input, meta, disabled=false}) => {
  const { dentistRoles: selectOptions, addOptions } = useSelectOptionsStore();
  const selectName = 'dentistRoles';

  useEffect(() => {
    if (!selectOptions){
      // clinicRoles has not been loaded
      //fetch options from 
      let url = ADMIN_API_URLS.getSelectOptions.url + snakeCase(selectName);
      const requestOptions = {
        method: 'GET',
        headers: authHeader()
      };
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then(
          (json) => {
            addOptions(selectName, json) ;
          },
          (err) => {
            return []
          }
        )
    }
    return () => {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptions])

  return (
    <SelectFieldFromList 
      input={input}
      name={input.name}
      disabled={disabled}
      selectName={selectName}
      isError={isFieldErrorfromMeta(meta)}
      options={selectOptions}
    />
  )
}


export const CurrenciesSelectField = ({input, meta, disabled=false}) => {
  const { currencies: selectOptions, addOptions } = useSelectOptionsStore();
  const selectName = 'currencies';

  useEffect(() => {
    if (!selectOptions){
      // clinicRoles has not been loaded
      //fetch options from 
      let url = ADMIN_API_URLS.getSelectOptions.url + snakeCase(selectName);
      const requestOptions = {
        method: 'GET',
        headers: authHeader()
      };
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then(
          (json) => {
            addOptions(selectName, json) ;
          },
          (err) => {
            return []
          }
        )
    }
    return () => {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptions])

  return (
    <SelectFieldFromList 
      input={input}
      name={input.name}
      disabled={disabled}
      multi={false}
      selectName={selectName}
      isError={isFieldErrorfromMeta(meta)}
      options={selectOptions}
    />
  )
}


export const ProductTypesSelectField = ({input, meta, disabled=false}) => {
  const { productTypes: selectOptions, addOptions } = useSelectOptionsStore();
  const selectName = 'productTypes';

  useEffect(() => {
    if (!selectOptions){
      // clinicRoles has not been loaded
      //fetch options from 
      let url = ADMIN_API_URLS.getSelectOptions.url + snakeCase(selectName);
      const requestOptions = {
        method: 'GET',
        headers: authHeader()
      };
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then(
          (json) => {
            addOptions(selectName, json) ;
          },
          (err) => {
            return []
          }
        )
    }
    return () => {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptions])

  return (
    <SelectFieldFromList 
      input={input}
      name={input.name}
      disabled={disabled}
      multi={false}
      selectName={selectName}
      isError={isFieldErrorfromMeta(meta)}
      options={selectOptions}
    />
  )
} 