import { useState } from 'react';

import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { customAlphabet } from 'nanoid';
import {
  Button as ButtonChakra, Flex, Box, Center, Text, Stack, Image, FormErrorMessage
} from '@chakra-ui/react';

import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from "final-form";

import { FormattedMessage, useIntl } from 'react-intl';


import { useAuth } from '../_libs/auth';
import backgroundImage from '../../images/dental-lab-background-min.png';

import { upperFirst } from 'lodash';
import { getIntlLocale } from '../Intl';
import { LoginForm } from './LoginForm';
import { ResetForm } from './ResetForm';
import { ForgotForm } from './ForgotForm';

export const LoginAppPage = () => {
  const location = useLocation();
  const history = useHistory();
  const forgotMatch = useRouteMatch('/forgot')
  const resetMatch = useRouteMatch('/reset/:uid/:token')
  const urlParams = useParams();
  const { login, isLoggingIn } = useAuth();
  const [ isGoogleLoading, setGoogleLoading ] = useState(false);
  const [ googleError, setGoogleError ] = useState(false);
  const [ generalFormError, setGeneralFormError ] = useState(false);
  const intlLocale = useSelector(getIntlLocale);
  const { formatMessage } = useIntl();
  const nanoid = customAlphabet(
    'useandom26T198340PX75pxJACKVERYMINDBUSHWOLFGQZbfghjklqvwyzrict',
    15
  );

  console.log("resetmatch ", resetMatch)
  const state = nanoid();
  const onSuccessfulLogin = (from) => {
    if (from && from !== '/login'){
      history.push(location.state.from.pathname)
    } else {
      history.push('/');
    }
  }


  // handles form submission
  const handleLogin = async (values) => {
    if (isGoogleLoading) return;
    let from = location?.state?.from?.pathname
    try{
      await login({...values, provider: ""});
      onSuccessfulLogin(from);
    } catch (err){
      return {
        ...err,
        [FORM_ERROR] : upperFirst('general error')
      }
    }
  }

  const handleLoginGoogle = async (response) => {
    let from = location?.state?.from?.pathname
    setGoogleLoading(true);
    setGoogleError(false);
    // NOT THIS ONE const access_tok = response.accessToken;
    const tok_id = response.credential //THIS ONE
    if (!tok_id){
      setGoogleError('google error')
      setGoogleLoading(false);
      return
    }
    try{
      const authCredentials = { code: tok_id, provider: 'google'};
      await login(authCredentials);
      onSuccessfulLogin(from);
    } catch (err){
      setGeneralFormError('general error')
    } finally {
      setGoogleLoading(false);
    }
  }

  return (
    <Box
      height={'100vh'}
      bgImage={backgroundImage}
      bgSize={'cover'}
      fontFamily={'Poppins'}
    >
      <Center h="100%">
        <Flex
          justify={['center', 'flex-start']}
          align={'center'}
          background={'#FFF'}
          px={[8, 20]}
          pb={50}
          pt={[0, 50]}
          borderRadius={[0, '50px']}
          boxShadow={'0px 8px 10px rgba(0, 0, 0, 0.25);'}
          minH={['full', 700]}
          minW={['full', 550]}
          direction={'column'}
        >
          {
            forgotMatch?.isExact ?
              <ForgotForm /> :
              resetMatch?.isExact ?
                <ResetForm /> :
                <LoginForm />
          }
         
        </Flex>
      </Center>
    </Box>
  )

}