import * as React from 'react';
import { useDispatch } from 'react-redux';
import storage from '../_helpers/storage';
import { updateIntl } from 'react-intl-redux';

async function loadLocaleData(locale) {
  switch (locale) {
    case 'zh':
    case 'zh-cn':
    case 'cn':
      return import('../../locales/cn.json');
    case 'ja':
    case 'ja-jp':
      return import('../../locales/ja.json');
    case 'th-th':
    case 'th':
      return import('../../locales/th.json');
    default:
      return import('../../locales/en.json');
  }
}
export const useLocale = (
  initial = { current: storage.getLocale(), currentMsgs: { default: {} } }
) => {
  const dispatch = useDispatch();
  const [{ current, currentMsgs }, setCurrent] = React.useState(initial);

  const setLocale = React.useCallback(async (val) => {
    console.log("LANG val ", val)
    const messages = await loadLocaleData(val);
    storage.setLocale(val)
    dispatch(updateIntl({locale: val, messages}))
    //setCurrent({ current: val, currentMsgs: messages });
  }, [dispatch]);

  return { locale: current, messages: currentMsgs, setLocale };
};
