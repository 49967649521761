import React from 'react';
import AsyncSelect from 'react-select/async';
import { FormControl } from '@chakra-ui/react';

class FormSearchInput extends React.Component{
	render () {
		return (
				<AsyncSelect
					{...this.props}
					isMulti={false}
					backspaceRemovesValue={true}
          debounceInterval={500}
          isOptionDisabled={(option) => option?.data?.is_active === false}
				/>
		);
	}
};

const defaultSelectStyles = {
  container: (provided, state) => {
    return { ...provided, 
      fontFamily: 'Poppins'
    }
  },
  dropdownIndicator: (provided, state) => {
    const display = 'none';
    return { ...provided, display };
  },
  indicatorSeparator:  (provided, state) => {
    const display = 'none';
    return { ...provided, display };
  },
  placeholder: (provided, state) => {
    return { ...provided, color: '#A0AEC0' }
  },
  control: (provided, state) => {
    return { ...provided, 
      boxShadow: state.isFocused ? '0 0 0 1px #3182ce' : 'none' , 
      borderColor: state.isFocused? '#3182ce' :'#E2E8F0', 
      height: 40,
      "&:hover": {
        borderColor: '#CBD5E0'
      },
    }
  },
  menu: (provided, state) => {
    return { ...provided,
      zIndex: 9999
    }
  },
  menuPortal: (provided, state) => {
    return { ...provided,
      zIndex: 9999
    }
  },
  

}

const errorStyles = {
  indicatorSeparator:  (provided, state) => {
    const display = 'none';
    return { ...provided, display };
  },
  placeholder: (provided, state) => {
    return { ...provided, color: '#A0AEC0' }
  },
  control: (provided, state) => {
    return { ...provided, 
      boxShadow: state.isFocused ? '0 0 0 1px #3182ce' : 'none' , 
      borderColor: '#d0021b', 
      height: 40,
      "&:hover": {
        borderColor: '#d0021b'
      },
    }
  },
  menuPortal: (base) => ({ ...base, zIndex: 9999 })
}
export const SearchFieldComponent = ({
  initValue, 
  name,
  meta, 
  values, 
  label, 
  intlLocale, 
  intlMessages, 
  className, 
  disabled, 
  createNewValue, 
  menuPortalTarget,
  defaultSearch,
  loadOptions, onInputChange, onChange, onValueClick, 
  defaultOptions=true,
  selectStyles={},
  isError=false,
  ...rest }) => (
  <FormControl id={name}>
    <FormSearchInput
      {...rest}
      loadOptions={loadOptions}
      defaultOptions={createNewValue ? [createNewValue] : defaultSearch ? true : []}
      onChange={inputValue => onChange(inputValue)}
      onValueClick={onValueClick}
      isDisabled={disabled}
      onInputChange={onInputChange}
      isClearable={true}
      styles={ isError 
        ? {...defaultSelectStyles, ...selectStyles, ...errorStyles} 
        : {...defaultSelectStyles, ...selectStyles}
      }
      onBlurResetsInput={false}
      onSelectResetsInput={false}
      menuPortalTarget={menuPortalTarget}
    />
  </FormControl>
)