import React from 'react';
import ContentLoader from 'react-content-loader';
import { Skeleton, Stack } from '@chakra-ui/react';
const RequestDetailLoader = props => (
	<ContentLoader
		height={160}
		width={400}
		speed={2}
		primaryColor="#f3f3f3"
		secondaryColor="#ecebeb"
		{...props}
	>
		<rect x="0" y="0" rx="3" ry="3" width="70" height="10" />
		<rect x="80" y="0" rx="3" ry="3" width="100" height="10" />
		<rect x="190" y="0" rx="3" ry="3" width="10" height="10" />
		<rect x="15" y="20" rx="3" ry="3" width="130" height="10" />
		<rect x="155" y="20" rx="3" ry="3" width="130" height="10" />
		<rect x="15" y="40" rx="3" ry="3" width="90" height="10" />
		<rect x="115" y="40" rx="3" ry="3" width="60" height="10" />
		<rect x="185" y="40" rx="3" ry="3" width="60" height="10" />
		<rect x="0" y="60" rx="3" ry="3" width="30" height="10" />
		<rect x="0" y="80" rx="3" ry="3" width="70" height="10" />
		<rect x="80" y="80" rx="3" ry="3" width="100" height="10" />
		<rect x="190" y="80" rx="3" ry="3" width="10" height="10" />
		<rect x="15" y="100" rx="3" ry="3" width="130" height="10" />
		<rect x="155" y="100" rx="3" ry="3" width="130" height="10" />
		<rect x="15" y="120" rx="3" ry="3" width="90" height="10" />
		<rect x="115" y="120" rx="3" ry="3" width="60" height="10" />
		<rect x="185" y="120" rx="3" ry="3" width="60" height="10" />
		<rect x="0" y="140" rx="3" ry="3" width="30" height="10" />
	</ContentLoader>
)

const RequestListLoader = props => (
	<ContentLoader
		height={475}
		width={400}
		speed={2}
		primaryColor="#f3f3f3"
		secondaryColor="#ecebeb"
		{...props}
	>
		<rect x="30" y="29" rx="0" ry="0" width="130" height="18" />
		<rect x="176" y="29" rx="0" ry="0" width="130" height="18" />
		<rect x="328" y="29" rx="0" ry="0" width="130" height="18" />
		<rect x="29" y="66" rx="0" ry="0" width="130" height="18" />
		<rect x="179" y="67" rx="0" ry="0" width="130" height="18" />
		<rect x="329" y="66" rx="0" ry="0" width="130" height="18" />
	</ContentLoader>
)

const ConsultRequestListLoader = props => (
    <Stack spacing={2}>
      <Skeleton w="full" h="50"/>
      <Skeleton w="98%"  h="50"/>
      <Skeleton w="full" h="50"/>
      <Skeleton w="97%"  h="50"/>
    </Stack>
)

const TabContentLoader = props => (
	<ContentLoader
		height={475}
		width={400}
		speed={2}
		primaryColor="#f3f3f3"
		secondaryColor="#ecebeb"
		{...props}
	>
		<rect x="15" y="34" rx="0" ry="0" width="192.08" height="64.9" />
		<rect x="235" y="35" rx="0" ry="0" width="130" height="18" />
		<rect x="235" y="59" rx="0" ry="0" width="130" height="18" />
		<rect x="235" y="82" rx="0" ry="0" width="130" height="18" />
	</ContentLoader>
)

const LoadingEllipsis = ({width, height, viewBox}) => {
  console.log("LOADINGELLIPSIS", width, height, viewBox)
  const svgWidth = (width) ? width : '120px';
  const svgHeight = (height) ? height : '30px';
  const svgViewBox = (viewBox) ? viewBox : '0 30 100 50';
  return (
    <svg width={svgWidth}  height={svgHeight} xmlns="http://www.w3.org/2000/svg" viewBox={svgViewBox} preserveAspectRatio="xMidYMid">
      <circle cx="84" cy="50" r="0" fill="#a3a3ac">
        <animate attributeName="r" values="10;0;0;0;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="0s"></animate>
        <animate attributeName="cx" values="84;84;84;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="0s"></animate>
      </circle>
      <circle cx="49.9762" cy="50" r="10" fill="#b8babd">
        <animate attributeName="r" values="0;10;10;10;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="-1.05s"></animate>
        <animate attributeName="cx" values="16;16;50;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="-1.05s"></animate>
      </circle>
      <circle cx="16" cy="50" r="9.99299" fill="#d8dddf">
        <animate attributeName="r" values="0;10;10;10;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="-0.525s"></animate>
        <animate attributeName="cx" values="16;16;50;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="-0.525s"></animate>
      </circle>
      <circle cx="84" cy="50" r="0.00701046" fill="#f3f3f3">
        <animate attributeName="r" values="0;10;10;10;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="0s"></animate>
        <animate attributeName="cx" values="16;16;50;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="0s"></animate>
      </circle>
      <circle cx="83.9762" cy="50" r="10" fill="#a3a3ac">
        <animate attributeName="r" values="0;0;10;10;10" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="0s"></animate>
        <animate attributeName="cx" values="16;16;16;50;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="0s"></animate>
      </circle>
    </svg>
  )
}

const LoadingEllipsisRotated = ({width, height, viewBox, transform}) => {
  const svgWidth = (width) ? width : '30px';
  const svgHeight = (height) ? height : '100px';
  const svgViewBox = (viewBox) ? viewBox : '0 20 50 100';
  const svgTransform = (transform) ? transform : '';
  return (
    <svg width={svgWidth}  height={svgHeight} xmlns="http://www.w3.org/2000/svg" viewBox={svgViewBox} transform={svgTransform} preserveAspectRatio="xMidYMid">
      <circle cx="30" cy="84" r="0" fill="#a3a3ac">
        <animate attributeName="r" values="10;0;0;0;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="0s"></animate>
        <animate attributeName="cy" values="84;84;84;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="0s"></animate>
      </circle>
      <circle cx="30" cy="49.9762" r="10" fill="#b8babd">
        <animate attributeName="r" values="0;10;10;10;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="-1.05s"></animate>
        <animate attributeName="cy" values="16;16;50;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="-1.05s"></animate>
      </circle>
      <circle cx="30" cy="16" r="9.99299" fill="#d8dddf">
        <animate attributeName="r" values="0;10;10;10;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="-0.525s"></animate>
        <animate attributeName="cy" values="16;16;50;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="-0.525s"></animate>
      </circle>
      <circle cx="30" cy="84" r="0.00701046" fill="#f3f3f3">
        <animate attributeName="r" values="0;10;10;10;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="0s"></animate>
        <animate attributeName="cy" values="16;16;50;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="0s"></animate>
      </circle>
      <circle cx="30" cy="83.9762" r="10" fill="#a3a3ac">
        <animate attributeName="r" values="0;0;10;10;10" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="0s"></animate>
        <animate attributeName="cy" values="16;16;16;50;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="0s"></animate>
      </circle>
    </svg>
  )

}

const LoadingCircle = () => {
  return(
    <svg width="100px"  height="100px"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style={{background:'none'}}>
      <circle cx="50" cy="50" fill="none"
          stroke="#f3f3f3" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138"
          transform="rotate(113.506 50 50)">
        <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform>
      </circle>
    </svg>
  )
}

export { TabContentLoader, RequestListLoader, ConsultRequestListLoader, RequestDetailLoader, LoadingEllipsis, LoadingEllipsisRotated, LoadingCircle};
