import { schedulingConstants } from '../_constants';
import { schedulingService } from '../_services';
import { history } from '../_helpers';
import moment from 'moment'
export const schedulingActions = {
    getAppointments,
    getActivityLog,
    createAppointment,
    getAppointmentbyID,
    addDentist,
    removeDentist,
    changeDate,
    changeView,
    closeApptForm,
    openApptForm,
    openNewApptForm,
    changeApptForm,
    clearApptForm,
    openAddDentistForm,
    closeAddDentistForm,
    getPracticeProviders,
    getPracticePatients,
    getPracticeOperatories,
    openPatientForm,
    changePatientForm,
    closePatientForm,
    createPatient,
    selectApptPatient
};

// get appointment after click on calendar
function getAppointmentbyID(apptID){
	return dispatch => {
		dispatch(request());
		schedulingService.getAppointmentbyID(apptID)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	}

	function request() { return { type: schedulingConstants.GETAPPOINTMENT_BYID_REQUEST}}
	function success(data) {
		let tempAppt = data.appointment
		tempAppt = {
			...tempAppt,
			["patient"]: {...tempAppt["patient"]},
			["date"]: moment(tempAppt.start_time).format('YYYY-MM-DD'),
			["start_time"] : moment(tempAppt.start_time).format("HH:mm"),
			["end_time"] : moment(tempAppt.end_time).format("HH:mm")
		}
		data["appointment"] = tempAppt;
		return { type: schedulingConstants.GETAPPOINTMENT_BYID_SUCCESS, data }
	}
    function failure(error) { return { type: schedulingConstants.GETAPPOINTMENT_BYID_FAILURE, error } }
}

// get appointments to fill calendar
function getAppointments(dentistList = [], dateRange) {
	return dispatch => {
		dispatch(request());
		schedulingService.getAppointments(dentistList, dateRange)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: schedulingConstants.GETAPPOINTMENTS_REQUEST } }
    function success(data) {
    	let result = []
    	for (let i=0;i<data.appointments.length;i++){
    		let tempAppt = data.appointments[i];
    		result.push({
    			...tempAppt,
    			["dentist"] : {...tempAppt["dentist"]},
    			["patient"] : {...tempAppt["patient"]},
    			["start_time"]: new Date(tempAppt["start_time"]),
    			["end_time"]: new Date(tempAppt["end_time"]),
    		})
    	}
    	data.appointments = result
    	return { type: schedulingConstants.GETAPPOINTMENTS_SUCCESS, data }
    };
    function failure(error) { return { type: schedulingConstants.GETAPPOINTMENTS_FAILURE, error } }
};

// get Activity log
function getActivityLog(init){
	return (dispatch, getState) => {
		dispatch(request());
		var lastActivity;
		if (init == undefined){
			// get activity with date filter
			lastActivity = getState().scheduling.activityLogLatest
		}
		schedulingService.getActivityLog(lastActivity)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: schedulingConstants.GETACTIVITYLOG_REQUEST } }
    function success(data) {
    	return { type: schedulingConstants.GETACTIVITYLOG_SUCCESS, data }
    };
    function failure(error) { return { type: schedulingConstants.GETACTIVITYLOG_FAILURE, error } }
};

//return patient list for select lists mostly

function getPracticePatients(){
	return dispatch => {
		dispatch(request());
		schedulingService.getPracticePatients()
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: schedulingConstants.GETPRACTICEPATIENTS_REQUEST } }
    function success(data) {
    	return { type: schedulingConstants.GETPRACTICEPATIENTS_SUCCESS, data }
    };
    function failure(error) { return { type: schedulingConstants.GETPRACTICEPATIENTS_FAILURE, error } }
};

//return provider list for select lists mostly

function getPracticeOperatories(){
	return dispatch => {
		dispatch(request());
		schedulingService.getPracticeOperatories()
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: schedulingConstants.GETPRACTICEOPERATORIES_REQUEST } }
    function success(data) {
    	return { type: schedulingConstants.GETPRACTICEOPERATORIES_SUCCESS, data }
    };
    function failure(error) { return { type: schedulingConstants.GETPRACTICEOPERATORIES_FAILURE, error } }
};

//return operatory list for select lists mostly

function getPracticeProviders(){
	return dispatch => {
		dispatch(request());
		schedulingService.getPracticeProviders()
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: schedulingConstants.GETPRACTICEPROVIDERS_REQUEST } }
    function success(data) {
    	return { type: schedulingConstants.GETPRACTICEPROVIDERS_SUCCESS, data }
    };
    function failure(error) { return { type: schedulingConstants.GETPRACTICEPROVIDERS_FAILURE, error } }
};


// remove provider from filter
function removeDentist(dentist, dentistList){
	return dispatch => {
		const inputList = [...dentistList]
		const newList = schedulingService.removeDentist(dentist, dentistList);
		if ( (newList.length == inputList.length)) {
			dispatch(closeAddDentistForm());
		} else {
			dispatch(remove(newList));
			dispatch(getAppointments(newList));
		}
	}
	function remove(dentistList) { return { type: schedulingConstants.REMOVEDENTIST, dentistList }}
};

// add a provider filter
function addDentist(dentist, dentistList) {
	return dispatch => {
		let duplicateDentist = dentistList.filter(user => { return user.id === parseInt(dentist.id) }).length;
		console.log(dentist, duplicateDentist)
		if (duplicateDentist){
			dispatch(closeAddDentistForm());
		}else {
			const newlist = schedulingService.addDentist(dentist, dentistList);
			dispatch(add(newlist));
			dispatch(closeAddDentistForm());
			dispatch(getAppointments(newlist));
		}
	}
	function add(dentistList) { return { type: schedulingConstants.ADDDENTIST, dentistList }}
};

// open appt form
function openApptForm(){
	return dispatch => {
		dispatch(open());
	}
	function open() { return {type: schedulingConstants.OPENAPPTFORM} }
};

function openNewApptForm(form){
	return dispatch => {
		dispatch(open(form));
	}
	function open() { return {type: schedulingConstants.OPENNEWAPPTFORM, form} }
};

// close appt form
function closeApptForm(){
	return dispatch => {
		dispatch(close());
	}
	function close() { return {type: schedulingConstants.CLOSEAPPTFORM}}
};

function changeApptForm(form){
	return dispatch => {
		dispatch(change(form));
	}
	function change() { return {type: schedulingConstants.CHANGEAPPTFORM, form} }
};

function selectApptPatient(patient){
	return dispatch => {
		dispatch(change(patient));
	}
	function change() { return {type: schedulingConstants.CHANGEAPPTPATIENT, patient} }
};
// open patient form
function openPatientForm(){
	return dispatch => {
		dispatch(open());
	}
	function open() { return {type: schedulingConstants.OPENPATIENTFORM}}
};

// close patient form
function closePatientForm(){
	return dispatch => {
		dispatch(close());
	}
	function close() { return {type: schedulingConstants.CLOSEPATIENTFORM} }
};

function changePatientForm(form){
	return dispatch => {
		dispatch(change(form));
	}
	function change() { return {type: schedulingConstants.CHANGEPATIENTFORM, form} }
};

// open add dentist form
function openAddDentistForm(){
	return dispatch => {
		dispatch(open());
	}
	function open() { return {type: schedulingConstants.OPENADDDENTISTFORM}}
};

// close add dentist form
function closeAddDentistForm(){
	return dispatch => {
		dispatch(close());
	}
	function close() { return {type: schedulingConstants.CLOSEADDDENTISTFORM} }
};


// clear the appt form from fetched data
function clearApptForm(){
	return dispatch => {
		dispatch(clear());
	}
	function clear() { return {type: schedulingConstants.CLEARAPPTFORM}}
};

// change the calendar date
function changeDate(date){
	return dispatch => {
		dispatch(change(date));
	}
	function change(date) { return {type: schedulingConstants.CHANGEDATE, date}}
};

// change the calendar view
function changeView(view){
	return dispatch => {
		dispatch(change(view));
	}
	function change(view) { return {type: schedulingConstants.CHANGEVIEW, view}}
};

// create an appointment
function createAppointment(){
	return (dispatch, getState) => {
		dispatch(request());
		const form = {...getState().scheduling.apptForm}

        form["start_time"] = form["date"]+"T"+form["start_time_time"]
        form["end_time"] = form["date"]+"T"+form["end_time_time"]
		schedulingService.createAppointment(form)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};
    function request() { return { type: schedulingConstants.CREATEAPPOINTMENT_REQUEST } }
    function success(data) {
		let tempAppt = data.appointment
		tempAppt = {
    			...tempAppt,
    			["dentist"] : {...tempAppt["dentist"]},
    			["patient"] : {...tempAppt["patient"]},
    			["start_time"]: new Date(tempAppt["start_time"]),
    			["end_time"]: new Date(tempAppt["end_time"])
		}
		data["appointment"] = tempAppt;
    	return { type: schedulingConstants.CREATEAPPOINTMENT_SUCCESS, data }
    }
    function failure(error) { return { type: schedulingConstants.CREATEAPPOINTMENT_FAILURE, error } }
}

// create Patient
function createPatient(){
  return (dispatch, getState) => {
    dispatch(request());
    const form = {...getState().scheduling.patientForm}
    schedulingService.createPatient(form)
    .then(
      data => dispatch(success(data)),
      error => dispatch(failure(error))
    );
  };
  function request() { return { type: schedulingConstants.CREATEPATIENT_REQUEST } }
  function success(data) {
    return { type: schedulingConstants.CREATEPATIENT_SUCCESS, data }
  }
  function failure(error) { return { type: schedulingConstants.CREATEPATIENT_FAILURE, error } }
}

/*
 * Actions for redux forms
 * NOTE: currently testing
 */
// action after successful patient added Quickly
function addedPatient(data){
  return dispatch => {
    dispatch(success(data));
  }
  function success(data) {
    return { type: schedulingConstants.CREATEPATIENT_SUCCESS, data }
  }
}
// action after appointment is created
function addedAppointment(data){
  return dispatch => {
    dispatch(success(data))
  }
  function success(data) {
  let tempAppt = data.appointment;
  tempAppt = {
    ...tempAppt,
    ["dentist"] : {...tempAppt["dentist"]},
    ["patient"] : {...tempAppt["patient"]},
    ["start_time"]: new Date(tempAppt["start_time"]),
    ["end_time"]: new Date(tempAppt["end_time"])
  }
  data["appointment"] = tempAppt;
    return { type: schedulingConstants.CREATEAPPOINTMENT_SUCCESS, data }
  }
}
