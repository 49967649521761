import { patientSearchConstants, settingsConstants } from '../_constants';
import { patientSearchService } from '../_services';

export const patientSearchActions = {
    patientInputChange,
    doPatientSearch,
    messageFormChange,
    messageFormOpen,
    messageFormClose,
    messagePatient,
};

/*
 * Message Form Actions
 *
 */
function messageFormOpen(patientID, patientName){
	return dispatch => {
		const output = {form: {}, patientName}
		output.form['id'] = patientID;
		dispatch(change(output))
	}
	function change(data){ return {type: patientSearchConstants.OPEN_MESSAGEFORM, data}}
}

function messageFormChange(name, value){
	return dispatch => {
		const form = {}
		form[name] = value;
		dispatch(change(form))
	}
	function change(data){ return {type: patientSearchConstants.CHANGE_MESSAGEFORM, data}}
}

function messageFormClose(){
	return dispatch => {
		dispatch(close())
	}
	function close(data){ return {type: patientSearchConstants.CLOSE_MESSAGEFORM}}
}


function messagePatient(query) {
	return dispatch => {
		dispatch(request());
		patientSearchService.messagePatient(query)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	}
    function request() { return { type: settingsConstants.POST_MESSAGE_REQUEST } }
    function success(data) {
    	console.log(data, "success");
    	return { options: data.search_list }
    }
    function failure(error) { return { options: []} }
};

/*
 * Patient Search Actions
 *
 */


function patientInputChange(value){
	return dispatch => {
		dispatch(change(value))
	}
	function change(data){ return {type: patientSearchConstants.CHANGE_PATIENTINPUT, data}}
}


function doPatientSearch(init=null) {
	return (dispatch, getState) => {
		dispatch(request());
		const { patientSearchInput, patientLoadMoreOffset } = getState().patientSearch;
		const currentOffset = (init != null) ? init : patientLoadMoreOffset;
		console.log(init)
		patientSearchService.doPatientSearch(patientSearchInput, currentOffset)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	}
    function request() { return { type: patientSearchConstants.SEARCH_PATIENT_REQUEST } }
    function success(data) {
    	console.log(data, "success");
    	return { type:patientSearchConstants.SEARCH_PATIENT_SUCCESS, data}
    }
    function failure(error) { return { type:patientSearchConstants.SEARCH_PATIENT_FAILURE, error} }
};
