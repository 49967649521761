import { patientSearchConstants } from '../_constants'

const initMessageForm = {
	body: "",
	patient: "",
}

const initState = {
	patientSearchInput: "",
	patientSearchFormLoading: false,
	patientHasMoreLoad: false,
	patientSearchFormError: "",
	patientLoadMoreOffset: 0,
	patientSearchResults: [],
	messageForm: {...initMessageForm},
	messageFormOpen: false,
	messageFormLoading: false,
	messageFormError: "",
	messagePatientName: ""
}

export function patientSearch(state = initState, action){
	switch (action.type){
		case patientSearchConstants.OPEN_MESSAGEFORM:
			return {
				...state,
				messageFormOpen: true,
				messageForm: {...initMessageForm, ...action.data.form},
				messagePatientName: action.data.patientName
			}
		case patientSearchConstants.CLOSE_MESSAGEFORM:
			return {
				...state,
				messageForm: {...initMessageForm},
				messageFormOpen: false,
				messagePatientName: ""
			}
		case patientSearchConstants.CHANGE_MESSAGEFORM:
			return {
				...state,
				messageForm: {...state.messageForm, ...action.data}
			}


		case patientSearchConstants.SEND_MESSAGE_REQUEST:
			return {
				...state,
				messageFormLoading: true,
				messageFormError: ""
			}

		case patientSearchConstants.SEND_MESSAGE_SUCCESS:
			return {
				...state,
				messageFormLoading: false,
				messageFormError: "",
				messageForm: {...initMessageForm},
				messageFormOpen: false,
				messagePatientName: ""
			}

		case patientSearchConstants.SEND_MESSAGE_FAILURE:
			return {
				...state,
				messageFormLoading: false,
				messageFormError: ""
			}

		/*  */

		case patientSearchConstants.CHANGE_PATIENTINPUT:
			return {
				...state,
				patientSearchInput: action.data
			}

		case patientSearchConstants.SEARCH_PATIENT_REQUEST:
			return {
				...state,
				patientSearchFormLoading: true,
				patientSearchFormError: ""
			}

		case patientSearchConstants.SEARCH_PATIENT_SUCCESS:
			// hacky: patientHasMoreLoad is false when the next retrieved result is empty
			const initLoad = action.data.offset == 0;
			return {
				...state,
				patientSearchFormLoading: false,
				patientSearchFormError: "",
				patientSearchResults: (initLoad) ? action.data.patient_list : [...state.patientSearchResults, ...action.data.patient_list],
				patientLoadMoreOffset: action.data.offset + 1,
				patientHasMoreLoad: (action.data.patient_list.length == action.data.pg_sz)
			}

		case patientSearchConstants.SEARCH_PATIENT_FAILURE:
			return {
				...state,
				patientSearchFormLoading: false,
				patientSearchFormError: ""
			}

    default:
      return state
	}
}