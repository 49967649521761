import { Icon } from '@chakra-ui/react';

const AppointmentRequestsIcon = (props) => {
  return (
      <Icon  width="24" height="24" viewBox="0 0 24 24" color={'#C5C5C5'} {...props}>
        <path 
          d="M16 3C16.2652 3 16.5196 3.10536 16.7071 3.29289C16.8946 3.48043 17 3.73478 17 4V5H19C19.5046 4.99984 19.9906 5.19041 20.3605 5.5335C20.7305 5.87659 20.9572 6.34684 20.995 6.85L21 7V19C21.0002 19.5046 20.8096 19.9906 20.4665 20.3605C20.1234 20.7305 19.6532 20.9572 19.15 20.995L19 21H5C4.49542 21.0002 4.00943 20.8096 3.63945 20.4665C3.26947 20.1234 3.04284 19.6532 3.005 19.15L3 19V7C2.99984 6.49542 3.19041 6.00943 3.5335 5.63945C3.87659 5.26947 4.34684 5.04284 4.85 5.005L5 5H7V4C7 3.73478 7.10536 3.48043 7.29289 3.29289C7.48043 3.10536 7.73478 3 8 3C8.26522 3 8.51957 3.10536 8.70711 3.29289C8.89464 3.48043 9 3.73478 9 4V5H15V4C15 3.73478 15.1054 3.48043 15.2929 3.29289C15.4804 3.10536 15.7348 3 16 3ZM14.824 9.379L10.582 13.621L9.167 12.207C8.9784 12.0248 8.7258 11.924 8.4636 11.9263C8.2014 11.9286 7.95059 12.0338 7.76518 12.2192C7.57977 12.4046 7.4746 12.6554 7.47233 12.9176C7.47005 13.1798 7.57084 13.4324 7.753 13.621L9.867 15.736C9.9608 15.8299 10.0722 15.9044 10.1948 15.9552C10.3174 16.006 10.4488 16.0321 10.5815 16.0321C10.7142 16.0321 10.8456 16.006 10.9682 15.9552C11.0908 15.9044 11.2022 15.8299 11.296 15.736L16.238 10.793C16.3335 10.7008 16.4097 10.5904 16.4621 10.4684C16.5145 10.3464 16.5421 10.2152 16.5433 10.0824C16.5444 9.94962 16.5191 9.81794 16.4688 9.69505C16.4185 9.57215 16.3443 9.4605 16.2504 9.3666C16.1565 9.27271 16.0449 9.19846 15.922 9.14818C15.7991 9.0979 15.6674 9.0726 15.5346 9.07375C15.4018 9.0749 15.2706 9.10249 15.1486 9.1549C15.0266 9.20731 14.9162 9.28349 14.824 9.379Z" 
          fill={'currentColor'}
          />
       </Icon>
    )
}

export default AppointmentRequestsIcon;