import React, { Component } from 'react';

import { 
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';


class CustomButton extends Component {
	render(){
		return(
			<Button 
        borderRadius={'30px'}
        fontSize={12}
        fontWeight={600}
        {...this.props }
      >
				{this.props.children}
			</Button>
		)
	}
}


class CustomDialog extends Component {
  render(){
    return <Modal  width={'700px'} bg={'#FAFAFA'}  p={'15px 25px'} {...this.props}>{this.props.children}</Modal>
  }
}
class CustomDialogLarge extends Component {
  render(){
    return <Modal bg={'#FAFAFA'} width={'1000px'} p={'15px 25px'}  {...this.props}>{this.props.children}</Modal>
  }
}



class CustomDialogTitle extends Component {
	render(){
		return (
    <ModalHeader 
      fontSize={'18px'}
      minW={'350px'}
      bg={'#FAFAFA'}
      {...this.props}
      >
      {this.props.children}
    </ModalHeader>)
	}
}

class CustomDialogContent extends Component {
	render(){
		return <ModalContent {...this.props}>{this.props.children}</ModalContent>
	}
}

class CustomDialogContentText extends Component {
	render(){
		return <ModalBody fontSize={14} mb={'10px'} pb={0} {...this.props}>{this.props.children}</ModalBody>
	}
}

class CustomDialogActions extends Component {
	render(){
		return <ModalFooter pr={'24px'} pb={'10px'} {...this.props}>{this.props.children}</ModalFooter>
	}
}



export {
	CustomButton,
	CustomDialog,
  CustomDialogLarge,
	CustomDialogTitle,
	CustomDialogContent,
	CustomDialogContentText,
	CustomDialogActions
}
