import { Grid, Box, Flex, Spinner } from '@chakra-ui/react';

import { Topbar } from '../Topbar';
import { Notifications } from 'js/Notifications/Notifications';
import { PubnubWrapper } from 'js/Notifications/PubnubWrapper';

export const MainLayout = ({ children }) => {
  return (
    <>
    <Notifications />
    <PubnubWrapper />
    <Grid 
      id={'MainLayout'}
      h={'100vh'}
      w="full" 
      position="relative" 
      templateAreas={`
        "topbar"
        "border"
        "main"`}
      gridTemplateRows={'max-content 1px auto'}
      gridTemplateColumns={'auto'}
    >
        <Topbar area={'topbar'} />
        <Box id={'MainLayout__border'}  mx={4} borderBottom="1px" borderBottomColor={'#00948233'} area={'border'}/>
        
        <Flex id={'MainLayout__main'} position={"relative"} h={'calc(100vh - 87px - 1px)'} w="full" direction={'column'} px={12} pt={8} pr={0} area={'main'}>
          {children}
        </Flex>
    </Grid>
    </>
  );
};

export const MainLayoutSuspense = () => {
  return (
    <Box h="full" w="full" position="relative">
      <Flex h="full" w="full" direction={'column'} p={[0, 0]} >
        <Topbar />
        <Notifications />
        <Box mx={4} borderBottom="1px" borderBottomColor={'#33009482'}/>
        <Flex h="full" w="full" direction={'column'} px={12} pt={4}>
          <Spinner thickness="4px" speed="0.65s" size="xl" />
        </Flex>
      </Flex>
    </Box>
  );
}