import { patientConstants } from '../_constants';
import { patientService, procedureService } from '../_services';
import { history } from '../_helpers';
import moment from 'moment';
import {toUpper} from 'lodash';

export const patientActions = {
	initialize,
    getPatientPageDetails,
    getPatientDetails,
    openPatientForm,
    closePatientForm,
    changePatientForm,
    editPatientDetails,
    openLedgerForm,
    closeLedgerForm,
    changeLedgerForm,
    createLedger,
    editPatientDetails,
    addProcedure,
		unmountPatient,
		selectTooth,
		selectProcedure,
    getVideoChatToken,
    getPatientProcedures,
    getInbox,
    getMessages,
    getNewMessages,
    getProcedureCodes,
    getToothRegions,
    getNewInbox,
    getMessages,
    getNewMessages,
    sendMessage,
    openChatBox,
    closeChatBox,
    nextUploads,
    prevUploads,
    changeContentView,
    openProcedurePopup,
    closeProcedurePopup,
    openVideoChat,
    closeVideoChat,
    openProcForm,
    closeProcForm,
    changeProcForm,
    changeSurfaceForm,
    openInvoiceForm,
    closeInvoiceForm,
    changeInvoiceForm,
    createInvoice,
    toggleSelectTreatmentPlan,
    completeTreatmentPlan,
    getPatientInsurances,
    openInsuranceForm,
    closeInsuranceForm,
    changeInsuranceForm,
    addInsurance,
    addNote,
    openNoteForm,
    closeNoteForm,
    changeNoteForm,

    getToothNumbers
};


/***

CHAT ACTIONS

***/
function initialize(){
	return dispatch => {
		dispatch(initialize())
	}

	function initialize() { return { type: patientConstants.INITIALIZE }}
}

function getVideoChatToken(sessionID){
	return dispatch => {
		dispatch(request());
		patientService.getVideoChatToken(sessionID)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: patientConstants.GETVIDEOCHAT_TOKEN_REQUEST } }
    function success(data) {
    	return { type: patientConstants.GETVIDEOCHAT_TOKEN_SUCCESS, data }
    }
    function failure(error) { return { type: patientConstants.GETVIDEOCHAT_TOKEN_FAILURE, error } }
}

function getVideoChatDetails( dentistID, appointmentID){
	return dispatch => {
		dispatch(request());
		patientService.getVideoChatDetails(dentistID, appointmentID)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: patientConstants.GETVIDEOCHAT_DETAILS_REQUEST } }
    function success(data) {
    	return { type: patientConstants.GETVIDEOCHAT_DETAILS_SUCCESS, data }
    }
    function failure(error) { return { type: patientConstants.GETVIDEOCHAT_DETAILS_FAILURE, error } }
}

function openChatBox(chatID, name, avatar){
	return dispatch => {
		dispatch(open());
		dispatch(getMessages());
		dispatch(getNewMessages());
	}
	function open() { return {type: patientConstants.OPENCHATBOX , data:{chatID:chatID, name:name, avatar:avatar}} }
}

function closeChatBox(chatID){
	return dispatch => {
		dispatch(close());
	}
	function close() { return {type: patientConstants.CLOSECHATBOX } }
}


function getInbox(){
	return dispatch => {
		dispatch(request());
		patientService.getInbox()
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			)
	};
    function request() { return { type: patientConstants.GET_INBOX_REQUEST } }
    function success(data) {
    	// flattening the objects to a list of ids and dictionary of objects
    	// [id1,id2] & {id1: {thread}, id2: {thread} }
    	const inboxObj = data.chat_list.reduce((accumulator, current) => {
    		accumulator[current.thread.id] = current
    		return accumulator
    	}, {});
    	const inboxList = data.chat_list.map((thread, index) => {
    		return thread.thread.id
    	});
    	const result = {chat_list: inboxList, chat_objects: inboxObj}
    	return { type: patientConstants.GET_INBOX_SUCCESS, data: result }
    }
    function failure(error) { return { type: patientConstants.GET_INBOX_FAILURE, error } }
};


function getNewInbox(){
	return (dispatch, getState) => {
		var tm;
		if (getState().patient.chatLastRetrieved == null){
			tm = moment(new Date()).format()
		} else {
			tm = moment(getState().patient.chatLastRetrieved).format()
		}
		dispatch(request());
		patientService.getNewInbox(tm)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			)
	};
    function request() { return { type: patientConstants.GET_NEWINBOX_REQUEST } }
    function success(data) {
    	// flattening the objects to a list of ids and dictionary of objects
    	// [id1,id2] & {id1: {thread}, id2: {thread} }
    	const inboxObj = data.chat_list.reduce((accumulator, current) => {
    		accumulator[current.thread.id] = current
    		return accumulator
    	}, {});
    	const inboxList = data.chat_list.map((thread, index) => {
    		return thread.thread.id
    	});
    	const result = {chat_list: inboxList, chat_objects: inboxObj}

    	return { type: patientConstants.GET_NEWINBOX_SUCCESS, data:result }
    }
    function failure(error) { return { type: patientConstants.GET_NEWINBOX_FAILURE, error } }
};

function sendMessage(message){
	return (dispatch, getState) => {
		dispatch(request());
		const chatID = getState().patient.activeChatBox
		patientService.sendMessage({body: message, thread:chatID})
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			)
	};
    function request() { return { type: patientConstants.SEND_MESSAGE_REQUEST } }
    function success(data) { return { type: patientConstants.SEND_MESSAGE_SUCCESS, data } }
    function failure(error) { return { type: patientConstants.SEND_MESSAGE_FAILURE, error } }
};

function getMessages(){
	return (dispatch, getState)  => {
		const chatID = getState().patient.activeChatBox
		dispatch(request());
		patientService.getMessages(chatID)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			)
	};
    function request() { return { type: patientConstants.GET_MESSAGES_REQUEST } }
    function success(data) {
    	return { type: patientConstants.GET_MESSAGES_SUCCESS, data }
 	}
    function failure(error) { return { type: patientConstants.GET_MESSAGES_FAILURE, error } }
};

function getNewMessages(){
	return (dispatch, getState) => {
		var tm;
		if (getState().patient.activeChatLastRetrieved == null){
			tm = moment(new Date()).format()
		} else {
			tm = moment(getState().patient.activeChatLastRetrieved).format()
		}
		const chatID = getState().patient.activeChatBox
		dispatch(request());
		if (chatID == null){
			dispatch(failure("noChatID"));
		}
		patientService.getNewMessages(chatID)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			)
	};

    function request() { return { type: patientConstants.GET_NEWMESSAGES_REQUEST } }
    function success(data) { return { type: patientConstants.GET_NEWMESSAGES_SUCCESS, data } }
    function failure(error) { return { type: patientConstants.GET_NEWMESSAGES_FAILURE, error } }
};

// FUNCTIONAL OPEN ACTIONO FOR VIDEO CHAT
function openVideoChat(apptID){
	return dispatch => {
		dispatch(open(apptID))
	}

	function open(){ return {type: patientConstants.OPEN_VIDEO_CHAT, apptID}}
}

// FUNCTIONAL CLOSE ACTIONO FOR VIDEO CHAT
function closeVideoChat(){
	return dispatch => {
		dispatch(close())
	}

	function close(){ return {type: patientConstants.END_VIDEO_SESSION}}
}

/***

FUNCTIONAL ACTIONS FOR PAGE

***/

// CLEAR STATE FOR PATIENT PAGE (CHANGE PATIENT LINK)
function unmountPatient(){
	return dispatch => {
		dispatch(unmount())
	}

	function unmount(){ return {type: patientConstants.UNMOUNT_PATIENT}}
}

// CHANGE CURRENT TAB VIEW FOR PATIENT
function changeContentView(contentView){
	return (dispatch, getState) => {
		if (contentView == "BILLING"){
			dispatch(getPatientProcedures(getState().patient.patient.id, "unbilled"))
		} else if(contentView == "INSURANCE"){
			dispatch(getPatientInsurances(getState().patient.patient.id))
		}
		dispatch(change(contentView))
	}

	function change(contentView){ return {type: patientConstants.CHANGE_PAGECONTENT, contentView}}
}

// CHANGE CURRENT TOOTH FOR PATIENT PAGE (CHARTING, PROCEDURES)
function selectTooth(toothNum){
	return (dispatch, getState) => {
		dispatch(set(toothNum))
	}

	function set(toothNum){ return {type: patientConstants.CHART_SELECT_TOOTH, toothNum}}
}

// CHANGE CURRENT PROCEDURE FOR PATIENT PAGE (CHARTING, PROCEDURES)
function selectProcedure(procID, toothNum){
	console.log(procID, toothNum)
	const data = {procID, toothNum}
	return dispatch => {
		dispatch(set(data))
	}
	function set(data){ return {type: patientConstants.CHART_SELECT_TOOTH_PROCEDURE, data}}
}

// SCROLL TO NEXT SET OF UPLOADS
function nextUploads(){
	return dispatch => {
		dispatch(next())
	}

	function next(toothNum){ return {type: patientConstants.NEXT_UPLOADS}}
}

// SCROLL TO PREVIOUS SET OF UPLOADS
function prevUploads(){
	return dispatch => {
		dispatch(prev())
	}

	function prev(){ return {type: patientConstants.PREV_UPLOADS}}
}

// OPEN PROCEDURE DETAIL POPUP
function openProcedurePopup(){
	return (dispatch, getState) => {
		dispatch(open());
	}
	function open() { return {type: patientConstants.OPENPROCEDUREPOPUP } }
}

// CLOSE PROCEDURE DETAIL POPUP
function closeProcedurePopup(){
	return dispatch => {
		dispatch(close());
	}
	function close() { return {type: patientConstants.CLOSEPROCEDUREPOPUP}}
};

// CHANGE SURFACE ON PROCEDURE FORM (UPDATE SURFACES ON PROCEUDRE FORM)
function changeSurfaceForm(surfaceName, checked){
	return (dispatch, getState) => {
		const currentTooth = getState().patient.procFormCurrentTooth;
		const currentSurfaces = getState().patient.procFormCurrentSurfaces;
		let realSurfaceName = toUpper(surfaceName.charAt(0));
		let form;
		if ( ((currentTooth >=6 ) && (currentTooth <=11)) || ((currentTooth >= 22) && (currentTooth <=27)) ) {
			if (realSurfaceName == "O"){
				realSurfaceName = "I";
			} else if (realSurfaceName == "B"){
				realSurfaceName = "F";
			}
		}
		if (checked) {
			form = patientService.addToSurfaceForm(realSurfaceName, currentSurfaces);
		} else {
			form = patientService.removeFromSurfaceForm(realSurfaceName, currentSurfaces);
		}
		dispatch(change(form));
	}
	function change(data) {
		return {type: patientConstants.CHANGESURFACEFORM, data}
	}
}

/***

GET INFORMATION FOR PAGE

***/

//
function toggleSelectTreatmentPlan(procedureID){
	return (dispatch, getState) => {
		let treatmentPlanIDs = [...getState().patient.patientTreatmentPlanProcedureIDs]
		const index = treatmentPlanIDs.indexOf(procedureID)
		if (index !== -1){
			treatmentPlanIDs.splice(index, 1)
		} else {
			treatmentPlanIDs = [...treatmentPlanIDs, procedureID]
		}
		dispatch(change(treatmentPlanIDs))
	};

    function change(data) { return { type: patientConstants.CHANGEPATIENT_TREATMENTPLAN_LIST, data } }
}

// INITIAL PATIENT PAGE INFORMATION
function getPatientPageDetails(patientID) {
	return dispatch => {
		dispatch(request());
		patientService.getPatientPageDetails(patientID)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: patientConstants.GETPATIENT_PAGEDETAILS_REQUEST } }
    function success(data) {
    	let procedure_list = {};
    	// create dictionary for tooth to list of procedures
    	const toothProceduresObj = data.procedures.reduce((accumulator, proc) => {
    		accumulator[proc.tooth] = (proc.tooth in accumulator) ? [...accumulator[proc.tooth], proc] : [proc] ;
    		return accumulator
    	}, {});
    	const toothProcedureList = data.procedures.map((proc, index) => {
    		return proc.tooth
    	});
    	const proceduresObj = data.procedures.reduce((accumulator, proc) => {
    		accumulator[proc.id] = proc;
    		return accumulator
    	}, {});
    	const notesObj = {};
    	const toothNotes = {};
    	for (var i=0; i < data.notes.length; i++){ // loop through notes to create dict and link by tooth number
    		notesObj[data.notes[i].id] = data.notes[i]
    		for (var j=0; j < data.notes[i].tooth.length; j++){ //loop through list of teeth referred to in .tooth
    			toothNotes[ data.notes[i].tooth[j] ] = (data.notes[i].tooth[j] in toothNotes) ? [ ...toothNotes[data.notes[i].tooth[j]], data.notes[i].id ] : [ data.notes[i].id ]
    		}
    	}
    	//data["toothObj"] = toothProceduresObj;
    	//data["toothList"] = toothProcedureList;
    	//data["proceduresObj"] = proceduresObj;
    	//data["notesObj"] = notesObj;
    	//data["toothNotesObj"] = toothNotes;
    	data = {
    		...data,
    		toothObj: toothProceduresObj,
    		toothList: toothProcedureList,
    		proceduresObj: proceduresObj,
    		notesObj: notesObj,
    		toothNotesObj: toothNotes
    	}
    	return { type: patientConstants.GETPATIENT_PAGEDETAILS_SUCCESS, data }
    }
    function failure(error) { return { type: patientConstants.GETPATIENT_PAGEDETAILS_FAILURE, error } }
};

//
function getPatientDetails(patientID) {
	return dispatch => {
		dispatch(request());
		patientService.getPatientDetails(patientID)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: patientConstants.GETPATIENT_DETAILS_REQUEST } }
    function success(data) { return { type: patientConstants.GETPATIENT_DETAILS_SUCCESS, data } }
    function failure(error) { return { type: patientConstants.GETPATIENT_DETAILS_FAILURE, error } }
};

function getPatientProcedures(patientID, filt){
	return dispatch => {
		dispatch(request());
		patientService.getPatientProcedures(patientID, filt)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			)
	};
    function request() { return { type: patientConstants.GETPATIENT_BILLINGPROCEDURES_REQUEST } }
    function success(data) { return { type: patientConstants.GETPATIENT_BILLINGPROCEDURES_SUCCESS, data } }
    function failure(error) { return { type: patientConstants.GETPATIENT_BILLINGPROCEDURES_FAILURE, error } }
};

function getPatientInsurances(patientID){
	return dispatch => {
		dispatch(request());
		patientService.getPatientInsurances(patientID)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			)
	};
    function request() { return { type: patientConstants.GETPATIENT_INSURANCE_REQUEST } }
    function success(data) { return { type: patientConstants.GETPATIENT_INSURANCE_SUCCESS, data } }
    function failure(error) { return { type: patientConstants.GETPATIENT_INSURANCE_FAILURE, error } }
};

// GET CONFIG INFORMATION FOR FORMS' SELECT VALUES PROCEDURE CODES
function getProcedureCodes(){
	return dispatch => {
		dispatch(request());
		patientService.getProcedureCodes()
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: patientConstants.GETPROCEDURECODES_REQUEST } }
    function success(data) {
    	return { type: patientConstants.GETPROCEDURECODES_SUCCESS, data }
    };
    function failure(error) { return { type: patientConstants.GETPROCEDURECODES_FAILURE, error } }
};

// GET CONFIG INFORMATION FOR FORMS' SELECT VALUES TOOTH REGIONS
function getToothRegions(){
	return dispatch => {
		dispatch(request());
		patientService.getToothRegions()
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: patientConstants.GET_TOOTHREGIONS_REQUEST } }
    function success(data) {
    	return { type: patientConstants.GET_TOOTHREGIONS_SUCCESS, data }
    };
    function failure(error) { return { type: patientConstants.GET_TOOTHREGIONS_FAILURE, error } }
};

// GET CONFIG INFORMATION FOR FORMS' SELECT VALUES TOOTH NUMBERS
function getToothNumbers(){
	return dispatch => {
		dispatch(request());
		patientService.getToothNumbers()
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: patientConstants.GET_TOOTHNUMBERS_REQUEST } }
    function success(data) {
    	return { type: patientConstants.GET_TOOTHNUMBERS_SUCCESS, data }
    };
    function failure(error) { return { type: patientConstants.GET_TOOTHNUMBERS_FAILURE, error } }
}

/***

FORMS SUBMIT ACTION: REQUEST, SUCCESS, ERROR

***/

// INVOICE FORM
function createInvoice(){
	return (dispatch, getState) => {
		dispatch(request());
		const form = {
			procedures: getState().patient.invoiceForm,
			patient: getState().patient.patient.id
		}

		patientService.createInvoice(form)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	}

    function request() { return { type: patientConstants.CREATEPATIENT_INVOICE_REQUEST } }
    function success(data) {
    	return { type: patientConstants.CREATEPATIENT_INVOICE_SUCCESS, data }
    }
    function failure(error) { return { type: patientConstants.CREATEPATIENT_INVOICE_FAILURE, error } }
};

// LEDGER FORM
function createLedger(){
	return (dispatch, getState) => {
		dispatch(request());
		const form = {
			...getState().patient.patientLedgerForm,
			patient: getState().patient.patient.id
		}

		patientService.createLedger(form)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	}

    function request() { return { type: patientConstants.CREATEPATIENT_LEDGERENTRY_REQUEST } }
    function success(data) {
    	return { type: patientConstants.CREATEPATIENT_LEDGERENTRY_SUCCESS, data }
    }
    function failure(error) { return { type: patientConstants.CREATEPATIENT_LEDGERENTRY_FAILURE, error } }
};


// CLINICAL NOTE FORM
function addNote() {
	return (dispatch, getState) => {
		// convert all dates?

		dispatch(request());

		// TODO: might have to update select to return [] for multiselects when
		// none is selected instead of null

		const toothCheck = getState().patient.noteForm.tooth;
		var teeth;
		if ( Array.isArray(toothCheck)) {
			// then can just map it,
			teeth = getState().patient.noteForm.tooth.map((item, i) => {
						return item.value
			});
		} else if ((toothCheck === null) || (toothCheck === "")) {
			// then empty list
			teeth = []
		} else {
			// this should be string or whatever. single tooth selection
			teeth = [getState().patient.noteForm.tooth.value]
		}


		const form = {
			...getState().patient.noteForm,
			patient: getState().patient.patient.id
		}

		form["tooth"] = teeth
		// convert date (moment.js) to CCYY-MM-DD, since not datetime

		form["date"] = form["date"].format("YYYY-MM-DD");

		console.log(form["date"])

		patientService.addNote(form)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	}

    function request() { return { type: patientConstants.ADDNOTE_REQUEST } }
    function success(data) {
    	return { type: patientConstants.ADDNOTE_SUCCESS, data }
    }
    function failure(error) { return { type: patientConstants.ADDNOTE_FAILURE, error } }
};

// INSURANCE FORM
function addInsurance(){
	return (dispatch, getState) => {
		dispatch(request());
		const form = {
			...getState().patient.insuranceForm,
			patient: getState().patient.patient.id
		}

		patientService.addInsurance(form)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	}

    function request() { return { type: patientConstants.CREATEPATIENT_INSURANCE_REQUEST } }
    function success(data) {
    	return { type: patientConstants.CREATEPATIENT_INSURANCE_SUCCESS, data }
    }
    function failure(error) { return { type: patientConstants.CREATEPATIENT_INSURANCE_FAILURE, error } }
};

// PATIENT FORM
function editPatientDetails(patientID) {
	return (dispatch, getState) => {
		dispatch(request());
		const form = {
			...getState().patient.patientForm
		}
		patientService.editPatientDetails(form)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: patientConstants.EDITPATIENT_DETAILS_REQUEST } }
    function success(data) {
    	return { type: patientConstants.EDITPATIENT_DETAILS_SUCCESS, data }
    }
    function failure(error) { return { type: patientConstants.EDITPATIENT_DETAILS_FAILURE, error } }
};

// PROCEDURE FORM
function addProcedure(data){
	return (dispatch, getState) => {
		dispatch(request());
		const form = {
			...getState().patient.procForm,
			tooth: getState().patient.procFormCurrentTooth,
			surface: getState().patient.procFormCurrentSurfaces.join(""),
			patient: getState().patient.patient.id,
			procedure: (parseInt(getState().patient.procForm.status) == 2) ? null : getState().patient.procForm.procedure ,
			condition: (parseInt(getState().patient.procForm.status) != 2) ? null : getState().patient.procForm.condition
		}
		patientService.addProcedure(form)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			)
	};
    function request() { return { type: patientConstants.ADDPROCEDURE_REQUEST } }
    function success(data) { return { type: patientConstants.ADDPROCEDURE_SUCCESS, data } }
    function failure(error) { return { type: patientConstants.ADDPROCEDURE_FAILURE, error } }
};

// COMPLETING TREATMENT PLAN PROCEDURES
function completeTreatmentPlan(){
	return (dispatch, getState) => {
		dispatch(request())

		let treatmentPlanIDs = [...getState().patient.patientTreatmentPlanProcedureIDs]
		const args = {
			procedures: treatmentPlanIDs
		}
		patientService.completeTreatmentPlan(args, getState().patient.patient.id)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	}

    function request() { return { type: patientConstants.COMPLETEPATIENT_TREATMENTPLAN_REQUEST } }
    function success(data) { return { type: patientConstants.COMPLETEPATIENT_TREATMENTPLAN_SUCCESS, data } }
    function failure(error) { return { type: patientConstants.COMPLETEPATIENT_TREATMENTPLAN_FAILURE, error } }
};


/***

MODAL FORM ACTIONS: OPENING, CLOSING, CHANGING

***/

//	INVOICE FORM: OPEN, CLOSE, CHANGE
function openInvoiceForm(){
	return (dispatch, getState) => {
		dispatch(open());
	}
	function open() { return {type: patientConstants.OPENINVOICEFORM } }
};

function closeInvoiceForm(){
	return dispatch => {
		dispatch(close());
	}
	function close() { return {type: patientConstants.CLOSEINVOICEFORM}}
};

function changeInvoiceForm(form, index){
	return (dispatch, getState) => {
		const data = {form: form, index: index}
		dispatch(change(data));
	}
	function change(data) { return {type: patientConstants.CHANGEINVOICEFORM, data } }
};


//	INSURANCE FORM: OPEN, CLOSE, CHANGE
function openInsuranceForm(){
	return (dispatch, getState) => {
		dispatch(open());
	}
	function open() { return {type: patientConstants.OPENINSURANCEFORM } }
};

function closeInsuranceForm(){
	return dispatch => {
		dispatch(close());
	}
	function close() { return {type: patientConstants.CLOSEINSURANCEFORM}}
};

function changeInsuranceForm(form){
	return dispatch => {
		dispatch(change(form));
	}
	function change(data) { return {type: patientConstants.CHANGEINSURANCEFORM, data } }
};


// PATIENT FORM: OPEN, CLOSE, CHANGE
function openPatientForm(){
	return (dispatch, getState) => {
		dispatch(open());
		dispatch(getPatientDetails(getState().patient.patient.id));
	}
	function open() { return {type: patientConstants.OPENPATIENTFORM } }
};

function closePatientForm(){
	return dispatch => {
		dispatch(close());
	}
	function close() { return {type: patientConstants.CLOSEPATIENTFORM}}
};

function changePatientForm(form){
	return dispatch => {
		dispatch(change(form));
	}
	function change() { return {type: patientConstants.CHANGEPATIENTFORM, form} }
};

//	LEDGER FORM: OPEN, CLOSE, CHANGE
function openLedgerForm(){
	return (dispatch, getState) => {
		dispatch(open());
	}
	function open() { return {type: patientConstants.OPENLEDGERENTRYFORM } }
};

function closeLedgerForm(){
	return dispatch => {
		dispatch(close());
	}
	function close() { return {type: patientConstants.CLOSELEDGERENTRYFORM}}
};

function changeLedgerForm(form){
	return dispatch => {
		dispatch(change(form));
	}
	function change() { return {type: patientConstants.CHANGELEDGERENTRYFORM, form} }
};


//	PROCEDURE FORM: OPEN, CLOSE, CHANGE
function openProcForm(toothNum, surface){
	return dispatch => {
		let realSurfaceName = null;
		if (surface != null){
			const currentTooth = parseInt(toothNum);
			realSurfaceName = toUpper(surface.charAt(0));
			if ( ((currentTooth >=6 ) && (currentTooth <=11)) || ((currentTooth >= 22) && (currentTooth <=27)) ) {
				if (realSurfaceName == "O"){
					realSurfaceName = "I";
				} else if (realSurfaceName == "B"){
					realSurfaceName = "F";
				}
			}
		}
		dispatch(open(toothNum, realSurfaceName));
	}
	function open(toothNum, surface) { return {type: patientConstants.OPENPROCFORM, data:{tooth:toothNum, surface:surface} } }
};

function closeProcForm(){
	return dispatch => {
		dispatch(close());
	}
	function close() { return {type: patientConstants.CLOSEPROCFORM}}
};

function changeProcForm(form){
	return dispatch => {
		dispatch(change(form));
	}
	function change() { return {type: patientConstants.CHANGEPROCFORM, form} }
};


//	CLINICAL NOTE FORM: OPEN, CLOSE, CHANGE
function openNoteForm(toothNum){
	return (dispatch, getState) => {
		let currentTooth = getState().patient.chartingCurrentTooth || ""
		const form = {tooth: currentTooth}
		dispatch(open( form ));
	}
	function open(data) { return {type: patientConstants.OPENNOTEFORM, data} }
};

function closeNoteForm(){
	return dispatch => {
		dispatch(close());
	}
	function close() { return {type: patientConstants.CLOSENOTEFORM}}
};

function changeNoteForm(form){
	return dispatch => {
		dispatch(change(form));
	}
	function change() { return {type: patientConstants.CHANGENOTEFORM, form} }
};
