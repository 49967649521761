export const procedureConstants = {
	ADDPROCEDURE_REQUEST : 'PROCEDURE_ADDPROCEDURE_REQUEST',
	ADDPROCEDURE_SUCCESS : 'PROCEDURE_ADDPROCEDURE_SUCCESS',
	ADDPROCEDURE_FAILURE : 'PROCEDURE_ADDPROCEDURE_FAILURE',

	EDITPROCEDURE_REQUEST : 'PROCEDURE_EDITPROCEDURE_REQUEST',
	EDITPROCEDURE_SUCCESS : 'PROCEDURE_EDITPROCEDURE_SUCCESS',
	EDITPROCEDURE_FAILURE : 'PROCEDURE_EDITPROCEDURE_FAILURE',

	DELETEPROCEDURE_REQUEST : 'PROCEDURE_DELETEPROCEDURE_REQUEST',
	DELETEPROCEDURE_SUCCESS : 'PROCEDURE_DELETEPROCEDURE_SUCCESS',
	DELETEPROCEDURE_FAILURE : 'PROCEDURE_DELETEPROCEDURE_FAILURE',

}