import { appointmentActions, schedulingActions } from '../_actions';
import { appointmentConstants, userConstants, schedulingConstants} from '../_constants';
import { delay } from 'redux-saga';
import { takeEvery, takeLatest, take , put, call, race, fork} from 'redux-saga/effects';


// Fetch data every 20 seconds                                           
function* pollInbox() {  
    try {
        yield call(delay, 100000);
        const inbox = yield call(appointmentActions.getNewInbox);
        yield put(inbox)

    } catch (error) {
        // cancellation error -- can handle this if you wish
        return;
    }
}

// Wait for successful response, then fire another request (latest request or every)
// takeEvery also works                                     
function* watchInboxData() { 
    yield takeLatest(appointmentConstants.GET_NEWINBOX_SUCCESS, pollInbox);
}

function* pollMessages() {  
    try {
        yield call(delay, 100000);
        const messages = yield call(appointmentActions.getNewMessages);
        yield put(messages)

    } catch (error) {
        // cancellation error -- can handle this if you wish
        return;
    }
}

function* watchMessages() {
    while(true){
        yield take(appointmentConstants.GET_NEWMESSAGES_SUCCESS);
        yield race([
            call(pollMessages),
            take(appointmentConstants.CLOSECHATBOX)
        ])
    }
}

function* pollActivityLog() {  
    try {
        yield call(delay, 1000*60);
        const activitylog = yield call(schedulingActions.getActivityLog);
        yield put(activitylog)

    } catch (error) {
        // cancellation error -- can handle this if you wish
        return;
    }
}


function* watchActivityLog(){
    yield takeLatest(schedulingConstants.GETACTIVITYLOG_SUCCESS, pollActivityLog);
}

function* getMessages_NOTINUSE(){
    try{
        const messages = yield call(appointmentActions.getNewMessages)
        yield put(messages)
    } catch (error){
        // cancellation error
        return
    }
}

//function* watchChatOpen(){
//    yield takeLatest(appointmentConstants.GET_MESSAGES_SUCCESS, pollMessages )
//}

// Daemonize tasks in parallel         
// fork will wait for somewhere in App to call the function             
export default function* rootSaga() {  
    yield [
        fork(watchInboxData),
        fork(watchMessages),
        fork(watchActivityLog),
        //fork(watchChatOpen)
        // other watchers here
    ];
}