import { authHeader, handleResponse } from '../_helpers';
import { API_URLS, ADMIN_API_URLS, PAGE_SIZE } from '../_config';

export const adminService = {
    getPracticeDetails,
    updatePracticeDetails,
    getAccountDetails,
    updateAccountDetails,
    updateCalendar,
    createNewCalendar,
    createNewAccount,
    doPracticeSearch,
    doCalendarSearch,
    doAccountSearch,
    getResetUrl,
    createNewPracticeAccount,
    updatePracticeAccountDetails,
    fetchProductCollection,
    fetchBillingUrl
};

function doCalendarSearch(query, offset=0, dpid=null) {
  const requestUrl = ADMIN_API_URLS.getCalendarsList;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader()}
  };
  let url = requestUrl.url + '?q='+query+'&offset='+offset+'&pgsz='+PAGE_SIZE;
  if (dpid){
    url = url + '&dpid='+ dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
};

function doPracticeSearch(query, offset=0, dpid=null) {
  const requestUrl = ADMIN_API_URLS.getPracticesList;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader()}
  };
  let url = requestUrl.url + '?q='+query+'&offset='+offset+'&pgsz='+PAGE_SIZE;
  if (dpid){
    url = url + '&dpid='+ dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
};

function doAccountSearch(query, offset=0, dpid=null) {
  const requestUrl = ADMIN_API_URLS.getAccountsList;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader()}
  };
  let url = requestUrl.url + '?q='+query+'&offset='+offset+'&pgsz='+PAGE_SIZE;
  if (dpid){
    url = url + '&dpid='+ dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
};

function createNewCalendar(data){
  const requestUrl = ADMIN_API_URLS.createNewCalendar;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function updateCalendar(id, data){
  const requestUrl = ADMIN_API_URLS.updateCalendarDetails;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url.replace("{id}", id);
  return fetch(url, requestOptions).then(handleResponse);
}


function getPracticeDetails(clinicId){
  const requestUrl = ADMIN_API_URLS.getPracticeDetails;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{clinicId}', clinicId);
  return fetch(url, requestOptions).then(handleResponse);
}

function createNewAccount(data){
  const requestUrl = ADMIN_API_URLS.createNewAccount;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function getResetUrl(clinicId, data){
  const requestUrl = ADMIN_API_URLS.getResetUrl;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function updatePracticeDetails(clinicId, data){
  const requestUrl = ADMIN_API_URLS.updatePracticeDetails;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url.replace('{clinicId}', clinicId);
  return fetch(url, requestOptions).then(handleResponse);
}

function getAccountDetails(accountId){
  const requestUrl = ADMIN_API_URLS.getAccountDetails;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{accountId}', accountId);
  return fetch(url, requestOptions).then(handleResponse);
}

function updateAccountDetails(accountId, data){
  const requestUrl = ADMIN_API_URLS.updateAccountDetails;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url.replace('{accountId}', accountId);
  return fetch(url, requestOptions).then(handleResponse);
}

function createNewPracticeAccount(clinicId, data){
  const requestUrl = ADMIN_API_URLS.createPracticeAccounts;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url.replace("{clinicId}", clinicId);
  return fetch(url, requestOptions).then(handleResponse);
}


function updatePracticeAccountDetails(clinicId, accountId, data){
  const requestUrl = ADMIN_API_URLS.updatePracticeAccounts;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url.replace('{clinicId}', clinicId).replace('{accountId}', accountId);
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchProductCollection(country, product){
  const requestUrl = ADMIN_API_URLS.fetchProductCollection;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader()}
  };
  let url = requestUrl.url.replace("{country}", country).replace("{product}", product);
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchBillingUrl(organization, price){
  const requestUrl = ADMIN_API_URLS.fetchBillingUrl;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({organization, price})
  };
  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}