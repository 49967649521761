import { API_URLS } from "../../_config";
import { authHeader, handleResponse } from "../../_helpers";

export function fetchClinicBillingPortal(referrerUrl) {
    const requestUrl = API_URLS.createBillingUrl;
    const requestOptions = {
      method: requestUrl.method,
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify({"referrer_url": referrerUrl}),
    };
    let url = requestUrl.url;
    return fetch(url, requestOptions).then(handleResponse);
  }