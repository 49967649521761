import storage from './storage';

export function authHeader() {
    // return authorization header with jwt token
    const token = storage.getToken();

    if (token) {
        return { 'Authorization': 'Token ' + token};
    } else {
        return {};
    }
}
