import React from 'react';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames';

import { LoadingEllipsisRotated } from '../_components/Loaders';
import { ToothSet } from '../_components/ToothSet';
import { upperFirst, toUpper } from 'lodash';

import { consultRequestsService } from '../_services';

import '../../css/ToothChart.css';

const toothStates = [
  'missing',
  'implant',
  'crown',
  'bridge',
  'inlay',
  'onlay'
]

const intlData = {
  'toothSet.tooth.state.missing': 'missing',
  'toothSet.tooth.state.implant': 'implant',
  'toothSet.tooth.state.crown': 'crown',
  'toothSet.tooth.state.bridge': 'bridge',
  'toothSet.tooth.state.inlay': 'inlay',
  'toothSet.tooth.state.onlay': 'onlay',
  'toothSet.tooth.state.cancel': 'cancel',
  'toothSet.tooth.state.clear': 'clear',
  'toothSet.movement.title.jaw.UPPER': 'Upper Only',
  'toothSet.movement.title.jaw.LOWER': 'Lower Only',
  'toothSet.movement.title': 'movement chart',
  'toothSet.condition.title': 'patient condition'
}

const baseCssClassName = 'ToothMovementChart';
const chartHeadingCssClassName = `${baseCssClassName}__heading`;
const chartChartingCssClassName = `${baseCssClassName}__charting`;
const chartButtonsCssClassName = `${baseCssClassName}__buttons`;

const hoverMenuCssClassName = `${baseCssClassName}__HoverMenu`;
const hoverMenuTitleClassName = `${hoverMenuCssClassName}__title`;
const hoverMenuItemCssClassName = `${hoverMenuCssClassName}__item`;
const hoverTipCssClassName = `${baseCssClassName}__HoverTip`;
const hoverTipItemCssClassName = `${hoverTipCssClassName}__item`;

const loadingOverlayCssClassName = `${baseCssClassName}__loadingOverlay`

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const LoadingOverlay = () => (
  <div className={loadingOverlayCssClassName}>
    <LoadingEllipsisRotated viewBox={'5 20 50 100'}  />
  </div>
)

class HoverMenu extends React.Component{
  constructor(props){
    super(props);
  }

  handleClick = (toothState) => {
    this.props.handleClick(this.props.toothId, toothState)
  }

  render(){
    return (
      <div className={cn('HoverMenu', hoverMenuCssClassName)}>
        <div className={hoverMenuTitleClassName}>{this.props.toothId}</div>
        {
          toothStates.map( (element, index) => {
            return(
              <div
                className={cn(hoverMenuItemCssClassName,
                            {selected: element == this.props.currentSelection })}
                onClick={() => this.handleClick(element)}
                key={element}
              >
                {toUpper(intlData[`toothSet.tooth.state.${element}`])}
              </div>
            )
          })
        }
        <div className={hoverMenuItemCssClassName} onClick={() => this.handleClick('')}>
          {toUpper(intlData['toothSet.tooth.state.clear'])}
        </div>
      </div>
    )
  }
}

class ToothMovementChart extends React.Component{
  constructor(props){
    super(props)
    this.toothMenu = React.createRef();
    this.toothType = React.createRef();
    this.state = {
      charting: {},
      initializing: true,
      isSaving: false,
      isDirty: false
    }
  }

  async componentDidMount(){
    // get initial tooth state from db
    // probably just json
    const response = await consultRequestsService.getToothChart(this.props.requestId, this.props.chartType);
    //await sleep(1000);
    //const response = {chart: {charting:{}}};
    this.setState({initializing:false, ...response.chart });
  }

  handleSave = async () => {
    this.setState({isSaving:true});
    //const toothChart = await sleep(400);
    //console.log("state of chart is....", this.state)
    const data = {charting: this.state.charting, id:this.state.id}
    const toothChart = await consultRequestsService.updateToothChart(this.props.requestId, data, 'movement');
    this.setState({isSaving:false, isDirty:false});
  }


  handleClick = (toothId, toothState) => {
    this.setState((state) => ({
      isDirty: true ,
      charting: {...state.charting, [toothId]: (state.charting[toothId] === 'move') ? '' : 'move'}
    }));

    // mark tooth to state
    //this.setState((state) => ({ isDirty: true , charting: {...state.charting, [toothId]: toothState} }));
    //this.toothMenu.current.tooltipRef = null;
    //ReactTooltip.hide();
  }

  render(){
    //const chartTitle =  (this.props.chartType === 'movement') ? 'Movement Chart' : 'Patient Condition' ;
    const chartSubTitle = (this.state.jaw !== 'BOTH') ? intlData[`toothSet.movement.title.jaw.${this.state.jaw}`] : '';
    return (
      <div className={baseCssClassName}>
        <div className={chartHeadingCssClassName}>{toUpper(intlData['toothSet.movement.title'])}<span>{toUpper(chartSubTitle)}</span></div>
        <div className={chartChartingCssClassName}>
          { (this.state.initializing || this.state.isSaving) &&
            <LoadingOverlay />
          }
          <ToothSet
            charting = {this.state.charting}
            chartType = {this.props.chartType}
            onClick= {this.handleClick}
            className= {`ToothSet__${this.state.jaw}`}
          />
        </div>
        { !this.props.printMode &&
          <div className={chartButtonsCssClassName}>
            <button
              onClick={this.handleSave}
              disabled={this.state.isSaving || !this.state.isDirty }
              >Save
            </button>
          </div>
        }

      </div>
    )
  }
}

class ToothConditionChart extends React.Component{
  constructor(props){
    super(props)
    this.toothMenu = React.createRef();
    this.toothType = React.createRef();
    this.state = {
      charting: {},
      initializing: true,
      isSaving: false,
      isDirty: false
    }
  }

  async componentDidMount(){
    // get initial tooth state from db
    // probably just json
    const response = await consultRequestsService.getToothChart(this.props.requestId, this.props.chartType);
    // await sleep(1000); //testing
    //const response = {chart: {charting:{}}}; //testing
    this.setState({initializing:false, ...response.chart });
  }

  handleSave = async () => {
    this.setState({isSaving:true});
    //const toothChart = await sleep(400); //testing
    //console.log("state of chart is....", this.state)
    const data = {charting: this.state.charting, id: this.state.id}
    const toothChart = await consultRequestsService.updateToothChart(this.props.requestId, data, 'condition');
    this.setState({isSaving:false, isDirty:false});
  }


  handleClick = (toothId, toothState) => {
    // mark tooth to state
    this.setState((state) => ({ isDirty: true , charting: {...state.charting, [toothId]: toothState} }));
    this.toothMenu.current.tooltipRef = null;
    ReactTooltip.hide();
  }

  render(){
    //const chartTitle =  (this.props.chartType === 'movement') ? 'Movement Chart' : 'Patient Condition' ;
    return (
      <div className={baseCssClassName}>

        <div className={chartHeadingCssClassName}>{toUpper(intlData['toothSet.condition.title'])}</div>
        <ReactTooltip
          type={'light'}
          effect={'solid'}
          ref={this.toothMenu}
          place={'right'}
          id={`indTooth_${this.props.chartType}`}
          disable={this.state.isSaving || this.state.initializing}
          clickable={true}
          getContent={
            (dataTip)=>
                <HoverMenu
                  toothId={dataTip}
                  currentSelection = {this.state.charting[dataTip]}
                  handleClick={this.handleClick}
                />
          }

        />
        <div className={chartChartingCssClassName}>
          { (this.state.initializing || this.state.isSaving) &&
            <LoadingOverlay />
          }
          <ToothSet
            charting = {this.state.charting}
            chartType = {this.props.chartType}
          />
        </div>

        { !this.props.printMode &&
          <div className={chartButtonsCssClassName}>
            <button
              onClick={this.handleSave}
              disabled={this.state.isSaving || !this.state.isDirty }
              >Save
            </button>
          </div>
        }

      </div>
    )
  }
}

export {ToothMovementChart, ToothConditionChart}
