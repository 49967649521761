 import { authHeader, handleResponse } from '../_helpers';
import { BASE_URL } from '../_config';

export const formsService = {
	submitPatientForm,
  submitPatientUploadForm
};

function submitPatientForm(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    let url = '/api/d/dental_practice/patient/create';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};


function submitPatientUploadForm(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader()},
        body: data
    };
    let url = '/api/d/dental_practice/patient/createupload';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};
