import React, { Component } from 'react';

import  { Progress } from '@chakra-ui/react';
import PopupDialog, { PopupDialogContentText } from '../Popup/PopupDialog';

import './LoadingDialog.css';

const baseCssClassName = 'LoadingDialog';
const progressCssClassName = `${baseCssClassName}__progress`;
const dialogContentClassName = `${baseCssClassName}__content`;

class LoadingDialog extends Component{

  _handleLoadingDialogClose = () => {
    return null;
  }

  render(){
    return (
      <PopupDialog
            cssClassNames={baseCssClassName}
  					headerProps={{
  						title:  this.props.title,
  					}}
  					content={(dialogInterface) => (
  						<PopupDialogContentText>
  							<div className={dialogContentClassName} >
  							<div className={progressCssClassName}
  								  dangerouslySetInnerHTML={{
  									__html: this.props.message,
  								}}
  							/>
  							<Progress colorScheme={'brand'} value={40}/>
  							</div>
  						</PopupDialogContentText>
  					)}
  					popupProps={{
  						onClose: this._handleLoadingDialogClose,
  					}}
  				/>
    )
  }

}

export default LoadingDialog;
