import create from 'zustand';
import produce from 'immer';

// Log every time state is changed
const log = config => (set, get, api) => config(args => {
  console.log("  applying", args)
  set(args)
  console.log("  new state", get())
}, get, api)

// Turn the set method into an immer proxy
const immer = config => (set, get, api) => config((partial, replace) => {
  const nextState = typeof partial === 'function'
      ? produce(partial)
      : partial
  return set(nextState, replace)
}, get, api)

export const useAdminAppointmentRequestStore = create(
  
  log(
    immer((set) => ({
      appointmentRequests: null,
      hasUpdate: false,
      lastFetched: null,

      setHasUpdate: () =>
        set((state) => ({
            ...state,
            hasUpdate: true
        })),
      setFetched: () =>
        set((state) => ({
            ...state,
            hasUpdate: false,
            lastFetched: Date.now()
        }))
    }))
  )
);