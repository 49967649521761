import React, { Component } from 'react';
import cn from 'classnames';
import { render } from 'react-dom'
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import Spinner from '../_components/Spinner';
import Select from 'react-select';
import {SelectAdapter} from './AppointmentFormModal'

import { formsService  } from "../_services"
import { formsActions } from "../_actions"


const required = value => (value ? undefined : 'Required')
const mustBeNumber = value => (isNaN(value) ? 'Must be a number' : undefined)
const minValue = min => value =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`
const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)

// another way to validate.
// field by field or put all in one monolith check
const validate = values => {
  const errors = {}
  if (!values.firstName) {
    errors.firstName = 'Required'
  }
  if (!values.fruit) {
    errors.fruit = 'Required'
  }
  return errors
}

const simpleMemoize = fn => {
  let lastArg
  let lastResult
  return arg => {
    if (arg !== lastArg) {
      lastArg = arg
      lastResult = fn(arg)
    }
    return lastResult
  }
}

class SimpleFormSelect extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { onSubmit , patients} = this.props;

    return (
      <Form
        onSubmit={onSubmit}
        render = {({
          handleSubmit,
          reset,
          submitting,
          pristine,
          validating,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            {validating && <Spinner />}
            <Field
               name="age"
               validate={composeValidators(required, mustBeNumber, minValue(18))}
              >
              {({ input, meta}) => (
                <div>
                  <label className="Input__Label">Age</label>
                  <input {...input}
                    className={ cn("form-control Input_Text", {"Input_Error": meta.error && meta.touched})}
                    type="text" placeholder="Age" />
                  <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >{meta.error && meta.touched && meta.error}</div>
                </div>
              )}
            </Field>
            <Field
              name="patient"
              items={patients}
              label="Patient"
              component={SelectAdapter}
              placeholder="Patient"
              validate={required}
            />
            <div className="Buttons">
              <button
                type="button"
                className="Button Button__Cancel"
                onClick={reset}
                disabled={submitting || pristine}
                >
                CANCEL
              </button>
              <button type="submit" className="Button Button__Save" disabled={submitting}>
                SAVE
              </button>
            </div>
            <pre>{JSON.stringify(values, 0, 2)}</pre>
          </form>
        )}
      />
    )
  }
}

class QuickPatientForm extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { onSubmit, patients } = this.props;

    return (
      <Form
        onSubmit={onSubmit}
        render = {({
          handleSubmit,
          reset,
          submitting,
          pristine,
          validating,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            {validating && <Spinner />}
            <Field name="first_name" validate={required}>
              {({ input, meta}) => (
                <div>
                  <label className="Input__Label">First Name</label>
                  <input {...input} className="form-control Input_Text" type="text" placeholder="First Name" />
                  <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >{meta.error && meta.touched && meta.error}</div>
                </div>
              )}
            </Field>
            <Field name="last_name" validate={required}>
              {({ input, meta}) => (
                <div>
                  <label className="Input__Label">Last Name</label>
                  <input {...input} className="form-control Input_Text" type="text" placeholder="Last Name" />
                  <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >{meta.error && meta.touched && meta.error}</div>
                </div>
              )}
            </Field>
            <Field name="address">
              {({ input, meta}) => (
                <div>
                  <label className="Input__Label">Address</label>
                  <input {...input} className="form-control Input_Text" type="text" placeholder="Address" />
                  <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >{meta.error && meta.touched && meta.error}</div>
                </div>
              )}
            </Field>

            <Field name="gender" validate={required}>
              {({ input, meta}) => (
                <div>
                  <label className="Input__Label">Gender</label>
                  <input {...input} className="form-control Input_Text" type="text" placeholder="gender" />
                  <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >{meta.error && meta.touched && meta.error}</div>
                </div>
              )}
            </Field>
            <Field name="date_of_birth" validate={required}>
              {({ input, meta}) => (
                <div>
                  <label className="Input__Label">Date of Birth</label>
                  <input {...input} className="form-control Input_Text" type="date" placeholder="date_of_birth" />
                  <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >{meta.error && meta.touched && meta.error}</div>
                </div>
              )}
            </Field>
            <Field name="phone_number" validate={required}>
              {({ input, meta}) => (
                <div>
                  <label className="Input__Label">Phone Number</label>
                  <input {...input} className="form-control Input_Text" type="tel" placeholder="Phone Number" />
                  <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >{meta.error && meta.touched && meta.error}</div>
                </div>
              )}
            </Field>
            <Field name="email_address" >
              {({ input, meta}) => (
                <div>
                  <label className="Input__Label">Email Address</label>
                  <input {...input} className="form-control Input_Text" type="email" placeholder="Email Address" />
                  <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >{meta.error && meta.touched && meta.error}</div>
                </div>
              )}
            </Field>

            <Field name="notes">
              {({ input, meta}) => (
                <div>
                  <label className="Input__Label">Patient Notes</label>
                  <textarea {...input} className="form-control Input_Text" type="text" placeholder="Patient Notes" />
                  <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >{meta.error && meta.touched && meta.error}</div>
                </div>
              )}
            </Field>
            <Field
               name="age"
               validate={composeValidators(required, mustBeNumber, minValue(18))}
              >
              {({ input, meta}) => (
                <div>
                  <label className="Input__Label">Age</label>
                  <input {...input}
                    className={ cn("form-control Input_Text", {"Input_Error": meta.error && meta.touched})}
                    type="text" placeholder="Age" />
                  <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >{meta.error && meta.touched && meta.error}</div>
                </div>
              )}
            </Field>
            <Field
              name="patient"
              items={patients}
              label="Patient"
              component={SelectAdapter}
              placeholder="Patient"
              validate={required}
            />
            <div className="Buttons">
              <button
                type="button"
                className="Button Button__Cancel"
                onClick={reset}
                disabled={submitting || pristine}
                >
                CANCEL
              </button>
              <button type="submit" className="Button Button__Save" disabled={submitting}>
                SAVE
              </button>
            </div>
          <pre>{JSON.stringify(values, 0, 2)}</pre>
          </form>
        )}
      />
    )
  }
}

class QuickPatientFormModal extends Component {
  constructor(props) {
    super(props);
  }

  onSubmit = async values => {
    console.log("submitting to forms actions", values)
    // call api to submit this...
    formsActions.submitPatientForm(values)
    console.log("done");
  }


  render() {
    //const { } = this.props;
    return (
      <SimpleFormSelect
        onSubmit={this.onSubmit}
       />
    )
  }
}

function mapStateToProps(state) {
    const { bdforms } = state;
    return {
        bdforms
    };
}
const connectedQuickPatientFormModal = connect(mapStateToProps)(QuickPatientFormModal);
export { connectedQuickPatientFormModal as QuickPatientFormModal };
