import { lazyImport } from '../_helpers/lazyImport';

import { Switch, Redirect } from 'react-router-dom';

import { PrivateRoute } from '../_components';

const { PhotoAssessments } =lazyImport(()=> import("../PhotoAssessments/PhotoAssessments"), "PhotoAssessments")
const { Orders } =lazyImport(()=> import("../Orders/Orders"), "Orders")
const { Prescriptions } =lazyImport(()=> import("../Prescriptions/Prescriptions"), "Prescriptions")
const { ConsultRequests } =lazyImport(()=> import("../ConsultRequestsPage/ConsultRequests"), "ConsultRequests")

// TODO change appointments components 

const TempAppointments = () => {
  return (
    <div>
      {'Appointments'}
    </div>
  )
}

export const MainPageRoutes = () => {
  return (
    <Switch>
      <PrivateRoute exact path="/photoassessments" component={PhotoAssessments} />
      <PrivateRoute exact path="/orders" component={Orders} />
      <PrivateRoute exact path="/prescriptions" component={Prescriptions} />
      <PrivateRoute exact path="/requests" component={ConsultRequests} />
      <PrivateRoute exact path="/">
        <Redirect to={{pathname: '/requests'}} />
      </PrivateRoute>
    </Switch>
  )
}