import { nanoid } from 'nanoid';
import create from 'zustand';
import produce from 'immer';

// Log every time state is changed
const log = config => (set, get, api) => config(args => {
  console.log("  applying", args)
  set(args)
  console.log("  new state", get())
}, get, api)
// Turn the set method into an immer proxy
const immer = config => (set, get, api) => config((partial, replace) => {
  const nextState = typeof partial === 'function'
      ? produce(partial)
      : partial
  return set(nextState, replace)
}, get, api)

export const useNotificationStore = create(log(immer((set) => ({
  notifications: [],
  error: null,
  addError: (error) =>
    set((state) => ({
      ...state,
      error: error,
    })),
  clearError: () =>
    set((state) => ({
      ...state,
      error: null,
    })),
  addNotification: (notification) =>
    set((state) => ({
      ...state,
      notifications: [...state.notifications, { id: nanoid(), ...notification }],
    })),
  dismissNotification: (id) =>
    set((state) => ({
      ...state,
      notifications: state.notifications.filter((notification) => notification.id !== id),
    })),
}))));
