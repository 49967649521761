import { authHeader, handleResponse } from '../_helpers';
import { BASE_URL } from '../_config';

export const insuranceClaimsService = {
	getUnsentClaims,
	getUnresolvedClaims,
	getUnattachedProcedures,
	getOverview,
	submitClaim,
	createClaim
} 

function getOverview(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/dental_practice/management/insurance/overview'

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
}

function submitClaim(data){
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    let url = '/api/d/dental_practice/management/submit_claim';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
}

function createClaim(data){
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    let url = '/api/d/dental_practice/management/create_claim';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
}

function getUnattachedProcedures(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/dental_practice/management/unattached_procedures'

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
}

function getUnresolvedClaims(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    const url_params = "?unresolved=1"
    let url = '/api/d/dental_practice/management/insurance/claims' + url_params

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
}


function getUnsentClaims(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    const url_params = "?unsent=1"
    let url = '/api/d/dental_practice/management/insurance/claims' + url_params

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
}