import { insuranceClaimsConstants as constants} from '../_constants';

// test data
const initClaimList= [{
        "name": "Jason Chung",
        "date": "04/15/2018",
        "total": "$700.00",
        "id": 1,
    },{
        "name": "Tifa Lockheart",
        "date": "04/15/2018",
        "total": "$500.00",
        "id": 2,
    },{
        "name": "Cait Sith",
        "date": "04/14/2018",
        "total": "$400.00",
        "id": 3,
    }

]

const initClaimDetailData = {
    1:{
        name: "Jason Chung",
        date: "04/15/2018",
        amount: "$700.00",
        carrier: "Aetna",
        plan: "Home Depot",
        rendering: "Jacob Chu",
        billing: "Jacob Chu",
        procedures: [
          {code:"D20032", 
            description: "Posterior Resin Composite 3s", 
            oral_cavity: "", 
            tooth: "14", 
            surface: "MOD", 
            amount: "250"},
          {code:"D20032", 
            description: "Posterior Resin Composite 3s", 
            oral_cavity: "", 
            tooth: "17", 
            surface: "MOD", 
            amount: "250"},
          {code:"D20032", 
            description: "Posterior Resin Composite 2s", 
            oral_cavity: "", 
            tooth: "28", 
            surface: "MO", 
            amount: "200"}
        ]
    },
    2:{
        name: "Tifa Lockheart",
        date: "04/15/2018",
        amount: "$500.00",
        carrier: "Delta Dental",
        plan: "39438129",
        rendering: "Jacob Chu",
        billing: "Jacob Chu",
        procedures: [
          {code:"D20032", 
            description: "Posterior Resin Composite 3s", 
            oral_cavity: "", 
            tooth: "17", 
            surface: "MOD", 
            amount: "250"},
          {code:"D20032", 
            description: "Posterior Resin Composite 3s", 
            oral_cavity: "", 
            tooth: "28", 
            surface: "MO", 
            amount: "250"}
        ]
    },
    3:{
        name: "Cait Sith",
        date: "04/14/2018",
        amount: "$400.00",
        carrier: "Blue Cross",
        plan: "Google",
        rendering: "Jiang Xiao",
        billing: "Jacob Chu",
        procedures: [
          {code:"D11011", 
            description: "General Patient Checkup", 
            oral_cavity: "", 
            tooth: "", 
            surface: "", 
            amount: "400"}
        ]
    }
}

// this will be initialized by reducer after data grab
const initCurrentClaim = initClaimList[0].id

const initUnsentClaims = []
const initUnresolvedClaims = [];
const initUnattachedProcedures = []

const initStateData = {
  currentClaim: null,
  unsentClaims: initUnsentClaims,
  unsentClaimsList: [],
  unsentClaimsObj: {},
  unresolvedClaims: initUnresolvedClaims,
  unresolvedClaimsList: [],
  unresolvedClaimsObj: {},
  unattachedProcedures: initUnattachedProcedures, //initUnattachedProcedures,
  unattachedProceduresNrmObj: {},
  unattachedProceduresList: [],
  datePatientObj: {}

}

const initState ={
  currentTab: 0,
  currentClaim: null,
  claimList: initClaimList,
  claimDetailData: initClaimDetailData,
  claimOverviewLoading: false,
  claimOverviewError: "",
  submitInsuranceClaimLoading: false,
  submitInsuranceClaimError: "",
  createInsuranceClaimLoading: false,
  createInsuranceClaimError: "",

  unattachedProceduresCount: 0,
  unattachedProceduresTotal: 0,
  unsentClaimsCount: 0,
  unsentClaimsTotal: 0,
  unresolvedClaimsCount: 0,
  unresolvedClaimsTotal: 0,
  unsentClaims: initUnsentClaims,
  unsentClaimsLoading: false,
  unsentClaimsError: "",
  unsentClaimsList: [],
  unsentClaimsObj: {},
  unresolvedClaims: initUnresolvedClaims,
  unresolvedClaimsLoading: false,
  unresolvedClaimsError: "",
  unresolvedClaimsList: [],
  unresolvedClaimsObj: {},
  unattachedProcedures: initUnattachedProcedures, //initUnattachedProcedures,
  unattachedProceduresLoading: false,
  unattachedProceduresError: "",
  unattachedProceduresNrmObj: {},
  unattachedProceduresList: [],
  datePatientObj: {}


}

export function insuranceClaims(state = initState, action) {
    switch(action.type){
      case constants.INITIALIZE:
        return initState

      case constants.CHANGE_CONTENTVIEW:
        return {
          ...state,
          currentTab: action.tabNum,
          ...initStateData,
        }
    
      case constants.CHANGE_PAGEDETAILVIEW:
        return {
          ...state,
          currentClaim: action.claimNum
        }


      /* over claim */

      case constants.GET_CLAIMSOVERVIEW_REQUEST:
        return {
          ...state,
          claimOverviewLoading: true,
          claimOverviewError: "",

        }
      
      case constants.GET_CLAIMSOVERVIEW_SUCCESS:
        return {
          ...state,
          claimOverviewLoading: false,
          claimOverviewError: "",
          unattachedProceduresCount: action.data.unattached_procedures.count,
          unattachedProceduresTotal: action.data.unattached_procedures.total,
          unsentClaimsCount: action.data.insurance_claims_unsent.count,
          unsentClaimsTotal: action.data.insurance_claims_unsent.total,
          unresolvedClaimsCount: action.data.insurance_claims_unresolved.count,
          unresolvedClaimsTotal: action.data.insurance_claims_unresolved.total,
        }

      case constants.GET_CLAIMSOVERVIEW_ERROR:
        return {
          ...state,
          claimOverviewLoading: false,
          claimOverviewError: "Error",
        }

      /* send claim */

      case constants.SUBMIT_INSURANCE_CLAIM_REQUEST:
        return {
          ...state,
          submitInsuranceClaimLoading: true,
          submitInsuranceClaimError: "",

        }
      
      case constants.SUBMIT_INSURANCE_CLAIM_SUCCESS:
        return {
          ...state,
          submitInsuranceClaimLoading: false,
          submitInsuranceClaimError: "",
          currentClaim: null

        }

      case constants.SUBMIT_INSURANCE_CLAIM_ERROR:
        return {
          ...state,
          submitInsuranceClaimLoading: false,
          submitInsuranceClaimError: "Error",
        }


      /* create claim from unattached procedures */

      case constants.CREATE_INSURANCE_CLAIM_REQUEST:
        return {
          ...state,
          createInsuranceClaimLoading: true,
          createInsuranceClaimError: "",

        }
      
      case constants.CREATE_INSURANCE_CLAIM_SUCCESS:

        return {
          ...state,
          createInsuranceClaimLoading: false,
          createInsuranceClaimError: "",
          currentClaim: null

        }

      case constants.CREATE_INSURANCE_CLAIM_ERROR:
        return {
          ...state,
          createInsuranceClaimLoading: false,
          createInsuranceClaimError: "Error",
        }


      /* unattached procedures */

      case constants.GET_UNATTACHEDPROCEDURES_REQUEST:
        return {
          ...state,
          unattachedProceduresLoading: true,
          unattachedProceduresError: "",

        }
      
      case constants.GET_UNATTACHEDPROCEDURES_SUCCESS:
        return {
          ...state,
          unattachedProceduresLoading: false,
          unattachedProceduresError: "",
          unattachedProcedures: action.data.unattached_procedures,
          unattachedProceduresNrmObj: action.data.unattachedProceduresNrmObj,
          unattachedProceduresList: action.data.unattachedProceduresList,
          datePatientObj: action.data.datePatientObj
        }

      case constants.GET_UNATTACHEDPROCEDURES_ERROR:
        return {
          ...state,
          unattachedProceduresLoading: false,
          unattachedProceduresError: "Error",
        }


      /* unsent claims */

      case constants.GET_UNSENTCLAIMS_REQUEST:
        return {
          ...state,
          unsentClaimsLoading: false,
          unsentClaimsError: "",
        }

      case constants.GET_UNSENTCLAIMS_SUCCESS:
        return {
          ...state,
          unsentClaimsLoading: false,
          unsentClaimsError: "",
          unsentClaimsList: action.data.claimsList,
          unsentClaimsObj: action.data.claimsNrmObj
        }

      case constants.GET_UNSENTCLAIMS_ERROR:
        return {
          ...state,
          unsentClaimsLoading: false,
          unsentClaimsError: "Error",
        }


        /* unresolved claims */

      case constants.GET_UNRESOLVEDCLAIMS_REQUEST:
        return {
          ...state,
          unresolvedClaimsLoading: true,
          unresolvedClaimsError: "",
          
        }

      case constants.GET_UNRESOLVEDCLAIMS_SUCCESS:
        return {
          ...state,
          unresolvedClaimsLoading: false,
          unresolvedClaimsError: "",
          unresolvedClaimsList: action.data.claimsList,
          unresolvedClaimsObj: action.data.claimsNrmObj
          
        }

      case constants.GET_UNRESOLVEDCLAIMS_ERROR:
        return {
          ...state,
          unresolvedClaimsLoading: false,
          unresolvedClaimsError: "Error",
          
        }

      default:
        return state
    }
}