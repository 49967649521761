import {Button} from '@chakra-ui/react';

export const ButtonFilled = ({
  children,
  bg = 'blue',
  color = 'white',
  ...props
}) => {
  return (
    <Button
      minW={'90px'}
      size={'lg'}
      variant={'solid'}
      fontWeight={400}
      fontSize={14}
      letterSpacing={0}
      color={color}
      colorScheme={bg}
      
      borderRadius={10}
      _hover={{
        bg: `${bg}.200`,
      }}
      _active={{
        bg: `${bg}.700`,
      }}
      _disabled={{
        bg: `${bg}.100`,
        _hover: {
          bg: `${bg}.100`,
        },
        _active: {
          bg: `${bg}.100`,
        }
      }}
      {...props}
    >
      {children}
    </Button>
  );
};
