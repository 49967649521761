import { useQuery } from "react-query";

import { queryConfig, queryClient } from "../../_libs/react-query";

import { fetchClinicAppointmentCollection } from "./_services";

export const QUERY_KEY_NAME = "ClinicDashboardAppointments";

const defaultQueryParams = {
  view: "day",
  status: "",
  orderBy: "",
  offset: 0,
  dpid: null,
  startDate: null,
};

export const queryClinicAppointments = (queryParams) => {
  let paramsObj = {};
  for (const [key, val] of Object.entries(queryParams)) {
    if (val) {
      paramsObj[key] = val;
    }
  }
  const searchParams = new URLSearchParams(paramsObj);
  return fetchClinicAppointmentCollection(searchParams);
};

const newQueryConfig = {
  ...queryConfig,
  queries: {
    ...queryConfig.queries,
    refetchOnMount: false,
  },
  keepPreviousData: true,
};

export const refetchAppointments = () => {
  queryClient.refetchQueries([QUERY_KEY_NAME, defaultQueryParams]);
};

export const addAppointmentData = (data) => {
  queryClient.setQueryData([QUERY_KEY_NAME, defaultQueryParams], (oldData) => {
    const newAppointments = [...(oldData?.appointments || []), data];
    return {
      ...oldData,
      appointments: newAppointments,
    };
  });
};

export const updateAppointmentData = (id, data, roomData) => {
  queryClient.setQueryData([QUERY_KEY_NAME, defaultQueryParams], (oldData) => {
    console.log(roomData, "roomData");
    const newAppointments = (oldData?.appointments || []).map((appointment) => {
      if (String(appointment?.id) === String(id)) {
        return {
          ...appointment,
          updated_at: new Date().toISOString(),
          status: data.status ? data.status : appointment.status,
          operatory:
            data.operatory !== undefined
              ? { ...roomData, id: data.operatory }
              : { ...appointment.operatory },
        };
      }

      return appointment;
    });
    console.log(newAppointments, "vnewAppointments");
    return {
      ...oldData,
      appointments: newAppointments,
    };
  });
};

export const updateAppointmentDentistData = (id, data, dentistData) => {
  queryClient.setQueryData([QUERY_KEY_NAME, defaultQueryParams], (oldData) => {
    console.log(dentistData, "dentistData");
    const newAppointments = (oldData?.appointments || []).map((appointment) => {
      if (appointment?.id === id) {
        return {
          ...appointment,
          // dentist: {
          //   ...appointment?.dentist,
          //   id: data?.dentist,
          // },
          dentist:
            data.dentist !== undefined
              ? { ...dentistData, id: data.dentist }
              : { ...appointment.dentist },
        };
      }
      return appointment;
    });
    console.log(newAppointments, "newAppointmentsnewAppointments");
    return {
      ...oldData,
      appointments: newAppointments,
    };
  });
};

export const ClinicDashboardAppointmentsQuery = ({
  startDate = null,
  view = "day",
  status = "",
  orderBy = "",
  offset = 0,
  date = null,
  dentist = null,
  dpid = null,
}) => {
  return useQuery({
    ...newQueryConfig,
    queryKey: [
      QUERY_KEY_NAME,
      { startDate, view, status, orderBy, offset, dpid, date, dentist },
    ],
    queryFn: () =>
      queryClinicAppointments({
        startDate,
        view,
        status,
        orderBy,
        offset,
        dpid,
        date,
        dentist,
      }),
  });
};
