import React, { Component } from 'react';
import { render } from 'react-dom'
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import Spinner from '../_components/Spinner';
import cn from 'classnames';
import Downshift from 'downshift';
import matchSorter from 'match-sorter';
import Select from 'react-select';

import store, { promiseListener } from "../_helpers/store";
import { formsService  } from "../_services"
import {
  CREATEPATIENT,
  CREATEPATIENT_SUCCESS,
  CREATEPATIENT_FAILURE
} from "../_reducers/test.reducer";


import {WorkRequestForm} from '../WorkRequestForm';

import { AppointmentFormModal } from '../_forms'


// mock wait for serverpackag
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const onSubmit = async values => {
  await sleep(400);
  window.alert(JSON.stringify(values, 0, 2))
};

const required = value => (value ? undefined : 'Required')
const mustBeNumber = value => (isNaN(value) ? 'Must be a number' : undefined)
const minValue = min => value =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`
const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)

// another way to validate.
// field by field or put all in one monolith check
const validate = values => {
  const errors = {}
  if (!values.firstName) {
    errors.firstName = 'Required'
  }
  if (!values.fruit) {
    errors.fruit = 'Required'
  }
  return errors
}

const simpleMemoize = fn => {
  let lastArg
  let lastResult
  return arg => {
    if (arg !== lastArg) {
      lastArg = arg
      lastResult = fn(arg)
    }
    return lastResult
  }
}


/*
 * dynamically create form from inputs given
 * [ {label, validation, type, size, error} ]
 */



class SimpleForm extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    //const { fields, onSubmit } = this.props;

    return (
      <Form
        onSubmit={onSubmit}
        render = {({
          handleSubmit,
          reset,
          submitting,
          pristine,
          validating,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            {validating && <Spinner />}
            <Field name="username" validate={required}>
              {({ input, meta}) => (
                <div>
                  <label className="Input__Label">Username</label>
                  <input {...input} className="form-control Input_Text" type="text" placeholder="Username" />
                  <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >{meta.error && meta.touched && meta.error}</div>
                </div>
              )}
            </Field>
            <Field
               name="age"
               validate={composeValidators(required, mustBeNumber, minValue(18))}
              >
              {({ input, meta}) => (
                <div>
                  <label className="Input__Label">Age</label>
                  <input {...input}
                    className={ cn("form-control Input_Text", {"Input_Error": meta.error && meta.touched})}
                    type="text" placeholder="Age" />
                  <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >{meta.error && meta.touched && meta.error}</div>
                </div>
              )}
            </Field>
            <div className="Buttons">
              <button type="submit" className="Button Button__Save" disabled={submitting}>
                Submit
              </button>
              <button
                type="button"
                className="Button Button__Cancel"
                onClick={reset}
                disabled={submitting || pristine}
                >
                Reset
              </button>
            </div>
          </form>
        )}
      />
    )
  }
}

const patients = [
  {value: '3', label: 'Jason Chung'},
  {value: '4', label: 'Amy Chong'},
  {value: '5', label: 'Shin Um'},
  {value: '6', label: 'Seung Lee'},
  {value: '7', label: 'Ji Hwan Park'}
]

const itemToString = item => (item ? item : '')

const SelectAdapter = ({input, meta, items, label, ...rest }) => (
  <div>
    <label>{label}</label>
    <Select
      {...input}
      className={ cn({"Input_Error": meta.error && meta.touched})}
      onChange={inputValue => input.onChange(inputValue)}
      options={items}
    />
    <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >{meta.error && meta.touched && meta.error}</div>
  </div>

)

const DownshiftInput = ({ label, input, meta, placeholder, items, ...rest}) => (

  <Downshift
    {...input}
    OnInputValueChange={inputValue => {
      input.onChange(inputValue)
    }}
    itemToString={itemToString}
    selectedItem={input.value}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem
      }) => {
        const filteredItems = matchSorter(items, inputValue, {
          keys: ['label'],
          maxRanking: matchSorter.rankings.STARTS_WITH
        })
        return (
          <div>
            <label className="Input__Label">{label}</label>
            <div className="downshift" style={{ position: 'relative' }}>
              <input
                {...getInputProps({
                  name: input.name,
                  placeholder
                })}
              />
              <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >{meta.error && meta.touched && meta.error}</div>
              {isOpen &&
                !!filteredItems.length && (
                  <div
                    className="downshift-options"
                    style={{
                      background: 'white',
                      position: 'absolute',
                      top: '100%',
                      left: 15,
                      right: 0,
                      zIndex: 4
                    }}
                  >
                    {filteredItems.map(({ value, label }, index) => (
                      <div
                        {...getItemProps({
                          key: value,
                          index,
                          item: value,
                          style: {
                            backgroundColor:
                              highlightedIndex === index ? 'lightgray' : 'white',
                            fontWeight: selectedItem === value ? 'bold' : 'normal'
                          }
                        })}
                      >
                        {label}
                      </div>
                    ))}
                  </div>
                )}

            </div>
          </div>
        )
    }}
    </Downshift>
)

class SimpleFormSelect extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { onSubmit } = this.props;

    return (
      <Form
        onSubmit={onSubmit}
        render = {({
          handleSubmit,
          reset,
          submitting,
          pristine,
          validating,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            {validating && <Spinner />}
            <Field
               name="age"
               validate={composeValidators(required, mustBeNumber, minValue(18))}
              >
              {({ input, meta}) => (
                <div>
                  <label className="Input__Label">Age</label>
                  <input {...input}
                    className={ cn("form-control Input_Text", {"Input_Error": meta.error && meta.touched})}
                    type="text" placeholder="Age" />
                  <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >{meta.error && meta.touched && meta.error}</div>
                </div>
              )}
            </Field>
            <Field
              name="patient"
              items={patients}
              label="Patient"
              component={SelectAdapter}
              placeholder="Patient"
              validate={required}
            />
            <div className="Buttons">
              <button
                type="button"
                className="Button Button__Cancel"
                onClick={reset}
                disabled={submitting || pristine}
                >
                CANCEL
              </button>
              <button type="submit" className="Button Button__Save" disabled={submitting}>
                SAVE
              </button>
            </div>
            <pre>{JSON.stringify(values, 0, 2)}</pre>
          </form>
        )}
      />
    )
  }
}

const quickPatientFormList = [
  {name: "first_name", validation: [], label: "First Name"},
  {name: "last_name", validation: [], label: "Last Name"},
  {name: "address", validation: [], label: "Address"},
  {name: "gender", validation: [], label: "Gender"},
  {name: "date_of_birth", validation: [], label: "Date of Birth"},
  {name: "phone_number", validation: [], label: "Phone Number"},
  {name: "email_address", validation: [], label: "Email Address"},
  {name: "notes", validation: [], label: "Patient Notes"}
]

const quickPatientFormListFormatted = [
  [{name: "first_name", validation: [], label: "First Name"},
   {name: "last_name", validation: [], label: "Last Name"}],
  [{name: "address", validation: [], label: "Address"}],
  [{name: "gender", validation: [], label: "Gender"},
   {name: "date_of_birth", validation: [], label: "Date of Birth"}],
  [{name: "phone_number", validation: [], label: "Phone Number"},
   {name: "email_address", validation: [], label: "Email Address"}],
  [{name: "notes", validation: [], label: "Patient Notes"}]
]

class QuickPatientForm extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { onSubmit } = this.props;

    return (
      <Form
        onSubmit={onSubmit}
        render = {({
          handleSubmit,
          reset,
          submitting,
          pristine,
          validating,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            {validating && <Spinner />}
            <Field name="first_name" validate={required}>
              {({ input, meta}) => (
                <div>
                  <label className="Input__Label">First Name</label>
                  <input {...input} className="form-control Input_Text" type="text" placeholder="First Name" />
                  <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >{meta.error && meta.touched && meta.error}</div>
                </div>
              )}
            </Field>
            <Field name="last_name" validate={required}>
              {({ input, meta}) => (
                <div>
                  <label className="Input__Label">Last Name</label>
                  <input {...input} className="form-control Input_Text" type="text" placeholder="Last Name" />
                  <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >{meta.error && meta.touched && meta.error}</div>
                </div>
              )}
            </Field>
            <Field name="address">
              {({ input, meta}) => (
                <div>
                  <label className="Input__Label">Address</label>
                  <input {...input} className="form-control Input_Text" type="text" placeholder="Address" />
                  <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >{meta.error && meta.touched && meta.error}</div>
                </div>
              )}
            </Field>

            <Field name="gender" validate={required}>
              {({ input, meta}) => (
                <div>
                  <label className="Input__Label">Gender</label>
                  <input {...input} className="form-control Input_Text" type="text" placeholder="gender" />
                  <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >{meta.error && meta.touched && meta.error}</div>
                </div>
              )}
            </Field>
            <Field name="date_of_birth" validate={required}>
              {({ input, meta}) => (
                <div>
                  <label className="Input__Label">Date of Birth</label>
                  <input {...input} className="form-control Input_Text" type="date" placeholder="date_of_birth" />
                  <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >{meta.error && meta.touched && meta.error}</div>
                </div>
              )}
            </Field>
            <Field name="phone_number" validate={required}>
              {({ input, meta}) => (
                <div>
                  <label className="Input__Label">Phone Number</label>
                  <input {...input} className="form-control Input_Text" type="tel" placeholder="Phone Number" />
                  <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >{meta.error && meta.touched && meta.error}</div>
                </div>
              )}
            </Field>
            <Field name="email_address" >
              {({ input, meta}) => (
                <div>
                  <label className="Input__Label">Email Address</label>
                  <input {...input} className="form-control Input_Text" type="email" placeholder="Email Address" />
                  <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >{meta.error && meta.touched && meta.error}</div>
                </div>
              )}
            </Field>

            <Field name="notes">
              {({ input, meta}) => (
                <div>
                  <label className="Input__Label">Patient Notes</label>
                  <textarea {...input} className="form-control Input_Text" type="text" placeholder="Patient Notes" />
                  <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >{meta.error && meta.touched && meta.error}</div>
                </div>
              )}
            </Field>
            <Field
               name="age"
               validate={composeValidators(required, mustBeNumber, minValue(18))}
              >
              {({ input, meta}) => (
                <div>
                  <label className="Input__Label">Age</label>
                  <input {...input}
                    className={ cn("form-control Input_Text", {"Input_Error": meta.error && meta.touched})}
                    type="text" placeholder="Age" />
                  <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >{meta.error && meta.touched && meta.error}</div>
                </div>
              )}
            </Field>
            <Field
              name="patient"
              items={patients}
              label="Patient"
              component={SelectAdapter}
              placeholder="Patient"
              validate={required}
            />
            <div className="Buttons">
              <button
                type="button"
                className="Button Button__Cancel"
                onClick={reset}
                disabled={submitting || pristine}
                >
                CANCEL
              </button>
              <button type="submit" className="Button Button__Save" disabled={submitting}>
                SAVE
              </button>
            </div>
          <pre>{JSON.stringify(values, 0, 2)}</pre>
          </form>
        )}
      />
    )
  }
}

const SubmitError = ({ name }) => (
  <Field
    name={name}
    subscription={{ submitError: true, dirtySinceLastSubmit: true }}
  >
    {({ meta: { submitError, dirtySinceLastSubmit } }) =>
      submitError && !dirtySinceLastSubmit ? <span>{submitError}</span> : null
    }
  </Field>
);

function success(data) {
  console.log("success", data)
}

function failure(error) {
  console.log("error", error)
}

class TestPage extends Component {
  constructor(props) {
    super(props);
    this.state = {isOpen: false}
  }

  openModal = () => {
    console.log("open");
    this.setState({isOpen: true})
  }

  closeModal = () => {
    this.setState({isOpen:false})
  }

  render() {
    //const { } = this.props;
    return (
      <div>
        <WorkRequestForm />
      </div>
    )
  }
}

function mapStateToProps(state) {
    const { test } = state;
    return {
        test
    };
}
const connectedTestPage = connect(mapStateToProps)(TestPage);
export { connectedTestPage as TestPage };
