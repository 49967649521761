import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { store } from './js/_helpers';
import { App } from './js/App';
import * as serviceWorker from './serviceWorker';

/*
// internationalization
import {addLocaleData, IntlProvider} from 'react-intl';
import en from 'react-intl/locale-data/en';
import xh from 'react-intl/locale-data/xh';

import localeData from './locales/data.json';

addLocaleData([...en, ...xh]);

const language = (navigator.languages && navigator.languages[0]) ||
                     navigator.language ||
                     navigator.userLanguage;

// Split locales with a region code
const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

// Try full locale, fallback to locale without region code, fallback to en
const messages = localeData[languageWithoutRegionCode] || localeData[language] || localeData.en;
*/
import './css/index.css';
import './css/forms.style.css'

ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
    document.getElementById('app')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

/*
if (!window.intl){

    require.ensure([
        'intl',
        'intl/locale-data/jsonp/en.js',
        'intl/locale-data/jsonp/es.js',
        'intl/locale-data/jsonp/ja.js',
        'intl/locale-data/jsonp/ko.js',
    ], (require) => {
        require('intl');
        require('intl/locale-data/jsonp/en.js');
        require('intl/locale-data/jsonp/es.js');
        require('intl/locale-data/jsonp/ja.js');
        require('intl/locale-data/jsonp/ko.js');
        render(
            <Provider store={store}>
                <IntlProvider locale={languageWithoutRegionCode} key={languageWithoutRegionCode} messages={messages}>
                    <App />
                </IntlProvider>
            </Provider>,
            document.getElementById('app')
        );
    });
} else {
    render(
        <Provider store={store}>
            <IntlProvider locale={languageWithoutRegionCode} key={languageWithoutRegionCode} messages={messages}>
                <App />
            </IntlProvider>
        </Provider>,
        document.getElementById('app')
    );
};*/
