import { alertConstants } from '../_constants';

export function alert(state = {}, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: 'alert-success',
        ...action
      };
    case alertConstants.ERROR:
      return {
        type: 'alert-danger',
        ...action
      };
    case alertConstants.CLEAR:
      return {};
    case alertConstants.SHOW_GENERAL:
      // data should be type
      return {
        ...action.data
      };
    default:
      return state
  }
}
