import { useQuery } from 'react-query';

import { queryConfig } from '../../_libs/react-query';

import { fetchChannels } from './_services';

export const QUERY_KEY_NAME = 'PNCHannels';

export const getPNChannels = () => {
  return fetchChannels()
};

const newQueryConfig = {
    ...queryConfig, 
    queries : {
      ...queryConfig.queries, 
      refetchOnMount: false
    },
};

export const GetPNChannelsQuery = ({config}) => {
  return useQuery({
    ...newQueryConfig,
    ...config,
    queryKey: [QUERY_KEY_NAME],
    queryFn: () => getPNChannels(),
  });
}; 
