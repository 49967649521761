import moment from "moment";
import { appointmentConstants } from "../_constants";

/*
 * Update state for state.scheduling here...
 * have to copy state then return it
 * sets entire state, doesn't update just one field
 * shortcuts: https://daveceddia.com/immutable-updates-react-redux/
 *
 * init {appointments: [], dentistList: [], }
 *
 */

const initChart = {
  1: [],
  2: [],
  3: [],
  4: [],
  5: [],
  6: [],
  7: [],
  8: [],
  9: [],
  10: [],
  11: [],
  12: [],
  13: [],
  14: [],
  15: [],
  16: [],
  17: [],
  18: [],
  19: [],
  20: [],
  21: [],
  22: [],
  23: [],
  24: [],
  25: [],
  26: [],
  27: [],
  28: [],
  29: [],
  30: [],
  31: [],
  32: [],
};
const initChatBox = {
  activeChatBoxMessages: [],
  activeChatBoxDetails: {},
  activeChatBoxTitle: "",
  activeChatBox: null,
  activeChatLastRetrieved: null,
};

const initProcForm = {
  patient: "",
  tooth: "",
  surface: "",
  procedure: "",
  dentist: "",
  notes: "",
  cost: 0,
  insurance_coverage: 0,
};

const initState = {
  currentAppointmentID: null,
  currentContentView: "CHARTING",
  isVideoOpen: false,
  videoChatApptId: "",
  loading: false,
  loadingSendMessage: false,
  loadingVideoChatToken: false,
  procedureCodeList: [],
  procLoading: false,
  procFormClear: false,
  procForm: { ...initProcForm },
  appointment: {
    patient: {
      first_name: "Loading...",
      middle_name: "",
      last_name: "",
      phone_number: "Loading...",
      address: "Loading...",
      email_address: "Loading...",
      gender: "-",
      date_of_birth: "",
    },
    dentist: {
      name: "",
      id: "",
    },
    start_time: "",
    end_time: "",
    procedures: [],
    uploads: [],
    video_chat_session: null,
    video_chat_token: null,
  },

  upcomingAppointments: [],
  error: "",
  chatList: [],
  chatObjects: {},
  chatLastRetrieved: null,
  activeChatBoxMessages: [],
  activeChatBoxDetails: {},
  activeChatBoxTitle: "",
  activeChatBoxAvatar: "",
  activeChatBox: null,
  activeChatLastRetrieved: null,
  charting: { ...initChart },
  //add active procedure for viewing
  chartingCurrentTooth: null,
  chartingCurrentRegions: [],
  chartingCurrentToothProcedures: [],
  chartingCurrentProcedure: null,
  uploadsIndex: 0,
  uploadsNumberOf: 5,
  currentDateSelected: moment().format(),
};

export function appointment(state = initState, action) {
  switch (action.type) {
    case appointmentConstants.UNMOUNT_APPT:
      return {
        ...initState,
        ["charting"]: { ...initChart },
      };

    case appointmentConstants.SETAPPOINTMENT_ID:
      return {
        ...state,
        currentAppointmentID: action.apptID,
      };

    //  change date	SET_CURRENT_DATE
    case appointmentConstants.SET_CURRENT_DATE:
      return {
        ...state,
        currentDateSelected: action.currentDate,
      };
    // uploads navigation
    case appointmentConstants.PREV_UPLOADS:
      return {
        ...state,
        uploadsIndex: Math.max(state.uploadsIndex - state.uploadsNumberOf, 0),
      };

    case appointmentConstants.NEXT_UPLOADS:
      return {
        ...state,
        uploadsIndex: state.uploadsIndex + state.uploadsNumberOf,
      };

    case appointmentConstants.CHANGE_PAGECONTENT:
      return {
        ...state,
        currentContentView: action.contentView,
      };

    case appointmentConstants.OPEN_VIDEO_CHAT:
      return {
        ...state,
        videoChatApptId: action.apptID,
        isVideoOpen: true,
      };
    case appointmentConstants.END_VIDEO_SESSION:
      return {
        ...state,
        videoChatApptId: "",
        isVideoOpen: false,
      };

    /*
     * Procedure form
     */
    case appointmentConstants.OPENPROCFORM:
      const test = { ...state.procForm, ...action.data };
      console.log(test);
      return {
        ...state,
        procFormOpen: true,
        procForm: { ...state.procForm, ...action.data },
      };

    case appointmentConstants.CLOSEPROCFORM:
      return {
        ...state,
        procFormOpen: false,
        procForm: { ...initProcForm },
      };

    case appointmentConstants.CHANGEPROCFORM:
      console.log(state.procForm);
      return {
        ...state,
        procForm: { ...state.procForm, ...action.form },
      };

    /*
     * LOAD PROCEUDRE CODES, TOOTH REGIONS, ETC
     *
     */
    case appointmentConstants.GETPROCEDURECODES_REQUEST:
      return {
        ...state,

        errorProcedureCodes: "",
        loadingProcedureCodes: true,
      };
    case appointmentConstants.GETPROCEDURECODES_SUCCESS:
      return {
        ...state,

        errorProcedureCodes: "",
        procedureCodeList: action.data.code_list,
        loadingProcedureCodes: false,
      };
    case appointmentConstants.GETPROCEDURECODES_FAILURE:
      return {
        ...state,

        errorProcedureCodes: action.error,
        loadingProcedureCodes: false,
      };

    case appointmentConstants.GET_TOOTHREGIONS_REQUEST:
      return {
        ...state,

        errorToothRegion: "",
        loadingToothRegions: true,
      };
    case appointmentConstants.GET_TOOTHREGIONS_SUCCESS:
      return {
        ...state,

        errorToothRegion: "",
        toothRegionList: action.data.toothregion_list,
        loadingToothRegions: false,
      };
    case appointmentConstants.GET_TOOTHREGIONS_FAILURE:
      return {
        ...state,

        errorToothRegion: action.error,
        loadingToothRegions: false,
      };
    /*
     * GET APPOINTMENT PAGE DATA
     *  retrieve all necessary data for appointment page at once
     *
     */
    case appointmentConstants.GETAPPOINTMENT_DETAILS_REQUEST:
      return {
        ...initState,

        error: "",
        loading: true,
      };
    case appointmentConstants.GETAPPOINTMENT_DETAILS_SUCCESS:
      const tempToothObj = action.data.appointment.toothObj;
      const toothChart = { ...initChart };
      for (var i = 0; i < action.data.appointment.toothList.length; i++) {
        // loop through procedures. add filled surfaces to chart.
        let currentTooth = action.data.appointment.toothList[i];
        let tempCurrentSurfaceList = tempToothObj[currentTooth].map(
          (proc, index) => {
            return proc.surface;
          }
        );
        // addds a comma to tooth wtih multiple proceuddres... fix it later
        let currentSurfaceList = tempCurrentSurfaceList.reduce(function (
          prev,
          curr
        ) {
          return prev.concat(curr.split(""));
        });
        let tempToothSurfaceList = currentSurfaceList.concat(
          toothChart[currentTooth]
        );
        toothChart[currentTooth] = [...new Set(tempToothSurfaceList)];
      }
      return {
        ...state,
        error: "",
        loading: false,
        appointment: action.data.appointment,
        upcomingAppointments: action.data.upcoming_appointments,
        charting: toothChart,
      };
    case appointmentConstants.GETAPPOINTMENT_DETAILS_FAILURE:
      return {
        ...state,

        loading: false,
        error: action.error,
      };

    /*
     * GET Video Chat Details
     *
     */
    case appointmentConstants.GETVIDEOCHAT_TOKEN_REQUEST:
      return {
        ...state,
        errorVideoChatToken: "",
        loadingVideoChatToken: true,
      };
    case appointmentConstants.GETVIDEOCHAT_TOKEN_SUCCESS:
      return {
        ...state,

        errorVideoChatToken: "",
        loadingVideoChatToken: false,
        appointment: {
          ...state.appointment,
          video_chat_token: action.data.token,
          isVideoOpen: true,
        },
      };
    case appointmentConstants.GETVIDEOCHAT_TOKEN_FAILURE:
      return {
        ...state,
        loadingVideoChatToken: false,
        errorVideoChatToken: action.error,
      };

    // Select Tooth
    case appointmentConstants.CHART_SELECT_TOOTH:
      return {
        ...state,

        chartingCurrentTooth: action.toothNum,
        chartingCurrentRegions: state.charting[action.toothNum] || [],
        chartingCurrentToothProcedures:
          state.appointment.toothObj[action.toothNum] || [],
        chartingCurrentProcedure: null,
      };
    // not implemented yet, just defaults to select tooth
    case appointmentConstants.CHART_SELECT_TOOTH_PROCEDURE:
      const tooth = action.toothNum;
      const procID = action.procID;
      return {
        ...state,

        chartingCurrentTooth: action.toothNum || null,
        chartingCurrentRegions: state.charting[action.toothNum] || [],
        chartingCurrentToothProcedures:
          state.appointment.toothObj[action.toothNum] || [],
        chartingCurrentProcedure: null,
      };

    // Get Inbox
    case appointmentConstants.GET_INBOX_REQUEST:
      return {
        ...state,

        errorInbox: "",
        loadingInbox: true,
      };
    case appointmentConstants.GET_INBOX_SUCCESS:
      // chatList is list of ids [1,2,3]
      // chatObject is dictionary of objects by key(id)  {1: {..}, 2: {..}}
      return {
        ...state,

        errorInbox: "",
        chatList: action.data.chat_list,
        chatObjects: action.data.chat_objects,
        loadingInbox: false,
        chatLastRetrieved: new Date(),
      };
    case appointmentConstants.GET_INBOX_FAILURE:
      return {
        ...state,

        errorInbox: action.error,
        loadingInbox: false,
      };

    // Get Unread Inbox
    case appointmentConstants.GET_NEWINBOX_REQUEST:
      return {
        ...state,

        errorInbox: "",
      };

    case appointmentConstants.GET_NEWINBOX_SUCCESS:
      // update the chat list and objects with only new items
      const tempChatObjects = { ...state.chatObjects };
      const tempChatList = action.data.chat_list.concat(state.chatList);
      let finalChatList = [...new Set(tempChatList)];
      // replace the chat object with newlyd received objects
      for (var i = 0; i < action.data.chat_list.length; i++) {
        tempChatObjects[action.data.chat_list[i]] =
          action.data.chat_objects[action.data.chat_list[i]];
      }
      return {
        ...state,

        errorInbox: "",
        chatObjects: tempChatObjects,
        chatList: finalChatList,
        chatLastRetrieved: new Date(),
      };
    case appointmentConstants.GET_NEWINBOX_FAILURE:
      return {
        ...state,

        errorInbox: action.error,
      };

    // open chat box
    case appointmentConstants.OPENCHATBOX:
      return {
        ...state,
        activeChatBox: action.data.chatID,
        activeChatBoxTitle: action.data.name,
        activeChatBoxAvatar: action.data.avatar,
      };
    // close chat box
    case appointmentConstants.CLOSECHATBOX:
      return {
        ...state,
        activeChatBoxMessages: [],
        activeChatBoxDetails: {},
        activeChatBoxTitle: "",
        activeChatBoxAvatar: "",
        activeChatBox: null,
        activeChatLastRetrieved: null,
      };

    // Get Messages for Active Chat Box
    case appointmentConstants.GET_MESSAGES_REQUEST:
      return {
        ...state,

        errorChatBox: "",
        loadingChatBox: true,
      };
    case appointmentConstants.GET_MESSAGES_SUCCESS:
      return {
        ...state,

        errorChatBox: "",
        activeChatBoxDetails: action.data.thread,
        activeChatBoxMessages: action.data.message_list,
        //activeChatBoxAvatar: "",
        activeChatLastRetrieved: new Date(),
        loadingChatBox: false,
      };
    case appointmentConstants.GET_MESSAGES_FAILURE:
      return {
        ...state,

        errorChatBox: action.error,
        loadingChatBox: false,
      };

    // Get Unread Inbox
    case appointmentConstants.GET_NEWMESSAGES_REQUEST:
      return {
        ...state,
      };

    case appointmentConstants.GET_NEWMESSAGES_SUCCESS:
      return {
        ...state,
        activeChatBoxMessages: state.activeChatBoxMessages.concat(
          action.data.message_list
        ),
      };
    case appointmentConstants.GET_NEWMESSAGES_FAILURE:
      return {
        ...state,
      };

    // Send Message
    case appointmentConstants.SEND_MESSAGE_REQUEST:
      return {
        ...state,

        errorSendMessage: "",
        loadingSendMessage: true,
        activeChatLastRetrieved: new Date(),
      };

    case appointmentConstants.SEND_MESSAGE_SUCCESS:
      return {
        ...state,

        errorSendMessage: "",
        loadingSendMessage: false,
        activeChatBoxMessages: [
          ...state.activeChatBoxMessages,
          { message: action.data.new_message },
        ],
        activeChatLastRetrieved: new Date(action.data.new_message.sent_at),
      };
    case appointmentConstants.SEND_MESSAGE_FAILURE:
      return {
        ...state,
        errorSendMessage: action.error,
        loadingSendMessage: false,
      };

    // Editing any appointment details
    case appointmentConstants.EDITAPPOINTMENT_REQUEST:
      return {
        ...state,

        error: "",
        loading: true,
      };
    case appointmentConstants.EDITAPPOINTMENT_SUCCESS:
      return {
        ...state,

        error: "",
        loading: false,
        appointment: action.data.appointment,
      };
    case appointmentConstants.EDITAPPOINTMENT_FAILURE:
      return {
        ...state,

        loading: false,
        error: action.error,
      };

    /*
     * ADD PROCEDURE
     *   add a procedure to the appointment (treatment plan)
     */
    case appointmentConstants.ADDPROCEDURE_REQUEST:
      return {
        ...state,

        procLoading: true,
        procFormClear: false,
      };

    case appointmentConstants.ADDPROCEDURE_SUCCESS:
      let newChartObj = {};
      const newProc = action.data.procedure;
      const newToothChart = state.charting;
      const newToothList = [
        ...new Set(state.appointment.toothList.concat([newProc.tooth])),
      ];

      const newToothObj = { ...state.appointment.toothObj };

      const surfaceList = newProc.surface.split("");
      if (newProc.tooth) {
        // add new proceudre to tooth chart
        newToothChart[newProc.tooth] = [
          ...new Set(surfaceList.concat(newToothChart[newProc.tooth])),
        ];
        newToothObj[newProc.tooth] =
          newProc.tooth in newToothObj
            ? [...newToothObj[newProc.tooth], newProc]
            : [newProc];
        if (newProc.tooth == state.chartingCurrentTooth) {
          newChartObj = {
            charting: newToothChart,
            chartingCurrentRegions: newToothChart[newProc.tooth],
            chartingCurrentToothProcedures: newToothObj[newProc.tooth],
            chartingCurrentProcedure: null,
          };
        }
      }
      return {
        ...state,

        procLoading: false,
        procFormOpen: false,
        procForm: { ...initProcForm },
        appointment: {
          ...state.appointment,
          procedures: [
            ...state.appointment["procedures"],
            action.data.procedure,
          ],
          toothObj: newToothObj,
          toothList: newToothList,
        },
        procError: "",
        procFormClear: true,
        ...newChartObj,
        // add something to clear the form?
      };

    case appointmentConstants.ADDPROCEDURE_FAILURE:
      return {
        ...state,

        procError: action.error,
        procLoading: false,
        procFormClear: false,
      };

    // retrieve any unassigned procedures for patient (for ease of use)
    case appointmentConstants.GETPATIENT_UNASSIGNEDPROCEDURES_REQUEST:
      return {
        ...state,
        appointment: {
          ...state.appointment,
          ["procedures"]: state.appointnment["procedures"],
          ["patient"]: state.patient,
        },
      };

    case appointmentConstants.GETPATIENT_UNASSIGNEDPROCEDURES_SUCCESS:
      return {
        ...state,
        dentistList: action.dentistList,
      };

    case appointmentConstants.GETPATIENT_UNASSIGNEDPROCEDURES_FAILURE:
      return {
        ...state,
        loading: true,
      };

    // editing procedure
    case appointmentConstants.EDITPROCEDURE_REQUEST:
      return {
        ...state,
        dentistList: action.dentistList,
      };

    case appointmentConstants.EDITPROCEDURE_SUCCESS:
      return {
        ...state,
        dentistList: action.dentistList,
      };

    case appointmentConstants.EDITPROCEDURE_FAILURE:
      return {
        ...state,
        loading: true,
      };

    // remove a procedure
    case appointmentConstants.DELETEPROCEDURE_REQUEST:
      return {
        ...state,
        appointments: [...state.appointments, action.data.appointment],
      };
    case appointmentConstants.DELETEPROCEDURE_SUCCESS:
      return {
        ...state,
      };
    case appointmentConstants.DELETEPROCEDURE_FAILURE:
      return {
        ...state,
      };

    // change appointment status to confirmed, completed
    case appointmentConstants.CHANGEAPPOINTMENT_STATUS_REQUEST:
      return {
        ...state,
      };
    case appointmentConstants.CHANGEAPPOINTMENT_STATUS_SUCCESS:
      return {
        ...state,
      };
    case appointmentConstants.CHANGEAPPOINTMENT_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
