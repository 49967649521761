import { settingsConstants } from '../_constants';

const initialState = {
  loading: false,
  procedureCodeList: [],
  procedureStatusList: [],
  conditionList: [],
  providerList: [],
  accountsList: [],
  patientList: [],
  operatoryList: [],
  error: "",
  accountsLoading: false,
  operatoriesLoading: false,
  operatoriesError: false,
  accountsError: "",
  searchLoading: false,
  searchList: [],
  searchError: "",
  adminCurrentDentalPractice: "",

}
export function settings(state = initialState, action) {
  switch (action.type) {
    case settingsConstants.SET_SETTINGVALUE:
      return {
        ...state,
        ...action.data
      }

    case settingsConstants.GET_FORMDATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: ""
      }

    case settingsConstants.GET_FORMDATA_SUCCESS:
      return {
        ...state,
        error: "",
        procedureCodeList: action.data.code_list,
        procedureStatusList: action.data.procedurestatus_list,
        conditionList: action.data.condition_list,
        loading: false
      }

    case settingsConstants.GET_FORMDATA_FAILURE:
      return {
        ...state,
        error: "Error fetching data",
        loading: false,
      }

    case settingsConstants.GET_DENTALACCOUNTS_REQUEST:
      return {
        ...state,
        accountsError: "",
        accountsLoading: true
      }

    case settingsConstants.GET_DENTALACCOUNTS_SUCCESS:
      return {
        ...state,
        accountsError: "",
        accountsLoading: false,
        accountsList: action.data.account_list
      }

    case settingsConstants.GET_DENTALACCOUNTS_FAILURE:
      return {
        ...state,
        accountsError: "Error fetching data",
        accountsLoading: false
      }


    case settingsConstants.GET_OPERATORIES_REQUEST:
      return {
        ...state,
        operatoriesError: "",
        operatoriesLoading: true
      }

    case settingsConstants.GET_OPERATORIES_SUCCESS:
      return {
        ...state,
        operatoriesError: "",
        operatoriesLoading: false,
        operatoryList: action.data.dentist_list
      }

    case settingsConstants.GET_OPERATORIES_FAILURE:
      return {
        ...state,
        operatoriesError: "Error fetching data",
        operatoriesLoading: false
      }

    case settingsConstants.GET_PROVIDERS_REQUEST:
      return {
        ...state,
        providersError: "",
        providersLoading: true
      }

    case settingsConstants.GET_PROVIDERS_SUCCESS:
      return {
        ...state,
        providersError: "",
        providersLoading: false,
        providerList: action.data.dentist_list
      }

    case settingsConstants.GET_PROVIDERS_FAILURE:
      return {
        ...state,
        providersError: "Error fetching data",
        providersLoading: false
      }

    case settingsConstants.GET_PATIENTS_REQUEST:
      return {
        ...state,
        patientsLoading: true,
        patientsError: ""
      }

    case settingsConstants.GET_PATIENTS_SUCCESS:
      return {
        ...state,
        patientsError: "",
        patientsLoading: false,
        patientsList: action.data.patient_list.map(patient => ({value:patient.id,label:patient.first_name+" "+patient.last_name}))

      }

    case settingsConstants.GET_PATIENTS_FAILURE:
      return {
        ...state,
        patientsError: "Error fetching data",
        patientsLoading: false
      }
    case settingsConstants.GET_SEARCH_REQUEST:
      return {
        ...state,
        searchLoading: true,
        searchError: ""
      }

    case settingsConstants.GET_SEARCH_SUCCESS:
      return {
        ...state,
        searchError: "",
        searchLoading: false,
        searchList: action.data.search_list

      }

    case settingsConstants.GET_SEARCH_FAILURE:
      return {
        ...state,
        searchError: "Error fetching data",
        searchLoading: false
      }
    default:
      return state
  }
}
