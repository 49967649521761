import { useState } from 'react';
import { BASE_URL } from 'js/_config';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { customAlphabet } from 'nanoid';
import {
  Flex, Box, Center, Text, VStack, FormErrorMessage,
  Input, InputGroup, FormHelperText, FormControl, FormLabel
} from '@chakra-ui/react';

import { useNavigatorLanguage } from 'js/_hooks/useNavigatorLanguage';

import { FormattedMessage, useIntl } from 'react-intl';

import PrevArrowIcon from 'images/icons/PrevArrowIcon';

import { LineClinicDetailsQuery } from './useLineClinicDetailsQuery';

import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from "final-form";

import { required, isFieldErrorfromMeta, getErrorMessage } from 'js/_libs/forms';
import { InputField } from 'js/_components/Forms/InputField';
import { useAuth } from '../_libs/auth';
import { useNotificationStore } from 'js/Notifications/store';
import backgroundImage from '../../images/dental-lab-background-min.png';

import { upperFirst } from 'lodash';
import { getIntlLocale } from '../Intl';
import { isBlank } from 'js/_helpers/utils';
import { userService } from 'js/_services';
import { LineLogin } from 'js/_components/LineLogin';
import { TextAreaField } from 'js/_components/Forms/TextAreaField';

export const LineLinkAccount = () => {
  const location = useLocation();
  const history = useHistory();
  const urlParams = useParams();
  const { language, setNavigatorLanguage } = useNavigatorLanguage();
  const { login, isLoggingIn } = useAuth();
  const [ isGoogleLoading, setGoogleLoading ] = useState(false);
  const [ googleError, setGoogleError ] = useState(false);
  const [ generalFormError, setGeneralFormError ] = useState(false);
  const intlLocale = useSelector(getIntlLocale);
  const { formatMessage } = useIntl();

  console.log("urlParams?.uid", urlParams)
  const query = LineClinicDetailsQuery({
    clinicId: urlParams?.uid, config: {}
  })


  const nanoid = customAlphabet(
    'useandom26T198340PX75pxJACKVERYMINDBUSHWOLFGQZbfghjklqvwyzrict',
    15
  );
  const state = nanoid();

  const onSuccessfulLogin = (from) => {
    if (from && from !== '/login'){
      history.push(location.state.from.pathname)
    } else {
      history.push('/');
    }
  }

  const handleSubmit = () => {

  }

  const setIdToken = async (data) => {
    console.log('setIdToken ', data);
    let from = location?.state?.from?.pathname
    const authCredentials = {
      code: data.access_token,
      user_id: data.sub,
      nonce: data.nonce,
      id_token: data.id_token,
      provider: 'line',
      friendship_status_changed: data.friendship_status_changed,
    };
    try{
      await userService.connectLine(authCredentials,  urlParams?.uid, urlParams?.token);
      onSuccessfulLogin(from);
    } catch(err){
      return {
        ...err,
        [FORM_ERROR] : upperFirst('general error')
      }
    }
  };

  return (
    <Box
      height={'100vh'}
      bgImage={backgroundImage}
      bgSize={'cover'}
      fontFamily={'Poppins'}
    >
      <Center h="100%">
        <Flex
          justify={'flex-start'}
          align={'center'}
          background={'#FFF'}
          px={[8, 20]}
          pb={50}
          pt={[110, 50]}
          borderRadius={'50px'}
          boxShadow={'0px 8px 10px rgba(0, 0, 0, 0.25);'}
          minH={['full', 700]}
          minW={['full', 550]}
          direction={'column'}
        >
        <Form
          onSubmit={handleSubmit}
          initialValues = {{ 
            dental_practice: query.data?.practice_name,
            address: query.data?.address,
            email: query.data?.email
          }}
          //mutators={{ setFieldData }}
          render = {({
            handleSubmit,
            reset,
            submitting,
            pristine,
            validating,
            values
          }) => (
            <form
              onSubmit={handleSubmit}
              style={{width:'100%'}}
            >
            <Flex direction={'column'} justify={'space-between'}>
              <Box>
                <Flex justify={'flex-start'} align={'center'}>
                  <PrevArrowIcon 
                    onClick={()=>history.push('/login')}
                    _hover={{
                      cursor:'pointer'
                    }}
                    height={'15px'} width={'15px'} color={'black'} />
                  <Text ml={10} color={'#1B2B29'} fontWeight={'600'} fontSize={'30px'}>
                    <FormattedMessage 
                      id={'linelinkaccount.header'}
                      description={'line link account header'}
                      defaultMessage={'Connect Your Account'}
                    />
                  </Text>
                </Flex>
                <VStack spacing={10} mt={10}>
                  <Field name="email" validate={required}>
                    {({ input, meta }) => (
                      <InputField 
                        borderColor={'rgba(123, 136, 135, 0.5)'}
                        borderRadius={'15px'}
                        inputProps={{
                          borderRadius: '15px',
                          minH: '50px'
                        }}
                        input={input}
                        meta={meta}
                        label={upperFirst('email address')}
                      />
                    )}
                  </Field>
    
                  <Field name="dental_practice" validate={required}>
                    {({ input, meta }) => (
                      
                      <FormControl id={input.name} isInvalid={isFieldErrorfromMeta(meta)} >
                      <FormLabel align="center" fontSize={"14px"} pb={1}>
                        <FormattedMessage 
                          id={'loginpage.authorizationCode.label'}
                          description={'loginpage authorization code label'}
                          defaultMessage={upperFirst('clinic name')}
                        />
                      </FormLabel>
                      <InputGroup size="md">
                        <Input
                          {...input} 
                          borderColor={'rgba(123, 136, 135, 0.5)'}
                          borderRadius={'15px'}
                          pr="80px"
                          type={"text"}
                          isDisabled={true}
                          minH="50px" 
                          fontSize={"14px"}
                          background="white"
                          placeholder={upperFirst('clinic name')} 
                        />
                      </InputGroup>
                      {
                        getErrorMessage(meta) 
                          ? <FormErrorMessage>{getErrorMessage(meta)}</FormErrorMessage>
                          : <FormHelperText></FormHelperText>
                      }
                      </FormControl>
                    )}
                  </Field>
                  
                  <Field name="address" validate={required}>
                    {({ input, meta }) => (
                      <TextAreaField 
                        borderRadius={'15px'}
                        inputProps={{
                          borderRadius: '15px',
                          borderColor: 'rgba(123, 136, 135, 0.5)'
                        }}
                        input={input}
                        isDisabled={true}
                        fontSize={'14px'}
                        meta={meta}
                        label={upperFirst(formatMessage({
                          id: 'linklinkaccount.address.label',
                          description: 'linelinkaccount address label',
                          defaultMessage: 'Address'
                        }))}
                      />
                    )}
                  </Field>
                </VStack>
              </Box>
              <Flex mt={10}>
                <LineLogin
                  clientID={`${process.env.REACT_APP_LINE_ID}`}
                  clientSecret={`${process.env.REACT_APP_LINE_KEY}`}
                  state={state}
                  scope={'profile openid email'}
                  setIdToken={setIdToken}
                  locale={language}
                  redirectURI={`${BASE_URL}/auth/login/${language}`}
                  isLoading={isLoggingIn || query.isLoading || query.isFetching}
                  text={formatMessage({'id': 'line login label', 'description': 'line login label', 'defaultMessage':'LINE Login' })}
                />
              </Flex>
              </Flex>
            </form>
          )} 
          />
        </Flex>
      </Center>
    </Box>
  )

}