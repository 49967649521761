
import { Icon } from '@chakra-ui/react';

const ChevronDownIcon = (props) => {
  const iconWidth = props?.width || 12;
  const iconHeight = props?.height || 8;
  return (
    <Icon 
      viewBox="0 0 12 8"
      color={'#7B8887'}
      fill="none"
      {...props}
      width={`${iconWidth}px`}
      height={`${iconHeight}px`}
    >
        <path 
            d="M1.43678 1.5L6.21839 6.5L11 1.5" 
            stroke="currentColor" 
            strokeWidth="1.5" 
            strokeLinecap="round" 
            strokeLinejoin="round"
        />
    </Icon>
  );
};

export default ChevronDownIcon;
