 import { formsConstants as constants } from '../_constants'

const initPatientForm = {
    id: null,
    first_name: "",
    middle_name: "",
    last_name: "",
    gender: "",
    date_of_birth: "",
    phone_number: "",
    email_address: "",
    social_no: "",
    address: "",
    license_number: "",
    marital_status: "",
    medical_notes: "",
    econtact_first_name: "",
    econtact_last_name: "",
    econtact_name: "",
    econtact_relation: "",
    econtact_address: "",
    econtact_phone_number: "",
    main_provider: null
}

const initPatientUploadForm = new FormData();

const initState = {
	patientFormOpen: false,
	patientForm: {...initPatientForm},
	patientFormLoading: false,
	patientFormError: "",
	redirect: false,
	patientUploadFormOpen: false,
	patientUploadForm: initPatientUploadForm,
	patientUploadFormLoading: false,
	patientUploadFormError: "",

}

export function forms(state = initState, action){
	switch (action.type){
		case constants.OPENFORM_APPOINTMENT:
			return {
				...state,
				apptIsOpen: true
			}


		case constants.CLOSEFORM_APPOINTMENT:
			return {
				...state,
				apptIsOpen: {...initPatientForm}
			}
		case constants.OPEN_PATIENTFORM:
			return {
				...state,
				patientForm: {...state.patientForm, ...action.data},
				patientFormOpen: true
			}


		case constants.CLOSE_PATIENTFORM:
			return {
				...state,
				patientFormOpen: false,
				patientForm: {...initPatientForm}
			}


		case constants.CHANGE_PATIENTFORM:
			return {
				...state,
				patientForm: {...state.patientForm ,...action.data}
			}


		case constants.SUBMIT_PATIENTFORM_REQUEST:
			return {
				...state,
				patientFormLoading: true,
				patientFormError: ""
			}

		case constants.SUBMIT_PATIENTFORM_SUCCESS:
			return {
				...state,
				patientFormLoading: false,
				patientFormError: "",
				patientForm: [], 
				patientFormOpen: false,
				redirect: '/patient/'+action.data.patient.id
			}

		case constants.SUBMIT_PATIENTFORM_FAILURE:
			return {
				...state,
				patientFormLoading: false,
				patientFormError: ""
			}


		case constants.OPEN_PATIENT_IMAGEUPLOAD:
			return {
				...state,
				patientUploadForm: initPatientUploadForm,
				patientUploadFormOpen: true
			}


		case constants.CLOSE_PATIENT_IMAGEUPLOAD:
			return {
				...state,
				patientUploadFormOpen: false,
				patientUploadForm: initPatientUploadForm
			}


		case constants.CHANGE_PATIENT_IMAGEUPLOAD:
			return {
				...state,
				patientUploadForm: action.data
			}


		case constants.SUBMIT_PATIENT_IMAGEUPLOAD_REQUEST:
			return {
				...state,
				patientUploadFormLoading: true,
				patientUploadFormError: ""
			}

		case constants.SUBMIT_PATIENT_IMAGEUPLOAD_SUCCESS:
			return {
				...state,
				patientUploadFormLoading: false,
				patientUploadFormError: "",
				patientUploadForm: initPatientUploadForm, 
				patientUploadFormOpen: false
			}

		case constants.SUBMIT_PATIENT_IMAGEUPLOAD_FAILURE:
			return {
				...state,
				patientUploadFormLoading: false,
				patientUploadFormError: ""
			}
		/* scheduling pop up forms */

		// quick patient add

		case constants.SUBMIT_QUICKPATIENTFORM_REQUEST:
			return {
				...state,
				patientUploadFormLoading: true,
				patientUploadFormError: ""
			}

		case constants.SUBMIT_QUICKPATIENTFORM_SUCCESS:
			return {
				...state,
				patientUploadFormLoading: false,
				patientUploadFormError: "",
				patientUploadForm: initPatientUploadForm, 
				patientUploadFormOpen: false
			}

		case constants.SUBMIT_QUICKPATIENTFORM_FAILURE:
			return {
				...state,
				patientUploadFormLoading: false,
				patientUploadFormError: ""
			}

		// appointment form

		case constants.SUBMIT_APPOINTMENTFORM_REQUEST:
			return {
				...state,
				patientUploadFormLoading: true,
				patientUploadFormError: ""
			}

		case constants.SUBMIT_APPOINTMENTFORM_SUCCESS:
			return {
				...state,
				patientUploadFormLoading: false,
				patientUploadFormError: "",
				patientUploadForm: initPatientUploadForm, 
				patientUploadFormOpen: false
			}

		case constants.SUBMIT_APPOINTMENTFORM_FAILURE:
			return {
				...state,
				patientUploadFormLoading: false,
				patientUploadFormError: ""
			}

		case constants.SUBMIT_MODALFORM_REQUEST:
			return {
				modalFormLoading: true,
				modalSubmitted: true
			}

		case constants.SUBMIT_MODALFORM_SUCCESS:
			return {
				modalFormLoading:false,

			}

		case constants.SUBMIT_MODALFORM_FAILURE:
			return {
				modalFormLoading:false,
				modalFormErrors: action.data.errors
			}

    default:
      return state
	}
}