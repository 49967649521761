import React, { Component } from 'react';

import { connect } from 'react-redux';
import cn from 'classnames';
import { alertActions } from '../_actions';
import { size, upperFirst, toUpper, startCase } from 'lodash';
import { Progress } from '@chakra-ui/react'
import PopupDialog, { getFooterCloseButton, PopupDialogContentText } from '../_components/Popup/PopupDialog';

import '../../css/overarchingDialog.css';

const baseCssClassName = 'OverarchingDialog';
const generalCssClassName = `${baseCssClassName}-general`;
const loadingCssClassName = `${baseCssClassName}-loading`;
const progressCssClassName = `${baseCssClassName}__progress`;
const dialogContentClassName = `${baseCssClassName}__content`;


// types for different dialogs
//
// Loading, Message (Error and General),
// Confirmation Dialogs go to ConfirmPopUp.jsx or whatever
//
//
//
//
//
/* other footer props for reference
footerProps={{
					buttons: (dialogInterface) => {
						return [
							( !isSaved && !isSaving && !hasSavingError ) && (
								<Button
									key={'save_button'}
									theme={Button.AVAILABLE_THEMES.PRIMARY}
									size={Button.AVAILABLE_SIZES.LARGE}
									onClick={this._handleChangeButtonClick}
								>{tempi18n['buttons.change']}</Button>
							),
							getFooterCloseButton(dialogInterface),
						].filter(Boolean);
					}
				}}

footerProps={{
  buttons: (dialogInterface) => [
    (
      <Button
        key={'confirm_button'}
        theme={Button.AVAILABLE_THEMES.PRIMARY}
        size={Button.AVAILABLE_SIZES.LARGE}
        onClick={() => this._handleConfirm(dialogInterface)}
      >{ ( this.props.confirmButtonText || "Yes" ) }</Button>
    ),
    (
      <Button
        key={'cancel_button'}
        theme={Button.AVAILABLE_THEMES.SECONDARY}
        size={Button.AVAILABLE_SIZES.LARGE}
        disabled={this.state.confirmIsInProgress}
        onClick={() => this._handleCancel(dialogInterface)}
      >{( this.props.cancelButtonText || tempi18n['confirm.buttons.no'] )}</Button>
    )
  ]
}}
*/

class OverarchingDialog extends Component{
  constructor(props){
    super(props);
  }

  componentDidMount(){

  }

  _handleReloadPage = () => {
    window.location.reload();
  }

  _handleDialogClose = () => {
    this.props.dispatch(alertActions.clear());
  }

  render(){

    const { intl, alert } = this.props;
    if ( size(alert) === 0 ) {
      return (<div></div>)
    }
    else {
      let footerProps = {};
      if (alert.type.startsWith('general')){
        footerProps = {
          buttons: (dialogInterface) => {
            if (alert.type.startsWith('loading')){
              return getFooterCloseButton(
                      dialogInterface,
                      {text: toUpper(intl.messages['popUp.error.button'])}
              )
            } else {
              return getFooterCloseButton(
                      dialogInterface,
                      {text: toUpper(intl.messages['popUp.error.button'])}
              )
            }
          },
        }
      }
      const alertTitle = startCase(intl.messages[`popUp.${alert.title_type || alert.type}.title`])
      console.log("alert title", alertTitle)
      const alertMessage = alert.message || upperFirst(intl.messages[`popUp.${alert.type}.message`])
      return (
        <PopupDialog
          cssClassNames={cn(baseCssClassName, {[generalCssClassName]: alert.type.startsWith('general'),
                            [loadingCssClassName]: alert.type.startsWith('loading')})}
					headerProps={{
						title: alertTitle,
					}}
					content={(dialogInterface) => (
						<PopupDialogContentText>
							<div className={dialogContentClassName} >
							<div className={progressCssClassName}
								  dangerouslySetInnerHTML={{
									__html: upperFirst(alertMessage),
								}}
							/>
							{ alert.type.startsWith('loading') && <Progress isIndeterminate />}
							</div>
						</PopupDialogContentText>
					)}
          footerProps={footerProps}
					popupProps={{
						onClose: this._handleDialogClose
					}}
				/>
      )

    }
  }

}

function mapStateToProps(state) {
    const { alert, intl } = state;
    return {
        alert,
        intl
    };
}

const connectedOverarchingDialog = connect(mapStateToProps)(OverarchingDialog);
export { connectedOverarchingDialog as OverarchingDialog };
