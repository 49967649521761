import React from 'react';
import PropTypes from 'prop-types';

const baseCssClassName = 'popup-dialog__content';
const contentWithoutPaddingCssClassName = `${baseCssClassName}-without-padding`;
const contentTextCssClassName = `${baseCssClassName}-text`;

const propTypes = {
	children: PropTypes.node,
	dialogInterface: PropTypes.object,
};

const contentWithoutPaddingPropTypes = {
	children: PropTypes.node,
	dialogInterface: PropTypes.object,
};


function PopupDialogContent (props) {
	return (
		<div className={baseCssClassName}>
			{props.children}
		</div>
	);
}

PopupDialogContent.propTypes = propTypes;


function PopupDialogContentWithoutPadding (props) {
	return (
		<div className={contentWithoutPaddingCssClassName}>
			{props.children}
		</div>
	);
}

PopupDialogContentWithoutPadding.propTypes = contentWithoutPaddingPropTypes;


function PopupDialogContentText (props) {
	return (
		<div className={contentTextCssClassName}>
			{props.children}
		</div>
	);
}

export default PopupDialogContent;

export {
	PopupDialogContentWithoutPadding,
	PopupDialogContentText,
};
