import { useState, useEffect } from "react";
import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import {
  VStack,
  Flex,
  Box,
  FormControl,
  FormLabel,
  Text,
  InputGroup,
  Input,
  InputRightElement,
  FormHelperText,
  FormErrorMessage,
  Button,
} from "@chakra-ui/react";
import { useAuth } from "../_libs/auth";

import { InputField } from "js/_components/Forms/InputField";
import {
  required,
  isFieldErrorfromMeta,
  getErrorMessage,
} from "js/_libs/forms";

import { upperFirst } from "lodash";
import { isBlank } from "js/_helpers";
import { useCountdown } from "js/_hooks/useCountdown";

import { userService } from "js/_services";

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";

import { FormattedMessage, useIntl } from "react-intl";
import { ButtonFilled } from "js/_components/Button/ButtonFilled";
import PrevArrowIcon from "images/icons/PrevArrowIcon";

export const ResetForm = ({ onBack }) => {
  const urlParams = useParams();
  const location = useLocation();
  const history = useHistory();
  const resetPasswordMatch = useRouteMatch('/reset/:uid/:token')
  const { formatMessage } = useIntl();

  const { login, isLoggingIn } = useAuth();

  const [countdown, setCountdown] = useState(60);
  const [isCounting, setIsCounting] = useState(false);

  useEffect(() => {
    let interval;

    if (isCounting && countdown > 0) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsCounting(false);
    }

    return () => clearInterval(interval);
  }, [isCounting, countdown]);

  const onSuccessfulLogin = (from) => {
    if (from && !from.includes("/login")) {
      console.log("from ", from);
      console.log("so go to... ", location.state.from.pathname);
      history.push(location.state.from.pathname);
    } else {
      console.log("from ", from);
      console.log("so go to... ", "appointments");
      history.push("/appointments");
    }
  };

  const handleSendCode = async (email = null) => {
    // send code
    console.log("send code please");
    if (!isCounting || isLoggingIn || isBlank(email)) {
    }
    if (isCounting || isLoggingIn || isBlank(email)) {
      return;
    }
    await userService.requestLoginCode({ email });
    setCountdown(60);
    setIsCounting(true);
  };

  const handleUpdate = async (values) => {
    const apiValues = {
        ...values,
        uid: resetPasswordMatch?.params?.uid,
        token: resetPasswordMatch?.params?.token
    }
    try{
        await userService.resetPassword(apiValues);
        history.push("/login")
    } catch (err){
        return {
          ...err,
          [FORM_ERROR]: "general error",
        };
    }
  }

  return (
    <Form
      onSubmit={handleUpdate}
      validate = {values => {
        const errors = {}
        if (!values.new_password1) {
          errors.new_password1 = 'form.error.required'
        }
        if (!values.new_password2) {
          errors.new_password2 = 'form.error.required'
        }
        if (values.new_password1 !== values.new_password2) {
          errors.new_password2 = 'resetForm.passwordMatch'
        }
        return errors
      }}
      initialValues={{uid: resetPasswordMatch?.params?.uid, token: resetPasswordMatch?.params?.token}}
      render={({
        handleSubmit,
        reset,
        submitting,
        submitError,
        pristine,
        validating,
        values,
      }) => (
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Flex
            h={["100%", "fit-content"]}
            direction={"column"}
            justify={["center", "space-between"]}
          >
            <Box>
              <Flex justify={"flex-start"} align={"center"}>
                <PrevArrowIcon
                  onClick={onBack}
                  _hover={{
                    cursor: "pointer",
                  }}
                  height={"15px"}
                  width={"15px"}
                  color={"black"}
                />
                <Text
                  ml={10}
                  color={"#1B2B29"}
                  fontWeight={"600"}
                  fontSize={"30px"}
                  textTransform={"capitalize"}
                >
                  <FormattedMessage 
                    id="resetForm.reset.title"
                    defaultMessage="Reset Password"
                  />
                </Text>
              </Flex>

              <VStack spacing={10} mt={10}>
                <Field name="new_password1">
                  {({ input, meta }) => (
                    <InputField
                      borderColor={"rgba(123, 136, 135, 0.5)"}
                      borderRadius={"15px"}
                      inputProps={{
                        borderRadius: "15px",
                        minH: "50px",
                      }}
                      input={input}
                      meta={meta}
                      type="password"
                      label={formatMessage({"id": "resetForm.password1.label", "defaultMessage": "New Password"})}
                    />
                  )}
                </Field>
                <Field name="new_password2">
                  {({ input, meta }) => (
                    <InputField
                      borderColor={"rgba(123, 136, 135, 0.5)"}
                      borderRadius={"15px"}
                      inputProps={{
                        borderRadius: "15px",
                        minH: "50px",
                      }}
                      input={input}
                      meta={meta}
                      type="password"
                      label={formatMessage({"id": "resetForm.password2.label", "defaultMessage": "Confirm Password"})}
                    />
                  )}
                </Field>
              </VStack>
            </Box>
            <Flex w={'full'} align={'center'} direction={'column'} mt={'15px'}>
                <FormErrorMessage>
                    {submitError && (
                        <FormattedMessage 
                            id="resetForm.generalError"
                            defaultMessage="error"
                        />
                    )}
                </FormErrorMessage>
            
                <Field name="uid">
                  {({ input, meta }) => (
                    <FormControl isInvalid={isFieldErrorfromMeta(meta)}>
                        <Input
                        value={input.value}
                        meta={meta}
                        type="hidden"
                        />
                        <FormErrorMessage justifyContent="center">
                            <Text fontSize="14px" textAlign={'center'}>
                                <FormattedMessage 
                                    id="resetForm.uid.message"
                                    defaultMessage="Error with token"
                                />   
                            </Text>
                        </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              <Field name="token">
                  {({ input, meta }) => (
                    <FormControl isInvalid={isFieldErrorfromMeta(meta)}>
                        <Input
                        value={input.value}
                        meta={meta}
                        type="hidden"
                        />
                        <FormErrorMessage justifyContent="center">
                            <Text fontSize="14px" textAlign={'center'}>
                                <FormattedMessage 
                                    id="resetForm.tokenError.message"
                                    defaultMessage="Error with token"
                                />   
                            </Text>
                        </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
            </Flex>
            <Flex mt={4}>
              <ButtonFilled
                bg={`brandLight`}
                w={"100%"}
                py={10}
                type={"submit"}
                isLoading={submitting}
                disabled={submitting || pristine}
                textTransform={"capitalize"}
              >
                <FormattedMessage
                    id={"forgotForm.reset"}
                    defaultMessage={"Reset Password"}
                />
              </ButtonFilled>
            </Flex>
          </Flex>
        </form>
      )}
    />
  );
};
