import { Icon } from "@chakra-ui/react";

const SettingIcon = (props) => {
  const iconWidth = props?.width || 19;
  const iconHeight = props?.height || 19;
  return (
    <Icon
      viewBox="0 0 22 22"
      color={"white"}
      {...props}
      width={`${iconWidth}px`}
      height={`${iconHeight}px`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8204 22H10.1804C9.9523 22 9.73107 21.9221 9.55337 21.7792C9.37567 21.6362 9.25218 21.4368 9.20337 21.214L8.79637 19.33C8.25343 19.0921 7.73861 18.7946 7.26137 18.443L5.42437 19.028C5.20694 19.0973 4.97232 19.0902 4.75949 19.0078C4.54666 18.9254 4.36842 18.7727 4.25437 18.575L2.43037 15.424C2.31752 15.2261 2.27516 14.9958 2.31021 14.7708C2.34527 14.5457 2.45568 14.3392 2.62337 14.185L4.04837 12.885C3.98357 12.2961 3.98357 11.7019 4.04837 11.113L2.62337 9.816C2.45544 9.66177 2.34489 9.45507 2.30982 9.22978C2.27476 9.00449 2.31726 8.77397 2.43037 8.576L4.25037 5.423C4.36442 5.22532 4.54266 5.07259 4.75549 4.99019C4.96832 4.90778 5.20294 4.90066 5.42037 4.97L7.25737 5.555C7.50137 5.375 7.75537 5.207 8.01737 5.055C8.27037 4.913 8.53037 4.784 8.79637 4.669L9.20437 2.787C9.25294 2.5642 9.3762 2.36469 9.55372 2.22155C9.73123 2.07841 9.95234 2.00024 10.1804 2H13.8204C14.0484 2.00024 14.2695 2.07841 14.447 2.22155C14.6245 2.36469 14.7478 2.5642 14.7964 2.787L15.2084 4.67C15.7506 4.90927 16.2653 5.20668 16.7434 5.557L18.5814 4.972C18.7987 4.90292 19.0331 4.91017 19.2457 4.99256C19.4583 5.07495 19.6364 5.22753 19.7504 5.425L21.5704 8.578C21.8024 8.985 21.7224 9.5 21.3774 9.817L19.9524 11.117C20.0172 11.7059 20.0172 12.3001 19.9524 12.889L21.3774 14.189C21.7224 14.507 21.8024 15.021 21.5704 15.428L19.7504 18.581C19.6363 18.7787 19.4581 18.9314 19.2453 19.0138C19.0324 19.0962 18.7978 19.1033 18.5804 19.034L16.7434 18.449C16.2665 18.8003 15.752 19.0975 15.2094 19.335L14.7964 21.214C14.7476 21.4366 14.6243 21.6359 14.4468 21.7788C14.2693 21.9218 14.0483 21.9998 13.8204 22ZM11.9964 8C10.9355 8 9.91809 8.42143 9.16795 9.17157C8.4178 9.92172 7.99637 10.9391 7.99637 12C7.99637 13.0609 8.4178 14.0783 9.16795 14.8284C9.91809 15.5786 10.9355 16 11.9964 16C13.0572 16 14.0747 15.5786 14.8248 14.8284C15.5749 14.0783 15.9964 13.0609 15.9964 12C15.9964 10.9391 15.5749 9.92172 14.8248 9.17157C14.0747 8.42143 13.0572 8 11.9964 8Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default SettingIcon;
