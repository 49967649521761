import { citadelConstants } from './citadel.constants';

// from files, can get the number of steps
//
const initState = {
  loadingState: 'init',
  url: undefined,
  files: [],
  models: {},
  assetsBundle: undefined,
  spinnerOn: false,
  stepState: 0,
  modelsLoaded: [],
}

export function citadel(state=initState, action={}){
  switch (action.type){
    case  citadelConstants.LOADINGSTATE_DOWNLOADINGZIP:
      return {
        ...state,
        loadingState: 'downloadingZip',
        stepState: 0,
        spinnerOn: true
      }
    case  citadelConstants.LOADINGSTATE_DOWNLOADINGZIP_ERROR:
      return {
        ...state,
        stepState: -1,
        spinnerOn: true
      }
    case  citadelConstants.LOADINGSTATE_DOWNLOADINGZIP_SUCCESS:
      return {
        ...state,
        ...action.data,
        stepState: 1,
        spinnerOn: true
      }
    case  citadelConstants.LOADINGSTATE_UNZIPPINGASSETS:
      return {
        ...state,
        loadingState: 'unzippingAssets',
        stepState: 0,
        spinnerOn: true
      }
    case  citadelConstants.LOADINGSTATE_UNZIPPINGASSETS_SUCCESS:
      return {
        ...state,
        ...action.data,
        stepState: 1,
        spinnerOn: true
      }
    case  citadelConstants.LOADINGSTATE_UNZIPPINGASSETS_ERROR:
      return {
        ...state,
        stepState: -1,
        spinnerOn: false
      }
    case  citadelConstants.LOADINGSTATE_BUILDINGMODELS:
      return {
        ...state,
        loadingState: 'buildingModels',
        stepState: 0,
        spinnerOn: true
      }
    case  citadelConstants.LOADINGSTATE_BUILDINGMODELS_PROCESSING:
      return {
        ...state,
        stepState: 2
      }
    case citadelConstants.LOADINGSTATE_BUILDINGMODELS_LOADSINGLEMODEL:
      return {
        ...state,
        modelsLoaded: [...state.modelsLoaded, action.data]
      }
    case  citadelConstants.LOADINGSTATE_BUILDINGMODELS_SUCCESS:
      return {
        ...state,
        ...action.data,
        stepState: 1,
        spinnerOn: true
      }
    case  citadelConstants.LOADINGSTATE_BUILDINGMODELS_ERROR:
      return {
        ...state,
        stepState: -1,
        spinnerOn: false
      }
    case citadelConstants.LOADINGSTATE_BUILDINGMODELS_COMPLETE:
      return {
        ...state,
        stepState: 1,
        spinnerOn: true
      }
    case citadelConstants.LOADINGSTATE_MODELCOMPLETE:
      return {
        ...state,
        loadingState: 'modelComplete',
        spinnerOn: false,
        stepState: 0
      }
    case citadelConstants.UNMOUNT_ALL:
      return {
        ...initState
      }
    default:
      return state
  }
}
