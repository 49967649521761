export const settingsConstants = {
  SET_SETTINGVALUE: 'SETTINGS_SET_SETTINGVALUE',

	GET_DENTALACCOUNTS_REQUEST: 'SETTINGS_GET_DENTALACCOUNTS_REQUEST',
	GET_DENTALACCOUNTS_SUCCESS: 'SETTINGS_GET_DENTALACCOUNTS_SUCCESS',
	GET_DENTALACCOUNTS_FAILURE: 'SETTINGS_GET_DENTALACCOUNTS_FAILURE',

	GET_PROVIDERS_REQUEST: 'SETTINGS_GET_PROVIDERS_REQUEST',
	GET_PROVIDERS_SUCCESS: 'SETTINGS_GET_PROVIDERS_SUCCESS',
	GET_PROVIDERS_FAILURE: 'SETTINGS_GET_PROVIDERS_FAILURE',

	GET_PATIENTS_REQUEST: 'SETTINGS_GET_PATIENTS_REQUEST',
	GET_PATIENTS_SUCCESS: 'SETTINGS_GET_PATIENTS_SUCCESS',
	GET_PATIENTS_FAILURE: 'SETTINGS_GET_PATIENTS_FAILURE',

	GET_SEARCH_REQUEST: 'SETTINGS_GET_SEARCH_REQUEST',
	GET_SEARCH_SUCCESS: 'SETTINGS_GET_SEARCH_SUCCESS',
	GET_SEARCH_FAILURE: 'SETTINGS_GET_SEARCH_FAILURE',

	GET_FORMDATA_REQUEST: 'SETTINGS_GET_FORMDATA_REQUEST',
	GET_FORMDATA_SUCCESS: 'SETTINGS_GET_FORMDATA_SUCCESS',
	GET_FORMDATA_FAILURE: 'SETTINGS_GET_FORMDATA_FAILURE',

	GET_OPERATORIES_REQUEST: 'SETTINGS_GET_OPERATORIES_REQUEST',
	GET_OPERATORIES_SUCCESS: 'SETTINGS_GET_OPERATORIES_SUCCESS',
	GET_OPERATORIES_FAILURE: 'SETTINGS_GET_OPERATORIES_FAILURE',

	// repeating settings pages...
	//
	// fee schedules, coverage tables, insurance claim, procedure codes, ledger options, patient comm, print, recare

	GET_FEESCHEDULES_REQUEST: 'SETTINGS_GET_FEESCHEDULES_REQUEST',
	GET_FEESCHEDULES_SUCCESS: 'SETTINGS_GET_FEESCHEDULES_SUCCESS',
	GET_FEESCHEDULES_FAILURE: 'SETTINGS_GET_FEESCHEDULES_FAILURE',

	GET_COVERAGETABLES_REQUEST: 'SETTINGS_GET_COVERAGETABLES_REQUEST',
	GET_COVERAGETABLES_SUCCESS: 'SETTINGS_GET_COVERAGETABLES_SUCCESS',
	GET_COVERAGETABLES_FAILURE: 'SETTINGS_GET_COVERAGETABLES_FAILURE',

	GET_INSURANCECLAIM_REQUEST: 'SETTINGS_GET_INSURANCECLAIM_REQUEST',
	GET_INSURANCECLAIM_SUCCESS: 'SETTINGS_GET_INSURANCECLAIM_SUCCESS',
	GET_INSURANCECLAIM_FAILURE: 'SETTINGS_GET_INSURANCECLAIM_FAILURE',

	GET_PROCEDURES_REQUEST: 'SETTINGS_GET_PROCEDURES_REQUEST',
	GET_PROCEDURES_SUCCESS: 'SETTINGS_GET_PROCEDURES_SUCCESS',
	GET_PROCEDURES_FAILURE: 'SETTINGS_GET_PROCEDURES_FAILURE',


	GET_LEDGEROPTIONS_REQUEST: 'SETTINGS_GET_LEDGEROPTIONS_REQUEST',
	GET_LEDGEROPTIONS_SUCCESS: 'SETTINGS_GET_LEDGEROPTIONS_SUCCESS',
	GET_LEDGEROPTIONS_FAILURE: 'SETTINGS_GET_LEDGEROPTIONS_FAILURE',

	GET_PATIENTCOMMUNICATIONS_REQUEST: 'SETTINGS_GET_PATIENTCOMMUNICATIONS_REQUEST',
	GET_PATIENTCOMMUNICATIONS_SUCCESS: 'SETTINGS_GET_PATIENTCOMMUNICATIONS_SUCCESS',
	GET_PATIENTCOMMUNICATIONS_FAILURE: 'SETTINGS_GET_PATIENTCOMMUNICATIONS_FAILURE',

	GET_PRINTSETTINGS_REQUEST: 'SETTINGS_GET_PRINTSETTINGS_REQUEST',
	GET_PRINTSETTINGS_SUCCESS: 'SETTINGS_GET_PRINTSETTINGS_SUCCESS',
	GET_PRINTSETTINGS_FAILURE: 'SETTINGS_GET_PRINTSETTINGS_FAILURE',

	GET_RECARE_REQUEST: 'SETTINGS_GET_RECARE_REQUEST',
	GET_RECARE_SUCCESS: 'SETTINGS_GET_RECARE_SUCCESS',
	GET_RECARE_FAILURE: 'SETTINGS_GET_RECARE_FAILURE',


	}
