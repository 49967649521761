import React from 'react';
import { lazyImport } from '../_helpers/lazyImport';


import { Switch, Redirect } from 'react-router-dom';

import { PrivateRoute } from '../_components';

const { RecipientsList } = lazyImport(()=> import("./Recipients/RecipientsList"), "RecipientsList")
const { StatsViews } =lazyImport(()=> import("./StatsViews/StatsViews"), "StatsViews")
const { ScheduledMessagesList } =lazyImport(()=> import("./Messages/MessagesList"), "ScheduledMessagesList")
const { TempTreatmentPlanPaymentsList } =lazyImport(()=> import("./TempTreatmentPlans/TempTreatmentPlanPaymentsList"), "TempTreatmentPlanPaymentsList")
const { ScanAppointmentsList }  = lazyImport(()=> import("./TempAppointments/ScanAppointmentsList"), "ScanAppointmentsList")
const { ClinicRecipientsList }  = lazyImport(()=> import("./ClinicRecipientsList/ClinicRecipientsList"), "ClinicRecipientsList")

//const { RecipientsList } = lazyImport(()=> import("./Recipients/RecipientsList"), "RecipientsList")
//const { ScheduledMessagesList } =lazyImport(()=> import("./Messages/MessagesList"), "ScheduledMessagesList")

const ScheduledMessagesRoutes = () => {
  return (
    <Switch>
      <PrivateRoute exact path="/scheduled/clinicrecipients" component={ClinicRecipientsList} />
      <PrivateRoute exact path="/scheduled/treatmentplans/progress" component={TempTreatmentPlanPaymentsList} />
      <PrivateRoute exact path="/scheduled/recipients" component={RecipientsList} />
      <PrivateRoute exact path="/scheduled/stats" component={StatsViews} />
      <PrivateRoute exact path="/scheduled/messages" component={ScheduledMessagesList} />
      <PrivateRoute exact path="/scheduled/appointments" component={ScanAppointmentsList} />
      <PrivateRoute path="/scheduled">
        <Redirect to={{pathname: '/scheduled/appointments'}} />
      </PrivateRoute>
    </Switch>
  )
}

export default ScheduledMessagesRoutes;