import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, Box, Flex, Text, Button, 
  Menu, MenuButton, MenuItem, MenuList, Stack } from "@chakra-ui/react";
import { userActions } from '../../_actions';
import { useHistory, Link as ReactRouterLink  } from 'react-router-dom';
import { FiChevronDown } from 'react-icons/fi';

import { useAuth } from '../../_libs/auth';

import Logo from "./Logo";

const NavBar = (props) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <NavBarContainer {...props}>
      <Logo
        w="250px"
        color={["white", "white", "primary.500", "primary.500"]}
        onClick={() =>history.push("/scheduled")}
        _hover={{cursor:'pointer'}}
      />
      <MenuToggle toggle={toggle} isOpen={isOpen} />
      <MenuLinks isOpen={isOpen} />
    </NavBarContainer>
  );
};

const CloseIcon = () => (
  <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <title>Close</title>
    <path
      fill="white"
      d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
    />
  </svg>
);

const MenuIcon = () => (
  <svg
    width="24px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="white"
  >
    <title>Menu</title>
    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  </svg>
);

const MenuToggle = ({ toggle, isOpen }) => {
  return (
    <Box display={{ base: "block", md: "none" }} onClick={toggle}>
      {isOpen ? <CloseIcon /> : <MenuIcon />}
    </Box>
  );
};

const NavMenuItem = ({ children, isLast, to = null, ...rest }) => {
  if (to){
    return (
      <Link as={ReactRouterLink} to={to}>
        <Text display="block" {...rest}>
          {children}
        </Text>
      </Link>
    );
  }
  return(
    <>{children}</>
  )
};

// HACKY add right here
const ComfyPlanNavMenu = ({isStaff}) => {
  if (isStaff){
    return (
      <Menu>
        <MenuButton>
          <Link as={Flex} align={'center'}>
            <Text mr={'5px'} display="block">
              {'Treatment Plans'}
            </Text>
            <FiChevronDown />
          </Link>
        </MenuButton>
        <MenuList>
          <MenuItem px={['10px']} as={ReactRouterLink} to='/scheduled/treatmentplans/plans'>
            <Text color={'brandDark.500'} display="block" py={['8px']} px={['10px']}>
              {'Treatments'}
            </Text>
          </MenuItem>
          <MenuItem px={['10px']}  as={ReactRouterLink} to='/scheduled/treatmentplans/progress?progressType=payments'>
            <Text color={'brandDark.500'} display="block" py={['8px']} px={['10px']}>
              {'Payments'}
            </Text>
          </MenuItem>
          <MenuItem px={['10px']}  as={ReactRouterLink} to='/scheduled/treatmentplans/progress?progressType=shipping'>
            <Text color={'brandDark.500'} display="block" py={['8px']} px={['10px']}>
              {'Shipping'}
            </Text>
          </MenuItem>
        </MenuList>
      </Menu>
    )
  } else {
    return (
      <Menu>
        <MenuButton>
          <Link as={Flex} align={'center'}>
            <Text mr={'5px'} display="block">
              {'Treatment Plans'}
            </Text>
            <FiChevronDown />
          </Link>
        </MenuButton>
        <MenuList>
          <MenuItem px={['10px']}  as={ReactRouterLink} to='/scheduled/treatmentplans/progress?progressType=payments'>  
            <Text color={'brandDark.500'} display="block" py={['8px']} px={['10px']}>
              {'Payments'}
            </Text>
          </MenuItem>
          <MenuItem px={['10px']}  as={ReactRouterLink} to='/scheduled/treatmentplans/progress?progressType=shipping'>
            <Text color={'brandDark.500'} display="block" py={['8px']} px={['10px']}>
              {'Shipping'}
            </Text>
          </MenuItem>
        </MenuList>
      </Menu>
    )

  }
}

const ScheduledMessageNavMenu = () => {
  return (
    <Menu>
      <MenuButton>
        <Link as={Flex} align={'center'}>
          <Text mr={'5px'} display="block">
            {'Scheduled Messages'}
          </Text>
          <FiChevronDown />
        </Link>
      </MenuButton>
      <MenuList>
        <MenuItem px={['10px']}  as={ReactRouterLink} to='/scheduled/messages'>  
          <Text color={'brandDark.500'} display="block">
            {'Messages'}
          </Text>
        </MenuItem>
        <MenuItem px={['10px']}  as={ReactRouterLink} to='/scheduled/recipients'>  
          <Text color={'brandDark.500'} display="block">
            {'Recipients'}
          </Text>
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

const MenuLinks = ({ isOpen }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { logout, user } = useAuth();

  const handleLogout =  () => {
    logout();
    //dispatch(userActions.logout());
    //history.push("/login");
  }

  return (
    <Box
      display={{ base: isOpen ? "block" : "none", md: "block" }}
      flexBasis={{ base: "100%", md: "auto" }}
    >
      <Stack
        spacing={9}
        align="center"
        justify={["center", "space-between", "flex-end", "flex-end"]}
        direction={["column", "row", "row", "row"]}
        pt={[6, 4, 0, 0]}
      >
        <NavMenuItem>
          <ScheduledMessageNavMenu/>
        </NavMenuItem>
        <NavMenuItem to="/scheduled/appointments">Appointments</NavMenuItem>
        <NavMenuItem>
          <ComfyPlanNavMenu isStaff={user?.user?.is_staff} />
        </NavMenuItem>
        <NavMenuItem to="/scheduled/clinicrecipients">Clinics</NavMenuItem>
        { user?.user?.is_staff && <NavMenuItem to="/scheduled/stats">View Stats</NavMenuItem>}
        <NavMenuItem isLast>
          <Button
            size={'lg'}
            onClick={handleLogout}
            variant={'outline'}
            color={["white", "white", "white", "white"]}
            bg={["primary", "primary", "primary", "primary"]}
            borderColor={'white'}
            _hover={{
              bg: ["primary.100", "primary.100", "primary.600", "primary.600"]
            }}
          >
            Logout
          </Button>
        </NavMenuItem>
      </Stack>
    </Box>
  );
};

const NavBarContainer = ({ children, ...props }) => {
  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      py={9}
      px={12}
      boxShadow="rgba(0, 0, 0, 0.5) 0px 2px 14px 0px"
      bg={["primary", "primary", "primary", "primary"]}
      color={["white", "white", "white", "white"]}
      {...props}
    >
      {children}
    </Flex>
  );
};

export default NavBar;