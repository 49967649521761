import { useQuery } from 'react-query';

import { queryConfig, queryClient } from "../../_libs/react-query";

import { fetchAdminAppointmentRequestCollection } from './_services';

export const QUERY_KEY_NAME = 'AdminAppointmentRequestList';

export const getQuery = ({query}) => {
  return fetchAdminAppointmentRequestCollection(query)
};

const newQueryConfig = {
    ...queryConfig, 
    queries : {
      ...queryConfig.queries, 
      refetchOnMount: false
    },
    refetchOnMount: false
};

export const refetchAdminAppointmentRequests = () => {
  queryClient.refetchQueries([QUERY_KEY_NAME], {dpid: null});
};


export const GetAdminAppointmentRequestList = ({query=null, config={}}) => {
  return useQuery({
    ...newQueryConfig,
    ...config,
    queryKey: [QUERY_KEY_NAME, {query}],
    queryFn: () => getQuery({query}),
  });
}; 
