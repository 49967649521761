import { authHeader, handleResponse } from '../_helpers';
import { BASE_URL } from '../_config';

export const procedureService = {
    addProcedure,
    getPatientUnassignedProcedures

};

function addProcedure(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)

    };
    let url = '/api/d/dental_practice/appointment/createprocedure';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};

function getPatientUnassignedProcedures(patientID, apptID){
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    let url = '/api/d/dental_practice/patient/'+patientID+'/procedures?unassigned='+apptID
};
