import * as React from 'react';

export const useNavigatorLanguage = () => {
  const [locale, setCurrent] = React.useState('ko');

  if (locale == null) {
    let newLocale = 'en';
    for (let i = 0; i < navigator.languages.length - 1; i++) {
      if (navigator.languages[i].toLowerCase().startsWith('ko')) {
        newLocale = 'ko';
        break;
      } else if (navigator.languages[i].toLowerCase().startsWith('th')) {
        newLocale = 'th';
        break;
      } else if (navigator.languages[i].toLowerCase().startsWith('ja')) {
        newLocale = 'ja';
        break;
      }
    }
    setCurrent(newLocale);
  }

  const setNavigatorLanguage = React.useCallback((val) => {
    setCurrent(val);
  }, []);

  return { language: locale, setNavigatorLanguage };
};
