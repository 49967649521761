import { insuranceClaimsConstants as constants } from '../_constants';
import { insuranceClaimsService as service } from '../_services';

import { history } from '../_helpers';

import moment from 'moment';

export const insuranceClaimsActions = {
	initialize,
	changeContentView,
	changeClaimDetailView,
	getOverview,
	submitClaim,
	createClaim,
	getUnsentClaims,
	getUnresolvedClaims,
	getUnattachedProcedures
};


// sort
// const ordered = {};
// Object.keys(unordered).sort().forEach(function(key) {
//   ordered[key] = unordered[key];
// });

function normalizeUnattachedProcedures(procs){
    const unattachedProceduresNrmObj = {};
    const unattachedProceduresList = []; // this is for sorting by name or date... 
    const datePatientObj = {}; // key, procList, date, name, total
    console.log("before loop", datePatientObj);
  	for (var i=0; i < procs.length; i++){
    	// loop through and "normalize" data
    	let currentProc = procs[i];
    	let currentProcID = currentProc.id;
    	let currentProcTotal = parseFloat(currentProc.total_amount);
    	let currentProcPatient = currentProc.patient.id;
    	let currentProcPatientName = currentProc.patient.first_name+" "+currentProc.patient.last_name;
    	let currentProcDate = moment(currentProc.serviced_at);
    	let currentKey = currentProcDate.format("YYYYMMDD") + currentProcPatient;
    	if (!(currentKey in datePatientObj) ){
    		var insert = {patientID: currentProcPatient, date: currentProcDate.format("L"), name: currentProcPatientName, total: currentProcTotal, procedureIDList: [currentProcID]}
    		datePatientObj[currentKey] = insert;
    		unattachedProceduresList.push({date: currentProcDate, patientID:currentProcPatient});
    	} else {
    		datePatientObj[currentKey].procedureIDList = [currentProcID, ...datePatientObj[currentKey].procedureIDList];
    		datePatientObj[currentKey].total = datePatientObj[currentKey].total + currentProcTotal;
    	}
    	unattachedProceduresNrmObj[currentProcID] = currentProc;
    }
    return {unattachedProceduresNrmObj, datePatientObj, unattachedProceduresList}
}

function normalizeInsuranceClaims(claims){
	const claimsNrmObj = {};
	const claimsList = [];
	for (var i=0;i<claims.length;i++){
		let total_amount = 0.0;
		// get total amount for this insurance claim
		for (var j=0;j<claims[i].insuranceclaim_claimitems.length;j++){
			total_amount = total_amount+ parseFloat(claims[i].insuranceclaim_claimitems[j].invoice_item.total_amount);
		}
		claims[i].total_amount = total_amount
		claimsNrmObj[claims[i].id] = claims[i]
		claimsList.push(claims[i].id)

	}
	return {claimsNrmObj, claimsList}

}

function initialize(){
	return dispatch => {
		dispatch(initialize())
	}

	function initialize(){ return { type: constants.INITIALIZE }}
}

function changeContentView(tab_num){
	/*
		when tab is changed, need to get data according to tab
		unsent claims, unresolved claims, unattached procedures
		
	*/
	return (dispatch, getState) => {
		const currTab = getState().insuranceClaims.currentTab
		if (currTab == tab_num){
			return
		}
		if(tab_num == 1){ // unsent claims
			//history.push("")
		} else if (tab_num == 2){ // unresolved claims

		} else { // unattachedm procedure

		}
		dispatch(change(tab_num));
	}

	function change(tabNum){ return {type: constants.CHANGE_CONTENTVIEW, tabNum}}
}

function submitClaim(claimID){
	return dispatch => {
		dispatch(request());
		service.submitClaim({insurance_claim: claimID})
			.then(
				data => { dispatch( success(data), dispatch(getUnsentClaims(), dispatch(getOverview())) )},
				error => dispatch( failure(error) )
			)
	};
    function request() { return { type: constants.SUBMIT_INSURANCE_CLAIM_REQUEST } }
    function success(data) { return { type: constants.SUBMIT_INSURANCE_CLAIM_SUCCESS, data } }
    function failure(error) { return { type: constants.SUBMIT_INSURANCE_CLAIM_ERROR, error } }
};

function createClaim(patientID, idList){
	return dispatch => {
		dispatch(request());
		service.createClaim({patient: patientID, invoice_items: idList})
			.then(
				data => { dispatch( success(data), dispatch(getUnattachedProcedures(), dispatch(getOverview())) )},
				error => dispatch( failure(error) )
			)
	};
    function request() { return { type: constants.CREATE_INSURANCE_CLAIM_REQUEST } }
    function success(data) { return { type: constants.CREATE_INSURANCE_CLAIM_SUCCESS, data } }
    function failure(error) { return { type: constants.CREATE_INSURANCE_CLAIM_ERROR, error } }
};

function changeClaimDetailView(id_num){
	return (dispatch, getState) => {
		const currClaim = getState().insuranceClaims.currentClaim;
		if (id_num == currClaim){
			return
		}
		dispatch(change(id_num));
	}

	function change(claimNum){ return {type: constants.CHANGE_PAGEDETAILVIEW, claimNum}}
}

function getOverview(){
	return dispatch => {
		dispatch(request());
		service.getOverview()
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			)
	}


    function request() { return { type: constants.GET_CLAIMSOVERVIEW_REQUEST } }
    function success(data) { 
    	return { type: constants.GET_CLAIMSOVERVIEW_SUCCESS,  data } 
    }
    function failure(error) { return { type: constants.GET_CLAIMSOVERVIEW_ERROR, error } } 
}

function getUnattachedProcedures(){
	return dispatch => {
		dispatch(request());
		service.getUnattachedProcedures()
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			)
	}


    function request() { return { type: constants.GET_UNATTACHEDPROCEDURES_REQUEST } }
    function success(data) { 
    	const normalized = normalizeUnattachedProcedures(data.unattached_procedures)

    	return { type: constants.GET_UNATTACHEDPROCEDURES_SUCCESS, data: normalized } 
    }
    function failure(error) { return { type: constants.GET_UNATTACHEDPROCEDURES_ERROR, error } } 
}

function getUnresolvedClaims(){
	return dispatch => {
		dispatch(request());
		service.getUnresolvedClaims()
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			)
	}


    function request() { return { type: constants.GET_UNRESOLVEDCLAIMS_REQUEST } }
    function success(data) { 
    	const normalized = normalizeInsuranceClaims(data.insurance_claims) 
    	return { type: constants.GET_UNRESOLVEDCLAIMS_SUCCESS,  data: normalized } 
    }
    function failure(error) { return { type: constants.GET_UNRESOLVEDCLAIMS_ERROR, error } } 
}

function getUnsentClaims(){
	return dispatch => {
		dispatch(request());
		service.getUnsentClaims()
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			)
	}


    function request() { return { type: constants.GET_UNSENTCLAIMS_REQUEST } }
    function success(data) { 
    	const normalized = normalizeInsuranceClaims(data.insurance_claims) 

    	return { type: constants.GET_UNSENTCLAIMS_SUCCESS, data: normalized } 
    }
    function failure(error) { return { type: constants.GET_UNSENTCLAIMS_ERROR, error } } 
}