 import { authHeader, handleResponse } from '../_helpers';
import { BASE_URL, API_URLS } from '../_config';

export const patientSearchService = {
	doPatientSearch,
  searchPatientCollection
};

function searchPatientCollection(search_query){
  let requestUrl = API_URLS.patientsList;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader()}
  };
  let url = requestUrl.url+"?"+search_query;

  return fetch(url, requestOptions).then(handleResponse);
};

function doPatientSearch(query, offset=0) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader()}
    };
    let url = '/api/d/dental_practice/search/patients?q='+query+'&offset='+offset;

    return fetch(API_URLS+url, requestOptions).then(handleResponse);
};
