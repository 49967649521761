import { authHeader, handleResponse } from '../_helpers';
import { BASE_URL } from '../_config';
export const videochatService = {
    getVideoChatDetails
};

// Load Procedure Codes (change to search in future)

function getVideoChatDetails(apptID){
    const requestOptions = {
        method: 'GET',
        headers: authHeader(apptID)
    };
    let url = '/api/d/msg/videochat/token?appt_id='+apptID;

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
}
