import { Icon } from '@chakra-ui/react';

const DatePickerIcon = (props) => {
  return (
    <Icon width="15px" height="16px" viewBox="0 0 15 16" color={'#6BB4EB'} {...props}>
      <path
        d="M4.67065 9.5C4.45815 9.5 4.2799 9.428 4.1359 9.284C3.9924 9.1405 3.92065 8.9625 3.92065 8.75C3.92065 8.5375 3.9924 8.35925 4.1359 8.21525C4.2799 8.07175 4.45815 8 4.67065 8C4.88315 8 5.0614 8.07175 5.2054 8.21525C5.3489 8.35925 5.42065 8.5375 5.42065 8.75C5.42065 8.9625 5.3489 9.1405 5.2054 9.284C5.0614 9.428 4.88315 9.5 4.67065 9.5ZM7.67065 9.5C7.45815 9.5 7.28015 9.428 7.13665 9.284C6.99265 9.1405 6.92065 8.9625 6.92065 8.75C6.92065 8.5375 6.99265 8.35925 7.13665 8.21525C7.28015 8.07175 7.45815 8 7.67065 8C7.88315 8 8.0614 8.07175 8.2054 8.21525C8.3489 8.35925 8.42065 8.5375 8.42065 8.75C8.42065 8.9625 8.3489 9.1405 8.2054 9.284C8.0614 9.428 7.88315 9.5 7.67065 9.5ZM10.6707 9.5C10.4582 9.5 10.2802 9.428 10.1367 9.284C9.99265 9.1405 9.92065 8.9625 9.92065 8.75C9.92065 8.5375 9.99265 8.35925 10.1367 8.21525C10.2802 8.07175 10.4582 8 10.6707 8C10.8832 8 11.0612 8.07175 11.2047 8.21525C11.3487 8.35925 11.4207 8.5375 11.4207 8.75C11.4207 8.9625 11.3487 9.1405 11.2047 9.284C11.0612 9.428 10.8832 9.5 10.6707 9.5ZM2.42065 15.5C2.00815 15.5 1.6549 15.3533 1.3609 15.0597C1.0674 14.7657 0.920654 14.4125 0.920654 14V3.5C0.920654 3.0875 1.0674 2.7345 1.3609 2.441C1.6549 2.147 2.00815 2 2.42065 2H3.17065V0.5H4.67065V2H10.6707V0.5H12.1707V2H12.9207C13.3332 2 13.6864 2.147 13.9804 2.441C14.2739 2.7345 14.4207 3.0875 14.4207 3.5V14C14.4207 14.4125 14.2739 14.7657 13.9804 15.0597C13.6864 15.3533 13.3332 15.5 12.9207 15.5H2.42065ZM2.42065 14H12.9207V6.5H2.42065V14Z"
        fill={'currentColor'}
      />
    </Icon>
  );
};

export default DatePickerIcon;