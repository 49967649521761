import { formsConstants, patientConstants, schedulingConstants } from '../_constants';
import { formsService, schedulingService } from '../_services';
import { history } from '../_helpers';
import moment from 'moment';

import Notifications from 'react-notification-system-redux';

export const formsActions = {
    openAppointmentForm,
    closeAppointmentForm,
    openPatientForm,
    closePatientForm,
    changePatientForm,
    submitPatientForm,
    openPatientImageUploadForm,
    closePatientImageUploadForm,
    changePatientImageUploadForm,
    submitPatientImageUploadForm
}

function openAppointmentForm(form){
	return dispatch => {
		dispatch(open(form))
	}
	function open(data) { return {type: formsConstants.OPENFORM_APPOINTMENT } }
}

function closeAppointmentForm(){
	return dispatch => {
		dispatch(close());
	}
	function close() { return {type: formsConstants.CLOSEFORM_APPOINTMENT}}
};


function openPatientForm(initVal){
	return (dispatch, getState) => {
		let nameArray = initVal.split(" ")
		const form = {}
		if (nameArray.length == 1){
			form["first_name"] = nameArray[0]
		}
		else if (nameArray.length == 2){
			form["first_name"] = nameArray[0]
			form["last_name"] = nameArray[1]
		}
		else if (nameArray.length == 3){
			form["first_name"] = nameArray[0]
			form["middle_name"] = nameArray[1]
			form["last_name"] = nameArray[2]
		}

		dispatch(open(form));
	}
	function open(data) { return {type: formsConstants.OPEN_PATIENTFORM, data } }
};

function closePatientForm(){
	return dispatch => {
		dispatch(close());
	}
	function close() { return {type: formsConstants.CLOSE_PATIENTFORM}}
};

function changePatientForm(e, selectName){
	return dispatch => {
		const form = {};
		if (selectName != undefined){
			form[selectName] = e.value;
		} else {
			const {name, value} = e.target;
			form[name] = value;
		}
		dispatch(change(form));
	}
	function change(data) { return {type: formsConstants.CHANGE_PATIENTFORM, data} }
};

function submitPatientForm(form){
	return (dispatch, getState) => {
		dispatch(request());
		const form = {
			...getState().forms.patientForm
		}
		formsService.submitPatientForm(form)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: formsConstants.SUBMIT_PATIENTFORM_REQUEST } }
    function success(data) {
    	history.push('/patient/'+data.patient.id)
    	return { type: formsConstants.SUBMIT_PATIENTFORM_SUCCESS, data }
    }
    function failure(error) { return { type: formsConstants.SUBMIT_PATIENTFORM_FAILURE, error } }
};

function openPatientImageUploadForm(){
	return dispatch => {
		dispatch(open());
	}
	function open() { return {type: formsConstants.OPEN_PATIENT_IMAGEUPLOAD } }
};

function closePatientImageUploadForm(){
	return dispatch => {
		dispatch(close());
	}
	function close() { return {type: formsConstants.CLOSE_PATIENT_IMAGEUPLOAD}}
};

function changePatientImageUploadForm(val, name){
	// sending multipart/form-data
	return (dispatch, getState) => {
		const form = getState().forms.patientUploadForm;
		console.log(name)
		if (name == "file"){
			form.append(name, val, "video");
		}else {
			form.append(name, val);
		}
		dispatch(change(form));
	}
	function change(data) { return {type: formsConstants.CHANGE_PATIENT_IMAGEUPLOAD, data} }
};

function submitPatientImageUploadForm(form){
	return (dispatch, getState) => {
		dispatch(request());
		const form = getState().forms.patientUploadForm;
		form.append("patient", getState().patient.currentPatientID)
		formsService.submitPatientUploadForm(form)
			.then(
				data => {dispatch(success(data));dispatch(update(data))},
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: formsConstants.SUBMIT_PATIENT_IMAGEUPLOAD_REQUEST } }
    function success(data) {
    	return { type: formsConstants.SUBMIT_PATIENT_IMAGEUPLOAD_SUCCESS, data }
    }
    function update(data){
    	return { type: patientConstants.SUBMIT_PATIENT_IMAGEUPLOAD_SUCCESS, data}
    }
    function failure(error) { return { type: formsConstants.SUBMIT_PATIENT_IMAGEUPLOAD_FAILURE, error } }
};

/* SCHEDULING PAGE */
// create an appointment
function createAppointment(form){
	return (dispatch, getState) => {
		dispatch(request());

        form["start_time"] = form["date"]+"T"+form["start_time_time"]
        form["end_time"] = form["date"]+"T"+form["end_time_time"]
		schedulingService.createAppointment(form)
			.then(
				data => dispatch(success(data)),
				error => {dispatch(failure(error)); dispatch()}
			);
	};
    function request() { return { type: schedulingConstants.CREATEAPPOINTMENT_REQUEST } }
    function success(data) {
		let tempAppt = data.appointment
		tempAppt = {
    			...tempAppt,
    			["dentist"] : {...tempAppt["dentist"]},
    			["patient"] : {...tempAppt["patient"]},
    			["start_time"]: new Date(tempAppt["start_time"]),
    			["end_time"]: new Date(tempAppt["end_time"])
		}
		data["appointment"] = tempAppt;
    	return { type: schedulingConstants.CREATEAPPOINTMENT_SUCCESS, data }
    }
    function failure(error) { return { type: schedulingConstants.CREATEAPPOINTMENT_FAILURE, error } }
}

// create Patient
/*function submitQuickPatientForm(form){
  return (dispatch) => {
    dispatch(request());

    formsService.submitQuickPatientForm(form)
      .then(
        // send notif to react, nad have saga listening for it...
        data => { dispatch(success(data))},
        error => { dispatch(Notifications.error("Error in creating Patient")(error))}
      )
  };
    function request() { return { type: formsConstants.SUBMIT_QUICKPATIENTFORM_REQUEST } }
    function success(data) {
    	return { type: formsConstants.SUBMIT_QUICKPATIENTFORM_SUCCESS, data }
    }
    function failure(error) { return { type: formsConstants.SUBMIT_QUICKPATIENTFORM_FAILURE, error } }
}
*/
// create Patient
function createPatient(form){
	return (dispatch, getState) => {
		dispatch(request());

		schedulingService.createPatient(form)
			.then(
				data => {	dispatch(success(data));
							dispatch(Notifications())
						},
				error => {	dispatch(failure(error));
							dispatch(Notifications.error("Error in creating Patient"))
						}
			);
	};
    function request() { return { type: schedulingConstants.CREATEPATIENT_REQUEST } }
    function success(data) {
    	return { type: schedulingConstants.CREATEPATIENT_SUCCESS, data }
    }
    function failure(error) { return { type: schedulingConstants.CREATEPATIENT_FAILURE, error } }
}
