const PREFIX = 'CITADEL_';
export const citadelConstants = {
  LOADINGSTATE_DOWNLOADINGZIP: `${PREFIX}LOADINGSTATE_DOWNLOADINGZIP`,
  LOADINGSTATE_DOWNLOADINGZIP_SUCCESS: `${PREFIX}LOADINGSTATE_DOWNLOADINGZIP_SUCCESS`,
  LOADINGSTATE_DOWNLOADINGZIP_ERROR: `${PREFIX}LOADINGSTATE_DOWNLOADINGZIP_ERROR`,
  LOADINGSTATE_UNZIPPINGASSETS: `${PREFIX}LOADINGSTATE_UNZIPPINGASSETS`,
  LOADINGSTATE_UNZIPPINGASSETS_SUCCESS: `${PREFIX}LOADINGSTATE_UNZIPPINGASSETS_SUCCESS`,
  LOADINGSTATE_UNZIPPINGASSETS_ERROR: `${PREFIX}LOADINGSTATE_UNZIPPINGASSETS_ERROR`,
  LOADINGSTATE_BUILDINGMODELS: `${PREFIX}LOADINGSTATE_BUILDINGMODELS`,
  LOADINGSTATE_BUILDINGMODELS_SUCCESS: `${PREFIX}LOADINGSTATE_BUILDINGMODELS_SUCCESS`,
  LOADINGSTATE_BUILDINGMODELS_ERROR: `${PREFIX}LOADINGSTATE_BUILDINGMODELS_ERROR`,
  LOADINGSTATE_BUILDINGMODELS_PROCESSING: `${PREFIX}LOADINGSTATE_BUILDINGMODELS_PROCESSING`,
  LOADINGSTATE_BUILDINGMODELS_LOADSINGLEMODEL: `${PREFIX}LOADINGSTATE_BUILDINGMODELS_LOADSINGLEMODEL`,
  LOADINGSTATE_BUILDINGMODELS_ADDMODEL: `${PREFIX}LOADINGSTATE_BUILDINGMODELS_ADDMODEL`,
  LOADINGSTATE_BUILDINGMODELS_COMPLETE: `${PREFIX}LOADINGSTATE_BUILDINGMODELS_COMPLETE`,
  LOADINGSTATE_MODELCOMPLETE: `${PREFIX}LOADINGSTATE_MODELCOMPLETE`,
  UNMOUNT_ALL: `${PREFIX}UNMOUNT_ALL`
}
