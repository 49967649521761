import { imageSetConstants } from '../_constants';

export const imageSetActions = {
  updateRequiredImages
}

function updateRequiredImages(data){
  return dispatch => {
    dispatch(update(data));
  }

  function update(data) { return { type: imageSetConstants.UPDATE_REQUIREDIMAGES, data}}
}
