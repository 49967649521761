import {
  Box,
  Flex,
  Image,
  Heading,
  Text,
  Icon,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from "@chakra-ui/react";
import { useRef, useEffect } from "react";
import { startCase } from "lodash";
import Logo from "../../../images/icons/logo";
import { getFullNameString } from "js/_helpers";
import ChevronDownIcon from "images/icons/ChevronDownIcon";

import { AdminClinicSearch } from "./AdminClinicSearch";

import DatePickerIcon from "images/icons/DatePickerIcon";
import AppointmentRequestsIcon from "images/icons/AppointmentRequestsIcon";
import { useAuth } from "js/_libs/auth";
import { FaChevronDown } from "react-icons/fa";
import LogoutIcon from "images/icons/LogoutIcon";
import SettingIcon from "images/icons/SettingIcon";
import { URLS } from "js/_config";
import { useHistory, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useAdminClinicStore } from "js/AdminPage/stores/adminClinic";

export const Topbar = ({ ...props }) => {
  const {
    logout,
    user: { user },
  } = useAuth();
  const location = useLocation();
  const dpid = useAdminClinicStore(state => state.dpid);
  const displayName = getFullNameString(
    "{givenName} {familyName}",
    user.first_name,
    user.last_name
  );
  const history = useHistory();

  const handleClickOnSetting = () => {
    history.push(URLS.appointmentsSettings);
  };
  const handleClickOnLogout = () => {
    logout();
  };

  const handleOnClickAdminRequests = () => {
    history.push("/appointments/admin/requests")
  }

  const handleOnClickAdminAppointments = () => {
    history.push("/appointments/admin/appointments")
  }
  console.log("location", location.pathname)

  const isAdminPage = location.pathname.includes("/appointments/admin");

  const isAdmin = user?.is_staff === true || user?.user_role === "BEFO" || user?.user_role === "SUPE";

  let isInAdminMode = dpid != null;

  return (
    <Flex
      w={"full"}
      backgroundColor={(isAdminPage) ? '#179b8b' : "#FFFFFF"}
      justify={"space-between"}
      pl={[12, 12]}
      py={[8, 8]}
      pr={[24, 24]}
      {...props}
    >
      <Flex align={"center"}>
        <Logo />
      </Flex>
      { (isAdmin) &&
        <Flex align={"center"}>
           <AdminClinicSearch isInAdminMode={!isAdminPage && isInAdminMode} isAdminPage={isAdminPage} />
        </Flex>
      }
      <Flex align={"center"}>
        <Flex cursor={"pointer"}>
          <Menu 
            background={"#FFF"} 
              placement={'bottom-end'}
            >
            <MenuButton>
              <Flex align={"center"}>
                <Flex>
                  <Avatar
                    borderRadius="full"
                    boxSize={47}
                    border={"3px solid #FFFFFF"}
                    filter={"drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))"}
                    src={user?.avatar}
                    name={displayName}
                    bg={"brandLight.primary"}
                    color={"white"}
                    letterSpacing={"1.1px"}
                    mr={6}
                  />
                </Flex>
                <Flex direction={"column"} justify={"center"}>
                  <Heading fontSize={14} fontWeight={500}>
                    {displayName}
                  </Heading>
                  <Text textAlign={"left"} fontSize={12} color={isAdminPage ? 'white' : "#7B8887"}>
                    {startCase((user?.user_role || "").toLowerCase())}
                  </Text>
                </Flex>
                <ChevronDownIcon
                  ml={'20px'}
                  color={isAdminPage ? 'white' : "#7B8887"}
                />
              </Flex>
            </MenuButton>

            <MenuList
              minW={"150px"}
              background={"white"}
              zIndex={50}
              mt={"10px"}
              borderRadius={"10px"}
              px={'10px'}
              py={'10px'}
              boxShadow={"rgba(0, 0, 0, 0.4) 0px 30px 90px;"}
            >
              {
                isAdmin && (
                  <>
                    <MenuItem
                      fontSize={"12px"}
                      fontWeight={400}
                      color={"#005750"}
                      p={'10px'}
                      onClick={handleOnClickAdminAppointments}
                    > 
                    <Icon
                      as={DatePickerIcon}
                      color={"#005750"}
                      h={18}
                      w={18}
                      mr={'10px'}
                    />
                      <Text fontWeight={500}>
                        <FormattedMessage 
                          id={'sidebar.navItem.admin.appointments'}
                          defaultMessage={'Admin Appointments'}
                        />
                      </Text>
                    </MenuItem>
                    <MenuItem
                      fontSize={"12px"}
                      fontWeight={400}
                      color={"#005750"}
                      p={'10px'}
                      onClick={handleOnClickAdminRequests}
                    >
                    <Icon
                      as={AppointmentRequestsIcon}
                      color={"#005750"}
                      h={18}
                      w={18}
                      mr={'10px'}
                    />
                      <Text fontWeight={500}>
                        <FormattedMessage 
                          id={'sidebar.navItem.admin.requests'}
                          defaultMessage={'Admin Appointment Requests'}
                        />
                      </Text>
                    </MenuItem>
                  </>
                )
              }
              <MenuItem
                // icon={<LogoutIcon color={"#7B8887"} />}
                fontSize={"12px"}
                fontWeight={400}
                color={"#7B8887"}
                p={'10px'}
                onClick={() => {
                  handleClickOnSetting();
                }}
              > <SettingIcon color={"#7B8887"} mr={"10px"} />
                <Text>
                  <FormattedMessage 
                    id={'sidebar.navItem.settings'}
                    defaultMessage={'Settings'}
                  />
                  </Text>
              </MenuItem>
              <MenuItem
                // icon={<LogoutIcon color={"#7B8887"} />}
                fontSize={"12px"}
                p={'10px'}
                fontWeight={400}
                color={"#7B8887"}
                onClick={() => {
                  handleClickOnLogout();
                }}
              >
                <LogoutIcon color={"#7B8887"} mr={"10px"} />  
                <Text>
                  <FormattedMessage 
                    id={'sidebar.navItem.logout.label'}
                    defaultMessage={'Settings'}
                  />
                  </Text>
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </Flex>
  );
};
