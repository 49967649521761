import { adminConstants } from '../_constants'

const initUserAccountForm = {
    id: null,
    first_name: "",
    middle_name: "",
    last_name: "",
    gender: "",
    date_of_birth: "",
    phone_number: "",
    email_address: "",
    is_owner: false,
    can_create: true,
    user_role: "",
}

const initAvailabilityForm = {
	0:[],
	1:[],
	2:[],
	3:[],
	4:[],
	5:[],
	6:[]
}

const initDentalAccountForm = {
    id: null,
    first_name: "",
    middle_name: "",
    last_name: "",
    gender: "",
    date_of_birth: "",
    phone_number: "",
    email_address: "",
    is_owner: false,
    can_create: true,
    user_role: "",
    availability: {},
    description: "",
    associations: "",
    certification: "",
    education: "",
    languages: "",
    tags: ""

}

const initPracticeLocationForm = {
	id:null,
	practice_name: "",
	address: "",
	phone_number: "",
	email_address: "",
	timezone: "",
    geopoint_lat: "",
    geopoint_lon: "",
    organization: {
    	organization_name: "",
    	address: ""
    }

}

const initState = {
	userAccounts: [],
	userAccountsLoading: false,
	userAccountsError: "",
	userAccountInfoLoading: false,
	userAccountInfoError: "",
	dentalAccountInfo: {},
	dentalAccountForm: {...initDentalAccountForm},
	dentalAccountFormLoading: false,
	dentalAccountFormError: "",
	userAccountForm: {...initUserAccountForm},
	availabilityForm: {...initAvailabilityForm},
	userAccountFormOpen: false,
	userAccountFormLoading: false,
	userAccountFormError: "",
	currentContentView: "DETAILS",
	dentalAccountFormChanged: false,

	practiceLocationFormChanged: false,
	practiceLocationInfo: {},
	practiceLocationForm: {...initPracticeLocationForm},
	practiceLocationFormLoading: false,
	practiceLocationFormError: "",

  currentPracticeDetailsLoading: false,
  currentPracticeDetails: {},
  currentPracticeDetailsError: false,

  currentAccountDetailsLoading: false,
  currentAccountDetails: {},
  currentAccountDetailsError: false,

  searchResultsModel: '',
  searchResultsLoading: false,
  searchResultsError: false,
  searchResults: [],
  searchResultsTotalResults: 0
}

export function dentalAdmin(state = initState, action){
	switch (action.type){
    case adminConstants.SEARCH_MODELS_REQUEST:
      return {
        ...state,
        searchResultsLoading: true,
        searchResultsError: false
      }
    case adminConstants.SEARCH_MODELS_SUCCESS:
      return {
        ...state,
        searchResultsModel: action.data.model,
        searchResultsLoading: false,
        searchResultsError: false,
        searchResults: action.data.search_list,
        searchResultsTotalResults: action.data.total_count,
        searchResultsLoadMoreOffset: action.data.offset + 1,
      }
    case adminConstants.SEARCH_MODELS_FAILURE:
      return {
        ...state,
        searchResultsLoading: false,
        searchResultsError: false,
        searchResults: [],
        searchResultsTotalResults: 0
      }
    case adminConstants.SEARCH_MODELS_CLEAR:
      return {
        ...state,
        searchResultsModel: '',
        searchResultsLoading: false,
        searchResultsError: true
      }
    case adminConstants.GET_PRACTICEDETAILS_REQUEST:
      return {
        ...state,
        currentPracticeDetailsLoading: true,
        currentPracticeDetails: {}
      }
    case adminConstants.GET_PRACTICEDETAILS_SUCCESS:
      return {
        ...state,
        currentPracticeDetailsLoading: false,
        currentPracticeDetails: action.data.clinic
      }
    case adminConstants.GET_PRACTICEDETAILS_FAILURE:
      return {
        ...state,
        currentPracticeDetailsLoading: false,
        currentPracticeDetailsError: "Error fetching practice details"
      }
    case adminConstants.UPDATE_PRACTICEDETAILS:
      return {
        ...state,
        currentPracticeDetails: {
          ...state.currentPracticeDetails,
          ...action.data
        }
      }
    case adminConstants.GET_ACCOUNTDETAILS_REQUEST:
      return {
        ...state,
        currentAccountDetailsLoading: true,
        currentAccountDetails: {}
      }
    case adminConstants.GET_ACCOUNTDETAILS_SUCCESS:
      return {
        ...state,
        currentAccountDetailsLoading: false,
        currentAccountDetails: action.data.account
      }
    case adminConstants.GET_ACCOUNTDETAILS_FAILURE:
      return {
        ...state,
        currentAccountDetailsLoading: false,
        currentAccountDetailsError: "Error fetching practice details"
      }
    case adminConstants.UPDATE_ACCOUNTDETAILS:
      return {
        ...state,
        currentAccountDetails: {
          ...state.currentAccountDetails,
          ...action.data
        }
      }
    case adminConstants.CREATE_NEWACCOUNT_REQUEST:
      return {
        ...state,
        currentAccountDetailsLoading: true,
        currentAccountDetails: {}
      }
    case adminConstants.CREATE_NEWACCOUNT_SUCCESS:
      return {
        ...state,
        currentAccountDetailsLoading: false,
        currentAccountDetails: {
          ...action.data
        }
      }
		case adminConstants.GET_USERACCOUNTS_REQUEST:
			return {
				...state,
				userAccountsLoading: true,
				userAccountsError: ""
			}
		case adminConstants.GET_USERACCOUNTS_SUCCESS:
			return {
				...state,
				userAccountsLoading: false,
				userAccountsError: "",
				userAccounts: action.data.account_list
			}
		case adminConstants.GET_USERACCOUNTS_FAILURE:
			return {
				...state,
				userAccountsLoading: false,
				userAccountsError: "Error getting accounts"
			}
		case adminConstants.GET_PRACTICELOCATIONS_REQUEST:
			return {
				...state,
				practiceLocationsLoading: true,
				practiceLocationsError: ""
			}
		case adminConstants.GET_PRACTICELOCATIONS_SUCCESS:
			return {
				...state,
				practiceLocationsLoading: false,
				practiceLocationsError: "",
				practiceLocations: action.data.location_list
			}
		case adminConstants.GET_PRACTICELOCATIONS_FAILURE:
			return {
				...state,
				practiceLocationsLoading: false,
				practiceLocationsError: "Error getting accounts"
			}
		case adminConstants.GET_USERACCOUNTINFO_REQUEST:
			return {
				...state,
				dentalAccountFormLoading: true,
				dentalAccountFormError: ""
			}
		case adminConstants.GET_USERACCOUNTINFO_SUCCESS:
			return {
				...state,
				dentalAccountFormLoading: false,
				dentalAccountFormError: "",
				dentalAccountInfo: action.data.dental_account,
				dentalAccountForm: action.data.dental_account,
				availabilityForm: action.data.dental_account.availability.schedule
			}
		case adminConstants.GET_USERACCOUNTINFO_FAILURE:
			return {
				...state,
				dentalAccountFormLoading: false,
				dentalAccountFormError: "Error getting account info"
			}
		case adminConstants.GET_PRACTICELOCATIONINFO_REQUEST:
			return {
				...state,
				practiceLocationFormLoading: true,
				practiceLocationFormError: ""
			}
		case adminConstants.GET_PRACTICELOCATIONINFO_SUCCESS:
			return {
				...state,
				practiceLocationFormLoading: false,
				practiceLocationFormError: "",
				practiceLocationInfo: action.data.dental_practice,
				practiceLocationForm: action.data.dental_practice,
			}
		case adminConstants.GET_PRACTICELOCATIONINFO_FAILURE:
			return {
				...state,
				practiceLocationFormLoading: false,
				practiceLocationFormError: "Error getting account info"
			}

		case adminConstants.CANCEL_PRACTICELOCATIONFORM:
			return {
				...state,
				practiceLocationFormChanged: false,
				practiceLocationForm: {...state.practiceLocationInfo}
			}
		case adminConstants.CHANGE_PRACTICELOCATIONFORM:
			return {
				...state,
				practiceLocationFormChanged: true,
				practiceLocationForm: {...state.practiceLocationForm, ...action.data}
			}

		case adminConstants.EDIT_PRACTICELOCATION_INFO_REQUEST:
			return {
				...state,
				practiceLocationFormLoading: true,
				practiceLocationFormError: ""
			}
		case adminConstants.EDIT_PRACTICELOCATION_INFO_SUCCESS:
			return {
				...state,
				practiceLocationFormChanged: false,
				practiceLocationFormLoading: false,
				practiceLocationFormError: "",
				practiceLocationInfo: action.data.dental_practice

			}
		case adminConstants.EDIT_PRACTICELOCATION_INFO_FAILURE:
			return {
				...state,
				practiceLocationFormLoading: false,
				practiceLocationFormError: "Error editing location"
			}




		case adminConstants.CHANGE_DENTALACCOUNTFORM:
			return {
				...state,
				dentalAccountFormChanged: true,
				dentalAccountForm: {...state.dentalAccountForm, ...action.data}
			}
		case adminConstants.CANCEL_DENTALACCOUNTFORM:
			return {
				...state,
				dentalAccountFormChanged: false,
				dentalAccountForm: {...state.dentalAccountInfo}
			}
		case adminConstants.EDIT_DENTALACCOUNT_INFO_REQUEST:
			return {
				...state,
				dentalAccountFormLoading: true,
				dentalAccountFormError: ""
			}
		case adminConstants.EDIT_DENTALACCOUNT_INFO_SUCCESS:
			return {
				...state,
				dentalAccountFormChanged: false,
				dentalAccountFormLoading: false,
				dentalAccountFormError: "",
				dentalAccountInfo: action.data.dental_account

			}
		case adminConstants.EDIT_DENTALACCOUNT_INFO_FAILURE:
			return {
				...state,
				dentalAccountFormLoading: false,
				dentalAccountFormError: "Error adding user"
			}
		case adminConstants.CHANGE_DENTALACCOUNTAVAILABILITY:
			return {
				...state,
				dentalAccountFormChanged: true,
				availabilityForm: {...state.availabilityForm, ...action.data}
			}
		case adminConstants.EDIT_DENTALACCOUNT_AVAILABILITY_REQUEST:
			return {
				...state,
				dentalAccountFormLoading: true,
				dentalAccountFormError: ""
			}
		case adminConstants.EDIT_DENTALACCOUNT_AVAILABILITY_SUCCESS:
			return {
				...state,
				dentalAccountFormChanged: false,
				dentalAccountFormLoading: false,
				dentalAccountFormError: "",
				dentalAccountInfo: {...state.dentalAccountInfo, availability: action.data.availability}

			}
		case adminConstants.EDIT_DENTALACCOUNT_AVAILABILITY_FAILURE:
			return {
				...state,
				dentalAccountFormLoading: false,
				dentalAccountFormError: "Error adding user"
			}

		// add account form
		case adminConstants.OPEN_USERACCOUNTFORM:
			return {
				...state,
				userAccountFormOpen: true
			}
		case adminConstants.CLOSE_USERACCOUNTFORM:
			return {
				...state,
				userAccountFormOpen: false,
				userAccountForm: {...initUserAccountForm}
			}
		case adminConstants.CHANGE_USERACCOUNTFORM:
			return {
				...state,
				userAccountForm: {...state.userAccountForm, ...action.form}
			}
		case adminConstants.SUBMIT_USERACCOUNTFORM_REQUEST:
			return {
				...state,
				userAccountFormLoading: true,
				userAccountFormError: ""
			}
		case adminConstants.SUBMIT_USERACCOUNTFORM_SUCCESS:
			return {
				...state,
				userAccountFormLoading: false,
				userAccountFormError: "",
			}
		case adminConstants.SUBMIT_USERACCOUNTFORM_FAILURE:
			return {
				...state,
				userAccountFormLoading: false,
				userAccountFormError: "Error adding user"
			}
		case adminConstants.CHANGE_PAGECONTENT:
			return {
				...state,
				currentContentView: action.contentView
			}
    default:
      return state
	}
}
