export const insuranceClaimsConstants = {

	INITIALIZE: 'INSURANCECLAIMS_INITIALIZE',

	CHANGE_CONTENTVIEW : 'INSURANCECLAIMS_CHANGE_CONTENTVIEW',
	CHANGE_PAGEDETAILVIEW : 'INSURANCECLAIMS_CHANGE_PAGEDETAILVIEW',

	GET_CLAIMSOVERVIEW_REQUEST: 'INSURANCECLAIMS_GET_CLAIMSOVERVIEW_REQUEST',
	GET_CLAIMSOVERVIEW_SUCCESS: 'INSURANCECLAIMS_GET_CLAIMSOVERVIEW_SUCCESS',
	GET_CLAIMSOVERVIEW_ERROR: 'INSURANCECLAIMS_GET_CLAIMSOVERVIEW_ERROR',

	SUBMIT_INSURANCE_CLAIM_REQUEST: 'INSURANCECLAIMS_SUBMIT_INSURANCE_CLAIM_REQUEST',
	SUBMIT_INSURANCE_CLAIM_SUCCESS: 'INSURANCECLAIMS_SUBMIT_INSURANCE_CLAIM_SUCCESS',
	SUBMIT_INSURANCE_CLAIM_ERROR: 'INSURANCECLAIMS_SUBMIT_INSURANCE_CLAIM_ERROR',

	CREATE_INSURANCE_CLAIM_REQUEST: 'INSURANCECLAIMS_CREATE_INSURANCE_CLAIM_REQUEST',
	CREATE_INSURANCE_CLAIM_SUCCESS: 'INSURANCECLAIMS_CREATE_INSURANCE_CLAIM_SUCCESS',
	CREATE_INSURANCE_CLAIM_ERROR: 'INSURANCECLAIMS_CREATE_INSURANCE_CLAIM_ERROR',

	GET_UNATTACHEDPROCEDURES_REQUEST: 'INSURANCECLAIMS_GET_UNATTACHEDPROCEDURES_REQUEST',
	GET_UNATTACHEDPROCEDURES_SUCCESS: 'INSURANCECLAIMS_GET_UNATTACHEDPROCEDURES_SUCCESS',
	GET_UNATTACHEDPROCEDURES_ERROR: 'INSURANCECLAIMS_GET_UNATTACHEDPROCEDURES_ERROR',

	GET_UNRESOLVEDCLAIMS_REQUEST: 'INSURANCECLAIMS_GET_UNRESOLVEDCLAIMS_REQUEST',
	GET_UNRESOLVEDCLAIMS_SUCCESS: 'INSURANCECLAIMS_GET_UNRESOLVEDCLAIMS_SUCCESS',
	GET_UNRESOLVEDCLAIMS_ERROR: 'INSURANCECLAIMS_GET_UNRESOLVEDCLAIMS_ERROR',

	GET_UNSENTCLAIMS_REQUEST: 'INSURANCECLAIMS_GET_UNSENTCLAIMS_REQUEST',
	GET_UNSENTCLAIMS_SUCCESS: 'INSURANCECLAIMS_GET_UNSENTCLAIMS_SUCCESS',
	GET_UNSENTCLAIMS_ERROR: 'INSURANCECLAIMS_GET_UNSENTCLAIMS_ERROR'
}