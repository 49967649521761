import { activityNotificationsActions } from './actions'
import { getPollingState } from './reducer'
import { createSelector } from 'reselect';
import { authHeader, handleResponse } from '../_helpers';
import { BASE_URL, API_URLS, NOTIFICATION_POLLING_INTERVAL, NOTIFICATION_POLLING_HISTORYLENGTH } from '../_config';

const { createPollingActions } = activityNotificationsActions;
const pollingInterval = NOTIFICATION_POLLING_INTERVAL;
const historyLength = NOTIFICATION_POLLING_HISTORYLENGTH;

const reduxStateNameSpace = 'activityNotifications';

function getActivityLog(){
    const requestUrl = API_URLS.getNotifications; //API_URLS.getActivityLog;
    const requestOptions = {
      method: requestUrl.method,
      headers: { ...authHeader()}
    };
    let url = requestUrl.url;
    return fetch(url, requestOptions).then(handleResponse);
}

function getNewActivityLog(ts){
  const requestUrl = API_URLS.getNewNotifications; //API_URLS.getNewActivityLog;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader()}
  };
  let url = requestUrl.url.replace('{ts}', ts);
  return fetch(url, requestOptions).then(handleResponse);
}

export const activityPollingSelectors = {
    isActivityPollingActive: state => getPollingState(state, reduxStateNameSpace).isActive,
    getActivityHistory: state => getPollingState(state, reduxStateNameSpace).history,
    getLastEntry: state => getPollingState(state, reduxStateNameSpace).lastEntry,
    getFirstEntry: state => getPollingState(state, reduxStateNameSpace).firstEntry,
    getLastPoint: createSelector(
        state => getPollingState(state, reduxStateNameSpace).lastEntry,
        lastEntry => (lastEntry ? lastEntry.point : undefined),
    )
};

let isInitial = true;
let updateTimestamp = +new Date();

async function polling(getState) {
    let notifications, lastEntry;
    if (isInitial){
      notifications = await getActivityLog()
      updateTimestamp = +new Date();
    } else {
      // only new entires so getting stuff after
      notifications = await getNewActivityLog(updateTimestamp)
      updateTimestamp = +new Date();
    }
    isInitial = false;

    lastEntry = activityPollingSelectors.getLastEntry(getState());
    //lastEntry = activityPollingSelectors.getFirstEntry(getState());
    const lastEntryIndex = lastEntry ? lastEntry.index + 1 : 1;
    // TODO: dispatch updateActivityLog timestamp somewhere ehere
    const entries = notifications.unread_messages.map(( msg , index) =>
      ({ data: msg, index: index + lastEntryIndex })
    );
    return { multipleEntries: true, entries };
}

function shouldAddEntry() {
    return true;
}

function onReset() {
    isInitial = true;
}

const callbacks = { polling, shouldAddEntry, onReset };

export const activityPollingActions = createPollingActions(reduxStateNameSpace, callbacks, pollingInterval, historyLength);
