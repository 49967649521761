export const formsConstants = {


	OPENFORM_APPOINTMENT : 'FORMS_OPENFORM_APPOINTMENT',
	CLOSEFORM_APPOINTMENT : 'FORMS_CLOSEFORM_APPOINTMENT',
	OPEN_PATIENTFORM : 'FORMS_OPEN_PATIENTFORM',
	CLOSE_PATIENTFORM : 'FORMS_CLOSE_PATIENTFORM',
	CHANGE_PATIENTFORM : 'FORMS_CHANGE_PATIENTFORM',
	SUBMIT_PATIENTFORM_REQUEST: 'FORMS_SUBMIT_PATIENTFORM_REQUEST',
	SUBMIT_PATIENTFORM_SUCCESS: 'FORMS_SUBMIT_PATIENTFORM_SUCCESS',
	SUBMIT_PATIENTFORM_FAILURE: 'FORMS_SUBMIT_PATIENTFORM_FAILURE',
	OPEN_PATIENT_IMAGEUPLOAD : 'FORMS_OPEN_PATIENT_IMAGEUPLOAD',
	CLOSE_PATIENT_IMAGEUPLOAD : 'FORMS_CLOSE_PATIENT_IMAGEUPLOAD',
	CHANGE_PATIENT_IMAGEUPLOAD : 'FORMS_CHANGE_PATIENT_IMAGEUPLOAD',
	SUBMIT_PATIENT_IMAGEUPLOAD_REQUEST: 'FORMS_PATIENT_IMAGEUPLOAD_REQUEST',
	SUBMIT_PATIENT_IMAGEUPLOAD_SUCCESS: 'FORMS_PATIENT_IMAGEUPLOAD_SUCCESS',
	SUBMIT_PATIENT_IMAGEUPLOAD_FAILURE: 'FORMS_PATIENT_IMAGEUPLOAD_FAILURE',

	SUBMIT_QUICKPATIENTFORM_REQUEST: 'FORMS_SUBMIT_QUICKPATIENTFORM_REQUEST',
	SUBMIT_QUICKPATIENTFORM_SUCCESS: 'FORMS_SUBMIT_QUICKPATIENTFORM_SUCCESS',
	SUBMIT_QUICKPATIENTFORM_FAILURE: 'FORMS_SUBMIT_QUICKPATIENTFORM_FAILURE',

	SUBMIT_APPOINTMENTFORM_REQUEST: 'FORMS_SUBMIT_APPOINTMENTFORM_REQUEST',
	SUBMIT_APPOINTMENTFORM_SUCCESS: 'FORMS_SUBMIT_APPOINTMENTFORM_SUCCESS',
	SUBMIT_APPOINTMENTFORM_FAILURE: 'FORMS_SUBMIT_APPOINTMENTFORM_FAILURE',

	SUBMIT_MODALFORM_REQUEST: 'FORMS_SUBMIT_APPOINTMENTFORM_REQUEST',
	SUBMIT_MODALFORM_SUCCESS: 'FORMS_SUBMIT_MODALFORM_SUCCESS',
	SUBMIT_MODALFORM_FAILURE: 'FORMS_SUBMIT_MODALFORM_FAILURE'

}