import { authHeader, handleResponse } from '../_helpers';
import { BASE_URL } from '../_config';
export const appointmentService = {
    getAppointmentDetails,
    getProcedureCodes,
    getToothRegions,
    addProcedure,
    getInbox,
    getNewInbox,
    sendMessage,
    getMessages,
    getNewMessages,
    getVideoChatToken
};

// Load Procedure Codes (change to search in future)

function getProcedureCodes(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/dental_practice/search/procedurecodes';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
}

// Load Tooth Regions

function getToothRegions(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/dental_config/procedure';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
}


// details returns appt details, procedures, patient info
function getAppointmentDetails(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/dental_practice/appointment/'+id+'/details';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};

// get video chat email
function getVideoChatToken(sessionID) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/msg/videochat/token/'+sessionID

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};


function addProcedure(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json', 'Accept': 'application/json' },
        body: JSON.stringify(data)

    };
    let url = '/api/d/dental_practice/appointment/createprocedure';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};

// send message
function sendMessage(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)

    };
    let url = '/api/d/msg/reply';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};

// get message inbox
function getInbox(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/msg/inbox';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
}

// get chatbox messages
function getMessages(chatID){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/msg/view/' + chatID;

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
}

// get chatbox messages
function getNewMessages(chatID){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/msg/view/' + chatID+'/messages';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
}
// get unread messages for inbox
function getNewInbox(tm){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/msg/inbox?reqtime='+tm;

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
}