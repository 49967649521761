import { authHeader, handleResponse } from '../_helpers';
import { BASE_URL } from '../_config';

export const schedulingService  = {
	getAppointments,
	getActivityLog,
	createAppointment,
	createPatient,
	getAppointmentbyID,
	addDentist,
	removeDentist,
	getPracticeProviders,
	getPracticePatients,
	getPracticeOperatories
};


function getAppointmentbyID(apptID) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};
	let url = '/api/d/dental_practice/appointment/'+apptID+'/simple';
	return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};
function getAppointments(dentistList, dateRange) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};
	let url = '/api/d/dental_practice/calendar';
	if (dentistList.length > 0){
		const dentistQuery = dentistList.map((k) => {return k.id}).join(",");
		url = url + "?dentists="+dentistQuery
	}

	return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};

function getActivityLog(lastActivity){

	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};
	let url = '/api/d/dental_practice/activity_log';
	if (lastActivity != undefined){
		url = url + '?'+'reqdate='+lastActivity
	}

	return fetch(BASE_URL+url, requestOptions).then(handleResponse);
}

function getPracticeOperatories(){
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};
    let url = '/api/d/dental_practice/search/operatories';

	return fetch(BASE_URL+url, requestOptions).then(handleResponse);
}


function getPracticePatients(){
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};
	let url = '/api/d/dental_practice/search/patients';

	return fetch(BASE_URL+url, requestOptions).then(handleResponse);
}

function getPracticeProviders(){
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};
	let url = '/api/d/dental_practice/search/dentists';

	return fetch(BASE_URL+url, requestOptions).then(handleResponse);
}

function addDentist(dentist, dentistList){
	let duplicateDentist = dentistList.filter(user => { return user.id === dentist.id; }).length;
	if (duplicateDentist) {
        return dentistList
    }else {
    	return [...dentistList, dentist]
    }
};

function removeDentist(dentist, dentistList){
	for (let i = 0; i < dentistList.length; i++) {
        let user = dentistList[i];
        if (user.id === dentist.id) {
            // delete user
            dentistList.splice(i, 1);
            break;
        }
    }
	return dentistList
};


function createAppointment(appt) {
	const requestOptions = {
		method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(appt)
	};
	

	return fetch(BASE_URL+'/api/d/dental_practice/appointment/create', requestOptions).then(handleResponse);
};


function createPatient(patient) {
	const requestOptions = {
		method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(patient)
	};
	

	return fetch(BASE_URL+'/api/d/dental_practice/patient/create', requestOptions).then(handleResponse);
};