// 1. Import `extendTheme`
import { extendTheme, theme as baseTheme} from "@chakra-ui/react"
import { mode, transparentize } from '@chakra-ui/theme-tools';

// for outline
const variantGhost = (props) => {
  const { colorScheme: c, theme } = props

  if (c === "gray") {
    return {
      color: mode(`inherit`, `whiteAlpha.900`)(props),
      _hover: {
        bg: mode(`gray.100`, `whiteAlpha.200`)(props),
      },
      _active: { bg: mode(`gray.200`, `whiteAlpha.300`)(props) },
    }
  }

  const darkHoverBg = transparentize(`${c}.200`, 0.12)(theme)
  const darkActiveBg = transparentize(`${c}.200`, 0.24)(theme)

  return {
    color: mode(`${c}.600`, `${c}.200`)(props),
    bg: "transparent",
    _hover: {
      bg: mode(`${c}.50`, darkHoverBg)(props),
    },
    _active: {
      bg: mode(`${c}.100`, darkActiveBg)(props),
    },
  }
}

// 2. Call `extendTheme` and pass your custom values
export const theme = extendTheme({
  fonts: {
    heading: 'Poppins, Helvetica, sans-serif',
    
    body: 'Poppins, Helvetica, sans-serif',
    //body: 'Inter, sans-serif',
  },
  colors: {
    red: {

      "50": "#FFE7E5",
      "100": "#FFBCB8",
      "200": "#FF918A",
      "300": "#FF665C",
      "400": "#FF3B2E",
      "500": "#FF0F00",
      "600": "#CC0C00",
      "700": "#FF645A",
      "800": "#660600",
      "900": "#330300"
    },
    blue:{
      "50": "#E9F4FC",
      "100": "#C0DFF6",
      "200": "#98CAF1",
      "300": "#6FB6EB",
      "400": "#47A1E6",
      "500": "#1F8DE0",
      "600": "#1871B4",
      "700": "#125487",
      "800": "#0C385A",
      "900": "#061C2D"
    },
    brandDark: {
      50: '#C5D4DC',
      100: '#C5D4DC',
      200: '#99A8AF',
      300: '#707E85',
      400: '#49575D',
      500: '#253238',
      600: '#05151B',
      700: '#05151B',
      800: '#05151B',
      900: '#05151B',
    },
    brand: {
      50: '#defffd',
      100: '#b3fff7',
      200: '#86fef2',
      300: '#5afeed',
      400: '#3dfee8',
      500: '#31e5ce',
      600: '#21b2a0',
      700: '#137f73',
      800: '#004d46',
      900: '#001b17',
      primary: '#253238', 
    },
    brandLight: {
      50: '#e3f3f1',
      100: '#b9e1dc',
      200: '#8bcdc5',
      300: '#5db9ae',
      400: '#3aaa9c',
      500: '#179b8b',
      600: '#179b8b',
      700: '#179b8b',
      800: '#118978',
      900: '#076d5b',
      primary: "#179B8B",
    },
    "3d_scan": {
      50: '#CECDFF',
      100: '#CECDFF',
      200: '#CECDFF',
      300: '#9391FD',
      400: '#9391FD',
      500: '#9391FD',
      600: '#9391FD',
      700: '#9391FD',
      800: '#6360FF',
      900: '#6360FF',
    },
    scan: {
      50: '#CECDFF',
      100: '#CECDFF',
      200: '#CECDFF',
      300: '#9391FD',
      400: '#9391FD',
      500: '#9391FD',
      600: '#9391FD',
      700: '#9391FD',
      800: '#6360FF',
      900: '#6360FF',
    },
    ipr: {
      50: '#FFD9E2',
      100: '#FFD9E2',
      200: '#FFD9E2',
      300: '#F985A0',
      400: '#F985A0',
      500: '#F985A0',
      600: '#F985A0',
      700: '#F985A0',
      800: '#D86F87',
      900: '#D86F87',
    },
    general: {
      50: '#FFDFAF',
      100: '#FFDFAF',
      200: '#FFDFAF',
      300: '#FBBE62',
      400: '#FBBE62',
      500: '#FBBE62',
      600: '#FBBE62',
      700: '#FBBE62',
      800: '#CB8927',
      900: '#CB8927',
    },
    red1: '#FF645A',
    primaryLight: "#179B8B",
    lightGray: "#f7f9fa",
    darkGray: "#7B8887",
    primary: '#253238',
    blue1: '#56A9E8',
    blue2: '#6BB4EB',
    defaultBorder: '#00948233',
    appointmentTypes: {
      '3d_scan': '#9391FD',
      'scan': '#9391FD',
      'checkup': '#FBBE62',
      'ipr': '#F985A0',
    }
  },
  components: {
    Flex: {
      fontFamily: 'Poppins',
      baseStyle: {
        fontFamily: 'Poppins',
      }
    },
    FormLabel: {
      baseStyle: {
        fontSize: '15px',
        fontFamily: 'Poppins',
        color:'#12171a',
        letterSpacing: 'normal'
      },
      sizes: {
        xl: {
          pt: '12px'
        }
      },
      variants: {
        mainLabel: {
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '21px',
          marginBototm: '10px'
        },
        subLabel: {
          marginBottom: 0,
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '16px',
          color: '#000000',
          pb: '4px'
        }
      }
    },
    Tabs: {
      baseStyle: {
        fontFamily: 'Poppins',
      },
      sizes: {
        xl: {
          tab: {
            fontSize: 16,
          }
        }
      },
      variants: {
        line: (props) =>  ({
          tab: {
            fontFamily: 'Poppins',
            color: 'darkGray',
            fontWeight: 500,
            _selected: {
              fontWeight: 600,
              [props.orientation === "vertical" ? "borderStart" : "borderBottom"] : '4px solid',
              [props.orientation === "vertical" ? "borderStartColor" : "borderBottomColor"] : 'primaryLight',
              color: '#1B2B29',
            },
          }
        }
          )
      }
    },
    Input: {
      baseStyle: {
        border: 'solid 1px #7B888780',
        padding: '10px 15px',
        boxShadow: 'none',
        fontFamily: 'Poppins',
        borderRadius: '10px',
        color: '#12171a',
        background: 'white',
        field: {
          padding: '12px',
          fontFamily: 'Poppins',
        },
        _placeholder: {
          color: '#a9a9a9',
        }
      },
      sizes: {
        xl: {
          padding: '15px 18px'
        }
      }
    },
    Box: {
      baseStyle: {
        fontFamily: 'Poppins',
      }
    },
    Checkbox: {
      variants: {
        fillWidth: {
          label: {
            width: '100%'
          }
        }
      }

    },
    Textarea: {
      baseStyle: {
        border: 'solid 1px #7B888780',
        p: '12px',
        boxShadow: 'none',
        fontFamily: 'Poppins',
        borderRadius: '3px',
        color: '#12171a'
      }
    },
    Text: {
      baseStyle: {
        fontFamily: 'Poppins',
      }
    },
    AlertDialog: {
      baseStyle: {
        background: '#f7f9fa'
      }
    },
    Menu: {
      baseStyle: {
        fontFamly: "Poppins",
        _focus: { outlineColor: "brandDark.500" },
        item: {
          fontFamly: "Poppins",
          py: '5px',
          px: '8px',
          _focus: {  outlineColor: "brandLight.500" },
        }
      },
    },
    MenuItem: {
      baseStyle: {
        fontFamly: "Poppins",
        color: "#1b2b29"
      },
      sizes: {
        md: {
          fontSize: "14px"
        }
      }
    },
    Modal: {
      baseStyle: {
        header: {
          textAlign: 'center',
          fontSize: 20,
          py: '20px',
          color:'#12171a'
        },
        body: {
          py: '20px'
        },
        footer: {
          py: '20px'
        },
        dialogContainer: {
        },
        dialog: {
          px: '40px',
          borderRadius: '30px',
          boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25), 0px 10px 10px rgba(0, 0, 0, 0.25)'
        },
        overlay: {
          bg:'#8C8C8C1A'
        }
      }
    },
    Button: {
      baseStyle: {
        fontWeight: "500", // Normally, it is "semibold"
        borderRadius: "10px",
        letterSpacing: 1,
        fontFamily: 'Poppins',
        fontSize: '14px',
        padding: "10px 15px",
        color: 'white',
        letterSpacing: 'normal',
      },
      variants: {
        solid: (props) => ({
          _active: {
            bg: mode(`${props.colorScheme}.600`, `${props.colorScheme}.300`)(props)
          }
        }),
        solidLight: (props) => ({
          color: mode('white', 'gray.800')(props),
          backgroundColor: mode('brandLight.500', 'brandLight.200')(props),
          _hover: {
            backgroundColor: mode('brandLight.300', 'brandLight.100')(props),
          },
          _active: {
            backgroundColor: mode('brandLight.800', 'brandLight.400')(props),
          },
          _disabled: {
            backgroundColor: mode('brandLight.200', 'brandLight.300')(props),
            color: '#efefef'
          },
        }),
        primary: (props) => ({
          color: mode('white', 'gray.800')(props),
          backgroundColor: mode('brand.primary', 'brand.200')(props),

          _hover: {
            backgroundColor: mode('brand.600', 'brand.300')(props),
          },
          _active: {
            backgroundColor: mode('brand.700', 'brand.400')(props),
          },
          _disabled: {
            backgroundColor: mode('brand.500', 'brand.300')(props),
            color: '#efefef'
          },
        }),
        delete: (props) => ({
          color: mode('white', 'gray.800')(props),
          backgroundColor: mode('red', 'red.200')(props),

          _hover: {
            backgroundColor: mode('red.600', 'red.300')(props),
          },
          _active: {
            backgroundColor: mode('red.700', 'red.400')(props),
          },
          _disabled: {
            backgroundColor: mode('red.500', 'red.300')(props),
            color: '#efefef'
          },
        }),
        boldOutline: (props) => {
          const { colorScheme: c } = props
          const borderColor = mode(`gray.200`, `whiteAlpha.300`)(props)
          return {
            border: "2px solid",
            borderColor: c === "gray" ? borderColor : "currentColor",
            ".chakra-button__group[data-attached] > &:not(:last-of-type)": {
              marginEnd: "-1px",
            },
            ...variantGhost(props),
          }
        },
      },
      sizes: {
        sm: {
          minHeight:'30px',
          py:1,
          px:2
        },
        md: {
          minHeight:'40px',
          py:'10px',
          px:'15px',
          fontSize: '14px',
          fontWeight: 500, // Normally, it is "semibold"
        }
      }
    }
  }
})
