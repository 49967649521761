import React, { Component } from 'react';
import * as ReactDOM from 'react-dom';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from '../_helpers';
import cn from 'classnames';
import Modal from 'react-modal';
import { formsActions } from '../_actions';

import { FormattedMessage } from 'react-intl';

/*
 * Forms testing with final-form
 */
import { From, Field } from 'react-final-form';


import '../../css/forms.style.css';



class FormBase extends Component{
	constructor(props, refs){
		super(props, refs);
	}

	handleFormChange = (e, selectName) => {
	    const form = {};
	    if (e == null){ // react-select clear
	      form[selectName] = ""
	    }
	    else if (e.target == null){
	      if (Array.isArray(e) ){ // multi select (joinValues = true)
	        form[selectName] = e;
	      } else { // react-select (e = {label: x, value: y}) or datepicker (e = date)
	        form[selectName] = e.value || e;
	      }
	    } else { // other default inputs
	      const { name, value, type, checked } = e.target;
	      if (type == "checkbox"){ // checkbox
	        form[value] = checked
	      } else { // input/textareas
	        form[name] = value;
	      }
	    }

	    this.setState({...form})
	}

	render(){
		return
	}

}

class SimpleForm extends Component {
	render() {

		const {isOpen, handleOnClose, handleOpenQuickPatientForm,
					handleCancel, handleSave, patientIsOpen} = this.props;


		return (
			<Modal
				isOpen={isOpen}
				contentLabel="AppointmentForm"
				shouldCloseonOverlayClick={false}
				className="Content"
				overlayClassName="Overlay"
				onRequestClose={handleOnClose}
				>
					<div className="AppointmentForm ModalForm">
						<div className="ModalForm__Container">
							<div className="ModalForm__Header">
								<FormattedMessage
					              id={'forms.appointment.header'}
					              description= {'Scheduling appt form header'}
					              defaultMessage = {'Appointment'}
					            />
							</div>
							<div className="ModalForm__SubHeader">
								<FormattedMessage
					              id={'forms.appointment.subheader'}
					              description= {'Scheduling appt form subheader'}
					              defaultMessage = {'Quickly add a new appointment'}
					            />
							</div>
							<div className="ModalForm__Inputs AddaPatient">
								<div className="Input Three">
									<div className="Input__Label">
										<FormattedMessage
							              id={'forms.appointment.addpatient'}
							              description= {'patient label'}
							              defaultMessage = {'Add a patient'}
							            />
									</div>
									<input name="patient" className="form-control Input_Text" type="text" onChange={this.handleFormChange} />
								</div>
								<button
									className="Button Button__Stop"
									onClick = {handleOpenQuickPatientForm}
								>
									<FormattedMessage
						              id={'forms.button.new_patient'}
						              description= {'Form Add New Patient Button'}
						              defaultMessage = {'New Patient'}
						            />
								</button>
							</div>
							<div className="ModalForm__Inputs">
								<div className="Input One">
									<div className="Input__Label">
										<FormattedMessage
							              id={'forms.appointment.provider'}
							              description= {'Dentist input Label'}
							              defaultMessage = {'Dentist'}
							            />
										<input name="provider" className="form-control Input_Text" type="text" onChange={this.handleFormChange} />
									</div>
								</div>
							</div>
							<div className="ModalForm__Inputs">
								<div className="Input One">
									<div className="Input__Label">
										<FormattedMessage
							              id={'forms.appointment.notes'}
							              description= {'Notes Label'}
							              defaultMessage = {'Appointment Notes'}
							            />
										<textarea name="notes" className="form-control Input_Text" type="text" onChange={this.handleFormChange} />
									</div>
								</div>
							</div>
							<div className="ModalForm__Inputs">
								<div className="Input Three">
									<div className="Input__Label">
										<FormattedMessage
							              id={'forms.appointment.date'}
							              description= {'Date Label'}
							              defaultMessage = {'Date'}
							            />
										<input name="date" className="form-control Input_Text" type="text" onChange={this.handleFormChange} />
							        </div>
							    </div>
								<div className="Input Three">
									<div className="Input__Label">
										<FormattedMessage
							              id={'forms.appointment.starttime'}
							              description= {'Start Time Label'}
							              defaultMessage = {'Start Time'}
							            />
										<input name="start_time" className="form-control Input_Text" type="text" onChange={this.handleFormChange} />
							        </div>
							    </div>
								<div className="Input Three">
									<div className="Input__Label">
										<FormattedMessage
							              id={'forms.appointment.endtime'}
							              description= {'End Time Label'}
							              defaultMessage = {'End Time'}
							            />
										<input name="end_time" className="form-control Input_Text" type="text" onChange={this.handleFormChange} />
									</div>
								</div>
							</div>
							<div className="ModalForm__Buttons">
								<button
									className="Button Button__Cancel"
									onClick = {handleCancel}
								>
									<FormattedMessage
										id={'forms.button.cancel'}
										description= {'Form Cancel Button'}
										defaultMessage = {'CANCEL'} />
								</button>
								<button
									className="Button Button__Save"
									onClick = {handleSave}
								>
									<FormattedMessage
										id={'forms.button.save'}description= {'Form Save Button'}
										defaultMessage = {'SAVE'} />
								</button>

							</div>
						</div>
					</div>
				</Modal>
		)
	}
}

class BaseAppointmentForm extends FormBase{
	constructor(props, refs){
		super(props);
		this.state = {
			patient: null,
			provider: null,
			notes: "",
			date: null,
			start_time: null,
			end_time: null
		}
	}

	componentDidMount(){
		// probably take on initial props
		this.setState = {...this.props.form}
		console.log("mounting with ", this.props.form)

	}

	render(){

		const { isOpen, handleOnClose, handleSave, handleCancel, handleOpenQuickPatientForm } = this.props;


		return (
			<Modal
				isOpen={isOpen}
				contentLabel="AppointmentForm"
				shouldCloseonOverlayClick={false}
           		className="Content"
           		overlayClassName="Overlay"
           		onRequestClose= {handleOnClose}
			>
				<div className="AppointmentForm ModalForm">
					<div className="ModalForm__Container">
						<div className="ModalForm__Header">
							<FormattedMessage
				              id={'forms.appointment.header'}
				              description= {'Scheduling appt form header'}
				              defaultMessage = {'Appointment'}
				            />
						</div>
						<div className="ModalForm__SubHeader">
							<FormattedMessage
				              id={'forms.appointment.subheader'}
				              description= {'Scheduling appt form subheader'}
				              defaultMessage = {'Quickly add a new appointment'}
				            />
						</div>
						<div className="ModalForm__Inputs AddaPatient">
							<div className="Input Three">
								<div className="Input__Label">
									<FormattedMessage
						              id={'forms.appointment.addpatient'}
						              description= {'patient label'}
						              defaultMessage = {'Add a patient'}
						            />
								</div>
								<input name="patient" className="form-control Input_Text" type="text" onChange={this.handleFormChange} />
							</div>
							<button
								className="Button Button__Stop"
								onClick = {handleOpenQuickPatientForm}
							>
								<FormattedMessage
					              id={'forms.button.new_patient'}
					              description= {'Form Add New Patient Button'}
					              defaultMessage = {'New Patient'}
					            />
							</button>
						</div>
						<div className="ModalForm__Inputs">
							<div className="Input One">
								<div className="Input__Label">
									<FormattedMessage
						              id={'forms.appointment.provider'}
						              description= {'Dentist input Label'}
						              defaultMessage = {'Dentist'}
						            />
									<input name="provider" className="form-control Input_Text" type="text" onChange={this.handleFormChange} />
								</div>
							</div>
						</div>
						<div className="ModalForm__Inputs">
							<div className="Input One">
								<div className="Input__Label">
									<FormattedMessage
						              id={'forms.appointment.notes'}
						              description= {'Notes Label'}
						              defaultMessage = {'Appointment Notes'}
						            />
									<textarea name="notes" className="form-control Input_Text" type="text" onChange={this.handleFormChange} />
								</div>
							</div>
						</div>
						<div className="ModalForm__Inputs">
							<div className="Input Three">
								<div className="Input__Label">
									<FormattedMessage
						              id={'forms.appointment.date'}
						              description= {'Date Label'}
						              defaultMessage = {'Date'}
						            />
									<input name="date" className="form-control Input_Text" type="text" onChange={this.handleFormChange} />
						        </div>
						    </div>
							<div className="Input Three">
								<div className="Input__Label">
									<FormattedMessage
						              id={'forms.appointment.starttime'}
						              description= {'Start Time Label'}
						              defaultMessage = {'Start Time'}
						            />
									<input name="start_time" className="form-control Input_Text" type="text" onChange={this.handleFormChange} />
						        </div>
						    </div>
							<div className="Input Three">
								<div className="Input__Label">
									<FormattedMessage
						              id={'forms.appointment.endtime'}
						              description= {'End Time Label'}
						              defaultMessage = {'End Time'}
						            />
									<input name="end_time" className="form-control Input_Text" type="text" onChange={this.handleFormChange} />
								</div>
							</div>
						</div>
						<div className="ModalForm__Buttons">
							<button
								className="Button Button__Cancel"
								onClick = {handleCancel}
							>
								<FormattedMessage
					              id={'forms.button.cancel'}
					              description= {'Form Cancel Button'}
					              defaultMessage = {'CANCEL'}
					            />
							</button>
							<button
								className="Button Button__Save"
								onClick = {handleSave}
							>
								<FormattedMessage
					              id={'forms.button.save'}
					              description= {'Form Save Button'}
					              defaultMessage = {'SAVE'}
					            />
							</button>
						</div>
					</div>
				</div>
			</Modal>
		)
	}
}

class QuickPatientForm extends FormBase{
	constructor(props, refs){
		super(props);
		this.state = {
			first_name: "",
			middle_name: "",
			last_name: "",
			address: "",
			gender: "",
			date_of_birth: "",
			phone_number: "",
			email_address: "",
			notes: ""
		}
	}

	componentDidMount(){
		// probably take on initial props
		this.setState = {...this.props.form}

	}

	handleSave = () => {
		/* send to server with service */

	}

	render(){

		const { isOpen, handleOnClose , handleSave, handleCancel } = this.props;

		/* returns errors after trying to post */

		return (
			<Modal
				isOpen={isOpen}
				contentLabel="QuickPatientForm"
				shouldCloseonOverlayClick={false}
           		className="Content"
           		overlayClassName="Overlay"
           		onRequestClose= {handleOnClose}
			>
				<div className="QuickPatientForm ModalForm">
					<div className="ModalForm__Container">
						<div className="ModalForm__Header">
							<FormattedMessage
				              id={'forms.quickpatient.header'}
				              description= {'Scheduling quick patient form header'}
				              defaultMessage = {'Add New Patient'}
				            />
						</div>
						<div className="ModalForm__SubHeader">
							<FormattedMessage
				              id={'forms.quickpatient.subheader'}
				              description= {'Scheduling quick patient form subheader'}
				              defaultMessage = {'Quickly add a new patient'}
				            />
						</div>

						<div className="ModalForm__Inputs Three">
							<div className="Input Three">
								<div className="Input__Label">
									<FormattedMessage
						              id={'forms.quickpatient.first_name'}
						              description= {'First Name'}
						              defaultMessage = {'first_name'}
						            />
									<input name="first_name" className="form-control Input_Text" type="text" onChange={this.handleFormChange} />
						        </div>
						    </div>
							<div className="Input Three">
								<div className="Input__Label">
									<FormattedMessage
						              id={'forms.quickpatient.middle_name'}
						              description= {'Middle Name Label'}
						              defaultMessage = {'Middle Name'}
						            />
									<input name="middle_name" className="form-control Input_Text" type="text" onChange={this.handleFormChange} />
						        </div>
						    </div>
							<div className="Input Three">
								<div className="Input__Label">
									<FormattedMessage
						              id={'forms.quickpatient.last_name'}
						              description= {'Last Name Label'}
						              defaultMessage = {'Last Name'}
						            />
									<input name="last_name" className="form-control Input_Text" type="text" onChange={this.handleFormChange} />
								</div>
							</div>
						</div>

						<div className="ModalForm__Inputs Three">
							<div className="Input Three">
								<div className="Input__Label">
									<FormattedMessage
						              id={'forms.quickpatient.date_of_birth'}
						              description= {'Date of Birth Label'}
						              defaultMessage = {'Date of Birth'}
						            />
									<input name="date_of_birth" className="form-control Input_Text" type="text" onChange={this.handleFormChange} />
						        </div>
						    </div>
							<div className="Input Three">
								<div className="Input__Label">
									<FormattedMessage
						              id={'forms.quickpatient.phone_number'}
						              description= {'Phone Number Label'}
						              defaultMessage = {'Phone Number'}
						            />
									<input name="phone_number" className="form-control Input_Text" type="text" onChange={this.handleFormChange} />
						        </div>
						    </div>
							<div className="Input Three">
								<div className="Input__Label">
									<FormattedMessage
						              id={'forms.quickpatient.email_address'}
						              description= {'Email Address Label'}
						              defaultMessage = {'Email Address'}
						            />
									<input name="email_address" className="form-control Input_Text" type="text" onChange={this.handleFormChange} />
								</div>
							</div>
						</div>

						<div className="ModalForm__Buttons">
							<button
								className="Button Button__Cancel"
								onClick = {handleCancel}
							>
								<FormattedMessage
					              id={'forms.button.cancel'}
					              description= {'Form Cancel Button'}
					              defaultMessage = {'CANCEL'}
					            />
							</button>
							<button
								className="Button Button__Save"
								onClick = {handleSave}
							>
								<FormattedMessage
					              id={'forms.button.save'}
					              description= {'Form Save Button'}
					              defaultMessage = {'SAVE'}
					            />
							</button>

						</div>
					</div>
				</div>
			</Modal>
		)

	}
}

class FullAppointmentForm extends FormBase{
	constructor(props, refs){
		super(props);
		this.state = {
			patient: null,
			provider: null,
			notes: "",
			date: null,
			start_time: null,
			end_time: null,
			patientIsOpen: false
		}
	}

	componentDidMount(){
		// probably take on initial props
		this.setState = {...this.props.apptForm}

	}

	componentWillReceiveProps(nextProps){

	}

	handleAppointmentSave = () => {
		this.props.dispatch( formsActions.createAppointment() );
	}
	handleAppointmentCancel = () => {
	}
	handleNewPatientSave = () => {
		this.props.dispatch( formsActions.createPatient());
	}
	handlePatientNewPatientOpen = () => {
		this.props.dispatch( formsActions.openPatientForm(""));
	}
	patientHandleOpen = () => {
		this.setState({...this.state, patientIsOpen : true})
	}

	render(){

		const { apptIsOpen, apptHandleOnClose, apptHandleSave, apptHandleCancel,
				patientHandleOnClose, patientHandleSave, patientHandleCancel } = this.props;

		return(
			<div>
				<BaseAppointmentForm
					isOpen = {apptIsOpen}
					handleOnClose = {apptHandleOnClose}
					handleSave = {apptHandleSave}
					handleCancel = {apptHandleCancel}
					handleOpenQuickPatientForm = {this.patientHandleOpen}
				/>
				<QuickPatientForm
					isOpen = {this.state.patientIsOpen}
					handleOnClose = {patientHandleOnClose}
					handleSave = {patientHandleSave}
					handleCancel = {patientHandleCancel}
				/>
			</div>
		)

	}

}


function mapStateToProps(state) {
    const { forms, notifications } = state;
    return {
        forms,
        notifications
    };
}

const connectedFullAppointmentForm = connect(mapStateToProps)(FullAppointmentForm);
export { connectedFullAppointmentForm as AppointmentForm };
