import { authHeader, handleResponse } from '../_helpers';
import { BASE_URL, API_URLS } from '../_config';

export const settingsService = {
  getVersioning,
    loadConfig,
    preloadFormData,
    getProviders,
    getPatients,
    getOperatories,
    getDentalAccounts,
    doSearch
};

function getVersioning(){
  const requestUrl = API_URLS.getVersioning;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function loadConfig(){
  const requestUrl = API_URLS.getConfig;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}


function doSearch(query){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/dental_practice/search?q='+query;
    console.log("service.dosearch")
    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};

function preloadFormData(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/dental_practice/search/procedurecodes';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};

function getProviders(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/dental_practice/search/dentists';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};

function getOperatories(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/dental_practice/search/operatories';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};

function getDentalAccounts(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/dental_practice/search/accounts';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};

function getPatients(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/dental_practice/search/patients';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};

function getFeeSchedules(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/dental_practice/search/accounts';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};

function getCoverageTables(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/dental_practice/search/accounts';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};

function getInsuranceClaim(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/dental_practice/search/accounts';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};

function getProcedureCodes(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/dental_practice/search/accounts';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};

function getLedgerOptions(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/dental_practice/search/accounts';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};

function getPatientCommunications(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/dental_practice/search/accounts';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};
function getPrintSettings(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/dental_practice/search/accounts';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};

function getRecare(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/dental_practice/search/accounts';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};
