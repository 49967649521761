const PREFIX = 'TREATMENTPLAN_';
export const treatmentplanConstants = {
  GET_SMILEMODELS_BASE: `${PREFIX}GET_SMILEMODELS`,
  GET_SMILEMODELS_REQUEST: `${PREFIX}GET_SMILEMODELS_REQUEST`,
  GET_SMILEMODELS_SUCCESS: `${PREFIX}GET_SMILEMODELS_SUCCESS`,
  GET_SMILEMODELS_FAILURE: `${PREFIX}GET_SMILEMODELS_FAILURE`,


  GET_TREATMENTPLANDETAILS_BASE: `${PREFIX}GET_TREATMENTPLANDETAILS`,
  GET_TREATMENTPLANDETAILS_REQUEST: `${PREFIX}GET_TREATMENTPLANDETAILS_REQUEST`,
  GET_TREATMENTPLANDETAILS_SUCCESS: `${PREFIX}GET_TREATMENTPLANDETAILS_SUCCESS`,
  GET_TREATMENTPLANDETAILS_FAILURE: `${PREFIX}GET_TREATMENTPLANDETAILS_FAILURE`
}
