import {consultRequestsConstants} from '../_constants'

const testResults = [
  {
    "patient_name": "21094992323",
    "created_at" : "2019/01/01",
    "patient_start_date": "2019/01/10",
    "total_steps": 8 ,
    "currentStep": 2,
    "consultation": 1 ,
    "description": "Deeop bite",
    "payment_status": "Pending",
    "status": "Pending"
  },
  {
    "patient_name": "743594992323",
    "created_at" : "2019/01/03",
    "patient_start_date": "2019/01/12",
    "total_steps": 8 ,
    "currentStep": 1,
    "consultation": 1 ,
    "description": "Slight overbite",
    "payment_status": "Pending",
    "status": "In Process"
  },
  {
    "patient_name": "21094992323",
    "created_at" : "2018/12/18",
    "patient_start_date": "2018/12/24",
    "total_steps": 8 ,
    "currentStep": 4,
    "consultation": 1 ,
    "description": "Deep bite",
    "payment_status": "Pending",
    "status": "Pending"
  }

]

const initState = {
  consultRequestSearchInput: "",
  consultRequestFormLoading: false,
  consultRequestFormError: "",
  consultRequestLoadMoreOffset: 0,
  consultRequestSearchResults: [], //[]
  consultRequestTotalResults: 0 //""
}

export function consultRequests(state = initState, action){
  switch (action.type){
    case consultRequestsConstants.SEARCH_CONSULTREQUESTS_REQUEST:
      return {
        ...state,
        consultRequestFormLoading: true,
        consultRequestFormError: ""
      }

    case consultRequestsConstants.SEARCH_CONSULTREQUESTS_SUCCESS:
      const initLoad = action.data.offset == 0;
      return {
        ...state,
        consultRequestFormLoading: false,
        consultRequestFormError: "",
        //consultRequestSearchResults: (initLoad) ? action.data.request_list : [...state.consultRequestSearchResults, ...action.data.request_list],
        consultRequestSearchResults: action.data.requests_list,
        consultRequestTotalResults: action.data.total_count,
        consultRequestLoadMoreOffset: action.data.offset + 1,
        //patientHasMoreLoad: (action.data.patient_list.length == action.data.pg_sz)
      }

    case consultRequestsConstants.SEARCH_CONSULTREQUESTS_FAILURE:
      return {
        ...state,
        consultRequestFormLoading: false,
        consultRequestFormError: ""
      }

    case consultRequestsConstants.CHANGE_CONSULTREQUESTINPUT:
      return {
        ...state,
        consultRequestSearchInput: action.data
      }

    default:
      return state;

  }
}
