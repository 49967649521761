import PropTypes from 'prop-types';
import React from 'react';
import dates from 'react-big-calendar/lib/utils/dates';
import localizer from 'react-big-calendar/lib/localizer';
import { navigate as navTo} from 'react-big-calendar/lib/utils/constants';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';

function threeDayRange(date) {
  return dates.range(dates.add(date, -1,'day'), dates.add(date, 1,'day'))
}


class ThreeDayPage extends React.Component {


  range(date){
    return dates.range(dates.add(date, -1,'day'), dates.add(date, 1,'day'))
  }

  render() {
    let { date, ...props } = this.props
    let range = threeDayRange(date)

    return (
        <TimeGrid {...props} range={range} eventOffset={15} />
    );
  }
}

ThreeDayPage.defaultProps = TimeGrid.defaultProps;


ThreeDayPage.navigate = (date, action) => {
  switch (action){
    case navTo.PREVIOUS:
      return dates.add(date, -3, 'day');

    case navTo.NEXT:
      return dates.add(date, 3, 'day')

    default:
      return date;
  }
}

ThreeDayPage.title = (date, { formats, culture }) => {
  console.log(date)
  let [start, ...rest] = threeDayRange(date, { culture })
  console.log(start)
  console.log(rest)
  return localizer.format(
    { start, end: rest.pop() },
    formats.dayRangeHeaderFormat,
    culture
  );
}

ThreeDayPage.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
};

export {ThreeDayPage};