import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Flex,
  CloseButton,
} from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { motion } from 'framer-motion';
import { useEffect } from 'react';

const MotionAlert = motion(Alert);

const notificationProps = {
  completed: {
    alertProps : {
      background: '#D1EBE8',
      status: 'success'
    },
    alertIconProps: {
      color: '#179b8b',
    },
  }, 
  updated : {
    alertProps : {
      background: '#DBECFC',
      status: 'success'
    },
    alertIconProps: {
      color: '#4AA1F2',
    },
  }, 
  neutral : {
    alertProps : {
      background: '#F1F2F4',
      status: 'success'
    },
    alertIconProps: {
      color: '#8B9BA6',
    },
  }, 
  error : {
    alertProps : {
      background: '#7B8887',
      status: 'error'
    },
    alertIconProps: {
      color: '#FF645A',
    },
  }
}

const NotificationItem = ({notification, onDismiss, alertProps, alertIconProps}) => {
  let backgroundColor = '#f1f2f4'
  let iconColor = '#8B9BA6'
  let notifType = notification?.type ? notification?.type : 'success';
  if (notification?.type === 'error'){
    backgroundColor = '#FCE5E4';
    iconColor = '#FF645A';
  }

  return (
    <MotionAlert
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      status={notifType}
      pl={'20px'}
      pr={'24px'}
      py={['10px', '20px']}
      borderRadius={'10px'}
      w={'max-content'}
      boxShadow={'0px 2px 4px 0px #3A444A3D'}
      bg={backgroundColor}
      {...notification?.alertProps}
    >
        <AlertIcon boxSize={"28px"} color={iconColor} {...notification?.alertIconProps} />
        <Flex align={'flex-start'} direction={"column"} pl={[0, "16px"]} pr={[0, "40px"]}>
          <AlertTitle color={"#303030"}  fontFamily={'Poppins'} fontSize={["14px"]} fontWeight={'500'} >{notification?.title}</AlertTitle>
          <AlertDescription mt={'6px'} color={"#303030"} fontFamily={'Poppins'}  fontWeight={'400'} >{notification?.message}</AlertDescription>
        </Flex>
        <CloseButton
          color={"#7B8887"}
          boxSize={"14px"}
          onClick={() => {
            onDismiss(notification?.id);
          }}
        />
    </MotionAlert>
  )
}

const AppointmentRequestNotificationItem = ({ notification, onDismiss }) => {
  const { formatMessage } = useIntl();
  const extraAlertProps = notificationProps['neutral'];
  return (
    <NotificationItem 
      notification={{
        type: 'success',
        title: formatMessage({
          id: 'appointmentrequest.notification.title.created',
          defaultMessage: 'Appointment Request'
        }),
        message: formatMessage({
          id: 'appointmentrequest.notification.message.created',
          defaultMessage: "You have a new appointment request"
        }),
        ...extraAlertProps
      }}
      onDismiss={onDismiss}
    />
  )
}  

const AppointmentNotificationItem = ({ notification, onDismiss }) => {
  const { formatMessage } = useIntl();
  const messageAppointmentCompleted = formatMessage(
      {
          id: 'appointment.notification.message.status.completed',
          defaultMessage: "{first_name} {last_name}'s appointment has been completed."
      }, { first_name: notification.first_name, last_name: notification.last_name }
    );
  
  const messageAppointmentOngoing = formatMessage(
      {
          id: 'appointment.notification.message.status.in_progress',
          defaultMessage: "{first_name} {last_name}'s appointment has started."
      }, { first_name: notification.first_name, last_name: notification.last_name }
  ); 
  
  const messageAppointmentCheckedin = formatMessage(
      {
          id: 'appointment.notification.message.status.checked_in',
          defaultMessage: "{first_name} {last_name} has checked in."
      }, 
      { first_name: notification.first_name, last_name: notification.last_name }
  ); 

  const messageAppointmentCanceled = formatMessage(
    {
        id: 'appointment.notification.message.status.canceled',
        defaultMessage: "{first_name} {last_name}'s appointment has been canceled."
    }, 
    { first_name: notification.first_name, last_name: notification.last_name }
  ); 

  const messageAppointmentNoShow = formatMessage(
    {
        id: 'appointment.notification.message.status.noshow',
        defaultMessage: "{first_name} {last_name} did not show up."
    }, 
    { first_name: notification.first_name, last_name: notification.last_name }
  ); 

  const messageAppointmentDefault = formatMessage(
    {
        id: 'appointment.notification.message.default',
        defaultMessage: "Appointment has been updated"
    }, 
    { first_name: notification.first_name, last_name: notification.last_name }
  ); 

  const notificationTitleStatusCompleted= formatMessage({
      id: 'appointment.notification.title.status.completed',
      defaultMessage: "Appointment Finished"
  }); 

  const notificationTitleStatusOngoing = formatMessage({
      id: 'appointment.notification.title.status.in_progress',
      defaultMessage: "Appointment Started"
  }); 

  const notificationTitleStatusCheckedin = formatMessage({
      id: 'appointment.notification.title.status.checked_in',
      defaultMessage: "Checked In"
  }); 

  const notificationTitleStatusCanceled = formatMessage({
      id: 'appointment.notification.title.status.canceled',
      defaultMessage: "Appointment Canceled"
  }); 

  const notificationTitleStatusNoshow = formatMessage({
      id: 'appointment.notification.title.status.noshow',
      defaultMessage: "Marked as No Show"
  }); 

  const notificationTitleStatusUpdated = formatMessage({
      id: 'appointment.notification.title.status',
      defaultMessage: "Appointment Status Updated!"
  }); 

  const notificationTitleDefault = formatMessage( {
      id: 'appointment.notification.title.status',
      defaultMessage: "Appointment Updated!"
  }); 

  let appointmentNotification = {}

  if ("status" in notification){
      let iconType = 'success';
      let notificationMessage = messageAppointmentDefault;
      let notificationTitle = notificationTitleStatusUpdated;
      let backgroundColor = '#f1f2f4'
      let iconColor = '#8B9BA6'
      if (notification.status === 'COMPLETED') {
          iconType = 'success';
          notificationMessage = messageAppointmentCompleted;
          notificationTitle = notificationTitleStatusCompleted;
          backgroundColor = '#D1EBE8';
          iconColor = '#179b8b';
      } else if (notification.status === 'IN_PROGRESS') {
          iconType = 'success';
          notificationMessage = messageAppointmentOngoing;
          notificationTitle = notificationTitleStatusOngoing;
          backgroundColor = '#DBECFC';
          iconColor = '#4aa1f2';
      } else if (notification.status === 'CHECKED_IN') {
          iconType = 'success';
          notificationMessage = messageAppointmentCheckedin;
          notificationTitle = notificationTitleStatusCheckedin;
          backgroundColor = '#FEF7EB';
          iconColor = '#fbbe62';
      } else if (notification.status === 'CANCELED') {
          iconType = 'error';
          notificationMessage = messageAppointmentCanceled;
          notificationTitle = notificationTitleStatusCanceled;
          backgroundColor = '#FCE5E4';
          iconColor = '#FF645A';
      } else if (notification.status === 'NOSHOW') {
          iconType = 'error';
          notificationMessage = messageAppointmentNoShow;
          notificationTitle = notificationTitleStatusNoshow;
          backgroundColor = '#FCE5E4';
          iconColor = '#FF645A';
      }
  
      appointmentNotification = {
          type: 'success',
          title: notificationTitle,
          message: notificationMessage,
          alertProps: {
            background: backgroundColor,
            status: iconType
          },
          alertIconProps: {
            color: iconColor,
          }
      }
  } else{
      appointmentNotification =  {
          
          type: 'success',
          title: notificationTitleDefault,
          message: messageAppointmentDefault
      }
  }

  return (
      <NotificationItem
          notification={{...notification, ...appointmentNotification}}
          onDismiss={onDismiss}
      />
  )
}

export const Notification = ({
  notification,
  onDismiss,
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onDismiss(notification?.id);
    }, notification?.timeout || 5000);
    return () => {clearTimeout(timer)};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);


  if (notification?.type === 'appointment_status_update'){
    return (
        <AppointmentNotificationItem notification={notification} onDismiss={onDismiss} />
    )
  } else if (notification?.type === 'appointment_request_created') {
    return (
        <AppointmentRequestNotificationItem notification={notification} onDismiss={onDismiss} />
    )
  } else {
    return (
        <NotificationItem notification={notification} onDismiss={onDismiss} />
    );
  }

};
