import {
  Flex,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  FormErrorMessage,
  InputLeftAddon,
  InputLeftElement,
  InputRightElement,
  InputRightAddon,
  InputGroup,
} from "@chakra-ui/react";
import { AdminHelpPopover } from "./AdminHelpPopover";
import { isFieldErrorfromMeta, getErrorMessage } from "../../_libs/forms";
import { useIntl } from "react-intl";

export const SchedulingInputField = ({
  required,
  input,
  meta,
  label,
  helpheader = null,
  isEmptyError = true,
  helpbody = null,
  onChange = null,
  fontSize = "11px",
  isReadOnly = false,
  isDisabled = false,
  placeholder = null,
  type = "text",
  value,
  ...props
}) => {
  const labelIsInvisible = label === " ";
  console.log(value, input, "valuevaluevaluevaluevaluevalue");
  return (
    <FormControl
      id={input.name}
      isInvalid={isFieldErrorfromMeta(meta)}
      {...props}
    >
      {(label || helpbody || helpheader) && (
        <FormLabel
          textTransform={"capitalize"}
          letterSpacing={0}
          align="center"
          fontSize={fontSize}
          pb={"0px"}
          visibility={labelIsInvisible ? "hidden" : "visible"}
        >
          {labelIsInvisible ? "default" : label}
          {(helpbody || helpheader) && (
            <AdminHelpPopover header={helpheader} body={helpbody} />
          )}
        </FormLabel>
      )}
      <Input
        {...input}
        type={type}
        isReadOnly={isReadOnly}
        isDisabled={isDisabled}
        minH="40px"
        fontSize={fontSize}
        background="white"
        borderRadius={"10px"}
        onChange={onChange ? onChange : input.onChange}
        // border={"0.5px solid rgba(123, 136, 135, 0.50)"}
        _placeholder={{
          color: "#7b8886",
        }}
        _disabled={{
          cursor: "default",
          borderColor: "#7B888780",
        }}
        _active={{
          boxShadow: "0 0 0 0.1px #179B8B",
          borderColor: "#179B8B",
        }}
        _focus={{
          boxShadow: "0 0 0 0.1px #179B8B",
          borderColor: "#179B8B",
        }}
        _invalid={{
          boxShadow: "0 0 0 0.1px red",
          borderColor: "red",
        }}
        color="#7B8887"
        placeholder={placeholder ? placeholder : label}
        {...props.inputProps}
        value={value ? value : input.value}
        required={required}
      />
      <Flex
        height={isEmptyError || !getErrorMessage(meta) ? "10px" : "30px"}
        align={"center"}
      >
        {getErrorMessage(meta) ? (
          <FormErrorMessage>{getErrorMessage(meta)}</FormErrorMessage>
        ) : (
          <FormHelperText></FormHelperText>
        )}
      </Flex>
    </FormControl>
  );
};

export const InputField = ({
  input,
  meta,
  label,
  helpheader = null,
  helpbody = null,
  fontSize = "14px",
  labelColor = "#12171a",
  isDisabled = false,
  onChange = null,
  placeholder = null,
  type = "text",
  inputLeftAddon = null,
  inputRightAddon = null,
  inputLeftElement = null,
  inputRightElement = null,
  ...props
}) => {
  const { formatMessage } = useIntl();
  if (
    inputLeftAddon ||
    inputRightAddon ||
    inputLeftElement ||
    inputRightElement
  ) {
    return (
      <FormControl
        id={input.name}
        isInvalid={isFieldErrorfromMeta(meta)}
        {...props}
      >
        {(label || helpbody || helpheader) && (
          <FormLabel
            color={labelColor}
            align="center"
            fontSize={fontSize}
            pb={1}
            textTransform={"capitalize"}
          >
            {label}
            {(helpbody || helpheader) && (
              <AdminHelpPopover header={helpheader} body={helpbody} />
            )}
          </FormLabel>
        )}
        <InputGroup>
          {inputLeftAddon && (
            <InputLeftAddon minH="40px" children={inputLeftAddon} />
          )}
          {inputLeftElement && (
            <InputLeftElement minH="40px" children={inputLeftElement} />
          )}
          <Input
            {...input}
            type={type}
            isDisabled={isDisabled}
            minH="40px"
            fontSize={fontSize}
            background="white"
            _placeholder={{textTransform: "capitalize"}}
            placeholder={placeholder || label}
            onChange={onChange ? onChange : input.onChange}
            {...props.inputProps}
            _active={{
              boxShadow: "0 0 0 0.1px #179B8B",
              borderColor: "#179B8B",
            }}
            _focus={{
              boxShadow: "0 0 0 0.1px #179B8B",
              borderColor: "#179B8B",
            }}
            _invalid={{
              boxShadow: "0 0 0 0.1px red",
              borderColor: "red",
            }}
            color="#7B8887"
          />
          {inputRightAddon && (
            <InputRightAddon minH="40px" children={inputRightAddon} />
          )}
          {inputRightElement && (
            <InputRightElement minH="40px" children={inputRightElement} />
          )}
        </InputGroup>
        {getErrorMessage(meta) ? (
          <FormErrorMessage>
            {formatMessage({
              id: getErrorMessage(meta),
              defaultMessage: "error",
            })}
          </FormErrorMessage>
        ) : (
          <FormHelperText></FormHelperText>
        )}
      </FormControl>
    );
  } else {
    return (
      <FormControl
        id={input.name}
        isInvalid={isFieldErrorfromMeta(meta)}
        {...props}
      >
        {(label || helpbody || helpheader) && (
          <FormLabel
            color={labelColor}
            align="center"
            fontSize={fontSize}
            pb={1}
            textTransform={"capitalize"}
          >
            {label}
            {(helpbody || helpheader) && (
              <AdminHelpPopover header={helpheader} body={helpbody} />
            )}
          </FormLabel>
        )}
        <Input
          {...input}
          type={type}
          isDisabled={isDisabled}
          minH="40px"
          fontSize={fontSize}
          background="white"
          _placeholder={{textTransform: "capitalize"}}
          placeholder={placeholder || label}
          onChange={onChange ? onChange : input.onChange}
          {...props.inputProps}
          _active={{
            boxShadow: "0 0 0 0.1px #179B8B",
            borderColor: "#179B8B",
          }}
          _focus={{
            boxShadow: "0 0 0 0.1px #179B8B",
            borderColor: "#179B8B",
          }}
          _invalid={{
            boxShadow: "0 0 0 0.1px red",
            borderColor: "red",
          }}
          color="#7B8887"
        />
        {getErrorMessage(meta) ? (
          <FormErrorMessage>
            {formatMessage({
              id: getErrorMessage(meta),
              defaultMessage: "error",
            })}
          </FormErrorMessage>
        ) : (
          <FormHelperText></FormHelperText>
        )}
      </FormControl>
    );
  }
};
