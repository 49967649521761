import { Flex, Text } from "@chakra-ui/react";
import { ClinicSearchNoInput as ClinicSearch } from "./ClinicSearchNoInput";
import { FormattedMessage, useIntl } from "react-intl";
import { useAdminClinicStore } from "js/AdminPage/stores/adminClinic";

export const AdminClinicSearch = ({isInAdminMode, isAdminPage}) => {
    const { formatMessage } = useIntl();
    //const { adminClinic, setAdminClinic } = useAdminClinicStore.getState();
    const dpid = useAdminClinicStore(state => state.dpid);
    const adminClinic = useAdminClinicStore(state => state.adminClinic);
    const setAdminClinic = useAdminClinicStore(state => state.setAdminClinic);

    const handleClinicChange = ( val) => {
        console.log("handleClinicChange", val)
        //input.onChange(val);
        // update dpid
        setAdminClinic(val)
    }

    console.log("adminclinic ", adminClinic, dpid)

    return (
        <Flex minW={'480px'} align={'center'}>
            <Text mr={['10px']} minW={'100px'} align="center" pb={1} fontWeight={500} color={"#12171a"}>
                <FormattedMessage 
                    id="admin.appointments.adminclinicsearch.label"
                    defaultMessage="Current Clinic"
                />
            </Text>
            <ClinicSearch 
                name="dpid"
                value={adminClinic}
                isInAdminMode={isInAdminMode}
                isAdminPage={isAdminPage}
                placeholder={formatMessage({id: 'admin.appointments.adminclinicsearch.placeholder', defaultMessage: 'Select Clinic'})}
                onSelect={(val) => {handleClinicChange( val)}} 
            />
        </Flex>
    )
}