import { activityLogConstants } from '../_constants';
import { activityLogService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const activityLogActions = {
    getActivityLog
};

function getActivityLog(){
  return (dispatch, getState) => {
    dispatch(request());

    activityLogService.getActivityLog()
      .then(
        data => dispatch(success(data)),
        error => dispatch(failure(error))
      )
  }
  function request() { return {type: activityLogConstants.GET_ACTIVITYLOG_REQUEST} };
  function success(data) { return {type: activityLogConstants.GET_ACTIVITYLOG_SUCCESS, data} };
  function failure(error) { return {type: activityLogConstants.GET_ACTIVITYLOG_FAILURE, error} }
}
